// @flow

import { themeSkillYoga } from './themeSkillYoga';

const headlineStyles = Object.freeze({
  defaults: {
    marginTop: 0,
    marginBottom: 0,
    color: themeSkillYoga.colors.WHITE_COLOR,
    fontStretch: 'normal',
    fontStyle: 'normal',
    fontWeight: 400,
  },

  get h0() {
    return {
      ...this.defaults,
      fontSize: themeSkillYoga.fontSize(28),
      lineHeight: 1.3,
      letterSpacing: 1,
      fontFamily: themeSkillYoga.fonts.MEDIUM,

      [themeSkillYoga.getBreakpoint('SM')]: {
        fontSize: themeSkillYoga.fontSize(54),
        lineHeight: 1.2,
        letterSpacing: 0.81,
      },
    };
  },

  get h1() {
    return {
      ...this.defaults,
      fontSize: themeSkillYoga.fontSize(28),
      lineHeight: 1.3,
      letterSpacing: 1,
      fontFamily: themeSkillYoga.fonts.MEDIUM,

      [themeSkillYoga.getBreakpoint('SM')]: {
        fontSize: themeSkillYoga.fontSize(54),
        lineHeight: 1.2,
        letterSpacing: 0.81,
      },
    };
  },

  get h2() {
    return {
      ...this.defaults,
      fontSize: themeSkillYoga.fontSize(24),
      lineHeight: 1.375,
      letterSpacing: 0.12,
      fontFamily: themeSkillYoga.fonts.MEDIUM,

      [themeSkillYoga.getBreakpoint('SM')]: {
        fontSize: themeSkillYoga.fontSize(36),
        lineHeight: 1.25,
        letterSpacing: 0.54,
      },
    };
  },

  get h3() {
    return {
      ...this.defaults,
      fontSize: themeSkillYoga.fontSize(22),
      lineHeight: 1.41,
      letterSpacing: 0.11,
      fontFamily: themeSkillYoga.fonts.MEDIUM,

      [themeSkillYoga.getBreakpoint('SM')]: {
        fontSize: themeSkillYoga.fontSize(30),
        lineHeight: 1.47,
        letterSpacing: 0.75,
      },
    };
  },

  get h4() {
    return {
      ...this.defaults,
      fontSize: themeSkillYoga.fontSize(20),
      lineHeight: 1.25,
      letterSpacing: 0.4,
      fontFamily: themeSkillYoga.fonts.MEDIUM,

      [themeSkillYoga.getBreakpoint('SM')]: {
        fontSize: themeSkillYoga.fontSize(24),
        lineHeight: 1.5,
        letterSpacing: 0.6,
      },
    };
  },

  get h5() {
    return {
      ...this.defaults,
      fontSize: themeSkillYoga.fontSize(17),
      lineHeight: 1.47,
      letterSpacing: 0.34,
      fontFamily: themeSkillYoga.fonts.MEDIUM,

      [themeSkillYoga.getBreakpoint('SM')]: {
        fontSize: themeSkillYoga.fontSize(20),
        lineHeight: 1.55,
        letterSpacing: 0.5,
      },
    };
  },

  get h6() {
    return {
      ...this.defaults,
      fontSize: themeSkillYoga.fontSize(15),
      lineHeight: 1.73,
      letterSpacing: 0.08,

      [themeSkillYoga.getBreakpoint('SM')]: {
        fontSize: themeSkillYoga.fontSize(16),
        lineHeight: 1.5,
        letterSpacing: 0.6,
      },
    };
  },

  get h7() {
    return {
      ...this.defaults,
      fontSize: themeSkillYoga.fontSize(13),
      lineHeight: 1.73,
      letterSpacing: 0.08,

      [themeSkillYoga.getBreakpoint('SM')]: {
        fontSize: themeSkillYoga.fontSize(12),
        lineHeight: 1.5,
        letterSpacing: 0.6,
      },
    };
  },

  get h8() {
    return {
      ...this.defaults,
      fontSize: themeSkillYoga.fontSize(10),
      lineHeight: 1.73,
      letterSpacing: 0.08,

      [themeSkillYoga.getBreakpoint('SM')]: {
        fontSize: themeSkillYoga.fontSize(8),
        lineHeight: 1.5,
        letterSpacing: 0.6,
      },
    };
  },
});

export default headlineStyles;
