import Slider from 'react-slick';
import styled from 'styled-components';
import Caleb01Svg from '../../assets/svg/caleb-01-icon.svg';
import Caleb02Svg from '../../assets/svg/caleb-02-icon.svg';
import Caleb03Svg from '../../assets/svg/caleb-03-icon.svg';
import Caleb04Svg from '../../assets/svg/caleb-04-icon.svg';
import {
  CSS_BREAKPOINTS,
  CSS_BREAKPOINTS_MIN,
  GUTTER_SIZE,
} from '../../config/constants';
import { TitleElement } from '../text/CohortLp/CohortLpTextElements';

const Container = styled.section`
  padding: ${GUTTER_SIZE * 2}px 0 ${GUTTER_SIZE * 4}px;

  @media ${CSS_BREAKPOINTS_MIN.sm} {
    margin: 0 -${GUTTER_SIZE * 2}px;
    padding: ${GUTTER_SIZE * 2.5}px 0 ${GUTTER_SIZE * 3.5}px;
  }
`;

const Slide = styled.div`
  position: relative;
  padding: 0 ${GUTTER_SIZE * 2}px;

  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: 26px;
    width: 17px;
  }
`;

const Headline = styled.div`
  font-family: Poppins-Medium, sans-serif;
  font-size: 16px;
  line-height: 26px;
  margin: 0;

  @media ${CSS_BREAKPOINTS_MIN.sm} {
    font-size: 16px;
    line-height: 26px;
  }
`;

const Text = styled.div`
  font-family: Poppins-Light, sans-serif;
  font-size: 15px;
  line-height: 26px;

  @media ${CSS_BREAKPOINTS_MIN.sm} {
    font-size: 16px;
    line-height: 26px;
  }
`;

const TitleContainer = styled.div`
  width: 100%;
  height: 94px;
  padding-top: 18px;
  margin-bottom: 16px;
  background-color: green;
  background: url(${(props) => props.icon}) no-repeat left / cover;
  background-size: auto 94px;
`;

/* eslint-disable max-len */
const LIST = [
  {
    id: 1,
    icon: Caleb01Svg,
    title: 'WEEK 1',
    subtitle: 'Building the Base and Crow',
    text: 'First, we\'ll build the technical and strength foundations required for all of our arm balances; how we use the arms and fingers! Having refined our chaturanga and worked out how to connect our body parts as one, we\'ll move into the most common arm balance; crow. You\'ll also be introduced to a mediation designed to practice throughout the course.',
  },
  {
    id: 2,
    icon: Caleb02Svg,
    title: 'WEEK 2',
    subtitle: 'Hip Opening Arm Balances',
    text: 'Now that we\'ve built some strength in the upper body, we\'ll start to add more weight and complexity to our arm balance via Koundinyasana B and flying pigeon. Both of these poses require some opening of the hips and legs in very different directions. Remember; we\'re building flexibility as well as strength!',
  },
  {
    id: 3,
    icon: Caleb03Svg,
    title: 'WEEK 3',
    subtitle: 'Twisting Arm Balances',
    text: 'A huge benefit of a yoga practice is that your spine gets the opportunity to move in all of the directions in doesn\'t get the chance to in daily life. Arm balancing is no exception and so this week week take on 2 arm balances that require deep spinal rotation; Koundinyasana A and the cool-looking eight angle!',
  },
  {
    id: 4,
    icon: Caleb04Svg,
    title: 'WEEK 4',
    subtitle: 'Forearm Balance and GrassHopper',
    text: 'This week will involve some harder poses, but let go of expectation, don\'t force anything and let this be the beginning of a long term journey. Remember doing part of the pose with integrity, if better than throwing yourself in to the full pose with no control. Forearm balance is a wonderful inversion, requiring external rotator engagement and grasshopper is a complex, spinal twisting, hip opening arm balance.',
  },
];
/* eslint-enable max-len */

const AdamCoursePlan = () => (
  <Container>
    <TitleElement>Course plan</TitleElement>

    <Slider
      arrows={false}
      autoplay
      autoplaySpeed={5000}
      centerMode
      // rem is not an option here, because the slider library doesn't support rem
      centerPadding="0px"
      className="center"
      dots={false}
      slidesToShow={4}
      slidesToScroll={4}
      speed={500}
      responsive={[
        {
          breakpoint: CSS_BREAKPOINTS.sm,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: true,
          },
        },
      ]}
    >
      {
      LIST.map((
        {
          id, icon, title, subtitle, text,
        },
      ) => (
        <Slide key={`slide-cv-${id}`}>
          <TitleContainer icon={icon}>
            <Headline>{title}</Headline>
            <Headline>{subtitle}</Headline>
          </TitleContainer>
          <Text>{text}</Text>
        </Slide>
      ))
      }
    </Slider>
  </Container>
);

export default AdamCoursePlan;
