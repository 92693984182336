import styled from 'styled-components';
import { CLOUDFRONT_STATIC, CSS_BREAKPOINTS_MIN, GUTTER_SIZE } from '../../config/constants';
import AspectRatio from '../AspectRatio';
import { TitleElement } from '../text/CohortLp/CohortLpTextElements';
import InstagramSvg from '../../assets/svg/instagram-logo.svg';
import FacebookSvg from '../../assets/svg/facebook-logo.svg';
import YouTubeSvg from '../../assets/svg/youtube-logo.svg';
import SocialLink from '../SocialLink';
import makeImageUrl from '../../modules/makeImageUrl';

const Container = styled.div`
  padding: ${GUTTER_SIZE * 2}px 0;

  @media ${CSS_BREAKPOINTS_MIN.sm} {
    padding: ${GUTTER_SIZE * 2.5}px 0 ${GUTTER_SIZE * 3.5}px;
  }
`;

const Content = styled.div`
  @media ${CSS_BREAKPOINTS_MIN.sm} {
    display: flex;
    flex-direction: row;
  }
`;

const ImageWrapper = styled.div`
  margin: 0 0 ${GUTTER_SIZE * 2}px;

  @media ${CSS_BREAKPOINTS_MIN.sm} {
    flex: 0 0 40%;
    margin: 0;
    padding-right: 5%;
  }
`;

const TrainerName = styled.h4`
  font-size: 20px;
  line-height: 25px;
  letter-spacing: 0.4px;

  @media ${CSS_BREAKPOINTS_MIN.sm} {
    font-size: 30px;
    line-height: 44px;
    letter-spacing: 0.75px;
  }
`;

const ImageElement = styled(AspectRatio)`
  background: url(${makeImageUrl({ url: `${CLOUDFRONT_STATIC}/img/pages/adam-lp/adam-trainer-image.jpg` })}) no-repeat center / cover;
`;

const SocialLinks = styled.div`
  ${SocialLink} {
    &:first-child {
      padding-left: 0;
    }
  }

  @media ${CSS_BREAKPOINTS_MIN.sm} {
    margin-bottom: ${GUTTER_SIZE}px;
  }
`;

const SocialImg = styled.img`
  height: 21px;

  @media ${CSS_BREAKPOINTS_MIN.sm} {
    height: 31px;
  }
`;

const Text = styled.p`
  margin: 0;
  font-family: Poppins-Light, sans-serif;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: 0.3px;

  &:last-child {
    margin: 0;
  }

  @media ${CSS_BREAKPOINTS_MIN.sm} {
    font-size: 16px;
    line-height: 22px;
  }
`;

const AdamMeetTheCoach = () => (
  <Container>
    <TitleElement>Meet Your Coach</TitleElement>

    <Content>
      <ImageWrapper>
        <ImageElement ratio={1} />
      </ImageWrapper>

      <div>
        <TrainerName>Adam Husler</TrainerName>

        <SocialLinks>
          <SocialLink href="//www.instagram.com/adamhusler/" title="Link to Instagram" target="_blank" rel="noreferrer">
            <SocialImg src={InstagramSvg} alt="Instagram Account" />
          </SocialLink>
          <SocialLink href="//www.facebook.com/ADAMHUSLERYOGA" title="Link to Facebook" target="_blank" rel="noreferrer">
            <SocialImg src={FacebookSvg} alt="Facebook Account" />
          </SocialLink>
          <SocialLink href="//www.youtube.com/c/AdamHuslerUK" title="Link to YouTube channel" target="_blank" rel="noreferrer">
            <SocialImg src={YouTubeSvg} alt="YouTube Account" />
          </SocialLink>
        </SocialLinks>

        <Text>
          Having taught workshops, conferences and trainings in over 60 venues in over 20 countries, Adam Husler brings
          a unique perspective to those who seek more from yoga than making pretty shapes. He offers creative,
          effective and clearly-sequenced teachings that focus on balancing flexibility and strength; physically and mentally.
          Adam specialises in a signature style of Alignment-Based Vinyasa— fuelled by a fascination with anatomy and a desire to
          ask ‘why?’. Adam is a writer for Yoga Journal and co-hosts the Honestly Unbalanced podcast. His home studios in
          London are the industry leaders, triyoga and Equinox.
        </Text>
      </div>
    </Content>
  </Container>
);

export default AdamMeetTheCoach;
