// @flow

import styled from 'styled-components';
import { createRef } from 'react';
import _get from 'lodash/get';
import AbstractView from '../../AbstractView';
import {
  BLACK_COLOR_SEMI_TRANS,
  CLOUDFRONT_STATIC, COHORT_TRAINING_LANDINGPAGE_VIEW, CSS_BREAKPOINTS_MIN, GUTTER_SIZE,
} from '../../../config/constants';
import makeImageUrl from '../../../modules/makeImageUrl';
import SYPrimaryButton from '../../../components/SYPrimaryButton';
import PageElementContainer from '../../../components/PageElementContainer';
import { paymentPageElementCss } from '../../LoggedIn/PaymentView/PaymentView';
import CohortBenefits from '../../../components/CohorBenefits';
import Cohort4WeeksProgram from '../../../components/Cohort4WeeksProgram';
import CohortCurriculum from '../../../components/CohortCurriculum';
import CohortTransformation from '../../../components/CohortTransformation';
import CohortMeetTheCoach from '../../../components/CohortMeetTheCoach';
import CohortPriceOverview from '../../../components/CohortPriceOverview';
import CohortJoinEmailList from '../../../components/CohortJoinEmailList';

const Container = styled.div`
  max-width: none;
  margin: 0 auto;
  padding: 0;

  @media ${CSS_BREAKPOINTS_MIN.md} {
    padding: 0;
  }

  @media ${CSS_BREAKPOINTS_MIN.lg} {
    padding: 0;
  }
`;

const HeroContainer = styled.div`
  min-height: 60vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: ${GUTTER_SIZE}px;
  background: url(${makeImageUrl({ url: `${CLOUDFRONT_STATIC}/img/pages/cohort-lp/bg-top-mobile-1-min.jpg` })}) no-repeat center / cover;

  @media ${CSS_BREAKPOINTS_MIN.sm} {
    background-image: url(${makeImageUrl({ url: `${CLOUDFRONT_STATIC}/img/pages/cohort-lp/bg-top-desktop-3-min.jpg` })});
  }

  @media ${CSS_BREAKPOINTS_MIN.md} {
    min-height: 80vh;
    align-items: flex-start;
    margin-bottom: ${GUTTER_SIZE * 2}px;
  }
`;

const HeroTitle = styled.h2`
  margin-bottom: ${GUTTER_SIZE * 2}px;
  font-family: 'Poppins-Medium', sans-serif;
  font-size: 28px;
  line-height: 36px;
  text-align: center;

  @media ${CSS_BREAKPOINTS_MIN.md} {
    margin-bottom: ${GUTTER_SIZE * 2.5}px;
    font-size: 54px;
    line-height: 45px;
    text-align: left;
  }
`;

const HeroSubTitle = styled.h3`
  max-width: 310px;
  font-family: 'Poppins-Medium', sans-serif;
  font-size: 22px;
  line-height: 26px;
  text-align: center;

  @media ${CSS_BREAKPOINTS_MIN.md} {
    max-width: 580px;
    font-size: 32px;
    line-height: 48px;
    text-align: left;
  }
`;

const HeroCTAWrapper = styled.div`
  width: 100%;
`;

const HeroCTA = styled(SYPrimaryButton)`
  width: 100%;
  margin-top: ${GUTTER_SIZE * 5}px;

  @media ${CSS_BREAKPOINTS_MIN.sm} {
    width: auto;
    min-width: 320px;
  }

  @media ${CSS_BREAKPOINTS_MIN.md} {
    margin-top: ${GUTTER_SIZE * 7}px;
  }
`;

const CohortPageElement = styled.section`
  ${paymentPageElementCss}

  ${({ bg }) => (bg ? `background-color: ${BLACK_COLOR_SEMI_TRANS};` : '')}
`;

const FullWidthElement = styled.div`
  ${({ bg }) => (bg ? `background-color: ${BLACK_COLOR_SEMI_TRANS};` : '')}
`;

const OverviewWrapper = styled(FullWidthElement)`
  background: url(${makeImageUrl({ url: `${CLOUDFRONT_STATIC}/img/pages/cohort-lp/bg-bottom-desktop-1-min.jpg` })}) no-repeat center top / cover;
`;

const CTATitle = 'JOIN WAITING LIST';

class CohortTrainingView extends AbstractView<{}> {
  +viewName: string = COHORT_TRAINING_LANDINGPAGE_VIEW;

  constructor(props) {
    super(props);
    this.mailingListref = createRef();
    this.onCTAClick = this.onCTAClick.bind(this); // strangely this is needed here explicitly
  }

  onCTAClick() {
    window.scrollTo({
      behavior: 'smooth',
      left: 0,
      top: _get(this.mailingListref, 'current.offsetTop', 4800),
    });
  }

  render() {
    return (
      <Container>
        <HeroContainer>
          <PageElementContainer>
            <HeroTitle>Yoga Transformation</HeroTitle>
            <HeroSubTitle>30 Day Course to build a healthy yoga and mindfulness routine.</HeroSubTitle>

            <HeroCTAWrapper>
              <HeroCTA title={CTATitle} onClick={this.onCTAClick} />
            </HeroCTAWrapper>
          </PageElementContainer>
        </HeroContainer>

        <CohortPageElement>
          <CohortBenefits />
        </CohortPageElement>

        <FullWidthElement bg>
          <CohortPageElement bg>
            <Cohort4WeeksProgram onCTAClick={this.onCTAClick} ctaTitle={CTATitle} />
          </CohortPageElement>
        </FullWidthElement>

        <CohortPageElement>
          <CohortCurriculum />
        </CohortPageElement>

        <FullWidthElement bg>
          <CohortPageElement>
            <CohortTransformation onCTAClick={this.onCTAClick} ctaTitle={CTATitle} />
          </CohortPageElement>
        </FullWidthElement>

        <CohortPageElement>
          <CohortMeetTheCoach />
        </CohortPageElement>

        <OverviewWrapper>
          <CohortPageElement>
            <CohortPriceOverview onCTAClick={this.onCTAClick} ctaTitle={CTATitle} />
          </CohortPageElement>
        </OverviewWrapper>

        <FullWidthElement bg>
          <CohortPageElement>
            <CohortJoinEmailList refProp={this.mailingListref} ctaTitle={CTATitle} />
          </CohortPageElement>
        </FullWidthElement>
      </Container>
    );
  }
}

export default CohortTrainingView;
