// @flow
/* eslint max-len: 0 */
import React from 'react';
import Menu from 'react-burger-menu/lib/menus/slide';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
  CSS_BREAKPOINTS_MIN,
  MENU_LINKS_LOGGED_IN,
  MENU_LINKS_LOGGED_OUT,
  MENU_LINKS_ONBOARDING_LOGGED_IN,
  MENU_LINKS_ONBOARDING_LOGGED_OUT,
  SPACING_BASE,
} from '../config/constants';
import type { User } from '../types/User';
import NavigationLink from './NavigationLink';
import type { MenuLink } from '../types/MenuLink';
import SocialLink from './SocialLink';
import { ReactComponent as AppStoreSvg } from '../assets/svg/app-store.svg';
import { ReactComponent as PlayStoreSvg } from '../assets/svg/play-store.svg';
import { ReactComponent as InstagramSvg } from '../assets/svg/instagram-logo.svg';
import { ReactComponent as FacebookSvg } from '../assets/svg/facebook-logo.svg';
import { ReactComponent as YouTubeSvg } from '../assets/svg/youtube-logo.svg';
import NavigationLinkExternal from './NavigationLinkExternal';
import filterPremiumLinks from '../modules/filterLinksForPremiumUser';
import urls, { externalUrls } from '../config/urls';
import isOnboardingPath from '../modules/isOnboardingPath';
import { googleTrackingService } from '../services/GoogleTrackingProvider';
import _get from "lodash/get";
import Cookies from 'js-cookie';
import { LOCALSTORAGE_KEYS } from '../localStorageKeys';
import moment from 'moment-timezone';

const SYMenuNavigation18pt = styled(NavigationLink)`
  margin: 0 0 ${SPACING_BASE * 4}px;
  font-family: Poppins;
  font-size: 18px;
  line-height: 1.5;
  letter-spacing: 0.9px;

  &:focus,
  &:active {
    outline: 0;
  }

  @media ${CSS_BREAKPOINTS_MIN.sm} {
    margin: 0 0 ${SPACING_BASE * 6}px;
  }
`;

const SideBarLinkExternal = styled(NavigationLinkExternal)`
  margin: 0 0 ${SPACING_BASE * 4}px;
  font-family: Poppins;
  font-size: 18px;
  line-height: 1.5;
  letter-spacing: 0.9px;
  text-transform: none;

  &:focus,
  &:active {
    outline: 0;
  }

  @media ${CSS_BREAKPOINTS_MIN.sm} {
    margin: 0 0 ${SPACING_BASE * 6}px;
  }
`;

const AppStoreLink = styled(SocialLink)`
  margin: ${SPACING_BASE * 4}px 0 0;
`;

const SocialMediaPlattforms = styled.div`
  margin: ${SPACING_BASE * 8}px 0 0;
`;

const MenuWrapper = styled(Menu)``;

class SidebarMenu extends React.PureComponent<{
  isLoggedIn: boolean,
  location: Object,
  onStateChange: Function,
  user: User,
}> {
  onMenuItemClick = (link: MenuLink) => {
    const { onStateChange } = this.props;
    console.log({ onMenuItemClick: link });

    if (this.props.location?.pathname?.includes('/master-class')) {
      const slug = this.props.location?.pathname?.split("/").pop();
      let isRedirectToCourseFTUE = false;
      for (const property in urls) {
        if (!urls[property].includes(slug)) {
          isRedirectToCourseFTUE = true;
        }
      }
      console.log({ slug, isRedirectToCourseFTUE });
      if (!!slug && !!isRedirectToCourseFTUE) {
        Cookies.set(LOCALSTORAGE_KEYS.slugCourse, slug, { expires: moment().add(30, 'seconds').toDate() });
      }
    }

    if (link.destination === urls.SIGNOUT) {
      Cookies.set(LOCALSTORAGE_KEYS.slugCourse, "");
    }

    if (link.destination === urls.SIGNUP || link.destination === urls.ONBOARDING) {
      googleTrackingService.trackEvent('funnel', {
        event_category: 'Onboarding',
        event_name: 'Step 0 - Init',
        event_label: 'starting',
      });
      googleTrackingService.trackEvent('onboarding_started', {
        event_category: 'Onboarding',
        event_name: 'onboarding_started',
        event_label: 'starting',
      });
    }

    onStateChange({ isOpen: false });
  };

  renderSocialLinks = () => (
    <>
      <AppStoreLink href={externalUrls.get('appstore')} title="Link to App in App Store" target="_blank" rel="noreferrer"><AppStoreSvg /></AppStoreLink>
      <SocialLink href={externalUrls.get('playstore')} title="Link to App in PlayStore" target="_blank" rel="noreferrer"><PlayStoreSvg /></SocialLink>

      <SocialMediaPlattforms>
        <SocialLink href="//www.instagram.com/skillyoga/" title="Link to Instagram" target="_blank" rel="noreferrer"><InstagramSvg /></SocialLink>
        <SocialLink href="//www.facebook.com/SkillYogaWorkouts" title="Link to Facebook" target="_blank" rel="noreferrer"><FacebookSvg /></SocialLink>
        <SocialLink href="//www.youtube.com/channel/UC_D-YaHdGfXRPCrTm8_hwNw" title="Link to YouTube channel" target="_blank" rel="noreferrer"><YouTubeSvg /></SocialLink>
      </SocialMediaPlattforms>
    </>
  );

  renderLoggedInMenu = () => {
    const { user } = this.props;

    return MENU_LINKS_LOGGED_IN
      .filter((link) => filterPremiumLinks(link, user))
      .map((menuLink: MenuLink) => (
        <SYMenuNavigation18pt
          key={menuLink.id}
          onClick={() => this.onMenuItemClick(menuLink)}
          to={menuLink.destination}
          title={menuLink.name}
        >
          {menuLink.name}
        </SYMenuNavigation18pt>
      ));
  };

  renderLoggedOutMenu = () => MENU_LINKS_LOGGED_OUT.map((menuLink: MenuLink) => {
    if (menuLink.external) {
      return (
        <SideBarLinkExternal
          key={menuLink.id}
          href={menuLink.destination}
          title={`Link to ${menuLink.name}`}
          onClick={() => this.onMenuItemClick(menuLink)}
          target="_blank"
          rel="noreferrer"
        >
          {menuLink.name}
        </SideBarLinkExternal>
      );
    }

    return (
      <SYMenuNavigation18pt
        key={menuLink.id}
        onClick={() => this.onMenuItemClick(menuLink)}
        to={menuLink.destination}
        title={menuLink.name}
      >
        {menuLink.name}
      </SYMenuNavigation18pt>
    );
  });

  renderOnboardingMenu = () => {
    const {
      isLoggedIn,
    } = this.props;

    const menuLinks = isLoggedIn
      ? MENU_LINKS_ONBOARDING_LOGGED_IN
      : MENU_LINKS_ONBOARDING_LOGGED_OUT;

    return menuLinks.map((menuLink: MenuLink) => (
      <SYMenuNavigation18pt
        key={menuLink.id}
        to={menuLink.destination}
        title={menuLink.name}
      >
        {menuLink.name}
      </SYMenuNavigation18pt>
    ));
  };

  renderBurgerMenuItems = () => {
    const {
      location,
      isLoggedIn,
    } = this.props;

    if (isOnboardingPath(location)) {
      return this.renderOnboardingMenu();
    }

    if (isLoggedIn) {
      return this.renderLoggedInMenu();
    }

    return this.renderLoggedOutMenu();
  };

  render() {
    const { location } = this.props;

    return (
      <MenuWrapper {... this.props}>
        {this.renderBurgerMenuItems()}

        {!isOnboardingPath(location) && this.renderSocialLinks()}
      </MenuWrapper>
    );
  }
}

const mapStateToProps = ({ auth }) => ({
  user: auth.user,
  isLoggedIn: auth.isLoggedIn,
});

export default withRouter(connect(mapStateToProps, null)(SidebarMenu));
