// @flow
/* eslint import/no-cycle:0 */
import type { ApiResponseProduct } from '../types/ApiResponseProduct';
import { currencyConverter } from './CurrencyConverter';
import { MONTH_LENGTH_IN_DAYS } from '../config/constants';

class Product {
  subscriptionData: ApiResponseProduct;

  constructor(subscriptionData: ApiResponseProduct) {
    this.subscriptionData = subscriptionData;
  }

  get id(): string {
    const { externalId } = this.subscriptionData;
    return externalId;
  }

  get lengthInMonths(): number {
    return this.subscriptionData.lengthInMonths;
  }

  get discount(): number {
    return this.subscriptionData.discount || 0;
  }

  get formattedDiscount(): string {
    if (this.discount === 0) {
      return '';
    }

    return `${this.discount}%`;
  }

  get title(): string {
    const subscriptionPeriod = this.subscriptionData.lengthInMonths;

    if (subscriptionPeriod > 1) {
      return `${subscriptionPeriod} Months`;
    }

    return `${subscriptionPeriod} Month`;
  }

  get monthlyPrice(): number {
    if (this.discount) {
      return (this.price - this.price * (this.discount / 100)) / (this.lengthInMonths || 1);
    }
    return this.price / (this.lengthInMonths || 1);
  }

  get normalPrice(): number {
    return this.price / (this.lengthInMonths || 1);
  }

  get formattedNormalPriceDiscount(): number {
    return currencyConverter.getFormatted(this.price - this.price * (this.discount / 100));
  }

  get formattedNormalPrice(): string {
    return currencyConverter.getFormatted(this.normalPrice);
  }

  get formattedMonthlyPrice(): string {
    return currencyConverter.getFormatted(this.monthlyPrice);
  }

  get currency(): string {
    const { currency } = this.subscriptionData;
    return currency || 'USD';
  }

  get price(): number {
    const { price } = this.subscriptionData;
    return Number(price) || 0;
  }

  get formattedPrice(): string {
    return currencyConverter.getFormatted(this.price);
  }

  get prefix(): string {
    const { externalId } = this.subscriptionData;
    const [prefix] = externalId.split('_');

    return prefix || '';
  }

  get weight(): number {
    switch (this.lengthInMonths) {
      case 12:
        return 100;
      case 6:
        return 200;
      case 3:
        return 300;
      default:
        return 99999;
    }
  }

  get trialDays(): number {
    return this.subscriptionData.trialInDays;
  }

  get formattedTrialPeriod(): string {
    if (this.trialDays < 30) {
      return `For the first ${this.trialDays} days`;
    }

    const lengthInMonths = parseInt(this.trialDays / MONTH_LENGTH_IN_DAYS, 10);

    if (lengthInMonths > 1) {
      return `For the first ${lengthInMonths} months`;
    }

    return 'For the first month';
  }

  get formattedTrialPeriodShort(): string {
    if (this.trialDays < 30) {
      return `First ${this.trialDays} days`;
    }

    const lengthInMonths = parseInt(this.trialDays / MONTH_LENGTH_IN_DAYS, 10);

    if (lengthInMonths > 1) {
      return `First ${lengthInMonths} months`;
    }

    return 'For the first month';
  }

  get formattedTrialPrice(): string {
    return currencyConverter.getFormatted(0);
  }

  get defaultPaymentPeriod(): string {
    switch (this.subscriptionData.lengthInMonths) {
      case 3: return 'Per quarter';
      case 6: return 'Twice a year';
      case 12: return 'Yearly';
      default: return 'Monthly';
    }
  }

  get paymentPeriod(): string {
    const { coupon } = this.subscriptionData;

    switch (this.subscriptionData.lengthInMonths) {
      case 3: {
        if (!coupon || coupon.duration === 'forever') {
          return 'per quarter';
        }

        if (coupon.duration === 'once') {
          return 'for the first quarter';
        }

        if (coupon.duration === 'repeating') {
          return `for the first ${coupon.durationInMonths} quarters`;
        }

        return 'per quarter';
      }

      case 6: {
        if (!coupon || coupon.duration === 'forever') {
          return 'twice a year';
        }

        if (coupon.duration === 'once') {
          return 'for the first 6 months';
        }

        if (coupon.duration === 'repeating') {
          return `for the first ${coupon.durationInMonths} 6 months`;
        }

        return 'twice a year';
      }

      case 12: {
        if (!coupon || coupon.duration === 'forever') {
          return 'yearly';
        }

        if (coupon.duration === 'once') {
          return 'for the first year';
        }

        if (coupon.duration === 'repeating') {
          return `for the first ${coupon.durationInMonths} years`;
        }

        return 'yearly';
      }

      default: return 'monthly';
    }
  }

  get slug(): string | null {
    return this.subscriptionData.slug || null;
  }

  get isDiscount(): boolean {
    const { isPromotion, discount } = this.subscriptionData;
    return !!(isPromotion && discount && discount > 0);
  }

  get isTrial(): boolean {
    return this.subscriptionData.trialInDays > 0;
  }

  get hasStripeCoupon(): boolean {
    return !!this.subscriptionData.coupon;
  }

  get isPromotion(): boolean {
    return this.subscriptionData.isPromotion;
  }

  get mode(): string {
    return this.subscriptionData.mode;
  }

  get getExternalId(): string {
    return this.subscriptionData.externalId;
  }

  toString(): string {
    return JSON.stringify(this.subscriptionData);
  }
}

export default Product;
