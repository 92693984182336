// @flow
import styled from 'styled-components';
import ReactModal from 'react-modal';
import { ReactComponent as CloseSvg } from '../../assets/svg/close-x.svg';

import {
  BLACK_COLOR, CLOUDFRONT_STATIC, CSS_BREAKPOINTS_MIN, GREY_01_COLOR, GUTTER_SIZE, REACT_MODAL_STYLE, SPACING_BASE, WHITE_COLOR,
} from '../../config/constants';
import makeImageUrl from '../../modules/makeImageUrl';

const SYModal = styled(ReactModal).attrs({
  style: REACT_MODAL_STYLE,
})`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 80%;
  max-width: 800px;
  max-height: 90%;
  padding: 0;
  margin: 0 auto;
  transform: translate(-50%, -50%);
  background:
    url(${makeImageUrl({ url: `${CLOUDFRONT_STATIC}/img/backgrounds/background-dark-mobile@2x.jpg` })}) no-repeat top right / 100%,
    url(${makeImageUrl({ url: `${CLOUDFRONT_STATIC}/img/backgrounds/background-dark-mobile-repeater@2x.jpg` })}) repeat-y top right / 100%;
  overflow: visible;
  -webkit-overflow-scrolling: touch;
  outline: none;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.5);

  @media ${CSS_BREAKPOINTS_MIN.sm} {
    background: url(${makeImageUrl({ url: `${CLOUDFRONT_STATIC}/img/backgrounds/background-dark-desktop@2x@2x.jpg` })}) no-repeat top right / cover;
  }
`;

const SYModalVideoVimeo = styled(ReactModal).attrs({
  style: REACT_MODAL_STYLE,
})`
  position: absolute;
  top: 50%;
  left: 50%;
  max-height: 90%;
  padding: 0;
  margin: 0 auto;
  transform: translate(-50%, -50%);
  background:
    url(${makeImageUrl({ url: `${CLOUDFRONT_STATIC}/img/backgrounds/background-dark-mobile@2x.jpg` })}) no-repeat top right / 100%,
    url(${makeImageUrl({ url: `${CLOUDFRONT_STATIC}/img/backgrounds/background-dark-mobile-repeater@2x.jpg` })}) repeat-y top right / 100%;
  overflow: visible;
  -webkit-overflow-scrolling: touch;
  outline: none;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.5);

  @media ${CSS_BREAKPOINTS_MIN.sm} {
    background: url(${makeImageUrl({ url: `${CLOUDFRONT_STATIC}/img/backgrounds/background-dark-desktop@2x@2x.jpg` })}) no-repeat top right / cover;
  }
`;

const SYModalContent = styled.div`
  padding: ${SPACING_BASE * 4}px;
  background: ${BLACK_COLOR};
`;

const CloseActionRow = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const CloseIconWrapper = styled.div`
  display: inline-flex;
  padding: ${GUTTER_SIZE}px;
  margin-right: -${SPACING_BASE * 3}px;
  cursor: pointer;
`;

const CloseIconVideoVimeoWrapper = styled.div`
  display: inline-flex;
  padding: ${GUTTER_SIZE}px;
  margin-right: -${SPACING_BASE}px;
  cursor: pointer;
`;

const CloseIcon = styled(CloseSvg)`
  width: 16px;
  height: 16px;

  path {
    stroke: ${WHITE_COLOR};
  }

  @media ${CSS_BREAKPOINTS_MIN.sm} {
    width: 32px;
    height: 32px;

    path {
      stroke: ${GREY_01_COLOR};
    }
  }
`;

export {
  SYModal,
  SYModalVideoVimeo,
  SYModalContent,
  CloseActionRow,
  CloseIconWrapper,
  CloseIconVideoVimeoWrapper,
  CloseIcon,
};
