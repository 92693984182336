import * as types from '../actions/types';

export default function programsReducer(state = [], action) {
  switch (action.type) {
    case types.LOAD_PLAN_SUCCESS:
      return action.payload;

    case types.LOAD_PLAN_FAILED:
      return action.payload;

    default:
      return state;
  }
}
