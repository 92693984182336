import styled from 'styled-components';
import SYHeadlineThree from '../../SYHeadlineThree';
import { CSS_BREAKPOINTS_MIN, GUTTER_SIZE } from '../../../config/constants';
import SYHeadlineFour from '../../SYHeadlineFour';

const TitleElement = styled(SYHeadlineThree)`
  font-family: 'Poppins-Medium', sans-serif;
  font-size: 22px;
  line-height: 31px;
  margin: 0 0 ${GUTTER_SIZE * 2}px;
  text-align: center;

  @media ${CSS_BREAKPOINTS_MIN.md} {
    font-size: 36px;
    line-height: 45px;
    margin: 0 0 ${GUTTER_SIZE * 2.5}px;
  }
`;

const SubTitleElement = styled(SYHeadlineFour)`
  font-family: 'Poppins-Medium', sans-serif;
  font-size: 20px;
  line-height: 25px;
  margin: 0 0 ${GUTTER_SIZE / 2}px;
  text-align: center;
  flex: 1;

  @media ${CSS_BREAKPOINTS_MIN.md} {
    font-size: 30px;
    line-height: 44px;
    margin: 0 0 ${GUTTER_SIZE * 1.5}px;
  }
}`;

export {
  SubTitleElement,
  TitleElement,
};
