// @flow
import styled from 'styled-components';
import PageElementContainer from './PageElementContainer';
import { CSS_BREAKPOINTS_MIN } from '../config/constants';

const PostLoginContainer = styled(PageElementContainer)`
  padding-top: 0;
  padding-bottom: 0;
  
  @media ${CSS_BREAKPOINTS_MIN.md} {
    padding-top: 0;
    padding-bottom: 0;
  }
  
  @media ${CSS_BREAKPOINTS_MIN.lg} {
    padding-top: 0;
    padding-bottom: 0;
  }
`;

export default PostLoginContainer;
