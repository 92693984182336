// @flow

const urls = {
  ACCOUNT: '/account',
  APP_DOWNLOAD_VIEW: '/download',
  // COURSE_OVERVIEW_PAGE: '/courses',
  COHORT_TRAINING_LANDINGPAGE: '/cohort-training',
  CALEB_TRAINING_LANDINGPAGE: '/caleb-training',
  ADAM_TRAINING_LANDINGPAGE: '/adam-training',
  MARLENE_TRAINING_LANDINGPAGE: '/marlene',
  GET_SKILLED: '/getcoach',
  IMPRINT: '/pages/imprint',
  JOURNEY: '/your-journey',
  LIBRARY: '/library',
  MARKETING_VIEW: '/getskilled',
  ONBOARDING: '/onboarding',
  PASSWORD_CHANGE: '/change-password',
  PASSWORD_FORGOT: '/forgot-password',
  PAYMENT: '/payment',
  PAYMENT_FAILED: '/payment/failed',
  PAYMENT_SUCCESS: '/payment/success',
  PREMIUM: '/premium',
  PRIVACY_POLICY: '/pages/privacy-policy',
  PRODUCT_OVERVIEW_PUBLIC: '/getcoachnow',
  PROGRAM_DETAIL: '/program',
  PROGRAMS_OVERVIEW: '/programs',
  REFERRAL: '/referral',
  REFERRAL_EXTERN: '/r',
  ROOT: '/',
  SIGNIN: '/signin',
  SIGNOUT: '/signout',
  SIGNUP: '/signup',
  SUPPORT: 'https://skillyoga.atlassian.net/servicedesk/customer/portals',
  TERMS: '/pages/skillyoga-terms-of-use',
  TRAINING: '/training',
  UNLOCK: '/unlock',
  USER_INVOICES_VIEW: '/invoices',
  VOUCHER_PAGE: '/voucher',
  WORKOUT_DETAIL: '/workout',
  LANDING_PAGE: `/master-class/:slug`,
  SIGNUP_PAGE: '/master-class/:slug/signup',
  CREATE_PLAN: '/master-class/create-plan',
  PAYWALL: '/master-class/paywall/:slug',
  CONFIRM_MESSAGE_PURCHASE: '/master-class/confirm-message-purchase/:slug',
  CONFIRM_MESSAGE_SUBSCRIPTION: '/master-class/confirm-message-subscription/:slug',
  FIRST_TIME_TRAINING_MODAL: '/master-class/first-time-training-modal',
  TRAINING_HOME: '/master-class/training-home',
  PROGRESS_MODAL: '/master-class/progress-modal',
  MASTER_CLASS_WORKOUT_DETAIL: '/master-class/workout-detail/:slug',
  SWITCH_MODAL: '/master-class/switch-modal',
  COURSE_COMPLETION: '/master-class/course-completion',
  RECOMMENDATION: '/master-class/recommendation',
  COURSE_DETAIL_FTUE: '/master-class/course-detail-ftue/:slug',
  MASTER_COURSE_FREE_LIBRARY: '/master-class/master-course-free-library',
  PAYMENT_CONFIRMATION: '/master-class/payment-confirmation/:slug',
  SHOP: 'https://shop.skill-yoga.com/',
};

const externalUrls: Map<string, string> = new Map([
  [
    'appstore',
    '//apps.apple.com/us/app/skill-yoga-yoga-workouts/id1462051533?l=de&ls=1',
  ],
  ['blog', '//www.skill-yoga.blog'],
  ['cohort-training', 'https://skill-yoga.mn.co/sign_up'],
  ['facebook', '//www.facebook.com/groups/169627480403409/'],
  ['instagram', '//www.instagram.com/skillyoga/'],
  ['instagram-hashtag', '//www.instagram.com/explore/tags/skillyoga/'],
  ['playstore', '//play.google.com/store/apps/details?id=com.skillyoga.app'],
]);

export default urls;

export { externalUrls };
