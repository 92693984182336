// @flow
import { v4 as uuidv4 } from "uuid";
import * as types from "./types";
import { apiService } from "../../services/APIService";
import { logger } from "../../modules/Logger";

const activatePlanSuccess = (plan) => ({
  type: types.ACTIVATE_PLAN_SUCCESS,
  payload: plan,
});
const loadPlanSuccess = (plan) => ({
  type: types.LOAD_PLAN_SUCCESS,
  payload: plan,
});
const loadActivePlanSuccess = (plan) => ({
  type: types.LOAD_ACTIVE_PLAN_SUCCESS,
  payload: plan,
});
const loadPlanFailed = (error) => ({
  type: types.LOAD_PLAN_FAILED,
  payload: error,
});
const saveActivePlan = (plan) => ({
  type: types.ACTIVATE_PLAN_SUCCESS,
  payload: plan,
});

/**
 * New API
 */

export const fetchActivePlan = () => async (dispatch: Function) => {
  try {
    console.log("get active plan =====> ");
    const activePlan = await apiService.fetchActivePlan();
    dispatch(loadActivePlanSuccess(activePlan));
  } catch (error) {
    logger.error(error, [
      {
        fileName: "plan.js",
        className: "",
        functionName: "fetchActivePlan()",
      },
    ]);
  }
};

export const fetchActivePlanBySlug =
  ({ slug, userId }) =>
  async (dispatch: Function) => {
    try {
      console.log("get active plan by slug =====> ");
      const payload = {
        slug,
        startedAt: new Date().toISOString(),
        sessionId: uuidv4(),
        userId,
      };
      await apiService.fetchActivePlanBySlug(payload);
    } catch (error) {
      logger.error(error, [
        {
          fileName: "plan.js",
          className: "",
          functionName: "fetchActivePlanBySlug()",
        },
      ]);
    }
  };

export const saveNewActivePlan = (activePlan) => async (dispatch: Function) => {
  try {
    dispatch(saveActivePlan(activePlan));
  } catch (error) {
    logger.error(error, [
      {
        fileName: "plan.js",
        className: "",
        functionName: "fetchActivePlan()",
      },
    ]);
  }
};

export const checkAccessiblePlan = ({ programId }) => async (dispatch: Function) => {
  try {
    return apiService.checkAccessiblePlan({ programId });
  } catch (error) {
    logger.error(error, [
      {
        fileName: "plan.js",
        className: "",
        functionName: "checkAccessiblePlan()",
      },
    ]);
  }
};

export const fetchPrograms = () => async (dispatch: Function) => {
  try {
    const programs = await apiService.fetchPrograms();
    dispatch(loadPlanSuccess(programs));
  } catch (error) {
    dispatch(loadPlanFailed(error));
    throw new Error("Error fetching Programs from API");
  }
};

export const startProgram =
  (programId: number) => async (dispatch: Function) => {
    try {
      const program = await apiService.startProgram({
        programId,
        startedAt: new Date().toISOString(),
        sessionId: uuidv4(),
      });
      dispatch(activatePlanSuccess(program));
    } catch (error) {
      logger.error(error, [
        {
          fileName: "plan.js",
          className: "",
          functionName: "startProgram()",
        },
      ]);
      throw new Error(
        `Error starting a new program with ID ${programId} for the user with ID`
      );
    }
  };
