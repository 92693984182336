// @flow
/* eslint one-var: 0 */
import envUtils from '../utils/env.utils';

export default (callback?: Function) => {
  // Load the required SDK asynchronously
  ((d, s, id) => {
    const fjs = d.getElementsByTagName(s)[0];

    if (d.getElementById(id)) return;

    const js = d.createElement(s);
    js.id = id;
    js.src = '//connect.facebook.net/en_US/sdk.js';
    js.async = true;
    // $FlowFixMe
    fjs.parentNode.insertBefore(js, fjs);
  })(document, 'script', 'facebook-jssdk');

  window.fbAsyncInit = () => {
    window.FB.init({
      appId: envUtils.isLocalOrTestEnvironment() ? '181876672877715' : '1968436936559513',
      cookie: true, // enable cookies to allow the server to access the session
      xfbml: false, // parse social plugins on this page
      version: 'v3.2',
    });

    if (callback) {
      callback();
    }
  };
};
