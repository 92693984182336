// @flow

import { authService } from '../../services/AuthService';
import {
  AUTH_FETCHME,
  AUTH_FETCHME_FAILURE,
  AUTH_FETCHME_SUCCESS, AUTH_SIGNIN_FAILURE,
  AUTH_SIGNIN_START,
  AUTH_SIGNIN_SUCCESS, AUTH_SIGNOUT, AUTH_SIGNOUT_FAILURE, AUTH_SIGNOUT_SUCCESS, SET_AUTH_TYPE, SET_SOCIAL_LOGIN, SET_USER_ACCEPTED_COOKIES_ACTION,
  SET_USER_EMAIL,
} from './types';
import type {
  SetAuthSocialLoginAction, SetAuthTypeAction, SetUserAcceptedCookiesAction, SetUserEmailAction,
} from '../../types/Action';
import type { AllowedAuthType } from '../../types/AllowedAuthType';
import type { SocialLoginType } from '../../types/SocialLoginType';
import type { SocialSignInParams } from '../../services/AuthService';
import type { Dispatch } from '../../types/Dispatch';
import type { User } from '../../types/User';
import { adjustTrackingProvider } from '../../services/AdjustTrackingProvider';

type AuthSignInStart = {|
  type: 'AUTH_SIGNIN_START'
|}

type AuthSignInSuccess = {|
  type: 'AUTH_SIGNIN_SUCCESS',
  payload: User,
|}

type AuthSignInError = {|
  type: 'AUTH_SIGNIN_FAILURE',
  payload: Error,
|}

export const setUserActionCreators: {|
  setUserEmail: (string) => SetUserEmailAction,
  setAuthType: (AllowedAuthType) => SetAuthTypeAction,
  setAuthSocialLogin: ({|
    type: SocialLoginType,
    email: string,
    identityToken?: string,
    firstName?: string,
    lastName?: string,
  |}) => SetAuthSocialLoginAction,
  socialSignIn: (SocialSignInParams) => (Dispatch<AuthSignInStart | AuthSignInSuccess | AuthSignInError>) => Promise<void>,
  setUserAcceptedCookies: (boolean) => SetUserAcceptedCookiesAction,
|} = {
  setUserEmail: (payload) => ({ type: SET_USER_EMAIL, payload }),
  setAuthType: (payload) => ({ type: SET_AUTH_TYPE, payload }),
  setAuthSocialLogin: (payload) => ({ type: SET_SOCIAL_LOGIN, payload }),
  socialSignIn: (payload) => async (dispatch) => {
    dispatch({ type: AUTH_SIGNIN_START });

    try {
      const user = await authService.socialSignIn(payload);

      dispatch({ type: AUTH_SIGNIN_SUCCESS, payload: user });
    } catch (e) {
      dispatch({ type: AUTH_SIGNIN_FAILURE, payload: e });
      throw new Error(e.message || 'Error signin in the user');
    }
  },
  setUserAcceptedCookies: (payload) => ({ type: SET_USER_ACCEPTED_COOKIES_ACTION, payload }),
};

/**
 * @todo Refactor user actions!
 */

export const fetchMe = () => async (dispatch: Function) => {
  dispatch({ type: AUTH_FETCHME });

  try {
    const me = await authService.fetchMe();
    adjustTrackingProvider.setUserContext(me);

    dispatch({ type: AUTH_FETCHME_SUCCESS, payload: me });
  } catch (e) {
    dispatch({ type: AUTH_FETCHME_FAILURE, payload: e });
    // throw new Error('Error fetching user info');
  }
};

export const signIn = (username: string, password: string) => async (dispatch: Function) => {
  dispatch({ type: AUTH_SIGNIN_START });

  try {
    const user = await authService.signIn(username, password);

    dispatch({ type: AUTH_SIGNIN_SUCCESS, payload: user });
  } catch (e) {
    dispatch({ type: AUTH_SIGNIN_FAILURE, payload: e });
    throw new Error(e.message || 'Error signin in the user');
  }
};

export const signOut = () => async (dispatch: Function) => {
  dispatch({ type: AUTH_SIGNOUT });

  try {
    await authService.signOut();
    adjustTrackingProvider.clearUserContext();

    dispatch({ type: AUTH_SIGNOUT_SUCCESS });
  } catch (e) {
    dispatch({ type: AUTH_SIGNOUT_FAILURE });
    throw new Error('Error signing out the user');
  }
};
