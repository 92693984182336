// @flow
import _toPairs from 'lodash/toPairs';
import _snakeCase from 'lodash/snakeCase';
import _omitBy from 'lodash/omitBy';
import Adjust from '@adjustcom/adjust-web-sdk';

import type { TrackingServiceInterface } from '../types/TrackingServiceInterface';
import type { User } from '../types/User';
import {
  COMPLETED_REGISTRATION_EVENT,
  FIRST_TRAINING_COMPLETED_EVENT, INVITE_INITIATED_EVENT,
  PROGRAM_COMPLETED_EVENT,
  PROGRAM_SWITCHED_EVENT, SUBSCRIPTION_PURCHASE_FAILED_EVENT,
  SUBSCRIPTION_PURCHASE_INITIATED_EVENT, SUBSCRIPTION_PURCHASE_SUCCESS_EVENT,
  WORKOUT_DONE_EVENT,
  WORKOUT_STARTED_EVENT,
} from '../config/trackingEventNames';
import EnvironmentUtils from '../utils/env.utils';

type AdjustEventMapEntry = {|
  name: string,
  eventToken: string,
|};

const prodOrTest = (prodKey: string, testKey: string): string => (EnvironmentUtils.isProduction() ? prodKey : testKey);

const eventMap: Map<string, AdjustEventMapEntry[]> = new Map([
  [WORKOUT_DONE_EVENT, [{ name: 'Training Completed', eventToken: prodOrTest('w5m5xv', '9vx0uf') }]],
  [FIRST_TRAINING_COMPLETED_EVENT, [{ name: 'First Training Completed', eventToken: prodOrTest('mg3oqs', 'dfs2t8') }]],
  [WORKOUT_STARTED_EVENT, [{ name: 'First Training Started', eventToken: prodOrTest('ye7u2b', '2mogh0') }]],
  [INVITE_INITIATED_EVENT, [{ name: 'Invite', eventToken: prodOrTest('8gpb9u', 'o5dsry') }]],
  [PROGRAM_SWITCHED_EVENT, [{ name: 'Plan Changed', eventToken: prodOrTest('1tnsts', 'ffpp7v') }]],
  [PROGRAM_COMPLETED_EVENT, [{ name: 'Plan Completed', eventToken: prodOrTest('s1x5wl', 'kkdy9m') }]],
  [COMPLETED_REGISTRATION_EVENT, [{ name: 'Registration Completed', eventToken: prodOrTest('odhmz2', 'z0wo7o') }]],
  [SUBSCRIPTION_PURCHASE_INITIATED_EVENT, [{ name: 'Subscription Purchase Initiated', eventToken: prodOrTest('bpengl', 'st6m6p') }]],
  [SUBSCRIPTION_PURCHASE_FAILED_EVENT, [{ name: 'Subscription Purchase Failed', eventToken: prodOrTest('cfg79g', 'fvldnv') }]],
  [SUBSCRIPTION_PURCHASE_SUCCESS_EVENT, [{ name: 'Subscription Purchase Success', eventToken: prodOrTest('4gb40g', 'fiw237') }]],
]);

class AdjustTrackingProvider implements TrackingServiceInterface {
  constructor() {
    const appToken = EnvironmentUtils.isLocalOrTestEnvironment() ? 'fl7o7umpj280' : 'r2zkz2egql8g';
    const environment = EnvironmentUtils.isLocalEnvironment() ? 'sandbox' : 'production';

    Adjust.initSdk({
      appToken,
      environment,
    });
  }

  setUserContext(user: User) {
    if (user.id === null) {
      return;
    }

    Adjust.addGlobalCallbackParameters([
      {
        key: 'user_id',
        value: `${user.id}`,
      },
    ]);
  }

  clearUserContext() {
    Adjust.clearGlobalCallbackParameters();
  }

  async trackView(screenName: string, screenParams?: Object = {}): Promise<void> {
    this.trackEvent(`View_${screenName}`, screenParams);
  }

  async trackEvent(passedEventName: string, callbackParams?: Object = {}, eventParams?: Object = {}): Promise<void> {
    const adjustEvents: AdjustEventMapEntry[] | typeof undefined = eventMap.get(passedEventName);
    if (adjustEvents === undefined) {
      return;
    }

    const cleanEventParams = _omitBy(callbackParams, (v) => !v);

    adjustEvents.forEach(({ eventToken }) => {
      const adjustEvent = {
        ...eventParams,
        eventToken,
        callbackParams: [],
      };

      _toPairs(cleanEventParams).forEach(([key, value]) => {
        adjustEvent.callbackParams.push({
          key: _snakeCase(`${key}`),
          value: `${value}`,
        });
      });

      Adjust.trackEvent(adjustEvent);
    });
  }
}

// eslint-disable-next-line import/prefer-default-export
export const adjustTrackingProvider = new AdjustTrackingProvider();
