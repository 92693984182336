// @flow
import { css } from 'styled-components';
import { BACKGROUND_COLOR, DARK_GREY_COLOR, LIGHT_GREY_02 } from '../../config/constants';

const WebkitScrollBarCSS = css`
  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  ::-webkit-scrollbar-button {
    width: 0;
    height: 0;
  }

  ::-webkit-scrollbar-thumb {
    background: ${DARK_GREY_COLOR};
    border: 0 none ${DARK_GREY_COLOR};
    border-radius: 0;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: ${DARK_GREY_COLOR};
  }

  ::-webkit-scrollbar-thumb:active {
    background: ${BACKGROUND_COLOR};
  }

  ::-webkit-scrollbar-track {
    background: ${LIGHT_GREY_02};
    border: 0 none ${DARK_GREY_COLOR};
    border-radius: 0;
  }

  ::-webkit-scrollbar-track:hover {
    background: ${LIGHT_GREY_02};
  }

  ::-webkit-scrollbar-track:active {
    background: ${LIGHT_GREY_02};
  }

  ::-webkit-scrollbar-corner {
    background: transparent;
  }
`;

export default WebkitScrollBarCSS;
