// @flow

import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import queryString from "query-string";
import { withRouter } from "react-router-dom";
import _isEmpty from "lodash/isEmpty";
import _startsWith from "lodash/startsWith";
import _get from "lodash/get";
import { bindActionCreators } from "redux";
import Cookies from "js-cookie";
import moment from "moment-timezone";

import {
  CSS_BREAKPOINTS_MIN,
  GUTTER_SIZE,
  PRIMARY_COLOR,
  SPACING_BASE,
  WHITE_COLOR,
} from "../config/constants";
import SYHeadlineSix from "./SYHeadlineSix";
import type { User } from "../types/User";
import { Auth } from "../services/Amplify";
import * as authActionCreators from "../store/actions/auth";
import type { MarketingCampaignType } from "../types/MarketingCampaignType";
import { LOCALSTORAGE_KEYS } from "../localStorageKeys";
import urls from "../config/urls";
import isKnownUser from "../modules/isKnownUser";
import BannerCountdown from "./BannerCountdown";
import type { PaymentState } from "../types/PaymentState";
import type { LocationObjectReactRouter } from "../types/LocationObjectReactRouter";
import { withDocumentBanner } from "../hoc/withDocumentBanner";
import UserUtils from "../utils/user.utils";

const Banner = styled.a`
  display: block;
  background-color: ${PRIMARY_COLOR};
  cursor: pointer;
  outline: none;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
`;

const ContainerBanner = styled.div`
  @media ${CSS_BREAKPOINTS_MIN.sm} {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const BannerContent = styled.div`
  padding: ${GUTTER_SIZE}px;
`;

const Slug = styled(SYHeadlineSix)`
  margin: 0 0 ${({ hasCountdown }) => (hasCountdown ? SPACING_BASE * 5 : 0)}px;
  text-align: center;
  color: ${WHITE_COLOR};

  @media ${CSS_BREAKPOINTS_MIN.sm} {
    margin: 0 ${({ hasCountdown }) => (hasCountdown ? GUTTER_SIZE * 2 : 0)}px 0
      0;
    font-size: 16px;
  }
`;

class MarketingBanner extends React.PureComponent<
  {
    authActions: Object,
    campaign: MarketingCampaignType,
    location: LocationObjectReactRouter,
    payment: PaymentState,
    user: User,
  },
  {
    showBanner: boolean,
    activeCode?: string,
  }
> {
  state = {
    showBanner: false,
    activeCode: undefined,
    isFreeleticsTrial: false,
  };

  async componentDidMount(): any {
    const { authActions, campaign, location } = this.props;

    const { c: codeFromUrl } = queryString.parse(location.search);

    const isFreeleticsTrial = UserUtils.checkFreeleticsTrial(codeFromUrl);
    console.log('isFreeleticsTrial: ', isFreeleticsTrial);
    this.setState({
      isFreeleticsTrial: isFreeleticsTrial,
    });

    if (_isEmpty(campaign)) {
      return;
    }

    try {
      await Auth.currentAuthenticatedUser();

      await authActions.fetchMe();
      // eslint-disable-next-line react/destructuring-assignment
      this.setState({ showBanner: !this.props.user.isPremium });
    } catch (error) {
      this.setState({ showBanner: true });
    }
  }

  onBannerClick = () => {
    const { codeToApplyOnCheckout } = LOCALSTORAGE_KEYS;
    const { activeCode } = this.state;

    Cookies.set(codeToApplyOnCheckout, activeCode, {
      expires: moment().add(1, "hour").toDate(),
    });

    // const { campaign, location } = this.props;
    // const { c: codeFromUrl } = queryString.parse(location.search);
    // const cachedCodeFromSession = sessionStorage.getItem(codeToApplyOnCheckout);
    //
    // console.warn('STORING', codeFromUrl || cachedCodeFromSession || campaign);
    //
    // if (codeFromUrl) {
    //   Cookies.set(codeToApplyOnCheckout, codeFromUrl, { expires: moment().add(1, 'hour').toDate() });
    // } else if (cachedCodeFromSession) {
    //   Cookies.set(codeToApplyOnCheckout, cachedCodeFromSession, { expires: moment().add(1, 'hour').toDate() });
    // } else if (campaign.code) {
    //   Cookies.set(codeToApplyOnCheckout, campaign.code, { expires: campaign.end.toDate() });
    // }
  };

  isLocationToShowBanner = () => {
    const {
      location: { pathname },
      dataBanner: campaign,
    } = this.props;

    const blackList = [
      urls.ONBOARDING,
      urls.SIGNUP,
      urls.PAYMENT,
      urls.PRODUCT_OVERVIEW_PUBLIC + `?c=${campaign?.code}`,
      urls.GET_SKILLED,
      urls.PREMIUM,
      urls.PRODUCT_OVERVIEW_PUBLIC,
      urls.MASTER_COURSE_FREE_LIBRARY,
      urls.CALEB_TRAINING_LANDINGPAGE,
      urls.ADAM_TRAINING_LANDINGPAGE,
      urls.MARLENE_TRAINING_LANDINGPAGE,
    ];

    return (
      blackList.map((url: string) => _startsWith(pathname, url)).filter(Boolean)
        .length === 0
    );
  };

  isMarketingLandingPage = () => {
    const {
      location: { pathname },
    } = this.props;

    return pathname === urls.PRODUCT_OVERVIEW_PUBLIC || pathname === urls.ROOT;
  };

  isMasterClassPage = () => {
    const {
      location: { pathname },
    } = this.props;
    return pathname.includes("/master-class");
  };

  showBanner = () => {
    const {
      payment: { promotion },
      dataBanner: banner,
    } = this.props;

    if (this.isMasterClassPage()) {
      return false;
    }

    if (this.isMarketingLandingPage() && !!(banner && banner?.code)) {
      return true;
    }

    const { showBanner } = this.state;
    const { campaign } = this.props;

    return !(
      !showBanner ||
      _isEmpty(campaign) ||
      !this.isLocationToShowBanner()
    );
  };

  getClickLocation = () => {
    if (!!this.state.isFreeleticsTrial) {
      return urls.PRODUCT_OVERVIEW_PUBLIC + `?c=freeleticsn4b223`;
    }
    if (isKnownUser()) {
      return urls.PAYMENT;
    }
    const { dataBanner: campaign } = this.props;
    if (!!campaign?.code) {
      return urls.PRODUCT_OVERVIEW_PUBLIC + `?c=${campaign?.code}`;
    }
    return urls.PRODUCT_OVERVIEW_PUBLIC;
  };

  renderMarketingLpBanner = () => {
    const {
      payment: { promotion },
      campaign,
      dataBanner: banner,
    } = this.props;

    // const endDate = _get(promotion, 'code.validTo');
    const title = _get(promotion, "code.title");
    const promotionCode = _get(promotion, "code.code");
    const marketingCode = _get(campaign, "code");

    // in case the currently active marketing campaign is the same as the stored
    // promotion campaign, we render the default banner, because the default banner
    // is more likely to have a valid_to date set
    if (marketingCode === promotionCode || banner?.code === promotionCode) {
      return this.renderDefaultBanner();
    }

    // Workarounc: Replace "2m" with "2 Months" for coupon title
    const modifiedTitle = title.replace("2m", "2 Months");

    this.setState({ activeCode: promotionCode });

    return (
      <BannerContent>
        <Slug hasCountdown={false}>{modifiedTitle}</Slug>
        {/* {!!endDate && <BannerCountdown end={moment(endDate)} showDays={false} />} */}
      </BannerContent>
    );
  };

  renderDefaultBanner = () => {
    const { dataBanner: campaign } = this.props;
    console.log("campaign: ", JSON.stringify(campaign));

    this.setState({ activeCode: campaign?.code });

    return !!campaign ? (
      <BannerContent>
        <Slug hasCountdown={campaign?.showcountdown}>{campaign?.slug}</Slug>
        {campaign?.showcountdown && (
          <BannerCountdown end={moment(campaign?.end_date)} showDays={false} />
        )}
      </BannerContent>
    ) : null;
  };

  render() {
    const {
      payment: { promotion },
    } = this.props;
    if (!this.showBanner()) {
      return null;
    }

    const { c: codeFromUrl } = queryString.parse(location.search);

    // we're assuming that as soon as if there's a code in the URL
    // we will show what's there, not the marketing campaign,
    // but the code validation and redemption needs some time to be requested
    // therefore, we will wait until the promotion products are loaded
    if (codeFromUrl && !promotion) {
      return null;
    }

    return (
      <Banner onClick={this.onBannerClick} href={this.getClickLocation()}>
        {!!this.state.isFreeleticsTrial ? (
          <BannerContent>
            <Slug hasCountdown={false}>{"Freeletics Offer 1 Year Trial"}</Slug>
          </BannerContent>
        ) : (
          <ContainerBanner>
            {this.isMarketingLandingPage() && !!promotion
              ? this.renderMarketingLpBanner()
              : this.renderDefaultBanner()}
          </ContainerBanner>
        )}
      </Banner>
    );
  }
}

const mapStateToProps = ({ auth, payment }) => ({
  user: auth.user,
  payment,
});

const mapDispatchToProps = (dispatch) => ({
  authActions: bindActionCreators(authActionCreators, dispatch),
});

export default withDocumentBanner(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(MarketingBanner))
);
