import styled from 'styled-components';
import { SubTitleElement } from '../text/CohortLp/CohortLpTextElements';
import { CSS_BREAKPOINTS_MIN, GUTTER_SIZE, PAPER_WHITE_COLOR } from '../../config/constants';
import SYBody16pt from '../SYBody16pt';
import SelfMasterySvg from '../../assets/svg/caleb-self-mastery.svg';
import AdamIconSvg from '../../assets/svg/adam-icon.svg';
import CalebIconLearningSvg from '../../assets/svg/caleb-icon-learning.svg';

const Container = styled.section`
  text-align: center;
  padding-top: 40px;
  padding-bottom: 40px;

  @media ${CSS_BREAKPOINTS_MIN.md} {
    display: grid;
    grid-column-gap: 2em;
    grid-template-columns: repeat(3, 1fr);
  }

  @media ${CSS_BREAKPOINTS_MIN.lg} {
    grid-column-gap: 4em;
  }
`;

const Text = styled(SYBody16pt)`
  text-align: center;
  color: ${PAPER_WHITE_COLOR};
  padding-bottom: ${GUTTER_SIZE * 2}px;
`;

const ImageSelfMastery = styled.div`
  img {
    width: 72px;
    margin-bottom: 20px;

    @media ${CSS_BREAKPOINTS_MIN.md} {
      width: 110px;
      margin-bottom: 30px;
    }
  }
`;

const ImageCaleb = styled.div`
  img {
    width: 72px;
    margin-bottom: 20px;

    @media ${CSS_BREAKPOINTS_MIN.md} {
      width: 110px;
      margin-bottom: 30px;
    }
  }
`;

const ImageLearning = styled.div`
  img {
    width: 72px;
    margin-bottom: 20px;

    @media ${CSS_BREAKPOINTS_MIN.md} {
      width: 110px;
      margin-bottom: 30px;
    }
  }
`;

const Benefit = styled.div`
  margin-bottom: ${GUTTER_SIZE * 2}px;

  @media ${CSS_BREAKPOINTS_MIN.md} {
    display: contents;

    &:first-child ${ImageSelfMastery} {
      grid-row: 1;
      grid-column: 1;
    }

    &:nth-child(2) ${ImageCaleb} {
      grid-row: 1;
      grid-column: 2;
    }

    &:last-child ${ImageLearning} {
        grid-row: 1;
        grid-column: 3;
    }

    &:first-child ${SubTitleElement} {
      grid-row: 2;
      grid-column: 1;
    }

    &:nth-child(2) ${SubTitleElement} {
      grid-row: 2;
      grid-column: 2;
    }

    &:last-child ${SubTitleElement} {
      grid-row: 2;
      grid-column: 3;
    }

    &:first-child ${Text} {
      grid-row: 3;
      grid-column: 1;
    }

    &:nth-child(2) ${Text} {
      grid-row: 3;
      grid-column: 2;
    }

    &:last-child ${Text} {
      grid-row: 3;
      grid-column: 3;
    }
  }
`;

const AdamBenefits = () => (
  <>
    <Container>
      <Benefit>
        <ImageSelfMastery>
          <img src={AdamIconSvg} alt="Adam Husler" />
        </ImageSelfMastery>
        <SubTitleElement>Practice with Adam Husler</SubTitleElement>
        <Text>
          Adam has taught his signature style of alignment-focused yoga across classes, trainings and
          conferences in 20+ countries fuelled by a fascination with anatomy and a desire to ask ‘why?’
        </Text>
      </Benefit>

      <Benefit>
        <ImageCaleb>
          <img src={CalebIconLearningSvg} alt="Learning" />
        </ImageCaleb>

        <SubTitleElement>Build skill and knowledge</SubTitleElement>

        <Text>
          Move beyond the replication of shape and learn what you are stretching and lengthening in asanas,
          along with the physical skills you are cultivating.
        </Text>
      </Benefit>

      <Benefit>
        <ImageLearning>
          <img src={SelfMasterySvg} alt="Self mastery" />
        </ImageLearning>

        <SubTitleElement>Intelligent sequencing</SubTitleElement>

        <Text>
          Experience sequences where every asana, variation and cue is designed to help you get a close to the
          fullest expression of the pose as possible. Each sequence is supported
          by a full breakdown of the arm balance to support your learning.
        </Text>
      </Benefit>
    </Container>
  </>
);

export default AdamBenefits;
