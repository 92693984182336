// @flow
import type { cssThemeSYType } from '../styles/themeSkillYoga';
import makeImageUrl from '../modules/makeImageUrl';
import { CLOUDFRONT_STATIC } from '../config/constants';

/* eslint-disable max-len */
export default function componentStyles(theme: cssThemeSYType) {
  return Object.freeze({
    skillCoachCard: {
      width: '100%',
      margin: 0,
      padding: `${theme.getGutterSpacing(1)} ${theme.getGutterSpacing(1)} ${theme.getGutterSpacing(3)}`,
      background: `${theme.colors.BACKGROUND_COLOR} url(${makeImageUrl({ url: `${CLOUDFRONT_STATIC}/img/backgrounds/cards/background-dark-mobile@2x.jpg` })}) no-repeat top right / cover`,
      borderTop: `2px solid ${theme.colors.PRIMARY_COLOR}`,
      boxShadow: '6px 9px 17px 0 rgba(0, 0, 0, 0.5)',
    },

    children: {
      margin: `0 0 -${theme.getGutterSpacing()}`,
    },

    [theme.getBreakpoint('SM')]: {
      skillCoachCard: {
        maxWidth: theme.rem(440),
      },
    },
  });
}
/* eslint-enable max-len */
