// @flow
import _get from 'lodash/get';
import Cookies from 'js-cookie';
import convertToBit from '../../modules/convertToBit';
import type { User } from '../../types/User';
import {
  AUTH_SIGNIN_START,
  AUTH_FETCHME,
  AUTH_SIGNIN_SUCCESS,
  AUTH_FETCHME_SUCCESS,
  SET_USER_EMAIL,
  AUTH_SIGNIN_FAILURE,
  AUTH_FETCHME_FAILURE,
  AUTH_SIGNOUT,
  AUTH_SIGNOUT_SUCCESS,
  AUTH_SIGNOUT_FAILURE, SET_AUTH_TYPE, SET_SOCIAL_LOGIN, SET_USER_ACCEPTED_COOKIES_ACTION,
} from '../actions/types';
import type { AllowedAuthType } from '../../types/AllowedAuthType';
import type { SocialLoginType } from '../../types/SocialLoginType';
import type { SetAuthSocialLoginAction, SetAuthTypeAction, SetUserAcceptedCookiesAction } from '../../types/Action';
import { LOCALSTORAGE_KEYS } from '../../localStorageKeys';

export type AuthState = {
  loading: boolean,
  user: User | null,
  isLoggedIn: boolean,
  authType?: AllowedAuthType,
  socialLogin?: {|
    type: SocialLoginType,
    email: string,
    identityToken?: string,
    firstName?: string,
    lastName?: string,
  |},
  settings: {
    didAcceptCookies: boolean,
  },
};

export const makeDefaultState = (): AuthState => ({
  loading: false,
  isLoggedIn: false,
  user: {
    email: '',
    experienceId: null,
    experienceName: null,
    externalId: null,
    firstName: '',
    genderId: null,
    genderName: null,
    goalId: null,
    goalName: null,
    id: null,
    isFreeUser: false,
    isPremium: false,
    lastName: '',
    levelId: null,
    newsletter: false,
    uuid: null,
  },
  settings: {
    didAcceptCookies: !!Cookies.get(LOCALSTORAGE_KEYS.cookieBanner),
  },
});

export default function authReducer(state: AuthState = makeDefaultState(), action: Object): AuthState {
  const makeNewUserState = (params: any = {}): AuthState => ({
    ...state,
    user: {
      ...state.user,
      ...params,
    },
  });

  switch (action.type) {
    case SET_USER_EMAIL:
      return makeNewUserState({ email: action.payload });

    /**
     * @todo Refactor user reducer
     */
    case AUTH_SIGNIN_START:
    case AUTH_FETCHME:
      return {
        ...state,
        error: null,
        loading: true,
      };

    case AUTH_SIGNIN_SUCCESS:
    case AUTH_FETCHME_SUCCESS:
      const { payload } = action;

      payload.isPremium = convertToBit(payload.isFreeUser || payload.isPremium);

      return {
        loading: false,
        user: payload,
        isLoggedIn: true,
        settings: {
          ...state.settings,
        },
      };

    case AUTH_SIGNIN_FAILURE:
    case AUTH_FETCHME_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
        isLoggedIn: false,
      };

    case AUTH_SIGNOUT:
      return {
        ...state,
        error: null,
        loading: true,
      };

    case AUTH_SIGNOUT_SUCCESS:
      return makeDefaultState();

    case AUTH_SIGNOUT_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
        isLoggedIn: false,
      };

    case SET_AUTH_TYPE: {
      const castedAction: SetAuthTypeAction = (action: any);

      return {
        ...state,
        authType: castedAction.payload,
      };
    }
    case SET_SOCIAL_LOGIN: {
      const castedAction: SetAuthSocialLoginAction = (action: any);
      const { payload: socialLogin } = castedAction;

      return {
        ...state,
        socialLogin: {
          ...socialLogin,
          // Keep the previous set email, firstName and lastName if current one not provided.
          // Apple only gives personal information on first request.
          email: socialLogin.email || _get(state, 'socialLogin.email', ''),
          firstName: socialLogin.firstName || _get(state, 'socialLogin.firstName'),
          lastName: socialLogin.lastName || _get(state, 'socialLogin.lastName'),
        },
      };
    }

    case SET_USER_ACCEPTED_COOKIES_ACTION: {
      const castedAction: SetUserAcceptedCookiesAction = (action: any);
      Cookies.set(LOCALSTORAGE_KEYS.cookieBanner, castedAction.payload);

      return {
        ...state,
        settings: {
          ...state.settings,
          didAcceptCookies: castedAction.payload,
        },
      };
    }

    default:
      return state;
  }
}
