import styled from 'styled-components';
import { CLOUDFRONT_STATIC, CSS_BREAKPOINTS_MIN, GUTTER_SIZE } from '../config/constants';
import makeImageUrl from '../modules/makeImageUrl';
import CourseOverviewCard from './CourseOverviewCard';

import CalebLevelsSvg from '../assets/svg/caleb-levels-icon.svg';
import PlaySvg from '../assets/svg/cohort-play.svg';
import LightningSvg from '../assets/svg/caleb-lightning-icon.svg';
import CalendarSvg from '../assets/svg/caleb-small-calendar-icon.svg';
import RecoverySvg from '../assets/svg/recovery-icon.svg';
import HomeSvg from '../assets/svg/cohort-home.svg';
import ClipboardSvg from '../assets/svg/cohort-clipboard-check.svg';
import UnlockSvg from '../assets/svg/cohort-unlock.svg';
import ForumSvg from '../assets/svg/caleb-forum-icon.svg';

const Container = styled.section`
  padding: ${GUTTER_SIZE * 2}px 0 ${GUTTER_SIZE * 4}px;
  background-color: transparent;

  @media ${CSS_BREAKPOINTS_MIN.sm} {
    margin: 0 -${GUTTER_SIZE * 2}px;
    padding: ${GUTTER_SIZE * 2.5}px 40px ${GUTTER_SIZE * 3.5}px;
  }
`;


const Headline = styled.h1`
  font-family: Poppins-Medium, sans-serif;
  font-size: 17px;
  line-height: 26px;
  margin: 0;
  margin-bottom: 24px;
  text-align: left;

  @media ${CSS_BREAKPOINTS_MIN.md} {
    font-size: 30px;
    line-height: 45px;
    margin-bottom: 32px;
    text-align: left;
  }
`;


const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  gap: 16px 0px;
  align-items: start;
  justify-content:center;
  max-width: 100%;

  @media ${CSS_BREAKPOINTS_MIN.md} {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;
    gap: 0px 16px;
    align-items: stretch;
  }
`;


const CALEB_LIST = [
  { id: 1, text: 'For every yoga level', icon: CalebLevelsSvg },
  { id: 2, text: '4-week course', icon: CalendarSvg },
  { id: 3, text: '3 Classes per week', icon: ClipboardSvg },
  { id: 4, text: '1 weekly video inspiration', icon: LightningSvg },
  { id: 5, text: '2 months of Skill Yoga App access', icon: UnlockSvg },
  { id: 6, text: 'Community access to Caleb Packham', icon: HomeSvg },
];

const CALEB_CARD = {
  title: 'Awaken Your True Self by Caleb Jude Packham',
  level: 'All levels',
  duration: '4 weeks',
  subtitle: 'Enliven Your Spirit and Nourish Your Soul Through the Practice of Sattva Yoga',
  price: '$40',
  listItems: CALEB_LIST,
  url: '/caleb-training',
  cover: makeImageUrl({ url: `${CLOUDFRONT_STATIC}/img/pages/course-lp/caleb-course-cover.jpg` }),
};

const ADAM_LIST = [
  { id: 1, text: 'For every yoga level', icon: CalebLevelsSvg },
  { id: 3, text: '4-week course', icon: CalendarSvg },
  { id: 2, text: '2 x arm balance technique break-downs per week', icon: ClipboardSvg },
  { id: 4, text: '2 x 30min yoga flows per week', icon: PlaySvg },
  { id: 5, text: '1 recovery practice and grounding meditation ', icon: RecoverySvg },
];

const ADAM_CARD = {
  title: 'Flying into Arm Balances by Adam Husler',
  level: 'All levels',
  duration: '4 weeks',
  subtitle: '30 days of building the flexibility, strength and skill you’ll need for arm-balancing',
  price: '$40',
  listItems: ADAM_LIST,
  url: '/adam-training',
  cover: makeImageUrl({ url: `${CLOUDFRONT_STATIC}/img/pages/adam-lp/adam-course-banner.jpg` }),
};

const MARLENE_LIST = [
  { id: 1, text: 'For experienced yogis and beginners', icon: CalebLevelsSvg },
  { id: 2, text: '4-week course', icon: CalendarSvg },
  { id: 3, text: '3 Classes per week', icon: ClipboardSvg },
  { id: 4, text: '1 weekly Meditation (10min)', icon: PlaySvg },
  { id: 5, text: '2 months of Skill Yoga App access', icon: UnlockSvg },
  { id: 6, text: 'Direct contact to Marlene Schmitt', icon: ForumSvg },
];

const MARLENE_CARD = {
  title: 'Inner & Outer strength by Marlene Schmitt',
  level: 'Beginners - Intermediate',
  duration: '4 weeks',
  subtitle: '30 Days to rediscover your inner strength and stability through the practice of Myofascial-based Vinyasa Yoga',
  price: '$40',
  listItems: MARLENE_LIST,
  url: '/marlene',
  cover: makeImageUrl({ url: `${CLOUDFRONT_STATIC}/img/pages/marlene-lp/marlene-course-banner-new.jpg` }),
};

/*
const COHORT_LIST = [
  { id: 1, text: 'Great for breathwork beginners', icon: CalendarSvg },
  { id: 2, text: '3 Classes per week', icon: ClipboardSvg },
  { id: 3, text: '1 weekly knowledge session', icon: UsersSvg },
  { id: 4, text: '2 months of Skill Yoga App access', icon: PlaySvg },
  { id: 5, text: 'Community access to James Dowler', icon: HomeSvg },
];

const COHORT_CARD = {
  title: 'Breathwork for Performance with James Dowler',
  level: 'All levels',
  duration: '4 weeks',
  subtitle: '30 Day Course to Optimise Breathing for Physical Performance',
  price: '$40',
  listItems: COHORT_LIST,
  url: '/cohort-training',
  cover: makeImageUrl({ url: `${CLOUDFRONT_STATIC}/img/pages/course-lp/cohort-course-cover.jpg` }),
};
*/

const CourseOverViewCardView = () => (
  <Container>
    <Headline>Master Courses</Headline>
    <Grid>
      <CourseOverviewCard
        {...CALEB_CARD}
      />
      <CourseOverviewCard {...ADAM_CARD} />
      <CourseOverviewCard {...MARLENE_CARD} />
    </Grid>
  </Container>
);

export default CourseOverViewCardView;
