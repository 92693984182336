/* eslint max-len: 0 */
// @flow
import React from 'react';

import SYHeadlineOne from '../components/SYHeadlineOne';
import SYHeadlineTwo from '../components/SYHeadlineTwo';
import LegalPage from './LegalPage';
import SYBody18pt from '../components/SYBody18pt';
import SYUnorderedList from '../components/SYUnorderedList';
import SYBodyTextLink from '../components/SYBodyTextLink';
import SYHeadlineThree from '../components/SYHeadlineThree';

class TermsOfUse extends React.PureComponent<{}> {
  render() {
    return (
      <LegalPage bodyClass="terms">
        <SYHeadlineOne>Terms Of Use / AGB</SYHeadlineOne>

        <SYBody18pt>The following General Terms and Conditions provide the legal framework for using the Skill Yoga website (www.skill-yoga.com) and the Skill Yoga App.</SYBody18pt>
        <SYBody18pt>Therefore please carefully read these General Terms and Conditions.</SYBody18pt>
        <SYBody18pt>Last revised: August 2019</SYBody18pt>


        <SYHeadlineTwo>1. Applicability</SYHeadlineTwo>
        <SYHeadlineThree>1.1 Contract partners and contract subject matter</SYHeadlineThree>
        <SYBody18pt>
          These General Terms and Conditions provide the basis for the user agreement resulting between you and us, Skill Yoga GmbH, Kottbusser Damm 103A, 10967 Berlin (hereinafter “Us” or “We”). The contract subject matter is the free or paid use of services offered by Us on the website
          www.skill-yoga.com
          {' '}
          or the Skill Yoga App (hereinafter individually referred to as an “Skill Yoga Service” or, collectively, as the “Skill Yoga Services” or, generally, “Skill Yoga”).
        </SYBody18pt>
        <SYHeadlineThree>1.2 Additional terms and conditions</SYHeadlineThree>
        <SYBody18pt>We reserve the right to make your use of certain Skill Yoga Services subject to additional terms and conditions. We will notify you before using such additional terms and conditions.</SYBody18pt>
        <SYBody18pt>
          If you use Skill Yoga Services via the Skill Yoga App and you have downloaded the Skill Yoga App from the app store of a third party, the general terms and conditions and/or terms of use of the app store will apply in addition to these General Terms and Conditions. In case of the iTunes store, such terms include, for example, the “End User License Agreement for Licensed Applications” (which you will find at
          {' '}
          <SYBodyTextLink title="More info about this topic" target="_blank" rel="noreferrer" href="https://www.apple.com/legal/internet-services/itunes/ww/">https://www.apple.com/legal/internet-services/itunes/ww/</SYBodyTextLink>
          ). You will find the terms of use for Google Play, for example, at
          {' '}
          <SYBodyTextLink title="More info about this topic" target="_blank" rel="noreferrer" href="https://play.google.com/intl/de_de/about/play-terms.html">https://play.google.com/intl/de_de/about/play-terms.html</SYBodyTextLink>
          . Please be sure to review the terms of use that apply in your country.
        </SYBody18pt>
        <SYBody18pt>In the event of any conflicts, these Terms of Use will prevail.</SYBody18pt>

        <SYHeadlineTwo>2. Health Requirements</SYHeadlineTwo>
        <SYBody18pt>You will use the Skill Yoga Services at your own risk.</SYBody18pt>
        <SYBody18pt>Anytime you use the Skill Yoga Services you must be in good health. If you have any known preexisting illnesses or health conditions, please first consult a physician before you begin to use the Skill Yoga Services. This is particularly important if you suffer from any cardiovascular conditions, spinal and/or joint problems, or other health limitations that limit your athletic performance.</SYBody18pt>
        <SYBody18pt>Please do not use the Skill Yoga Services if, for example, you experience considerable pain, general malaise, shortness of breath, nausea, or dizziness. In such cases please consult your physician before you begin or continue to use Skill Yoga.</SYBody18pt>

        <SYHeadlineTwo>3. Participation Requirements</SYHeadlineTwo>
        <SYBody18pt>To open a user account and to use the Skill Yoga Services you must be at least 18 years old and have full legal capacity.</SYBody18pt>
        <SYBody18pt>By entering into a contract with Us you represent that you have no other user account with Us and that your user account has not been suspended or canceled in the past due to violations of our Terms of Use. In such cases We will not enter into another or new user agreement with you. You also represent that all information provided by you during the registration process is true and complete.</SYBody18pt>

        <SYHeadlineTwo>4. Conclusion of Contract, User Account</SYHeadlineTwo>
        <SYHeadlineThree>4.1 Registration process</SYHeadlineThree>
        <SYBody18pt>
          To use the Skill Yoga Services, you must first register and open a user account. You can open a user account either directly online at
          {' '}
          <SYBodyTextLink title="More info about this topic" target="_blank" rel="noreferrer" href="https://skill-yoga.com/">www.skill-yoga.com</SYBodyTextLink>
          {' '}
          or through the Skill Yoga App. When you register We will ask you to accept these General Terms and Conditions and our Data Privacy Policy.
        </SYBody18pt>
        <SYBody18pt>In the alternative, you can also register with your Facebook account. In that case, the registration process will be complete once you have entered the account information for your Facebook account and have clicked the “Confirm” button.</SYBody18pt>
        <SYBody18pt>How a user agreement will be concluded depends on how you first register and whether you sign up for any additional, paid services.</SYBody18pt>
        <SYHeadlineThree>4.2 Registration online at www.skill-yoga.com</SYHeadlineThree>
        <SYBody18pt>
          If you register on our website
          www.skill-yoga.com
          , a user agreement between you and Us will result after you have completed the registration process.
        </SYBody18pt>

        <SYHeadlineThree>4.3 Registration via mobile apps</SYHeadlineThree>
        <SYBody18pt>If you want to register through the Skill Yoga App, a user agreement will result according to the rules of your app store. As a general rule, a binding contract will result at the moment you click on the “Install” button in the store and you enter your password. To use the Skill Yoga Services, you must however still open a user account through the Skill Yoga App.</SYBody18pt>
        <SYHeadlineThree>4.4  Conclusion of contract for paid subscription</SYHeadlineThree>
        <SYBody18pt>
          You can activate additional services by purchasing a paid subscription. If you purchase additional services on our website
          www.skill-yoga.com
          , a binding contract will result when you click on the “Buy Now” button or a comparable button and you have successfully entered your payment information. If you purchase additional services through the Skill Yoga App, a subscription agreement will result when you click the “Buy Now” button during an in-app purchase or a comparable button and when you have entered your password for the App Store, if required.
        </SYBody18pt>
        <SYHeadlineThree>4.5 Correction of input errors</SYHeadlineThree>
        <SYBody18pt>
          If you wish to purchase a subscription on our website
          www.skill-yoga.com
          , you may cancel the transaction at any time. You can also correct any input errors until you have successfully input your payment information.
        </SYBody18pt>

        <SYBody18pt>If you want to purchase a subscription by in-app purchase through your Skill Yoga App, you will pay for the service through the account with your app store. Therefore please contact your app store if you want to correct any input errors</SYBody18pt>

        <SYHeadlineTwo>5. Contract Terms</SYHeadlineTwo>
        <SYHeadlineThree>5.1 User agreement</SYHeadlineThree>
        <SYBody18pt>The user agreement between you and Us that results when you open a user account will remain in effect for an indefinite term and will end when you delete the Skill Yoga Account and terminate the user agreement.</SYBody18pt>
        <SYHeadlineThree>5.2 Subscriptions</SYHeadlineThree>
        <SYBody18pt>Our subscriptions have different minimum contract terms. All subscriptions will be automatically renewed for the time period of the selected minimum contract term until either you or We terminate the subscription. You may however opt out of the automatic renewal of your subscription at any time by choosing the appropriate settings in the account with your app store.</SYBody18pt>

        <SYHeadlineTwo>6. Right of revocation</SYHeadlineTwo>
        <SYBody18pt>In some countries you have a legal right of revocation. This is the case, for example, in all countries of the European Union. Please refer to those terms of use to find out to what extent you have such rights. In the United States, for example, you have no right of revocation.</SYBody18pt>
        <SYBody18pt>If you have a legal right of revocation, please read the following information about how you can exercise this right and what revocation will mean for you.</SYBody18pt>
        <SYHeadlineThree>6.1 Information about revocation</SYHeadlineThree>
        <SYBody18pt>If you have a legal right of revocation, this right will come into existence as soon as you enter into a user agreement or subscription agreement with us.</SYBody18pt>
        <SYBody18pt>You may revoke the contract within 14 days of the contract date for any or no reason, in which case the contract will be unwound.</SYBody18pt>
        <SYBody18pt>To exercise your right of revocation, you must clearly notify Us (e.g., by letter, or e-mail) that you no longer wish to be bound by the contract. For this purpose you may, but do not have to, use the attached standard revocation form. Any clear notice will suffice. If you exercise this option, We will confirm receipt of your notice of revocation (e.g., by e-mail). Your notice of revocation will be timely as long as you send it before the revocation period has expired. Please address any notice of revocation to the following address:</SYBody18pt>
        <SYBody18pt>Skill Yoga GmbH</SYBody18pt>
        <SYBody18pt>
          c/o US+
          <br />
          <em>Kottbusser Damm 103A</em>
          <br />
          <em>10967 Berlin</em>
        </SYBody18pt>
        <SYHeadlineThree>6.2 Consequences of revocation</SYHeadlineThree>
        <SYBody18pt>If you revoke the contract, the contract will be unwound. You will receive a refund for any payments you have already made for the revoked contract. We will issue the refund at the latest 14 days after We receive your notice of revocation. The refund will be made by the same method you used for the original transaction, unless We expressly agree otherwise with you.</SYBody18pt>
        <SYBody18pt>If you have requested that services begin during the notice period for revocation, you must compensate Us in a reasonable amount for any services you have already received. This will be a prorated amount of the total amount due for the booked service, based on the time period from the start date of service until the date you provide Us with notice of revocation.</SYBody18pt>
        <SYHeadlineThree>6.3 Standard form for notice of revocation</SYHeadlineThree>
        <SYBody18pt>You may use the following form for your notice of revocation.</SYBody18pt>
        <SYBody18pt>Please be sure to always tell Us which Skill Yoga service you wish to revoke. You can simply send Us an e-mail to</SYBody18pt>
        <SYBody18pt>
          <SYBodyTextLink title="More info about this topic" target="_blank" rel="noreferrer" href="https://skillyoga.atlassian.net/servicedesk/customer/portals">Customer Support</SYBodyTextLink>
          .
        </SYBody18pt>
        <SYBody18pt>You can also mail your notice of revocation to the following address:</SYBody18pt>
        <SYBody18pt>
          Skill Yoga GmbH
          <br />
          <em>c/o US+</em>
          {' '}
          <br />
          <em>Kottbusser Damm 103A</em>
        </SYBody18pt>
        <SYBody18pt><em>10967 Berlin</em></SYBody18pt>
        <SYBody18pt>You can copy and paste into an e-mail or print out the following text for your notice of revocation:</SYBody18pt>
        <SYBody18pt>I/We (*) hereby revoke the contract for the purchase of the following product (*)/the following service (*) made by me/us(*):</SYBody18pt>
        <SYBody18pt>Ordered/received on (*)</SYBody18pt>
        <SYBody18pt>Name of consumer (…)</SYBody18pt>
        <SYBody18pt>Address of consumer (…)</SYBody18pt>
        <SYBody18pt><strong>Signature of consumer (…)</strong></SYBody18pt>
        <SYBody18pt><strong>Date</strong></SYBody18pt>
        <SYBody18pt>(*) please delete inapplicable alternative</SYBody18pt>

        <SYHeadlineTwo>7. Termination</SYHeadlineTwo>
        <SYHeadlineThree>7.1 User agreement</SYHeadlineThree>
        <SYBody18pt>You may cancel your user account at any time for any or no reason and thereby also terminate your user agreement in its entirety.</SYBody18pt>
        <SYBody18pt>To do so, you must send Us an e-mail via https://skillyoga.atlassian.net/servicedesk/customer/portals so that We can erase your user data. Please note that after your user account has been canceled all content and workout performance data will or may be erased by Us and you will no longer have access to content you have already purchased.</SYBody18pt>
        <SYBody18pt>If at the time you cancel your account you still have a valid subscription, any amount you may already have paid for the subscription will not be refunded – either in whole or in part.</SYBody18pt>
        <SYBody18pt>We have the right to terminate the user agreement for any or no reason in text form with two weeks’ prior notice, however not before the end of the minimum contract term or the end of the applicable renewal term of your subscription.</SYBody18pt>
        <SYHeadlineThree>7.2 Subscriptions</SYHeadlineThree>
        <SYBody18pt>You may terminate your subscription at any time for any or no reason effective as of the end of the minimum contract term or the end of the applicable renewal term.</SYBody18pt>
        <SYBody18pt>Subscriptions purchased by in-app purchase must be canceled by changing the appropriate settings in the app store where you bought the subscription. If your subscription fee is collected by iTunes, We ask, notwithstanding the above, that for technical reasons you comply with a notice period of 24 hours to the end of the minimum contract term or the end of the respective renewal term. After cancellation of your subscription your user account and any other subscriptions will continue unless and until you also cancel such additional subscriptions.</SYBody18pt>
        <SYBody18pt>Current in-app subscriptions cannot be terminated during the contract term.</SYBody18pt>
        <SYBody18pt>
          In the alternative, you may also send Us an e-mail to
          {' '}
          <SYBodyTextLink title="More info about this topic" target="_blank" rel="noreferrer" href="https://skillyoga.atlassian.net/servicedesk/customer/portals">Customer Support</SYBodyTextLink>
          {' '}
          or send Us a letter by regular mail if you purchased the subscription on the website.
        </SYBody18pt>
        <SYBody18pt>We have the right to cancel any subscription at the end of the minimum contract term or the end of the applicable renewal term by providing you with two weeks’ prior notice in text form.</SYBody18pt>
        <SYHeadlineThree>7.3 Termination for good cause</SYHeadlineThree>
        <SYBody18pt>Irrespective of the foregoing provisions, either party has the right to terminate contracts for good cause. In particular, We have the right to terminate the user agreement or your subscription with immediate effect and to cancel your user account if you have seriously or repeatedly breached provisions of the user agreement and/or these General Terms and Conditions or if you are behind with payment despite demand.</SYBody18pt>

        <SYHeadlineTwo>8. Skill Yoga Services</SYHeadlineTwo>
        <SYBody18pt>You may use the Skill Yoga services free of charge. You will receive access to the full range of training videos and available workouts as part of a paid subscription.</SYBody18pt>
        <SYBody18pt>When you download and use the Skill Yoga App, in particular through third-party networks or in other countries, you may incur transmission charges of your Internet service provider</SYBody18pt>
        <SYBody18pt>We strive to provide you with uninterrupted operation of the Skill Yoga Services and to make those services available as continuously as possible. We advise you however that full or uninterrupted availability is technically impossible, and We make no warranty for uninterrupted operation or any particular availability.</SYBody18pt>
        <SYBody18pt>Our applications are continuously updated and adjusted for your security and for the stability of our applications. As a result, system requirements may change. We assume no obligation to make available to you any application (such as the Skill Yoga App) that will be functional on your terminal device at all times, if functionality on your terminal device should be limited by technological changes.</SYBody18pt>
        <SYBody18pt>We reserve the right to change our business model at any time and, for example, to provide any or all Skill Yoga Services only in exchange for payment. You will then have the option to decide whether you wish to continue using the Skill Yoga Services in exchange for payment or stop using the Skill Yoga Services. Any such changes will have no effect on existing subscriptions until the end of the contract term.</SYBody18pt>
        <SYBody18pt>Our understanding of human health and athletic performance is evolving on a continuous basis. This may affect how workouts are evaluated. Even though our workouts are based on current studies and findings, We make no guarantee that our workouts are in conformity with current research results or findings.</SYBody18pt>
        <SYBody18pt>Please note that you may need certain tools or workout equipment to be able to fully use some Skill Yoga Services. Such tools or equipment are not part of the Skill Yoga Services and, if needed, must be purchased separately by you at your own cost.</SYBody18pt>
        <SYHeadlineTwo>9. Rights and Obligations of the User</SYHeadlineTwo>
        <SYBody18pt>The Skill Yoga Services are offered exclusively to consumers. This means you may not use the Skill Yoga Services for business or other commercial purposes.</SYBody18pt>
        <SYBody18pt>You may not allow any third parties to use your user account, and you are not permitted to</SYBody18pt>
        <SYUnorderedList>
          <li>use paid services simultaneously on multiple terminal devices, unless such use is expressly permitted under the terms of the offered service;</li>
          <li>allow any third parties to access or perceive the Skill Yoga Services, e.g., an undefined group of people by using the services in a public area (e.g., movie theaters, theaters, exhibitions, showrooms, hotels, bars, restaurants, or other public areas);</li>
          <li>make accessible or transfer to any third parties access data transmitted or used for authentication or identification purposes;</li>
          <li>circumvent any access control systems for paid services or take any other action to use services without authorization;</li>
          <li>introduce into our IT systems any viruses, worms, Trojan horses, or other malware that may jeopardize or impair the functionality of the Skill Yoga Services; or</li>
          <li>transfer or assign any rights or obligations under your contract with Us to any third parties.</li>
        </SYUnorderedList>
        <SYBody18pt>When using the Skill Yoga Services you must also comply with the terms of contracts with third parties, in particular with your app store or your Internet service provider.</SYBody18pt>
        <SYBody18pt>To guarantee smooth communication with you, We ask that you include our e-mail address in the list of trusted senders at your email provider.</SYBody18pt>
        <SYHeadlineTwo>10. Prices and Payment Terms</SYHeadlineTwo>
        <SYHeadlineThree>10.1  Prices</SYHeadlineThree>
        <SYBody18pt>Our prices differ from country to country. If you decide to purchase a subscription, the price that counts for you will be shown on the Skill Yoga website or the Skill Yoga App.</SYBody18pt>
        <SYBody18pt>
          Please check the website
          {' '}
          www.skill-yoga.com
          {' '}
          for current prices/subscription models and for available services. All quoted prices are exclusive of applicable value-added tax.
        </SYBody18pt>
        <SYBody18pt>We reserve the right to change prices at our sole discretion. Any price changes will however have no effect on subscriptions you have already purchased.</SYBody18pt>
        <SYHeadlineThree>10.2  Collection of fees</SYHeadlineThree>
        <SYBody18pt>Fees for a subscription are collected in advance for the applicable minimum contract term when the subscription agreement is concluded. If the subscription is automatically renewed, the fee will be collected in advance at the beginning of the applicable renewal term.</SYBody18pt>
        <SYHeadlineThree>10.3  Payment methods</SYHeadlineThree>
        <SYBody18pt>
          If you book paid Skill Yoga Services by in-app purchase, you will be charged by the app store. Please inquire with the app store which payment methods are available to you. I you book paid Skill Yoga Services on our website
          {' '}
          www.skill-yoga.com
          , you will be shown a list of currently accepted payment methods during the ordering process from which you may choose. If payment is declined for reasons for which you are responsible (e.g., because the amount paid is not covered by funds in your account or because the limit of your credit card has been exhausted), We have the right to charge you for any costs and/or expenses actually incurred by Us as a result.
        </SYBody18pt>
        <SYBody18pt>We reserve the right not to offer certain payment methods and to refer you to other payment methods if there is a valid reason for doing so.</SYBody18pt>
        <SYHeadlineThree>10.4  Payment default</SYHeadlineThree>
        <SYBody18pt>We reserve the right to pursue additional remedies that may be available to Us as a result of your payment default.</SYBody18pt>
        <SYHeadlineTwo>11. Responsibility for Content and Services</SYHeadlineTwo>
        <SYHeadlineThree>11.1  No responsibility for third-party content or services</SYHeadlineThree>
        <SYBody18pt>The Skill Yoga Services may include links to websites or apps of third-party providers. In some cases, content made available by third parties may be shown or the Skill Yoga Services may enable you to use additional services of third parties. We strive to design our applications in such a way that this will be readily apparent to you. All content and/or services of third-party providers are subject to the applicable terms, conditions, and rules of such third-party providers. We hereby expressly disclaim any responsibility or liability for content or services of third parties. You are personally responsible for making sure that you do not violate the terms and conditions of such third-party providers.</SYBody18pt>
        <SYHeadlineThree>11.2  No responsibility for user-generated content</SYHeadlineThree>
        <SYBody18pt>You are personally responsible for all content you upload using the Skill Yoga Services. We do not endorse or review such content.</SYBody18pt>
        <SYHeadlineThree>11.3  Responsibility of the user for violations of law</SYHeadlineThree>
        <SYBody18pt>When posting or making available your own content, you must comply with all applicable laws and other regulations that are applicable in the country in which you are using our Skill Yoga Services. Whether or not prohibited by criminal law, you are generally prohibited from making available content of a pornographic, sexual, violent, racist, inflammatory, discriminatory, offensive and/or defamatory nature.</SYBody18pt>
        <SYBody18pt>In addition, it is your responsibility to make sure that you infringe no third-party rights, including, without limitation, privacy or publicity rights of third parties and intellectual property rights of third parties (e.g., copyrights, trademark rights, etc.). In this connection, We advise you that you must also, and in particular, own the necessary rights to your profile image and to any other photos you upload.</SYBody18pt>
        <SYBody18pt>We may at any time remove and erase any content that is unlawful or violates the aforementioned principles. If you violate the aforementioned principles, We also have the right to send you a warning that We will suspend your user account or terminate the user agreement for good cause in its entirety in accordance with Section 7.3.</SYBody18pt>
        <SYHeadlineThree>11.4  Indemnity</SYHeadlineThree>
        <SYBody18pt>In the event that you have wrongfully (negligently or intentionally) violated any of the principles in Section 11.3, you are obligated to indemnify Us from and against any claims of third parties that may be brought as a result of such violations. We further reserve the right to claim damages and to pursue other remedies.</SYBody18pt>
        <SYHeadlineTwo>12. Warranties</SYHeadlineTwo>
        <SYHeadlineThree>12.1  Applicable law</SYHeadlineThree>
        <SYBody18pt>Any claims for defective service will be governed by applicable law. Your rights as a consumer remain unaffected.</SYBody18pt>
        <SYHeadlineThree>12.2  No warranty</SYHeadlineThree>
        <SYBody18pt>We make no representations or warranties that by using the chosen Skill Yoga Service you will reach your workout goal or achieve any other results.</SYBody18pt>
        <SYHeadlineTwo>13. Liability</SYHeadlineTwo>
        <SYHeadlineThree>13.1  General provisions</SYHeadlineThree>
        <SYBody18pt>When using our Skill Yoga Services you will be shown workout instructions (e.g., for certain exercises and workouts). You must follow those instructions at all times as otherwise there will be an injury and/or health risk.</SYBody18pt>
        <SYBody18pt>If you use any tools or equipment for exercises, you are fully responsible for making sure that such tools and/or equipment work properly and are installed and/or set up properly.</SYBody18pt>
        <SYBody18pt>You are obligated to heed our health notices in Section 2.</SYBody18pt>
        <SYHeadlineThree>13.2  Liability for free services</SYHeadlineThree>
        <SYBody18pt>In case of free services, our liability, whatever the legal basis, is limited exclusively to damages resulting from intentional or grossly negligent actions or omissions or from a lack of warranted qualities. Our liability for intentional actions or omissions is generally unlimited. Our liability for gross negligence or a lack of warranted qualities is limited to reasonably foreseeable damages. In all other cases any liability on our part is excluded.</SYBody18pt>
        <SYHeadlineThree>13.3  Liability for paid services</SYHeadlineThree>
        <SYBody18pt>In case of paid services, our liability, whatever the legal basis, is generally unlimited for damages resulting from intentional or grossly negligent actions or omissions or from a lack of warranted qualities.</SYBody18pt>
        <SYBody18pt>If We breach any material contractual obligations due to ordinary negligence, our liability is limited to reasonably foreseeable damages. A contractual obligation is material within the meaning of the foregoing sentence if its performance is necessary for achieving contractual purposes and if you, the consumer, may reasonably rely on its performance.</SYBody18pt>
        <SYBody18pt>Our liability for wrongful harm to life, limb, or health remains unaffected by the foregoing limitations.</SYBody18pt>
        <SYBody18pt>In all other cases any liability on our part is excluded.</SYBody18pt>
        <SYHeadlineThree>13.4  Liability of our employees</SYHeadlineThree>
        <SYBody18pt>The above limitations of liability (see Sections 13.2 and 13.3) also apply for the benefit of our employees and agents.</SYBody18pt>
        <SYHeadlineThree>13.5  Product liability</SYHeadlineThree>
        <SYBody18pt>
          Any claims under the German Product Liability Act (
          <em>Produkthaftungsgesetz</em>
          ) remain unaffected by the aforementioned exclusions or limitations of liability.
        </SYBody18pt>
        <SYHeadlineTwo>14. Licensed Rights</SYHeadlineTwo>
        <SYHeadlineThree>14.1  Skill Yoga content</SYHeadlineThree>
        <SYBody18pt>The services offered by Us in some cases include copyright protected or otherwise protected content to which We own the necessary rights. For example, the Skill Yoga App is a copyright protected software program.</SYBody18pt>
        <SYBody18pt>To the extent necessary for achieving the contractual purpose, We hereby license to you a non-exclusive, non-transferable right to use such protected content for non-commercial purposes in accordance with the terms of the contract. Note that you are prohibited from disseminating or making such content publicly available, e.g., on websites. Neither the Skill Yoga App nor its content may be leased or otherwise transferred to any third parties by you. You may not decompile, alter, or edit the app except as permitted by law.</SYBody18pt>
        <SYBody18pt>Licensed rights will lapse if and when you no longer have access to the relevant service (e.g., after your subscription has been terminated) or when the user agreement has ended. In the event of any violation of these provisions, We will have the right to terminate the agreement for good cause without notice in accordance with Section 7.3.</SYBody18pt>
        <SYHeadlineThree>14.2  Content uploaded by user</SYHeadlineThree>
        <SYBody18pt>To ensure that the Skill Yoga Services will at all times be fully operational, We need to be able to use, at any time, at any place, and without limitation, all content uploaded by the user. You therefore hereby license to us, throughout the world, in perpetuity and without limitation, non-exclusive rights to all protected content that is uploaded by you. We may sublicense these rights to third parties, which shall include, without limitation, the rights to reproduce, disseminate (by wirebound or wireless means), make available or communicate to the public, and edit protected content (for example, by changing the image resolution or image size for technical reasons). You further hereby license to Us the right to make all content uploaded by you also available to other users, provided that this is done within the scope of the contractual purpose (for example, by reporting your copyright protected messages to the coach or other users).</SYBody18pt>
        <SYHeadlineTwo>15. Personal data</SYHeadlineTwo>
        <SYBody18pt>We will process your personal data in compliance with our Data Privacy Policy. You will find the most recent version of our Data Privacy Policy at https://skill-yoga.com/pages/privacy-policy. The Data Privacy Policy regulates and discloses, in particular, to what extent your personal data will be viewable by other users and what options you have to control transfers of your personal data. In addition, We will inform you in detail how and where We process or cause third parties to process your personal data. By entering into the user agreement with Us you expressly consent to the foregoing provisions. Your legal rights of revocation remain unaffected.</SYBody18pt>
        <SYHeadlineTwo>16. Changes to General Terms and Conditions</SYHeadlineTwo>
        <SYBody18pt>We reserve the right to make changes to these General Terms and Conditions with effect for the future. We will notify you of any changes to our General Terms and Conditions at the latest four weeks before the planned effective date of the new version. You will have the opportunity to object to changes within the aforementioned four-week period. If you continue to use our Skill Yoga Services without objection, you will be deemed to have accepted the new General Terms and Conditions. If you do object to changes, We hereby expressly reserve our right to terminate the agreement by regular notice during its proper term. If and when We make changes to the General Terms and Conditions, We will once again advise you of your right to object, the time period within which the right of objection must be exercised, and the legal consequences of such objection.</SYBody18pt>
        <SYHeadlineTwo>17. Final Provisions</SYHeadlineTwo>
        <SYHeadlineThree>17.1  Official contract language</SYHeadlineThree>
        <SYBody18pt>The official contract language is German.</SYBody18pt>
        <SYHeadlineThree>17.2  Governing substantive law</SYHeadlineThree>
        <SYBody18pt>All dealings between the parties are governed exclusively by German law, under exclusion of the provisions of the UN Convention on Contracts for the International Sale of Goods (CISG). Business transactions with consumers residing in the European Union may also be subject to the law that is applicable at the place of the consumer’s residence, if and to the extent that mandatory consumer protection provisions are involved.</SYBody18pt>
        <SYHeadlineThree>17.3  Venue and Jurisdiction</SYHeadlineThree>
        <SYBody18pt>
          If there is no court in Germany or another EU country where venue and jurisdiction is proper based on your residence, habitual abode, or corporate domicile (
          <em>allgemeiner Gerichtsstand</em>
          ), or if you relocate your permanent place of residence to another country outside the EU after the effective date of these General Terms and Conditions, or if your place of residence or habitual abode is unknown at the time legal action is filed, exclusive venue and jurisdiction for any and all disputes arising from this Agreement will be in the courts at the place of our registered office.
        </SYBody18pt>
        <SYHeadlineThree>17.4  Severability</SYHeadlineThree>
        <SYBody18pt>
          If any provisions of these General Terms and Conditions should be or become invalid in whole or in part, the validity of the remaining provisions will remain unaffected thereby. The foregoing provisions apply,
          <em>mutatis mutandis</em>
          , if any provisions should have been inadvertently omitted from these General Terms and Conditions.
        </SYBody18pt>
        <SYHeadlineTwo>18. Downloading and Review Options</SYHeadlineTwo>
        <SYBody18pt>You may review and print out these General Terms and Conditions at any time at https://skill-yoga.com/pages/Skill Yoga-terms-of-use. Additional information regarding your user agreement and your subscription are available in your user account. In the alternative, you may also print out or download the automated order confirmation you received after placing the order.</SYBody18pt>
        <SYHeadlineTwo>19. Information about Provider</SYHeadlineTwo>
        <SYBody18pt>
          <em>Skill Yoga GmbH</em>
          {' '}
          <br />
          <em>Kottbusser Damm 103A</em>
        </SYBody18pt>
        <SYBody18pt>
          <em>10967 </em>
          <em>Berlin</em>
        </SYBody18pt>
        <SYBody18pt>
          Skill Yoga GmbH
          <br />
          Kottbusser Damm 103a
          <br />
          Berlin 10967
          <br />
          Germany
          <br />
          Contact: https://skillyoga.atlassian.net/servicedesk/customer/portals
          <br />
          Managing directors: Gerjet Efken, Benno Mielke, Timko Linssen
          <br />
          Recorded in the Commercial Register at the Local Court of Berlin Charlottenburg under number HRB 196904 B
          <br />
          VAT ID: DE319610005
        </SYBody18pt>
        <SYHeadlineTwo>20. Dispute Resolution</SYHeadlineTwo>
        <SYBody18pt>The European Commission makes available a platform for online dispute resolution (ODR platform). You can access the ODR platform at www.ec.europa.eu/consumers/odr. You will find our e-mail address in the imprint.</SYBody18pt>
        <SYBody18pt>We have no obligation to participate in any mediation proceedings before any consumer mediation board (ADR) or in any online dispute resolution of the EU (ODR), nor do We intend to do so.</SYBody18pt>
      </LegalPage>
    );
  }
}

export default TermsOfUse;
