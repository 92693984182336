// @flow

import fonts from './fonts';
import headlineStyles from './headlines';
import globalSkillYogaClasses from './globalSkillYogaClasses';
import reactSlickStyles from './thirdParty/reactSlick';

export default Object.freeze({
  ...fonts,
  '@global': {
    h1: headlineStyles.h0,
    h1: headlineStyles.h1,
    h2: headlineStyles.h2,
    h3: headlineStyles.h3,
    h4: headlineStyles.h4,
    h5: headlineStyles.h5,
    h6: headlineStyles.h6,
    h7: headlineStyles.h7,
    h8: headlineStyles.h8,
    ...globalSkillYogaClasses,
    ...reactSlickStyles,
  },
});
