// @flow
import styled from 'styled-components';
import {
  CSS_BREAKPOINTS_MIN, PAGE_CONTENT_WIDTH, PAGE_PADDING_LEFT_RIGHT, SPACING_BASE,
} from '../config/constants';
import DividerLineCSSBottom from './shared-styles/DividerLineCSSBottom';

const PageElementContainer = styled.section`
  max-width: ${PAGE_CONTENT_WIDTH}px;
  padding: 80px ${SPACING_BASE * 3}px;
  margin: 0 auto;
  ${(props) => (props.divider ? DividerLineCSSBottom : null)}
  
  @media ${CSS_BREAKPOINTS_MIN.md} {
    width: 100%;
    padding: 100px ${SPACING_BASE * 3}px;
  }
  
  @media ${CSS_BREAKPOINTS_MIN.lg} {
    padding: 120px ${PAGE_PADDING_LEFT_RIGHT}px;
  }
`;

export default PageElementContainer;
