// @flow

import React from 'react';
import styled from 'styled-components';

import hexToRgba from 'hex-to-rgba';
import GlobalInputStyle from './GlobalInputStyle';
import { SYInputWrapper } from './SYFormElements';
import {
  ERROR_COLOR,
  GREY_01_COLOR, GUTTER_SIZE, SPACING_BASE, TRANSITION_DEFAULT_LEN, WHITE_COLOR,
} from '../../config/constants';
import SYInput from './SYInput';
import { ReactComponent as EyeSvg } from '../../assets/svg/eye.svg';

const FloatingLabelWrapper = styled(SYInputWrapper)`
  width: 100%;
  margin: 0 0 ${GUTTER_SIZE}px;
`;

const FloatingLabelInput = styled.div`
  width: 100%;
  padding-top: ${SPACING_BASE * 4}px; // for the floating label
  border-bottom: 1px solid ${(props) => hexToRgba(props.error ? ERROR_COLOR : WHITE_COLOR, props.focus ? 1 : 0.4)};
`;

const FloatingLabelInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
  height: 2em;
  font-size: inherit;
`;

const FloatingLabel = styled.label`
  position: absolute;
  bottom: 0;
  width: 100%;
  margin: 0;
  padding: 0 ${(props) => (props.active ? 0 : `${SPACING_BASE * 6}px`)} ${(props) => (props.active ? 0 : `${SPACING_BASE}px`)} 0;
  border: 0;
  color: ${(props) => (props.error ? ERROR_COLOR : GREY_01_COLOR)};
  transition: all ${TRANSITION_DEFAULT_LEN / 2}s ease-in-out;
  transform-origin: left top;
  font-size: ${(props) => (props.active ? '11px' : '1em')};
  cursor: text;
  pointer-events: none;
  transform: ${(props) => (props.active ? 'translate3d(0, -35px, 0)' : 'none')};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-transform-style: preserve-3d;
  -webkit-backface-visibility: hidden;
`;

const FloatingInput = styled.input`
  padding: 0 ${(props) => (props.isPassword ? '30px!important' : '0')} 0 0;
  margin: 0;
  border: none;
  outline: none;
  font-size: 1em;

  &::placeholder {
    opacity: ${(props) => (props.active ? 1 : 0)};
    transition: opacity ${TRANSITION_DEFAULT_LEN / 2}s cubic-bezier(0.6, 0.04, 0.98, 0.335);
  }

  ${GlobalInputStyle};
`;

const PwWrapperHelper = styled.div`
  position: relative;
  width: 100%;

  ${SYInput} {
    padding-right: ${SPACING_BASE * 6}px;
  }
`;

const PwIcon = styled.svg`
  ${(props) => (props.active === 'true') && (`
    path {
      fill: ${WHITE_COLOR};
    }
  `)}
`;

const PwToggle = styled.div`
  position: absolute;
  right: 0;
  top: 50%;
  padding: ${SPACING_BASE}px;
  transform: translateY(-50%);
  cursor: pointer;
`;

class TextInput extends React.Component<{|
  className?: string,
  disabled?: boolean,
  error?: string,
  label: string,
  name: string,
  onBlur?: Function,
  onChange?: Function,
  onFocus?: Function,
  onKeyDown?: Function,
  style?: Object,
  type?: string,
  value: string,
  readOnly?: boolean,
  active?: boolean,
|}, {|
  active: boolean,
  focus: boolean,
  showPassword: boolean,
|}> {
  static defaultProps = {
    className: undefined,
    disabled: false,
    error: undefined,
    onBlur: undefined,
    onChange: undefined,
    onFocus: undefined,
    onKeyDown: undefined,
    readOnly: false,
    style: undefined,
    type: 'text',
  };

  state = {
    /* eslint react/destructuring-assignment:0 */
    active: this.props.value.length > 0,
    focus: false,
    showPassword: false,
  };

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    if (prevProps.active != this.props.active) {
      this.setState({
        active: !!this.props.active,
      });
    }
  };

  onShowPwClick = () => {
    const { showPassword } = this.state;

    this.setState({ showPassword: !showPassword });
  };

  onFocus = (e: SyntheticFocusEvent<HTMLInputElement>) => {
    const { onFocus } = this.props;

    this.setState({ active: true, focus: true });

    if (onFocus) {
      onFocus(e);
    }
  };

  onBlur = (e: SyntheticInputEvent<HTMLInputElement>) => {
    const { error, onBlur } = this.props;
    const {
      target: {
        value,
      },
    } = e;

    // keep the active state in case there's an error
    if (error) {
      this.setState({ active: true, focus: false });
    } else {
      this.setState({ active: value.length !== 0, focus: false });
    }

    if (onBlur) {
      onBlur(e);
    }
  };

  renderDefaultInput = () => {
    const { active } = this.state;
    const {
      className,
      disabled,
      error,
      label,
      name,
      onBlur,
      onFocus,
      onChange,
      type,
      ...rest
    } = this.props;

    const attributes = type === 'email'
      ? {
        ...rest, autoCapitalize: 'off', autoComplete: 'email', autoCorrect: 'off',
      }
      : { ...rest };

    return (
      <FloatingInput
        id={`fli-${name}`}
        name={name}
        active={active}
        onBlur={this.onBlur}
        onFocus={this.onFocus}
        onChange={onChange}
        type={type}
        disabled={disabled}
        title={error}
        {...attributes}
      />
    );
  };

  renderPasswordInput = () => {
    const { active, showPassword } = this.state;
    const {
      className,
      disabled,
      error,
      label,
      name,
      onBlur,
      onFocus,
      onChange,
      type,
      ...rest
    } = this.props;

    return (
      <PwWrapperHelper>
        <FloatingInput
          id={`fli-${name}`}
          name={name}
          active={active}
          onBlur={this.onBlur}
          onFocus={this.onFocus}
          onChange={onChange}
          type={showPassword ? 'text' : 'password'}
          disabled={disabled}
          title={error}
          isPassword
          {...rest}
        />

        <PwToggle>
          <PwIcon
            active={showPassword.toString()}
            as={EyeSvg}
            onClick={this.onShowPwClick}
          />
        </PwToggle>
      </PwWrapperHelper>
    );
  };

  render(): React$Element<*> {
    const {
      className,
      error,
      label,
      name,
      style,
      type,
    } = this.props;

    const { active, focus } = this.state;

    return (
      <FloatingLabelWrapper style={style}>
        <FloatingLabelInput error={error} focus={focus}>
          <FloatingLabelInputContainer className={className}>
            <FloatingLabel
              className={className}
              htmlFor={`fli-${name}`}
              active={active}
              error={error}
            >
              {error || label}
            </FloatingLabel>

            {type === 'password'
              ? this.renderPasswordInput()
              : this.renderDefaultInput()}
          </FloatingLabelInputContainer>
        </FloatingLabelInput>
      </FloatingLabelWrapper>
    );
  }
}

export default TextInput;
