// @flow

import { css } from 'styled-components';
import hexToRgba from 'hex-to-rgba';

import { BLACK_COLOR, WHITE_COLOR } from '../../config/constants';

const DividerLineCSSBottom = css`
  box-shadow: 0 1px 0 0 ${hexToRgba(WHITE_COLOR, 0.1)};
  border-bottom: solid 1px ${BLACK_COLOR};
`;

export default DividerLineCSSBottom;
