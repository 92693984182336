import styled from 'styled-components';
import { TitleElement } from './text/CohortLp/CohortLpTextElements';
import GenericCardBlack from './GenericCardBlack';
import { CSS_BREAKPOINTS_MIN, GUTTER_SIZE, PRIMARY_COLOR } from '../config/constants';
import SYPrimaryButton from './SYPrimaryButton';

import UsersSvg from '../assets/svg/cohort-users.svg';
import PlaySvg from '../assets/svg/cohort-play.svg';
import HomeSvg from '../assets/svg/cohort-home.svg';
import WindSvg from '../assets/svg/cohort-wind.svg';
import DocumentSvg from '../assets/svg/cohort-document-empty.svg';
import UnlockSvg from '../assets/svg/cohort-unlock.svg';
import ClipboardSvg from '../assets/svg/cohort-clipboard-check.svg';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${GUTTER_SIZE * 2}px 0;

  @media ${CSS_BREAKPOINTS_MIN.sm} {
    padding: ${GUTTER_SIZE * 2.5}px 0 ${GUTTER_SIZE * 3.5}px;
  }
`;

const CTA = styled(SYPrimaryButton)`
  width: 100%;
  margin-top: ${GUTTER_SIZE * 2}px;
`;

const Title = styled.h4`
  font-size: 20px;
  line-height: 30px;
  text-align: center;
`;

const PriceTag = styled.div`
  padding: ${GUTTER_SIZE}px 0;
  font-family: Poppins-Medium, sans-serif;
  font-size: 58px;
  line-height: 58px;
  text-align: center;
  letter-spacing: 0.4px;
`;

const List = styled.ul`
  margin: ${GUTTER_SIZE}px 0 ${GUTTER_SIZE * 2}px;
`;

const ListEntry = styled.li`
  display: flex;
  align-items: flex-start;
  margin: 0 0 ${GUTTER_SIZE}px;

  img {
    margin: 2px 10px 0 0;
  }
`;

const OrangeDivider = styled.div`
  height: 1px;
  margin: 0 -${GUTTER_SIZE}px ${GUTTER_SIZE * 1.5}px;
  border-top: 1px solid ${PRIMARY_COLOR}
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${GUTTER_SIZE / 2}px;
`;

const Key = styled.div`
  display: flex;
  align-items: center;
  font-family: Poppins-Light, sans-serif;
  font-size: 15px;
  line-height: 26px;
  letter-spacing: 0.075px;
  img {
    margin-right: ${GUTTER_SIZE}px;
  }
  @media ${CSS_BREAKPOINTS_MIN.sm} {
    font-size: 16px;
  }
`;

const Value = styled(Key)`
  font-family: Poppins-Semi-Bold, sans-serif;
`;

const SubTitle = styled.h5`
  margin: 0 auto ${GUTTER_SIZE}px;
  padding: 0 ${GUTTER_SIZE * 2}px;
  font-size: 15px;
  line-height: 26px;
  text-align: center;
  letter-spacing: 0.075px;
`;

const LIST = [
  { id: 1, text: '5 live events on Sundays at 6pm CET / 11am CT', icon: UsersSvg },
  { id: 2, text: '1:1 Coaching session and Q&A with Coach', icon: ClipboardSvg },
  { id: 3, text: '4 video-based weekly lessons', icon: PlaySvg },
  { id: 4, text: '25 on-demand yoga classes', icon: HomeSvg },
  { id: 5, text: '30 meditations and breathwork sessions', icon: WindSvg },
  { id: 6, text: 'PDF guidebooks', icon: DocumentSvg },
  { id: 7, text: 'Full access to the Skill Yoga App', icon: UnlockSvg },
];

const CohortPriceOverview = ({ onCTAClick, ctaTitle = 'ENROLL NOW' }) => (
  <Container>
    <TitleElement>30 day online course to build a health and mindfulness routine</TitleElement>

    <GenericCardBlack>
      <Title>Join the upcoming cohort for</Title>
      <PriceTag>€120</PriceTag>
      <SubTitle>Save 25% if you already have a running Skill Yoga subscription</SubTitle>

      <Title style={{ textAlign: 'left' }}>This course includes</Title>

      <List>
        {LIST.map(({ id, text, icon }) => (
          <ListEntry key={`course-entry-${id}`}>
            <img src={icon} alt="" />
            {text}
          </ListEntry>
        ))}
      </List>

      <OrangeDivider />

      <Row>
        <Key>Duration</Key>
        <Value>29 Days until 13.02.2021</Value>
      </Row>

      <Row>
        <Key>Starting Date</Key>
        <Value>16.1.2021</Value>
      </Row>

      <Row>
        <Key>Application Deadline</Key>
        <Value>15.1.2021</Value>
      </Row>

      <CTA title={ctaTitle} onClick={onCTAClick} />
    </GenericCardBlack>
  </Container>
);

export default CohortPriceOverview;
