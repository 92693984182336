// @flow
import React from 'react';
import videojs from 'video.js';
import 'videojs-seek-buttons';
import styled from 'styled-components';
import notEmpty from '../modules/notEmpty';
import videoConfig from '../config/video';

const VideoPlayerContainer = styled.section`
  height: 60vh; // needed for fluid option
  outline: none;
  
  * {
    outline: none;
  }
`;

class VideoJsPlayer extends React.PureComponent<{
  fullscreen?: boolean,
  isSingleWorkout?: boolean,
  onThresholdWatched?: Function,
  onVideoEnd?: Function,
  videoUrl: string,
}, {|
  timer: IntervalID | null,
|}> {
  static defaultProps = {
    fullscreen: false,
    isSingleWorkout: false,
    onVideoEnd: null,
    onThresholdWatched: null,
  };

  state = {
    timer: null,
  };

  player: Object = {};

  playerOptions = {
    autoplay: true,
    controls: true,
    fill: true,
    sources: [],
    plugins: {
      seekButtons: {
        forward: 10,
        back: 10,
      },
    },
  };

  videoElement = null;

  componentDidMount() {
    const {
      fullscreen,
      videoUrl,
      onVideoEnd,
      type,
    } = this.props;

    const options = Object.assign(this.playerOptions, {
      sources: [{
        src: videoUrl,
        type: type ?? 'application/x-mpegurl',
      }],
    });

    this.player = videojs(this.videoElement, options, () => {
      if (fullscreen) {
        this.player.requestFullscreen();
      }

      this.player.on('ended', () => {
        if (onVideoEnd) {
          onVideoEnd();
        }
      });

      this.playVideo();
    });
  }

  componentWillUnmount() {
    // just to be sure
    const { timer } = this.state;

    clearInterval(timer);

    if (notEmpty(this.player)) {
      this.player.dispose();
    }
  }

  intervalChecking = () => {
    const currentTimeInS = this.player.currentTime();
    const totalLengthInS = this.player.duration();
    const percentWatched = (currentTimeInS / totalLengthInS) * 100;

    if (percentWatched > videoConfig.DONE_PERCENTAGE) {
      const { timer } = this.state;
      const { onThresholdWatched } = this.props;

      clearInterval(timer);

      if (onThresholdWatched) {
        onThresholdWatched();
      }
    }
  };

  playVideo = () => {
    const {
      isSingleWorkout,
    } = this.props;

    this.setState({
      timer: (isSingleWorkout ? null : setInterval(this.intervalChecking, 1000)),
    });
  };

  render() {
    const { onVideoEnd, onThresholdWatched, ...rest } = this.props;

    return (
      <VideoPlayerContainer {...rest}>
        <section data-vjs-player>
          {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
          <video ref={(node) => { this.videoElement = node; }} className="video-js" />
        </section>
      </VideoPlayerContainer>
    );
  }
}

export default VideoJsPlayer;
