// @flow
import React from 'react';

import SYHeadlineOne from '../components/SYHeadlineOne';
import SYHeadlineTwo from '../components/SYHeadlineTwo';
import SYBody18pt from '../components/SYBody18pt';
import { GREY_01_COLOR, IMPRINT_VIEW_NAME } from '../config/constants';
import SYBodyTextLink from '../components/SYBodyTextLink';
import SYHeadlineThree from '../components/SYHeadlineThree';
import LegalPage from './LegalPage';
import AbstractView from '../views/AbstractView';
import type { ViewInterface } from '../views/AbstractView';

class Imprint extends AbstractView <{}> implements ViewInterface {
  +viewName: string = IMPRINT_VIEW_NAME;

  render() {
    return (
      <LegalPage bodyClass="imprint">
        <SYHeadlineOne>Imprint</SYHeadlineOne>

        <SYHeadlineTwo>Angaben gemäß §5 TMG</SYHeadlineTwo>
        <SYBody18pt color={GREY_01_COLOR}>
          Skill Yoga GmbH
          <br />
          Planufer 93A
          <br />
          10967 Berlin, Germany
          <br />
          Germany
        </SYBody18pt>

        <SYHeadlineTwo>Vertreten durch</SYHeadlineTwo>
        <SYBody18pt color={GREY_01_COLOR}>
          Herr Timko Linssen
        </SYBody18pt>

        <SYHeadlineTwo>Kontakt</SYHeadlineTwo>
        <SYBody18pt color={GREY_01_COLOR}>
          E-Mail:
          {' '}
          <SYBodyTextLink
            title="Write us an email"
            href="https://skillyoga.atlassian.net/servicedesk/customer/portals"
            target="_blank"
          >
            Customer Support
          </SYBodyTextLink>
        </SYBody18pt>

        <SYHeadlineTwo>Registereintrag</SYHeadlineTwo>
        <SYBody18pt color={GREY_01_COLOR}>
          Eintragung im Handelsregister.
          <br />
          Registergericht: Amtsgericht Berlin Charlottenburg
          <br />
          Registernummer: HRB 196904 B
        </SYBody18pt>

        <SYHeadlineTwo>Umsatzsteuer-ID / VAT</SYHeadlineTwo>
        <SYBody18pt color={GREY_01_COLOR}>
          Umsatzsteuer-Identifikationsnummer gemäß §27 a Umsatzsteuergesetz: DE319610005
        </SYBody18pt>

        <SYHeadlineTwo>Haftungsausschluss / Disclaimer</SYHeadlineTwo>
        <SYHeadlineThree>Haftung für Inhalte</SYHeadlineThree>
        <SYBody18pt color={GREY_01_COLOR}>
          Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen
          Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind
          wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte
          fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine
          rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung
          der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt.
          Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer
          konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen
          werden wir diese Inhalte umgehend entfernen.
        </SYBody18pt>

        <SYHeadlineThree>Haftung für Links</SYHeadlineThree>
        <SYBody18pt color={GREY_01_COLOR}>
          Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen
          Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen.
          Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der
          Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf
          mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der
          Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten Seiten
          ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei
          Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.
        </SYBody18pt>

        <SYHeadlineThree>Urheberrecht</SYHeadlineThree>
        <SYBody18pt color={GREY_01_COLOR}>
          Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen
          dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art
          der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung
          des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den
          privaten, nicht kommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser Seite nicht
          vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden
          Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung
          aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von
          Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.
        </SYBody18pt>
      </LegalPage>
    );
  }
}

export default Imprint;
