import * as types from '../actions/types';

const initState = {
  location: '',
};

export default function pageReducer(state = initState, action) {
  switch (action.type) {
    case types.PAGE_UPDATE:
      return { ...action.payload };

    case types.PAGE_UPDATE_GET:
      return state;

    default:
      return state;
  }
}
