// @flow

import styled from 'styled-components';
// import _get from 'lodash/get';
import AbstractView from '../../AbstractView';
import {
  COURSE_OVERVIEW_PAGE_VIEW, CSS_BREAKPOINTS_MIN,
} from '../../../config/constants';
import { paymentPageElementCss } from '../../LoggedIn/PaymentView/PaymentView';
import CourseOverViewCardView from '../../../components/CourseOverViewCardView';


const Container = styled.div`
  max-width: none;
  margin: 0 auto;
  padding: 0;

  @media ${CSS_BREAKPOINTS_MIN.md} {
    padding: 0;
  }

  @media ${CSS_BREAKPOINTS_MIN.lg} {
    padding: 0;
  }
`;

const CoursePageElement = styled.section`
  ${paymentPageElementCss}

    background-color: transparent;
`;

const FullWidthElement = styled.div`
    background-image: linear-gradient(to bottom, #28292C, #171717);
    padding: 35px 0;

    @media ${CSS_BREAKPOINTS_MIN.md} {
      padding: 35px 0;
    }
`;


class CourseOverviewPage extends AbstractView<{}> {
  +viewName: string = COURSE_OVERVIEW_PAGE_VIEW;

  /* constructor(props) {
    super(props);
  } */

  render() {
    return (
      <Container>
        <FullWidthElement bg>
          <CoursePageElement>
            <CourseOverViewCardView />
          </CoursePageElement>
        </FullWidthElement>
      </Container>
    );
  }
}

export default CourseOverviewPage;
