// @flow
import { css } from 'styled-components';

const InputTextStyleCSS = css`
  font-family: 'Poppins-Light', sans-serif;
  font-size: 15px;
  line-height: 1.73;
  letter-spacing: 0.08px;
`;

export default InputTextStyleCSS;
