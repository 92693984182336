// @flow

import styled from 'styled-components';
import { CSS_BREAKPOINTS_MIN, GREY_01_COLOR } from '../config/constants';

const SYBody16pt = styled.p`
  font-family: 'Poppins-Light', sans-serif;
  font-size: 15px;
  line-height: 26px;
  letter-spacing: 0.08px;
  color: ${GREY_01_COLOR};
  text-align: ${(props) => (props.center && 'center') || (props.right && 'right') || 'left'};
  padding-bottom: ${props => props.paddingBottom ?? "0"};

  @media ${CSS_BREAKPOINTS_MIN.sm} {
    font-size: 16px;
    line-height: 25px;
    letter-spacing: 0.4px;
    padding-bottom: ${props => props.paddingBottomDesktop ?? "0"};
  }
  strong {
    font-weight: 600;
  }
`;

export default SYBody16pt;
