import { combineReducers } from 'redux';

import activePlanReducer from './activePlan';
import authReducer from './auth';
import freemiumReducer from './freemium';
import onboardingReducer from './onboarding';
import pageReducer from './page';
import paymentReducer from './payment';
import programsReducer from './programs';
import masterCourseReducer from './masterCourse';
import referralReducer from './referral';
import workoutsReducer from './workouts';
import packagesReducer from './packagesReducer';

// each reducer represents a new state entry
const rootReducer = combineReducers({
  activePlan: activePlanReducer,
  auth: authReducer,
  freemium: freemiumReducer,
  onboarding: onboardingReducer,
  page: pageReducer,
  payment: paymentReducer,
  programs: programsReducer,
  masterCourses: masterCourseReducer,
  referral: referralReducer,
  workouts: workoutsReducer,
  packages: packagesReducer,
});

export default rootReducer;
