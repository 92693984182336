// @flow

/**
 * The actual event dispatching is turned off for now,
 * because the functionality is not yet implemented
 */

import React from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';

import type { TrackingButtonProps } from '../types/TrackingButtonProps';
import { googleTrackingService } from '../services/GoogleTrackingProvider';

const dispatchButtonPressed = (trackingInfo: any) => trackingInfo;

// we need that element, otherwise we will get errors regarding
// the props passed to the button HTML element
const Button = styled.button`
  ${(props) => {
    if (props.disabled) {
      return props.disabledStyle;
    }

    return props.buttonStyle;
  }}
`;

const Title = styled.span`
  display: flex;
  align-items: center;

  ${(props) => {
    if (props.disabled) {
      return props.disabledTitleStyle;
    }

    return props.titleStyle;
  }}
`;

class TrackingButton extends React.Component<TrackingButtonProps> {
  static +defaultProps = {
    tracking: true,
    dispatchButtonPressed: null,
    onClick: null,
    trackingValue: '',
    title: '',
  };

  onPress = () => {
    const {
      trackingName,
      trackingValue,
      // dispatchButtonPressed,
      onClick,
      title,
      tracking,
      to,
      history,
    } = this.props;

    if (tracking && dispatchButtonPressed) {
      googleTrackingService.trackEvent('generic_event', {
        event_category: trackingName,
        event_action: title,
        event_label: trackingValue || null,
      });
    }

    if (onClick) {
      onClick();
    }

    if (to) {
      // $FlowFixMe
      history.push(to);
    }
  };

  render() {
    const {
      buttonStyle,
      disabled,
      disabledStyle,
      disabledTitleStyle,
      icon,
      startIcon,
      title,
      titleStyle,
      ...rest
    } = this.props;

    return (
      <Button
        {...rest}
        type="submit"
        onClick={this.onPress}
        buttonStyle={buttonStyle}
        disabled={disabled}
        disabledStyle={disabledStyle}
      >
        <Title
          titleStyle={titleStyle}
          disabled={disabled}
          disabledTitleStyle={disabledTitleStyle}
        >
          {startIcon && startIcon}
          {title}
          {icon && icon}
        </Title>
      </Button>
    );
  }
}

export default withRouter(TrackingButton);
