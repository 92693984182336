// @flow

import styled from 'styled-components';
import { CSS_BREAKPOINTS_MIN, WHITE_COLOR } from '../config/constants';

const SYHeadlineTwo = styled.h2`
  font-family: Poppins-Light, sans-serif;
  color: ${WHITE_COLOR};
  font-size: 24px;
  line-height: 33px;
  letter-spacing: 0.12px;
  
  @media ${CSS_BREAKPOINTS_MIN.sm} {
    font-size: 36px;
    line-height: 1.25;
    letter-spacing: 0.54px;
  }
`;

export default SYHeadlineTwo;
