// @flow

import { css } from 'styled-components';
import DividerLineCSSBottom from './DividerLineCSSBottom';
import { CSS_BREAKPOINTS_MIN, PRIMARY_COLOR, WHITE_COLOR } from '../../config/constants';

const HANDLE_SIZE = 28;

const RCSliderCSS = css`
  div.rc-slider {
    width: 90%;
    width: calc(100% - ${HANDLE_SIZE}px);
    margin-top: 70px;
    margin-left: auto;
    margin-right: auto;

    .rc-slider-dot {
      height: 10px;
      width: 1px;
      margin-left: -1px;
      border-radius: unset;
      border: none;
    }

    .rc-slider-track {
      background-color: ${PRIMARY_COLOR};
      height: 1px;
    }

    .rc-slider-rail {
      height: 1px;
      background-color: transparent;
      ${DividerLineCSSBottom};
    }

    .rc-slider-mark-text {
      top: -60px;
      font-family: Barlow-Condensed, sans-serif;
      font-size: 13px;
      line-height: 2.77;

      @media ${CSS_BREAKPOINTS_MIN.sm} {
        font-size: 18px;
        line-height: 1.33;
        letter-spacing: 4.5px;
      }
    }

    .rc-slider-mark-text-active {
      color: ${WHITE_COLOR};
    }

    .rc-slider-handle {
      width: 28px;
      height: 28px;
      border: none;
      background-color: ${PRIMARY_COLOR};
      margin-top: -13px;

      &:active {
        border: 0;
        box-shadow: none;
      }
    }
  }
`;

export default RCSliderCSS;
