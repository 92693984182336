// @flow

import styled from 'styled-components';
import { createRef } from 'react';
// import _get from 'lodash/get';
import AbstractView from '../../AbstractView';
import {
  BLACK_COLOR_SEMI_TRANS,
  CLOUDFRONT_STATIC, ADAM_TRAINING_LANDINGPAGE_VIEW, CSS_BREAKPOINTS_MIN, GUTTER_SIZE,
} from '../../../config/constants';
import makeImageUrl from '../../../modules/makeImageUrl';
import SYPrimaryButton from '../../../components/SYPrimaryButton';
import PageElementContainer from '../../../components/PageElementContainer';
import { paymentPageElementCss } from '../../LoggedIn/PaymentView/PaymentView';
import AdamBenefits from '../../../components/Adam/AdamBenefits';
import AdamTrainWithBest from '../../../components/Adam/AdamTrainWithBest';
import AdamCoursePlan from '../../../components/Adam/AdamCoursePlan';
import AdamHowItWorks from '../../../components/Adam/AdamHowItWorks';
import AdamMeetTheCoach from '../../../components/Adam/AdamMeetTheCoach';
import AdamPriceOverview from '../../../components/Adam/AdamPriceOverview';
// import AdamJoinEmailList from '../../../components/Adam/AdamJoinEmailList';
import { TitleElement } from '../../../components/text/CohortLp/CohortLpTextElements';
// import AdamImageTextBanner from '../../../components/Adam/AdamImageTextBanner';
import urls from '../../../config/urls';
import { Redirect } from 'react-router-dom';

const Container = styled.div`
  max-width: none;
  margin: 0 auto;
  padding: 0;

  @media ${CSS_BREAKPOINTS_MIN.md} {
    padding: 0;
  }

  @media ${CSS_BREAKPOINTS_MIN.lg} {
    padding: 0;
  }
`;

const CTA = styled(SYPrimaryButton)`
  width: 100%;
  max-width: 300px;
`;

const BottomCTAContainer = styled.div`
  padding: ${GUTTER_SIZE * 2}px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media ${CSS_BREAKPOINTS_MIN.sm} {
    padding: ${GUTTER_SIZE * 2.5}px 0 ${GUTTER_SIZE * 3.5}px;
  }
`;

const HeroContainer = styled.div`
  min-height: 60vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: ${GUTTER_SIZE}px;
  background: url(${makeImageUrl({ url: `${CLOUDFRONT_STATIC}/img/pages/adam-lp/adam-hero-mobile.jpg` })}) no-repeat center / cover;

  @media ${CSS_BREAKPOINTS_MIN.sm} {
    background-image: url(${makeImageUrl({ url: `${CLOUDFRONT_STATIC}/img/pages/adam-lp/adam-hero-desktop.jpg` })});
  }

  @media ${CSS_BREAKPOINTS_MIN.md} {
    min-height: 80vh;
    align-items: flex-start;
    margin-bottom: ${GUTTER_SIZE * 2}px;
  }
`;

const HeroTitle = styled.h2`
  margin-bottom: ${GUTTER_SIZE * 2}px;
  font-family: 'Poppins-Medium', sans-serif;
  font-size: 28px;
  line-height: 36px;
  text-align: center;
  letter-spacing: 0.81px;

  @media ${CSS_BREAKPOINTS_MIN.md} {
    margin-bottom: ${GUTTER_SIZE * 2.5}px;
    font-size: 54px;
    line-height: 81px;
    text-align: left;
  }
`;

const HeroSubTitle = styled.h3`
  font-family: 'Poppins-Medium', sans-serif;
  font-size: 22px;
  line-height: 26px;
  text-align: center;
  width: 100%;

  @media ${CSS_BREAKPOINTS_MIN.md} {
    max-width: 580px;
    font-size: 32px;
    line-height: 48px;
    text-align: left;
  }
`;

const HeroCTAWrapper = styled.div`
  width: 100%;
`;

const HeroCTA = styled(SYPrimaryButton)`
  width: 100%;
  margin-top: ${GUTTER_SIZE * 5}px;

  @media ${CSS_BREAKPOINTS_MIN.sm} {
    width: auto;
    min-width: 320px;
  }

  @media ${CSS_BREAKPOINTS_MIN.md} {
    margin-top: ${GUTTER_SIZE * 7}px;
  }
`;

/*
const BannerContainer = styled.div`
  background-color: ${PRIMARY_COLOR};
  padding: 20px;
`;

const BannerText = styled.p`
  width: 100%;
  max-width: 800px;
  color: #ffffff;
  margin: 0 auto;
  text-align: center;
  font-family: 'Poppins-Medium', sans-serif;
`;
*/

const AdamPageElement = styled.section`
  ${paymentPageElementCss}

  ${({ bg }) => (bg ? `background-color: ${BLACK_COLOR_SEMI_TRANS};` : '')}
`;

const FullWidthElement = styled.div`
  ${({ bg }) => (bg ? `background-color: ${BLACK_COLOR_SEMI_TRANS};` : '')}
`;

const OverviewWrapper = styled(FullWidthElement)`
  background: url(${makeImageUrl({ url: `${CLOUDFRONT_STATIC}/img/pages/adam-lp/adam-price-bg-mobile.jpg` })}) no-repeat center top / cover;

  @media ${CSS_BREAKPOINTS_MIN.md} {
    background: url(${makeImageUrl({ url: `${CLOUDFRONT_STATIC}/img/pages/adam-lp/adam-price-bg-desktop.jpg` })}) no-repeat center bottom / cover;
  }
`;

const CTATitle = 'SIGN UP';

class AdamTrainingView extends AbstractView<{}> {
  +viewName: string = ADAM_TRAINING_LANDINGPAGE_VIEW;

  constructor(props) {
    super(props);
    this.mailingListref = createRef();
    this.onCTAClick = this.onCTAClick.bind(this); // strangely this is needed here explicitly
  }

  onCTAClick() {
    window.open('https://skillyoga.theworkademy.com/signin/?register=true', '_blank');
  }

  /* Top banner under navigation bar
  <BannerContainer>
    <BannerText>
       Just this week:
       <br />
       Get lifetime access for only 40 USD and get one free access code for a friend
    </BannerText>
  </BannerContainer>
  * */

  render() {
    if (true) {
      return <Redirect to={urls.LANDING_PAGE.replace(':slug', 'flying-into-arm-balances')} />;
    }
    return (
      <Container>
        <HeroContainer>
          <PageElementContainer>
            <HeroTitle>
              Flying into Arm Balances
              <br />
              by Adam Husler
            </HeroTitle>
            <HeroSubTitle>30 days of building the flexibility, strength and skill you’ll need for arm balancing</HeroSubTitle>

            <HeroCTAWrapper>
              <HeroCTA title={CTATitle} onClick={this.onCTAClick} />
            </HeroCTAWrapper>
          </PageElementContainer>
        </HeroContainer>

        <AdamPageElement>
          <AdamBenefits />
        </AdamPageElement>

        <FullWidthElement bg>
          <AdamPageElement bg>
            <AdamTrainWithBest onCTAClick={this.onCTAClick} ctaTitle={CTATitle} />
          </AdamPageElement>
        </FullWidthElement>

        <AdamPageElement>
          <AdamCoursePlan />
        </AdamPageElement>

        <FullWidthElement bg>
          <AdamPageElement>
            <AdamHowItWorks onCTAClick={this.onCTAClick} ctaTitle={CTATitle} />
          </AdamPageElement>
        </FullWidthElement>

        <AdamPageElement>
          <AdamMeetTheCoach />
        </AdamPageElement>

        <OverviewWrapper>
          <AdamPageElement>
            <AdamPriceOverview onCTAClick={this.onCTAClick} ctaTitle={CTATitle} />
          </AdamPageElement>
        </OverviewWrapper>

        <FullWidthElement bg>
          <AdamPageElement>
            <BottomCTAContainer>
              <TitleElement>Join Skill Yoga Master Courses to start training</TitleElement>
              <CTA title={CTATitle} onClick={this.onCTAClick} />
            </BottomCTAContainer>
          </AdamPageElement>
        </FullWidthElement>
      </Container>
    );
  }
}

export default AdamTrainingView;
