import Slider from 'react-slick';
import styled from 'styled-components';
import Caleb01Svg from '../../assets/svg/caleb-01-icon.svg';
import Caleb02Svg from '../../assets/svg/caleb-02-icon.svg';
import Caleb03Svg from '../../assets/svg/caleb-03-icon.svg';
import Caleb04Svg from '../../assets/svg/caleb-04-icon.svg';
import {
  CSS_BREAKPOINTS,
  CSS_BREAKPOINTS_MIN,
  GUTTER_SIZE,
} from '../../config/constants';
import { TitleElement } from '../text/CohortLp/CohortLpTextElements';

const Container = styled.section`
  padding: ${GUTTER_SIZE * 2}px 0 ${GUTTER_SIZE * 4}px;

  @media ${CSS_BREAKPOINTS_MIN.sm} {
    margin: 0 -${GUTTER_SIZE * 2}px;
    padding: ${GUTTER_SIZE * 2.5}px 0 ${GUTTER_SIZE * 3.5}px;
  }
`;

const Slide = styled.div`
  position: relative;
  padding: 0 ${GUTTER_SIZE * 2}px;

  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: 26px;
    width: 17px;
  }
`;

const Headline = styled.div`
  font-family: Poppins-Medium, sans-serif;
  font-size: 16px;
  line-height: 26px;
  margin: 0;

  @media ${CSS_BREAKPOINTS_MIN.sm} {
    font-size: 16px;
    line-height: 26px;
  }
`;

const Text = styled.div`
  font-family: Poppins-Light, sans-serif;
  font-size: 15px;
  line-height: 26px;

  @media ${CSS_BREAKPOINTS_MIN.sm} {
    font-size: 16px;
    line-height: 26px;
  }
`;

const TitleContainer = styled.div`
  width: 100%;
  height: 94px;
  padding-top: 18px;
  margin-bottom: 16px;
  background-color: green;
  background: url(${(props) => props.icon}) no-repeat left / cover;
  background-size: auto 94px;
`;

/* eslint-disable max-len */
const LIST = [
  {
    id: 1,
    icon: Caleb01Svg,
    title: 'WEEK 1',
    subtitle: 'Find Balance (Front & Back Line)',
    text: 'Let’s begin our journey of conscious exploration through the landscape of the physical and mental body. Physically we equally strengthen and release the front and back fascial line of the body. On a mental level, we will let go of the past and the future to fully arrive where we are in this moment as Marlene guides you through a process of self-discovery using Asana, components of fascial training, breath-work and meditation.',
  },
  {
    id: 2,
    icon: Caleb02Svg,
    title: 'WEEK 2',
    subtitle: 'Connected Presence (Lateral Line)',
    text: 'With every movement, you arrive more in your physical body, your anchor in the present moment. In this week’s practices, we will get in touch with the lateral fascial line of the body, supporting us on our path towards a healthy posture, confident being and connected presence.',
  },
  {
    id: 3,
    icon: Caleb03Svg,
    title: 'WEEK 3',
    subtitle: 'Step into your Power (Spiral Line)',
    text: 'You are ready to step out of your comfort zone and connect with your inherent trust. This week we will physically get to know the spiral line of the body, giving us strength and vitality. Awaken your inner fire with spinal twists, rotations and energising movements.',
  },
  {
    id: 4,
    icon: Caleb04Svg,
    title: 'WEEK 4',
    subtitle: 'You are Whole',
    text: 'In our final week, we will channel all that we have learned to step into our inner & outer strength. Experiencing body, mind & soul as one connected unity that you can guide purposefully towards the desired destiny. You are whole. And all that you need is already inside of you.',
  },
];
/* eslint-enable max-len */

const MarleneCoursePlan = () => (
  <Container>
    <TitleElement>Course plan</TitleElement>

    <Slider
      arrows={false}
      autoplay
      autoplaySpeed={5000}
      centerMode
      // rem is not an option here, because the slider library doesn't support rem
      centerPadding="0px"
      className="center"
      dots={false}
      slidesToShow={4}
      slidesToScroll={4}
      speed={500}
      responsive={[
        {
          breakpoint: CSS_BREAKPOINTS.sm,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: true,
          },
        },
      ]}
    >
      {
      LIST.map((
        {
          id, icon, title, subtitle, text,
        },
      ) => (
        <Slide key={`slide-cv-${id}`}>
          <TitleContainer icon={icon}>
            <Headline>{title}</Headline>
            <Headline>{subtitle}</Headline>
          </TitleContainer>
          <Text>{text}</Text>
        </Slide>
      ))
      }
    </Slider>
  </Container>
);

export default MarleneCoursePlan;
