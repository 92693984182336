// @flow
/* eslint max-len: 0 */
import React from 'react';
import loadable from '@loadable/component';
import LoadingAnimation from '../components/LoadingAnimation';
import {
  ONBOARDING_EMAIL_ENTRY_VIEW_NAME,
  ONBOARDING_EXPERIENCE_SELECTION_VIEW_NAME,
  ONBOARDING_GENDER_SELECTION_VIEW_NAME,
  ONBOARDING_GOAL_SELECTION_VIEW_NAME,
  ONBOARDING_MAILING_OPTIN_VIEW_NAME,
  ONBOARDING_PLAN_CREATION_VIEW_NAME,
  ONBOARDING_PLAN_PRESENTATION_VIEW_NAME,
  ONBOARDING_SPORT_SELECTION_VIEW_NAME,
  ONBOARDING_TERMS_VIEW_NAME,
} from '../config/constants';
import withUserDataLoader from '../hoc/withUserDataLoader';
import type { OnboardingOrderStep } from '../types/OnboardingOrderStep';
import urls from '../config/urls';

const OnboardingGoalSelectionView = loadable(() => import('../views/LoggedOut/OnboardingView/OnboardingGoalSelectionView'), { fallback: <LoadingAnimation /> });
const OnboardingSportSelectionView = loadable(() => import('../views/LoggedOut/OnboardingView/OnboardingSportSelectionView'), { fallback: <LoadingAnimation /> });
const OnboardingExperienceSelectionView = loadable(() => import('../views/LoggedOut/OnboardingView/OnboardingExperienceSelectionView'), { fallback: <LoadingAnimation /> });
const OnboardingGenderSelectionView = loadable(() => import('../views/LoggedOut/OnboardingView/OnboardingGenderSelectionView'), { fallback: <LoadingAnimation /> });
const OnboardingEmailEntryView = loadable(() => import('../views/LoggedOut/OnboardingView/OnboardingEmailEntryView'), { fallback: <LoadingAnimation /> });
const OnboardingTermsView = loadable(() => import('../views/LoggedOut/OnboardingView/OnboardingTermsView'), { fallback: <LoadingAnimation /> });
const OnboardingMailingOptinView = loadable(() => import('../views/LoggedOut/OnboardingView/OnboardingMailingOptinView'), { fallback: <LoadingAnimation /> });
const OnboardingPlanCreationView = loadable(() => import('../views/LoggedOut/OnboardingView/OnboardingPlanCreationView'), { fallback: <LoadingAnimation /> });
const OnboardingPlanPresentationView = loadable(() => import('../views/LoggedOut/OnboardingView/OnboardingPlanPresentationView'), { fallback: <LoadingAnimation /> });


class OnboardingSteps {
  sequence: Array<OnboardingOrderStep> = [
    // the first step must always be / url-wise
    { url: `${urls.ONBOARDING}`, title: ONBOARDING_GOAL_SELECTION_VIEW_NAME, component: OnboardingGoalSelectionView },
    { url: `${urls.ONBOARDING}/sport`, title: ONBOARDING_SPORT_SELECTION_VIEW_NAME, component: OnboardingSportSelectionView },
    { url: `${urls.ONBOARDING}/experience`, title: ONBOARDING_EXPERIENCE_SELECTION_VIEW_NAME, component: OnboardingExperienceSelectionView },
    { url: `${urls.ONBOARDING}/mwx`, title: ONBOARDING_GENDER_SELECTION_VIEW_NAME, component: OnboardingGenderSelectionView },
    { url: `${urls.ONBOARDING}/name`, title: ONBOARDING_EMAIL_ENTRY_VIEW_NAME, component: OnboardingEmailEntryView },
    { url: `${urls.ONBOARDING}/terms`, title: ONBOARDING_TERMS_VIEW_NAME, component: OnboardingTermsView },
    { url: `${urls.ONBOARDING}/mailing`, title: ONBOARDING_MAILING_OPTIN_VIEW_NAME, component: OnboardingMailingOptinView },
    { url: `${urls.ONBOARDING}/processing`, title: ONBOARDING_PLAN_CREATION_VIEW_NAME, component: OnboardingPlanCreationView },
    { url: `${urls.ONBOARDING}/your-plan`, title: ONBOARDING_PLAN_PRESENTATION_VIEW_NAME, component: withUserDataLoader(OnboardingPlanPresentationView) },
  ].map((step: Object, index: number) => ({
    ...step,
    id: index + 1, // the ids must always be in perfect order from 1 to n
  }));

  get order(): Array<OnboardingOrderStep> {
    return this.sequence;
  }

  get count(): number {
    return this.sequence.length;
  }

  getFirstStep = (): OnboardingOrderStep => this.sequence[0];

  getNextStep = (currentStep: number): OnboardingOrderStep => {
    if (currentStep >= this.count) {
      return this.sequence[this.count]; // there's no step after the last step
    }

    if (currentStep < 0) {
      return this.sequence[0];
    }

    return this.sequence[currentStep]; // 0-indexed, thus no +1 needed
  };
}

export const onboardingSteps = new OnboardingSteps();
export default OnboardingSteps;
