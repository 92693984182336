import styled from 'styled-components';
import { useState } from 'react';
import { TitleElement } from './text/CohortLp/CohortLpTextElements';
import EmailInput from './EmailInput';
import { CSS_BREAKPOINTS_MIN, GUTTER_SIZE } from '../config/constants';
import SYPrimaryButton from './SYPrimaryButton';
import AutopilotAPI from '../services/AutopilotService';

const Container = styled.div`
  padding: ${GUTTER_SIZE * 2}px 0;

  @media ${CSS_BREAKPOINTS_MIN.sm} {
    padding: ${GUTTER_SIZE * 2.5}px 0 ${GUTTER_SIZE * 3.5}px;
  }
`;

const CTAInputWrapper = styled.div`
  padding: 0;

  @media ${CSS_BREAKPOINTS_MIN.sm} {
    max-width: 750px;
    display: flex;
    align-items: center;
    margin: 0 auto;
  }
`;

const CTA = styled(SYPrimaryButton)`
  width: 100%;

  @media ${CSS_BREAKPOINTS_MIN.sm} {
    width: auto;
    min-width: 320px;
    margin-left: ${GUTTER_SIZE * 2}px;
  }
`;

const CohortJoinEmailList = ({ refProp, ctaTitle = 'ENROLL NOW' }) => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState(null);
  const [isFetching, setIsFetching] = useState(false);

  const onEmailChange = ({ target: { value } }) => {
    setEmail(value);
  };

  const onSubmitClick = async () => {
    setIsFetching(true);

    try {
      await AutopilotAPI.addEmailToCohortWaitingList(email);
      setError(null);
      setEmail('You joined successfully');
    } catch (e) {
      setError('There was an error. Please try again');
    }

    setIsFetching(false);
  };

  return (
    <Container ref={refProp}>
      <TitleElement>Join the waitlist to be notified of upcoming courses</TitleElement>

      <CTAInputWrapper>
        <EmailInput onChange={onEmailChange} value={email} label="Enter your email address" error={error} />
        <CTA title={ctaTitle} onClick={onSubmitClick} disabled={isFetching} />
      </CTAInputWrapper>
    </Container>
  );
};

export default CohortJoinEmailList;
