// @flow
import React from 'react';
import styled from 'styled-components';

import { CSS_BREAKPOINTS_MIN, GREY_01_COLOR, SPACING_BASE } from '../config/constants';
import SYHeadlineOne from '../components/SYHeadlineOne';
import DividerLineCSSBottom from '../components/shared-styles/DividerLineCSSBottom';
import SYHeadlineTwo from '../components/SYHeadlineTwo';
import SYHeadlineThree from '../components/SYHeadlineThree';
import SYBody18pt from '../components/SYBody18pt';
import SYUnorderedList from '../components/SYUnorderedList';
import PostLoginContainer from '../components/PostLoginContainer';

const LegalPageContainer = styled.section`
  padding: ${SPACING_BASE * 10}px 0 0;
  
  @media ${CSS_BREAKPOINTS_MIN.sm} {
    padding: ${SPACING_BASE * 30}px 0 0;
  }
  
  ${SYHeadlineOne} {
    margin-bottom: ${SPACING_BASE * 5}px;
    padding-bottom: ${SPACING_BASE * 5}px;
    font-family: Poppins-Medium;
    ${DividerLineCSSBottom};
    
    @media ${CSS_BREAKPOINTS_MIN.sm} {
      margin-bottom: ${SPACING_BASE * 9}px;
      padding-bottom: ${SPACING_BASE * 9}px;
    }
  }
  
  ${SYHeadlineTwo},
  ${SYHeadlineThree} {
    margin: ${SPACING_BASE * 2}px 0;
    
    @media ${CSS_BREAKPOINTS_MIN.sm} {
      margin: ${SPACING_BASE * 6}px 0;
    }
  }
  
  ${SYBody18pt} {
    margin: 0 0 ${SPACING_BASE * 5}px;
    color: ${GREY_01_COLOR};
  }
  
  ${SYUnorderedList} {
    margin: 0 0 ${SPACING_BASE * 5}px;
    list-style: disc;
    color: ${GREY_01_COLOR};
  }
`;

class LegalPage extends React.PureComponent<{
  children: Object,
}> {
  render() {
    const { children } = this.props;

    return (
      <PostLoginContainer>
        <LegalPageContainer>
          { children }
        </LegalPageContainer>
      </PostLoginContainer>
    );
  }
}

export default LegalPage;
