import styled from 'styled-components';
import { SubTitleElement, TitleElement } from './text/CohortLp/CohortLpTextElements';
import { CSS_BREAKPOINTS_MIN, GUTTER_SIZE, PAPER_WHITE_COLOR } from '../config/constants';
import SYBody16pt from './SYBody16pt';
import MeditationSvg from '../assets/svg/cohort-meditation.svg';
import LearningSvg from '../assets/svg/cohort-learning.svg';
import CommunitySvg from '../assets/svg/cohort-community.svg';

const Container = styled.section`
  text-align: center;

  @media ${CSS_BREAKPOINTS_MIN.md} {
    display: grid;
    grid-column-gap: 2em;
    grid-template-columns: repeat(3, 1fr);
  }

  @media ${CSS_BREAKPOINTS_MIN.lg} {
    grid-column-gap: 4em;
  }
`;

const Text = styled(SYBody16pt)`
  text-align: center;
  color: ${PAPER_WHITE_COLOR};
  padding-bottom: ${GUTTER_SIZE * 2}px;
`;

const Benefit = styled.div`
  margin-bottom: ${GUTTER_SIZE * 2}px;

  @media ${CSS_BREAKPOINTS_MIN.md} {
    display: contents;

    &:first-child ${SubTitleElement} {
      grid-row: 1;
      grid-column: 1;
    }

    &:nth-child(2) ${SubTitleElement} {
      grid-row: 1;
      grid-column: 2;
    }

    &:last-child ${SubTitleElement} {
      grid-row: 1;
      grid-column: 3;
    }

    &:first-child ${Text} {
      grid-row: 2;
      grid-column: 1;
    }

    &:nth-child(2) ${Text} {
      grid-row: 2;
      grid-column: 2;
    }

    &:last-child ${Text} {
      grid-row: 2;
      grid-column: 3;
    }
  }
`;

const ImageMeditation = styled.div`
  img {
    width: 125px;

    @media ${CSS_BREAKPOINTS_MIN.md} {
      width: 150px;
    }
  }
`;

const ImageLearning = styled.div`
  img {
    width: 215px;

    @media ${CSS_BREAKPOINTS_MIN.md} {
      width: 240px;
    }
  }
`;

const ImageCommunity = styled.div`
  img {
    width: 180px;

    @media ${CSS_BREAKPOINTS_MIN.md} {
      width: 215px;
    }
  }
`;

const CohortBenefits = () => (
  <>
    <TitleElement>Are you ready for a healthy mind and body?</TitleElement>

    <Container>
      <Benefit>
        <SubTitleElement>Boost your mental and physical health</SubTitleElement>

        <Text>
          Build yoga-based routines to feel better, build muscle, increase your flexibility,
          get rid of back pain, prevent injuries and decrease your stress levels.
        </Text>

        <ImageMeditation>
          <img src={MeditationSvg} alt="Meditation SVG" />
        </ImageMeditation>
      </Benefit>

      <Benefit>
        <SubTitleElement>Active learning with long-term results</SubTitleElement>

        <Text>
          Follow a structured syllabus, ask questions, collaborate with your peers and coach and receive
          effective feedback to build a personal yoga-inspired health routine that lasts.
        </Text>

        <ImageLearning>
          <img src={LearningSvg} alt="Learn together" />
        </ImageLearning>
      </Benefit>

      <Benefit>
        <SubTitleElement>Learn with a cohort of peers</SubTitleElement>

        <Text>Finish your journey successfully – your coach and peer group will motivate you and hold you accountable.</Text>

        <ImageCommunity>
          <img src={CommunitySvg} alt="Build a community" />
        </ImageCommunity>
      </Benefit>
    </Container>
  </>
);

export default CohortBenefits;
