// @flow

import {
  createStore, applyMiddleware,
} from 'redux';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import rootReducer from './reducer';
import { debugSettings } from '../config/constants';

export default function configureStore() {
  const logger = createLogger({
    diff: true,
  });

  const middlewares = [
    thunk,
    debugSettings.ENABLE_REDUX_LOGGER ? logger : undefined,
  ].filter(Boolean);

  return createStore(rootReducer, applyMiddleware(...middlewares));
}

// this will crash on server side rendering
export const store = configureStore(); // @todo refactor
