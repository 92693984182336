import * as types from '../actions/types';

export default function masterCourseReducer(state = [], action) {
  switch (action.type) {
    case types.LOAD_MASTER_COURSE_SUCCESS:
      return action.payload;

    case types.LOAD_MASTER_COURSE_FAILED:
      return action.payload;

    default:
      return state;
  }
}
