// @flow

// eslint-disable-next-line import/prefer-default-export
export const LOCALSTORAGE_KEYS = {
  changePasswordEmail: 'cpwea',
  codeToApplyOnCheckout: 'ctaoc',
  cookieBanner: 'cookieconsent_dismissed', // must be the same as on shopify app
  currencyData: 'cdsy', // contains data from the last currency converter update,
  email: 'email',
  introSeen: 'intro_seen',
  isKnownUser: 'syisk',
  languageCode: 'lnggcd',
  beginnerYogaForAthletesAnnouncement: 'beginner-yoga-for-athletes-announcement',
  hitStrengthAnnouncement: 'hit-strength-announcement',
  slugCourse: "slug-course",
  showCourseInfoModal: "showCourseInfoModal",
  masterCourse: "masterCourse",
};
