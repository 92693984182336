// @flow
import type { WorkoutsState } from '../../types/WorkoutsState';
import {
  FETCH_ALL_WORKOUTS_STARTED,
  FETCH_ALL_WORKOUTS_FAILED,
  FETCH_ALL_WORKOUTS_SUCCESS,
  FETCH_RECOMMENDED_WORKOUTS_ACTION,
  FETCH_RECOMMENDED_WORKOUTS_FAILED,
  FETCH_RECOMMENDED_WORKOUTS_SUCCESS,
  FILTER_ALL_WORKOUTS_STARTED,
  FILTER_ALL_WORKOUTS_FAILED,
  FILTER_ALL_WORKOUTS_SUCCESS, FILTER_ALL_WORKOUTS_RESET, MARK_WORKOUT_STARTED_SUCCESS,
} from '../actions/types';
import makeWorkoutFromResponse from '../../modules/makeWorkoutFromApiResponse';

export const makeDefaultState = (): WorkoutsState => ({
  all: [],
  filtered: [],
  isFetching: false,
  recommended: [],
  activeWorkoutSessionId: null,
});

export default function data(state: WorkoutsState = makeDefaultState(), action: Object): WorkoutsState {
  const { type, payload } = action;

  const makeNewState = (params: any = {}): WorkoutsState => ({
    ...state,
    ...params,
  });

  switch (type) {
    case FILTER_ALL_WORKOUTS_STARTED:
    case FETCH_ALL_WORKOUTS_STARTED:
    case FETCH_RECOMMENDED_WORKOUTS_ACTION: {
      return makeNewState({
        isFetching: true,
      });
    }

    case FILTER_ALL_WORKOUTS_FAILED:
    case FETCH_ALL_WORKOUTS_FAILED:
    case FETCH_RECOMMENDED_WORKOUTS_FAILED: {
      return makeNewState({
        isFetching: false,
      });
    }

    case FETCH_ALL_WORKOUTS_SUCCESS: {
      return makeNewState({
        isFetching: false,
        all: payload.map(makeWorkoutFromResponse),
      });
    }

    case FETCH_RECOMMENDED_WORKOUTS_SUCCESS: {
      const workouts = payload.map(makeWorkoutFromResponse);

      return makeNewState({
        isFetching: false,
        recommended: workouts,
      });
    }

    case FILTER_ALL_WORKOUTS_SUCCESS: {
      const filtered = payload.map(makeWorkoutFromResponse);

      return makeNewState({
        isFetching: false,
        filtered,
      });
    }

    case FILTER_ALL_WORKOUTS_RESET: {
      return makeNewState({
        filtered: [],
      });
    }

    case MARK_WORKOUT_STARTED_SUCCESS: {
      const {
        payload: {
          sessionId,
        },
      } = action;

      return {
        ...state,
        activeWorkoutSessionId: sessionId,
      };
    }

    default:
      return state;
  }
}
