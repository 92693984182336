// @flow

import type { Bit } from '../types/Bit';
import convertBitToBoolean from './convertBitToBoolean';
import makeImageUrl from './makeImageUrl';

type WorkoutIntro = {|
  description: string,
  video: {|
    description: string,
    url: string,
    imageM: string,
  |},
|}

type WorkoutData = {|
  id: number,
  description: string,
  shortDescription: string,
  duration: number,
  imageM: string,
  imageXl: string,
  intensity: number,
  isFreemium?: boolean, /* only for workouts in library for now */
  isListable: number,
  name: string,
  skill: string,
  skillId: number,
  videoUrl: string,
  totalFinishedWorkouts?: number,

  started: Bit,
  done: Bit,
  isFree: Bit,
  historyWorkoutId: number | null,
  historyPlanId: number | null,
  intro: WorkoutIntro | null,
  externalContentSlug?: string,
  parents: string[],
|}

class Workout {
  workoutData: WorkoutData;

  constructor(workoutData: WorkoutData) {
    this.workoutData = workoutData;
  }

  get id(): number {
    return this.workoutData.id;
  }

  get description(): string {
    return this.workoutData.description;
  }

  get shortDescription(): string {
    return this.workoutData.shortDescription;
  }

  get duration(): number {
    return this.workoutData.duration;
  }

  get formattedDuration(): string {
    return `${Math.round(this.duration / 60)} min`;
  }

  get imageM(): string {
    return makeImageUrl({ url: this.workoutData.imageM });
  }

  get imageXl(): string {
    return makeImageUrl({ url: this.workoutData.imageXl });
  }

  get intensity(): number {
    return this.workoutData.intensity;
  }

  get isListable(): boolean {
    return this.workoutData.isListable === 1;
  }

  get name(): string {
    return this.workoutData.name;
  }

  get externalContentSlug(): string {
    return this.workoutData?.externalContentSlug ?? "";
  }

  get skillId(): number | null {
    return this.workoutData.skillId;
  }

  get videoUrl(): string {
    return this.workoutData.videoUrl;
  }

  get hasIntro(): boolean {
    return this.intro !== null;
  }

  get historyWorkoutId(): number | null {
    return this.workoutData.historyWorkoutId;
  }

  get historyPlanId(): number | null {
    return this.workoutData.historyPlanId;
  }

  set historyPlanId(id: number) {
    this.workoutData.historyPlanId = id;
  }

  set historyWorkoutId(id: number | null) {
    this.workoutData.historyWorkoutId = id;
  }

  get started(): boolean {
    return convertBitToBoolean(this.workoutData.started);
  }

  set started(started: boolean) {
    this.workoutData.started = started ? 1 : 0;
  }

  get done(): boolean {
    return this.workoutData.done === 1;
  }

  set done(done: boolean) {
    this.workoutData.done = done === true ? 1 : 0;
  }

  get isFree(): boolean {
    if (this.workoutData.isFreemium) {
      return true;
    }

    return this.workoutData.isFree === 1;
  }

  get intro(): WorkoutIntro | null {
    return this.workoutData.intro || null;
  }

  get totalFinishedWorkouts(): number {
    return this.workoutData.totalFinishedWorkouts || 0;
  }

  get parents(): number {
    return this.workoutData.parents;
  }

  toJSON() {
    return this.workoutData;
  }
}

export default Workout;
