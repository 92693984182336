// @flow
import React from 'react';
import styled from 'styled-components';

import { SPACING_BASE, SPACING_NEXT_ELEMENT_MOBILE } from '../../config/constants';
import SYHeadlineThree from '../SYHeadlineThree';
import SYBody18ptMedium from '../SYBody18ptMedium';
import SYBodyTextLink from '../SYBodyTextLink';
import SYBody18pt from '../SYBody18pt';
import { logger } from '../../modules/Logger';

type ErrorProps = {
  error: {
    title: string,
    message: string,
  } | Error
};

const ErrorWrapper = styled.section`
  padding-top: ${SPACING_NEXT_ELEMENT_MOBILE}px;
`;

const GenericError = (props: ErrorProps) => {
  const { error } = props;
  const errorTitle = error.title || 'Whoops';
  const errorMessage = error.message || 'Something went wrong!';

  logger.error(new Error(errorMessage));

  return (
    <ErrorWrapper>
      <SYHeadlineThree style={{ marginBottom: SPACING_BASE * 4 }}>{errorTitle}</SYHeadlineThree>
      <SYBody18ptMedium>{errorMessage}</SYBody18ptMedium>

      <SYBody18pt>
        Try again or get in touch with us via our
        {' '}
        <SYBodyTextLink
          href="https://skillyoga.atlassian.net/servicedesk/customer/portals"
          title="Get in touch with us"
        >
          Customer Portal
        </SYBodyTextLink>
      </SYBody18pt>
    </ErrorWrapper>
  );
};

export default GenericError;
