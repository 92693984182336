// @flow
export type cssThemeSYType = {|
  colors: {|
    BACKGROUND_COLOR: string,
    BLACK_COLOR: string,
    BLACK_COLOR_SEMI_TRANS: string,
    DARK_GREY_COLOR: string,
    ERROR_COLOR: string,
    GREY_01_COLOR: string,
    GREY_02_COLOR: string,
    LIGHT_GREY_02: string,
    MAIN_ORANGE_DARK_COLOR: string,
    MAIN_ORANGE_LIGHT_COLOR: string,
    PAPER_WHITE_COLOR: string,
    PRIMARY_COLOR: string,
    WHITE_COLOR: string,
    FACEBOOK_COLOR: string,
    MESSENGER_COLOR: string,
  |},

  fonts: {|
    BARLOW: string,
    DEFAULT: string,
    EXTRA_LIGHT: string,
    LIGHT: string,
    MEDIUM: string,
    SEMI_BOLD: string,
  |},

  constants: {|
    ABOVE_FOLD_DESKTOP_HEIGHT: string,
    ABOVE_FOLD_MOBILE_HEIGHT: string,
    ABOVE_FOLD_MOBILE_HEIGHT_TRAINING_HOME: string,
    ABOVE_FOLD_MOBILE_HEIGHT_LANDING_PAGE: string,
    BASE_FONT_SIZE: number,
    DEFAULT_OPACITY: number,
    GUTTER_SIZE: number,
    PAGE_PADDING_LEFT_RIGHT: number,
    BREAKPOINTS: {
      XXS: number,
      XS: number,
      SM: number,
      MD: number,
      LG: number,
    }
  |},

  // functions
  rem: (number) => string,
  fontSize: (number) => string,
  getGutterSpacing: (?number) => string,
  orientation: (string) => string,
  getBreakpoint: (key: 'XXS' | 'XS' | 'SM' | 'MD' | 'LG') => string,
|};

export const themeSkillYoga: cssThemeSYType = Object.freeze({
  colors: {
    BACKGROUND_COLOR: '#212222',
    BLACK_COLOR: '#171717',
    BLACK_COLOR_SEMI_TRANS: '#1c1e1f',
    DARK_GREY_COLOR: '#373a3d',
    ERROR_COLOR: '#ff6855',
    GREY_01_COLOR: '#7b848d',
    GREY_02_COLOR: '#60686f',
    LIGHT_GREY_02: '#bec2c6',
    MAIN_ORANGE_DARK_COLOR: '#623412',
    MAIN_ORANGE_LIGHT_COLOR: '#ffad43',
    PAPER_WHITE_COLOR: '#f1ede9',
    PRIMARY_COLOR: '#ed9b33',
    WHITE_COLOR: '#ffffff',
    FACEBOOK_COLOR: '#3d73b8',
    MESSENGER_COLOR: '#0084ff',
    GREY_03_COLOR: 'rgba(55, 58, 61, 0.4)',
    GREY_04_COLOR: '#7B848D',
    GREY_05_COLOR: 'linear-gradient(0deg, rgba(55, 58, 61, 0.40) 0%, rgba(55, 58, 61, 0.40) 100%), #171717',
  },

  fonts: {
    BARLOW: 'Barlow-Condensed, sans-serif',
    DEFAULT: 'Poppins, sans-serif',
    EXTRA_LIGHT: 'Poppins-Extra-Light, sans-serif',
    LIGHT: 'Poppins-Light, sans-serif',
    MEDIUM: 'Poppins-Medium, sans-serif',
    SEMI_BOLD: 'Poppins-Semi-Bold, sans-serif',
  },

  constants: {
    ABOVE_FOLD_DESKTOP_HEIGHT: 'calc(100vh - 65px)',
    ABOVE_FOLD_MOBILE_HEIGHT: 'calc(100vh - 180px)',
    ABOVE_FOLD_MOBILE_HEIGHT_TRAINING_HOME: 'calc(70vh - 60px)',
    ABOVE_FOLD_MOBILE_HEIGHT_LANDING_PAGE: 'calc(85vh - 10px)',
    BASE_FONT_SIZE: 16,
    DEFAULT_OPACITY: 0.7,
    GUTTER_SIZE: 16,
    PAGE_PADDING_LEFT_RIGHT: 139,
    BREAKPOINTS: {
      XXS: 320,
      XS: 576,
      SM: 768,
      MD: 992,
      LG: 1200,
    },
  },

  rem(pixels) {
    return `${pixels / this.constants.BASE_FONT_SIZE}rem`;
  },

  fontSize(pixels) {
    return this.rem(pixels);
  },

  getGutterSpacing(multiple) {
    return this.rem(this.constants.GUTTER_SIZE * (multiple || 1));
  },

  getBreakpoint(key) {
    return `@media (min-width: ${this.constants.BREAKPOINTS[key.toUpperCase()]}px)`;
  },

  orientation(direction) {
    return `@media (orientation: ${direction})`;
  },
});
