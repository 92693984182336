// @flow
import React from 'react';
import styled from 'styled-components';
import hexToRgba from 'hex-to-rgba';
import {
  CSS_BREAKPOINTS_MIN, DARK_GREY_COLOR, GREY_01_COLOR, PRIMARY_COLOR, SPACING_BASE, TRANSITION_DEFAULT_LEN,
} from '../config/constants';
import SYTag13ptExtended from './SYTag13ptExtended';
import SYPrimaryButton from './SYPrimaryButton';

const CourseContainer = styled.a`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-decoration: none;
  color: inherit;
  background-color: ${hexToRgba(DARK_GREY_COLOR, 0.4)};

  @media ${CSS_BREAKPOINTS_MIN.md} {
    min-height: 100%;
  }
`;

const CourseTopWrapper = styled.div`
  flex: 1;
  width: 100%;
`;

const CourseBottomWrapper = styled.div`
  flex: 1;
  width: 100%;
  padding: 16px;
`;

const CourseTextWrapper = styled.div`
    flex: 1;
    width: 100%;
    padding: 16px;
`;

const CourseImageWrapper = styled.div`
  height: 200px;
  width: 100%;
  overflow: hidden;
`;

const CourseImage = styled.div`
  width: 100%;
  height: 100%;
  transition: all ${TRANSITION_DEFAULT_LEN * 2}s ease;
  background: ${DARK_GREY_COLOR} url(${(props) => props.src}) no-repeat center / cover;

  &:hover {
    transform: scale(1.1);
  }
`;

const CourseMeta = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: no-wrap;
    justify-content: space-between;
    align-items: center;
    text-transform: uppercase;
    margin-bottom: 8px;
    font-size: 13px;
    line-height: 20px;
`;

const CourseHeadline = styled.h2`
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: 0.4px;
`;

const CourseBodyText = styled.div`
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.35px;
`;


const List = styled.ul`
  margin: 0;
`;

const ListEntry = styled.li`
  display: flex;
  align-items: flex-start;
  margin: 0 0 10px;

  img {
    margin: 2px 10px 0 0;
  }
`;

const CoursePriceWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  margin-top: 54px;
`;

const CoursePricePrefix = styled.div`
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-align: right;
    margin-bottom: -4px;
`;

const CoursePrice = styled.div`
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 24px;
    text-align: right;
    margin-bottom: 0px;
    margin-left: 10px;
`;


const CourseDivider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #171717;
`;


type CourseOverviewCardProps = {|
  level: String,
  duration: String,
  title: String,
  subtitle: String,
  listItems: Array,
  price: String,
  cover: any,
  url: String;
|};

const CourseOverviewCard = ({
  level, duration, title, subtitle, listItems, price, cover, url,
}: CourseOverviewCardProps) => (
  <CourseContainer
    href={url}
    rel="noreferrer"
    title={`View our course: ${title}`}
  >

    <CourseTopWrapper>
      <CourseImageWrapper>
        <CourseImage src={cover} />
      </CourseImageWrapper>
      <CourseTextWrapper>
        <CourseMeta>
          {level && <SYTag13ptExtended color={GREY_01_COLOR} style={{ fontSize: 'inherit' }}>{ level }</SYTag13ptExtended>}
          <SYTag13ptExtended color={PRIMARY_COLOR} style={{ fontSize: 'inherit' }}>{ duration }</SYTag13ptExtended>
        </CourseMeta>
        <CourseHeadline style={{ marginBottom: SPACING_BASE * 3 }}>{ title }</CourseHeadline>
        <CourseBodyText style={{ marginBottom: '20px', minHeight: 63 }}>{ subtitle }</CourseBodyText>
        <CourseDivider style={{ backgroundColor: '#171717' }} />
      </CourseTextWrapper>
    </CourseTopWrapper>

    <CourseBottomWrapper>
      <List>
        {listItems.map(({ id, text, icon }) => (
          <ListEntry key={`course-entry-${id}`}>
            <img src={icon} alt="" />
            {text}
          </ListEntry>
        ))}
      </List>

      <CoursePriceWrapper>
        <CoursePricePrefix>Lifetime access for</CoursePricePrefix>
        <CoursePrice>{price}</CoursePrice>
      </CoursePriceWrapper>

      <SYPrimaryButton
        type="outline"
        title="VIEW COURSE"
        href={url}
        style={{
          marginTop: 40,
          width: '100%',
        }}
      />
    </CourseBottomWrapper>
  </CourseContainer>
);

export default CourseOverviewCard;
