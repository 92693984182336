// @flow
import styled from 'styled-components';
import { SPACING_BASE } from '../config/constants';

const SYUnorderedList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0 ${SPACING_BASE * 2}px 0 35px;
`;

export default SYUnorderedList;
