// @flow

import styled from 'styled-components';
import GlobalInputStyle from './GlobalInputStyle';

const SYInput = styled.input`
  ${GlobalInputStyle}
`;

export default SYInput;
