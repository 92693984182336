// @flow

import React from 'react';
import { Provider } from 'react-redux';
import Helmet from 'react-helmet';
import ApplicationRouting from './ApplicationRouting';
import envUtils from './utils/env.utils';
import { store } from './store/configureStore';

const App = () => (
  <Provider store={store}>
    {envUtils.isLocalOrTestEnvironment() && (
    <Helmet>
      <meta name="robots" content="noindex" />
    </Helmet>
    )}

    <Helmet>
      {/* eslint max-len:0 */}
      <meta name="keywords" content="alpham, influencer, youtube, yoga, workout, mobility, strength, meditation, focus, backpain, relaxation, mindfulness, training, app, athletes, functional, skills, personalized ,plan, flexibility, core, body" />
    </Helmet>

    <ApplicationRouting />
  </Provider>
);

export default App;
