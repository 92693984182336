// @flow
import _startsWith from 'lodash/startsWith';

import urls from '../config/urls';
import type { LocationObjectReactRouter } from '../types/LocationObjectReactRouter';

export default (location: LocationObjectReactRouter): boolean => {
  const {
    pathname,
  } = location;

  return _startsWith(pathname, urls.ONBOARDING)
    || _startsWith(pathname, urls.SIGNUP);
};
