// @flow

import styled from 'styled-components';
import { CSS_BREAKPOINTS_MIN, GUTTER_SIZE, PAPER_WHITE_COLOR } from '../config/constants';

const SYHeadlineSix = styled.h6`
  font-family: Poppins-Medium, sans-serif;
  color: ${PAPER_WHITE_COLOR};
  font-size: 15px;
  line-height: 1.73;
  letter-spacing: 0.08px;
  
  @media ${CSS_BREAKPOINTS_MIN.sm} {
    margin: 0 0 ${GUTTER_SIZE}px;
    font-size: 24px;
    line-height: 1.5;
    letter-spacing: 0.6px;
  }
`;

export default SYHeadlineSix;
