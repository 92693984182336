import styled from 'styled-components';
import { TitleElement } from '../text/CohortLp/CohortLpTextElements';
import GenericCardBlack from '../GenericCardBlack';
import { CSS_BREAKPOINTS_MIN, GUTTER_SIZE } from '../../config/constants';
import SYPrimaryButton from '../SYPrimaryButton';

import CalebLevelsSvg from '../../assets/svg/caleb-levels-icon.svg';
import PlaySvg from '../../assets/svg/cohort-play.svg';
import LightningSvg from '../../assets/svg/caleb-lightning-icon.svg';
import RecoverySvg from '../../assets/svg/recovery-icon.svg';
import UnlockSvg from '../../assets/svg/cohort-unlock.svg';
import CalendarSvg from '../../assets/svg/caleb-small-calendar-icon.svg';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${GUTTER_SIZE * 2}px 0;

  @media ${CSS_BREAKPOINTS_MIN.sm} {
    padding: ${GUTTER_SIZE * 2.5}px 0 ${GUTTER_SIZE * 3.5}px;
  }
`;

const CTA = styled(SYPrimaryButton)`
  width: 100%;
  margin-top: ${GUTTER_SIZE * 2}px;
`;

const Title = styled.h4`
  font-size: 20px;
  line-height: 30px;
  text-align: center;
`;

const PriceTag = styled.div`
  padding: ${GUTTER_SIZE}px 0;
  font-family: Poppins-Medium, sans-serif;
  font-size: 58px;
  line-height: 58px;
  text-align: center;
  letter-spacing: 0.4px;
`;

const List = styled.ul`
  margin: ${GUTTER_SIZE}px 0 ${GUTTER_SIZE * 2}px;
`;

const ListEntry = styled.li`
  display: flex;
  align-items: flex-start;
  margin: 0 0 ${GUTTER_SIZE}px;

  img {
    margin: 2px 10px 0 0;
  }
`;

const SubTitle = styled.h5`
  margin: 0 auto ${GUTTER_SIZE}px;
  padding: 0 ${GUTTER_SIZE * 2}px;
  font-size: 15px;
  line-height: 26px;
  text-align: center;
  letter-spacing: 0.075px;
`;

const LIST = [
  { id: 1, text: 'For every yoga level', icon: CalebLevelsSvg },
  { id: 2, text: '4-week course', icon: CalendarSvg },
  { id: 3, text: '2x arm balance technique break-downs per week', icon: PlaySvg },
  { id: 4, text: '2 yoga flows per week preparing you to take on the arm balances', icon: LightningSvg },
  { id: 5, text: '1 grounding meditation', icon: RecoverySvg },
  { id: 6, text: '2 months of Skill Yoga App access', icon: UnlockSvg },
];

const AdamPriceOverview = ({ onCTAClick, ctaTitle = 'ENROLL NOW' }) => (
  <Container>
    <TitleElement>30 days of building the flexibility, strength and skill you’ll need for arm balancing</TitleElement>

    <GenericCardBlack>
      <Title>Lifetime access for</Title>
      <PriceTag>$40</PriceTag>
      <SubTitle>Save 25% if you already have a running Skill Yoga subscription</SubTitle>

      <Title style={{ textAlign: 'left' }}>This course includes:</Title>

      <List>
        {LIST.map(({ id, text, icon }) => (
          <ListEntry key={`course-entry-${id}`}>
            <img src={icon} alt="" />
            {text}
          </ListEntry>
        ))}
      </List>

      <CTA title={ctaTitle} onClick={onCTAClick} />
    </GenericCardBlack>
  </Container>
);

export default AdamPriceOverview;
