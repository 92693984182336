// @flow

import rollbar from '../services/Rollbar';
import envUtils from '../utils/env.utils';

type LoggerErrorStackItem = {|
  fileName: string,
  className?: string, // default: "Unknown Class"
  functionName?: string, // default: "Unknown Function"
  lineNumber?: number, // default: -1
|};

type LoggerError = {|
  name: string,
  message: string,
  stack?: LoggerErrorStackItem[],
|};

const errorStyle = 'font-weight: bold; color: red';

class Logger {
  error(error: Error, stack?: LoggerErrorStackItem[]) {
    const { name, message } = error;
    const loggerError: LoggerError = {
      name,
      message,
      stack,
    };

    if (envUtils.isLocalOrTestEnvironment()) {
      console.group('Logger.error()');
      console.log('%cPassed error', errorStyle, error);
      console.log('%cName:', errorStyle, loggerError.name);
      console.log('%cMessage:', errorStyle, loggerError.message);
      console.log('%cStack', errorStyle, loggerError.stack);
      console.groupEnd();
    }

    rollbar.error(error, { stack });
  }
}

export const logger = new Logger();
export default Logger;
