// @flow

import styled from 'styled-components';
import { PRIMARY_COLOR } from '../config/constants';

const SYBodyTextLink = styled.a`
  color: ${(props) => (props.color ? props.color : PRIMARY_COLOR)};
  text-decoration: none;
  
  &:hover {
    text-decoration: underline;
  }
`;

export default SYBodyTextLink;
