// @flow

import styled from 'styled-components';
import { CSS_BREAKPOINTS_MIN, PAPER_WHITE_COLOR } from '../config/constants';

const SYBody18pt = styled.p`
  font-family: 'Poppins-Light';
  font-size: 17px;
  line-height: 1.5;
  letter-spacing: 0.09px;
  color: ${(props) => (props.color ? props.color : PAPER_WHITE_COLOR)}

  @media ${CSS_BREAKPOINTS_MIN.sm} {
    font-size: 18px;
    letter-spacing: 0.4px;
  }
`;

export default SYBody18pt;
