const loc = window.location;

if (!loc.origin) {
  const value = `${loc.protocol}//${loc.hostname}${loc.port ? `:${loc.port}` : ''}`;

  try {
    Object.defineProperty(loc, 'origin', {
      value, enumerable: true,
    });
  } catch (error) {
    loc.origin = value;
  }
}
