import styled from 'styled-components';
import { CLOUDFRONT_STATIC, CSS_BREAKPOINTS_MIN, GUTTER_SIZE } from '../../config/constants';
import AspectRatio from '../AspectRatio';
import makeImageUrl from '../../modules/makeImageUrl';

const Container = styled.section`
  padding: ${GUTTER_SIZE * 2}px 0 ${GUTTER_SIZE * 4}px;
  background-color: transparent;

  @media ${CSS_BREAKPOINTS_MIN.sm} {
    margin: 0 -${GUTTER_SIZE * 2}px;
    padding: ${GUTTER_SIZE * 2.5}px 40px ${GUTTER_SIZE * 3.5}px;
  }
`;

const Headline = styled.div`
  font-family: Poppins-Medium, sans-serif;
  font-size: 22px;
  line-height: 31px;
  margin: 0;
  margin-bottom: 16px;
  text-align: center;

  @media ${CSS_BREAKPOINTS_MIN.md} {
    font-size: 36px;
    line-height: 45px;
    margin-bottom: 24px;
    text-align: left;
  }
`;

const Text = styled.div`
  font-family: Poppins-Light, sans-serif;
  font-size: 15px;
  line-height: 22px;
  text-align: center;

  @media ${CSS_BREAKPOINTS_MIN.md} {
    font-size: 15px;
    line-height: 26px;
    text-align: left;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr; 
  grid-template-rows: auto; 
  gap: 40px 0px; 
  align-items: center;
  justify-content:center;
  max-width: 100%;

  @media ${CSS_BREAKPOINTS_MIN.md} {
    grid-template-columns: 1fr 1fr; 
    grid-template-rows: auto; 
    gap: 0px 128px; 
  }
`;

const ImageWrapper = styled.div`
  align-self: center;

  @media ${CSS_BREAKPOINTS_MIN.sm} {
    flex: 0 0 40%;
    margin: 0;
    align-self: center;

  }
`;

const ImageElement = styled(AspectRatio)`
  background: url(${makeImageUrl({ url: `${CLOUDFRONT_STATIC}/img/pages/caleb-lp/caleb-sattva-yoga.jpg` })}) no-repeat center / cover;
`;

const TextWrapper = styled.div`
  margin: 0;
  padding: 0;
  align-self: center;
  width: 100%;
`;

const contentText = 'Sattva in Sanskrit means whole.\n'
+ 'Developed in the Himalayas, Sattva Yoga is one of the most comprehensive approaches to yoga in the world today.\n'
+ 'With its roots in the timeless Vedantic and Tantric Traditions, Sattva is an ever-evolving and '
+ 'inclusive practice of asana (postures), pranayama (breath-work)  meditation, kriya (yoga of electricity), radical aliveness, and timeless wisdom.';

const CalebImageTextBanner = () => (
  <Container>
    <Grid>
      <ImageWrapper>
        <ImageElement ratio={1} />
      </ImageWrapper>
      <TextWrapper>
        <Headline>What is Sattva Yoga?</Headline>
        <Text>
          { contentText }
        </Text>
      </TextWrapper>
    </Grid>
  </Container>
);

export default CalebImageTextBanner;
