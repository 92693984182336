// @flow
import styled from 'styled-components';
import SYBodyTextLink from './SYBodyTextLink';
import { PRIMARY_COLOR, SPACING_BASE, TRANSITION_DEFAULT_LEN } from '../config/constants';

const SocialLink = styled(SYBodyTextLink)`
  display: inline-block;
  padding: ${SPACING_BASE * 2}px ${SPACING_BASE * 2}px ${SPACING_BASE}px;
  
  path {
    transition: all ${TRANSITION_DEFAULT_LEN}s;
  }

  &:hover {
    svg,
    svg * {
      fill: ${PRIMARY_COLOR};
    }
  }

  &:last-child {
    margin: 0;
  }
`;

export default SocialLink;
