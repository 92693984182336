// @flow

import React from 'react';
import styled from 'styled-components';
import {
  CSS_BREAKPOINTS_MIN, PRIMARY_COLOR, SPACING_BASE, WHITE_COLOR,
} from '../config/constants';

const BurgerButtonLine = styled.div`
  position: absolute;
  height: 2px;
  width: 100%;
  background: ${WHITE_COLOR};
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: .25s ease-in-out;
  
  &:nth-child(1) {
    top: 0;
    
    .open & {
      top: 8px;
      width: 0;
      left: 50%;
    }
  }
  
  &:nth-child(2),
  &:nth-child(3) {
    top: 8px;
  }
  
  &:nth-child(2) {
    .open & {
      transform: rotate(45deg);
    }
  }
  
  &:nth-child(3) {
    .open & {
      transform: rotate(-45deg);
    }
  }
  
  &:nth-child(4) {
    top: 16px;
    
    .open & {
      top: 9px;
      width: 0;
      left: 50%;
    }
  }
`;

const BurgerButton = styled.button`
  position: relative;
  display: inline-flex;
  width: 24px;
  height: 18px;
  margin: 0 0 0 ${SPACING_BASE * 4}px;
  transform: rotate(0deg);
  transition: .5s ease-in-out;
  background: transparent;
  border: 0;
  cursor: pointer;
  outline: none;
  
  &:hover {
    ${BurgerButtonLine} {
      background: ${PRIMARY_COLOR};
    }
  }
  
  @media ${CSS_BREAKPOINTS_MIN.sm} {
    margin: 0 0 0 ${SPACING_BASE * 9}px;
  }
  
  @media ${CSS_BREAKPOINTS_MIN.md} {
    display: none;
  }
`;

class SYBurgerMenuButton extends React.PureComponent<{
  isOpen?: boolean,
}> {
  static defaultProps = {
    isOpen: false,
  };

  render() {
    const { isOpen, ...rest } = this.props;

    return (
      <BurgerButton {...rest} className={isOpen ? 'open' : ''}>
        <BurgerButtonLine />
        <BurgerButtonLine />
        <BurgerButtonLine />
        <BurgerButtonLine />
      </BurgerButton>
    );
  }
}

export default SYBurgerMenuButton;
