// @flow

const configMap = {
  LOCAL: {
    baseUrl: 'https://skillyoga.eu.ngrok.io',
  },
  TEST: {
    baseUrl: 'https://test.skill-yoga.com',
  },
  PRODUCTION: {
    baseUrl: 'https://skill-yoga.com',
  },
};

class EnvironmentUtils {
  static testEnvironments = [
    'stage.skill-yoga.com',
    'test.skill-yoga.com',
  ];

  static localEnvironments = [
    'localhost',
    'skillyoga.eu.ngrok.io',
  ];

  static isLocalEnvironment() {
    const { hostname } = document.location;

    return this.localEnvironments.indexOf(hostname) > -1;
  }

  static isTestEnvironment() {
    const { hostname } = document.location;

    return this.testEnvironments.indexOf(hostname) > -1;
  }

  static isProduction(): boolean {
    return this.getEnvironment() === 'PRODUCTION';
  }

  /**
   * Returns environment string
   * @example PRODUCTION
   * @returns {string} - 'LOCAL' | 'TEST' | 'PRODUCTION'
   */
  static getEnvironment(): 'LOCAL' | 'TEST' | 'PRODUCTION' {
    if (this.isLocalEnvironment()) {
      return 'LOCAL';
    }

    if (this.isTestEnvironment()) {
      return 'TEST';
    }

    return 'PRODUCTION';
  }

  /**
   * Return base website url
   * @example https://skill-yoga.com
   * @returns {string}
   */
  static getBaseUrl(): string {
    return configMap[this.getEnvironment()].baseUrl;
  }

  /**
   * Checks if the host is localhost, or if the hostname is
   * on Heroku, which means, we have a test environment.
   *
   * @return {Boolean} true, if host is localhost or Heroku host, else false
   */
  static isLocalOrTestEnvironment() {
    return this.isLocalEnvironment() || this.isTestEnvironment();
  }
}

export default EnvironmentUtils;
