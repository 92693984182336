// @flow

import type { ReferralState } from '../../types/ReferralState';
import {
  FETCH_IS_VALID_REFERRER_ACTION,
  FETCH_IS_VALID_REFERRER_FAILED,
  FETCH_IS_VALID_REFERRER_SUCCESS,
} from '../actions/types';

export const makeDefaultState = (): ReferralState => ({
  invitedBy: null,
});

export default function data(state: ReferralState = makeDefaultState(), action: Object): ReferralState {
  const { type, payload } = action;

  const makeNewState = (params: any = {}): ReferralState => ({
    ...state,
    ...params,
  });

  switch (type) {
    case FETCH_IS_VALID_REFERRER_ACTION:
    case FETCH_IS_VALID_REFERRER_FAILED: {
      return makeNewState({
        invitedBy: null,
      });
    }

    case FETCH_IS_VALID_REFERRER_SUCCESS: {
      return makeNewState({
        invitedBy: payload,
      });
    }

    default: {
      return state;
    }
  }
}
