import * as prismic from '@prismicio/client'
import { AUTHORS, CATEGORIES, LESSON, MASTER_COURSES, TESTIMONIALS } from './config/constants'

// Fill in your repository name
export const repositoryName = 'skill-yoga'

export const client = prismic.createClient(repositoryName, {
  // If your repository is private, add an access token
  accessToken: 'MC5aSzdMT3hFQUFDQUFYOVMx.MO-_vQ3vv73vv71NZe-_vQXvv713dQhb77-9dRrvv73vv73vv70e77-977-977-9FH3vv73vv73vv73vv71z77-9',

  // This defines how you will structure URL paths in your project.
  // Update the types to match the custom types in your project, and edit
  // the paths to match the routing in your project.
  //
  // If you are not using a router in your project, you can change this
  // to an empty array or remove the option entirely.
  routes: [
    {
      type: MASTER_COURSES,
      path: '/master-class/',
    },
    {
      type: TESTIMONIALS,
      path: '/master-class/',
    },
    {
      type: LESSON,
      path: '/master-class/workout-detail/',
    },
    {
      type: AUTHORS,
      path: '/master-class/',
    },
    {
      type: AUTHORS,
      path: '/master-class/course-detail-ftue/',
    },
    {
      type: LESSON,
      path: '/master-class/course-detail-ftue/',
    },
    {
      type: CATEGORIES,
      path: '/master-class/course-detail-ftue/',
    },
  ],
})