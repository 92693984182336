import * as types from '../actions/types';

const initState = {
};

export default function packagesReducer(state = initState, action) {
  switch (action.type) {
    case types.LOAD_PACKAGES_SUCCESS:
      return { ...action.payload };

    case types.LOAD_PACKAGES_FAILED:
      return state;

    default:
      return state;
  }
}
