// @flow

import { themeSkillYoga } from './themeSkillYoga';
import type { CSSModuleGeneric } from '../types/CSSModuleGeneric';

type GlobalSkillYogaClasses = {|
  '.contentMaxWidthContainer': CSSModuleGeneric,
|};

const globalSkillYogaClasses: GlobalSkillYogaClasses = Object.freeze({
  '.contentMaxWidthContainer': {
    maxWidth: themeSkillYoga.rem(1400),
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingLeft: themeSkillYoga.constants.GUTTER_SIZE,
    paddingRight: themeSkillYoga.constants.GUTTER_SIZE,
  },

  [themeSkillYoga.getBreakpoint('MD')]: {
    '.contentMaxWidthContainer': {
      width: '100%',
    },
  },

  [themeSkillYoga.getBreakpoint('LG')]: {
    '.contentMaxWidthContainer': {
      paddingLeft: themeSkillYoga.rem(themeSkillYoga.constants.PAGE_PADDING_LEFT_RIGHT),
      paddingRight: themeSkillYoga.rem(themeSkillYoga.constants.PAGE_PADDING_LEFT_RIGHT),
    },
  },
});

export default globalSkillYogaClasses;
