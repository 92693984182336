// @flow
import _find from 'lodash/find';

import type { ProgramData } from '../types/ProgramData';
import { GOALS } from '../config/constants';
import type { Goal } from '../types/Goal';
import makeImageUrl from './makeImageUrl';

class Program {
  programData: ProgramData;

  constructor(programData: ProgramData) {
    this.programData = programData;
  }

  get id(): number {
    return this.programData.id;
  }

  get count(): number {
    return this.programData.count;
  }

  get name(): string {
    return this.programData.name;
  }

  get imageM(): string {
    return makeImageUrl({ url: this.programData.imageM });
  }

  get goal(): string | null {
    const goal: Goal = _find(GOALS, { id: this.programData.goalId });

    if (goal) {
      return goal.label;
    }

    return null;
  }

  get goalId(): number {
    return this.programData.goalId;
  }

  get levelId(): number {
    return this.programData.levelId;
  }

  get description(): string {
    return this.programData.description;
  }

  get shortDescription(): string {
    return this.programData.shortDescription;
  }

  get introVideo(): string | null {
    return this.programData.introVideo;
  }
}

export default Program;
