// @flow
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import styled from 'styled-components';
import DataPrivacyCookieText from './cookie-text';
import { BLACK_COLOR, SPACING_BASE } from '../../../config/constants';
import SYPrimaryButton from '../../SYPrimaryButton';
import type { AuthState } from '../../../store/reducer/auth';
import { setUserActionCreators } from '../../../store/actions/auth';

const CookieContent = styled.div`
  position: fixed;
  display: flex;
  bottom: 0;
  width: 100%;
  min-height: 65px;
  align-items: center;
  justify-content: space-between;
  padding: ${SPACING_BASE * 3}px;
  font-size: 14px;
  background-color: ${BLACK_COLOR};
  z-index: 15;
`;

type Props = {|
  auth: AuthState,
  authActions: typeof setUserActionCreators,
|};

class CookieBanner extends React.Component<Props, {| didAcceptCookies: boolean |}> {
  state = {
    didAcceptCookies: true,
  };

  componentDidMount() {
    const {
      auth: {
        settings: {
          didAcceptCookies,
        },
      },
    } = this.props;

    this.setState({ didAcceptCookies });
  }

  acceptCookies = () => {
    const {
      authActions: {
        setUserAcceptedCookies,
      },
    } = this.props;

    this.setState({ didAcceptCookies: true });
    setUserAcceptedCookies(true);
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.didAcceptCookies !== this.state.didAcceptCookies) {
      if (this.state.didAcceptCookies) {
        this.removeStyleTag();
      } else {
        this.addStyleTag();
      }
    }
  }

  removeStyleTag = () => {
    if(document.querySelector('style#support-styles')) {
      document.querySelector('style#support-styles').remove();
    }
  }

  addStyleTag = () => {
    if(!document.querySelector('style#support-styles')) {
      const style = document.createElement('style');
      style.id = 'support-styles';
      style.innerHTML = 'iframe#jsd-widget { bottom: 60px !important; }';
      document.body.appendChild(style);
    }
  }

  render() {
    const { didAcceptCookies } = this.state;

    if (didAcceptCookies) {
      return null;
    }

    return (
      <CookieContent id="CookieConsent">
        <div>
          <DataPrivacyCookieText />
        </div>

        <SYPrimaryButton
          title="OK"
          type="outline"
          small
          onClick={this.acceptCookies}
          style={{
            marginLeft: SPACING_BASE * 2,
          }}
        />
      </CookieContent>
    );
  }
}

const mapStateToProps = ({ auth }) => ({
  auth,
});

const mapDispatchToProps = (dispatch) => ({
  authActions: bindActionCreators(setUserActionCreators, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(CookieBanner);
