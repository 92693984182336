// @flow
import React from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';

import SYBody16pt from './SYBody16pt';
import PageElementContainer from './PageElementContainer';
import SYHeadlineFour from './SYHeadlineFour';

import { ReactComponent as AppStoreSvg } from '../assets/svg/app-store.svg';
import { ReactComponent as PlayStoreSvg } from '../assets/svg/play-store.svg';
import { ReactComponent as InstagramSvg } from '../assets/svg/instagram-logo.svg';
import { ReactComponent as FacebookSvg } from '../assets/svg/facebook-logo.svg';
import { ReactComponent as YouTubeSvg } from '../assets/svg/youtube-logo.svg';
import {
  CSS_BREAKPOINTS_MIN, DARK_GREY_COLOR, GUTTER_SIZE, PRIMARY_COLOR, SPACING_BASE, TRANSITION_DEFAULT_LEN, WHITE_COLOR,
} from '../config/constants';
import urls, { externalUrls } from '../config/urls';
import NavigationLink from './NavigationLink';
import SocialLink from './SocialLink';
import isOnboardingPath from '../modules/isOnboardingPath';
import type { LocationObjectReactRouter } from '../types/LocationObjectReactRouter';

const FooterHeadline = styled(SYHeadlineFour)`
  display: none;
  margin: 0 0 ${SPACING_BASE * 6}px;

  @media ${CSS_BREAKPOINTS_MIN.sm} {
    display: block;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  &:first-child {
    margin-bottom: ${GUTTER_SIZE * 3}px;

    @media ${CSS_BREAKPOINTS_MIN.sm} {
      margin-bottom: ${GUTTER_SIZE * 3.5}px;
    }
  }

  &:last-child {
    margin: 0;
  }

  @media ${CSS_BREAKPOINTS_MIN.sm} {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
`;

const ContentLeftRight = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ContentLeft = styled(ContentLeftRight)`
  text-align: left;
  margin-bottom: ${GUTTER_SIZE * 4.5}px;

  @media ${CSS_BREAKPOINTS_MIN.sm} {
    align-items: flex-start;
    margin-bottom: 0;
  }
`;

const ContentRight = styled(ContentLeftRight)`
  text-align: right;

  @media ${CSS_BREAKPOINTS_MIN.sm} {
    align-items: flex-end;
  }
`;

const FooterLinks = styled.div`
  display: flex;
  flex-wrap: wrap;
  min-width: 300px;
  max-width: 300px;
  justify-content: center;

  @media ${CSS_BREAKPOINTS_MIN.sm} {
    max-width: none;
    justify-content: flex-start;
  }
`;

const FooterTextLink = styled(NavigationLink)`
  display: block;
  margin: 0 ${GUTTER_SIZE}px ${SPACING_BASE}px 0;
  padding-right: ${GUTTER_SIZE}px;
  font-family: 'Poppins-Medium', sans-serif;
  font-size: 15px;
  line-height: 1.67;
  letter-spacing: 3.75px;
  border-right: 1px solid ${DARK_GREY_COLOR};

  &:last-child {
    margin: 0;
  }

  &:nth-child(2) {
    border: 0;
    margin-right: 0;
    padding-right: 0;
  }

  @media ${CSS_BREAKPOINTS_MIN.sm} {
    display: inline-block;
    border-right: 1px solid ${DARK_GREY_COLOR};

    &:nth-child(2) {
      border-right: 1px solid ${DARK_GREY_COLOR};
      margin-right: ${GUTTER_SIZE}px;
      padding-right: ${GUTTER_SIZE}px;
    }
  }
`;

const FooterATag = styled.a`
  display: block;
  margin: 0 0 ${SPACING_BASE};
  font-family: 'Poppins-Medium', sans-serif;
  font-size: 15px;
  line-height: 1.67;
  letter-spacing: 3.75px;
  color: ${WHITE_COLOR};
  text-decoration: none;
  outline: none;
  transition: color ${TRANSITION_DEFAULT_LEN}s;

  &:hover {
    color: ${PRIMARY_COLOR};
  }

  &:last-child {
    margin: 0;
  }

  @media ${CSS_BREAKPOINTS_MIN.sm} {
    display: inline-block;
    margin: 0 ${SPACING_BASE * 4}px 0 0;
    padding: 0 ${SPACING_BASE * 4}px 0 0;
  }
`;

const StoreLinks = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${CSS_BREAKPOINTS_MIN.sm} {
    display: block;
  }
`;

const StoreLink = styled(SocialLink)`
  padding: 0;
  margin: 0 0 ${GUTTER_SIZE / 2}px 0;

  @media ${CSS_BREAKPOINTS_MIN.sm} {
    margin-right: ${GUTTER_SIZE * 2}px;
  }
`;

const StoreIcon = styled.svg`
  width: 150px;

  @media ${CSS_BREAKPOINTS_MIN.sm} {
    width: auto;
    height: 50px;
  }
`;

type Props = {|
  location: LocationObjectReactRouter,
|};

const SYFooter = ({ location }: Props) => {
  if (isOnboardingPath(location)) {
    return null;
  }

  return (
    <PageElementContainer id="Footer">
      <ContentContainer>
        <ContentLeft>
          <FooterHeadline>Get the app</FooterHeadline>
          <StoreLinks>
            <StoreLink href={externalUrls.get('playstore')} title="Link to App in PlayStore" target="_blank" rel="noreferrer">
              <StoreIcon as={PlayStoreSvg} />
            </StoreLink>
            <StoreLink href={externalUrls.get('appstore')} title="Link to App in App Store" target="_blank" rel="noreferrer">
              <StoreIcon as={AppStoreSvg} />
            </StoreLink>
          </StoreLinks>
        </ContentLeft>

        <ContentRight>
          <FooterHeadline align="right">Find us online</FooterHeadline>

          <div>
            <SocialLink href="//www.instagram.com" title="Link to Instagram" target="_blank" rel="noreferrer"><InstagramSvg /></SocialLink>
            <SocialLink href="//www.facebook.com/SkillYogaWorkouts/" title="Link to Facebook" target="_blank" rel="noreferrer"><FacebookSvg /></SocialLink>
            <SocialLink
              href="//www.youtube.com/channel/UC_D-YaHdGfXRPCrTm8_hwNw"
              title="Link to YouTube channel"
              target="_blank"
              rel="noreferrer"
              style={{
                marginRight: -GUTTER_SIZE / 2,
              }}
            >
              <YouTubeSvg />
            </SocialLink>
          </div>
        </ContentRight>
      </ContentContainer>

      <ContentContainer>
        <ContentLeft>
          <FooterLinks>
            <FooterTextLink title="imprint" to={urls.IMPRINT}>IMPRINT</FooterTextLink>
            <FooterTextLink title="terms of use" to={urls.TERMS}>TERMS OF USE</FooterTextLink>
            <FooterTextLink title="privacy policy" to={urls.PRIVACY_POLICY}>PRIVACY POLICY</FooterTextLink>
            <FooterATag title="support" href={urls.SUPPORT} target="_blank">SUPPORT</FooterATag>
          </FooterLinks>
        </ContentLeft>

        <ContentRight>
          <SYBody16pt right paddingBottom={"70px"} paddingBottomDesktop={0}>
            {`© ${new Date().getFullYear()} `}
            Skill Yoga GmbH. All rights reserved.
          </SYBody16pt>
        </ContentRight>
      </ContentContainer>
    </PageElementContainer>
  );
};

export default withRouter(SYFooter);
