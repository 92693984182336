const ApiEnum = {
  API_PATH: '/api',
  AUTOPILOT: {
    MOCK: 'https://private-anon-f19e8d0a9b-autopilot.apiary-proxy.com',
    MOCK_VERSION: 'v1',
    PROD: 'https://api2.autopilothq.com',
    PROD_VERSION: 'v1',
  },
  VERSION: 'v1',
};

export default Object.freeze({
  AUTOPILOT_MOCK: `${ApiEnum.AUTOPILOT.MOCK}/${ApiEnum.AUTOPILOT.MOCK_VERSION}`,
  AUTOPILOT_PROD: `${ApiEnum.AUTOPILOT.PROD}/${ApiEnum.AUTOPILOT.PROD_VERSION}`,
  PUBLIC_BASE_URL: `${ApiEnum.API_PATH}/${ApiEnum.VERSION}/public`,
  SECURE_BASE_URL: `${ApiEnum.API_PATH}/${ApiEnum.VERSION}/secure`,
});
