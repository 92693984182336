// @flow

import React from 'react';
import styled from 'styled-components';

import {
  CSS_BREAKPOINTS_MIN, GUTTER_SIZE, PRIMARY_COLOR, WHITE_COLOR,
} from '../config/constants';
import { ReactComponent as CloseSvg } from '../assets/svg/close-x.svg';
import SYTag11pt from './SYTag11pt';

const OuterContainer = styled.div`
  margin: ${GUTTER_SIZE / 2}px 0;
  background: ${WHITE_COLOR};
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.3);
  
  @media ${CSS_BREAKPOINTS_MIN.sm} {
    position: relative;
    left: 50%;
    display: inline-block;
    transform: translateX(-50%);
  }
`;

const InnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${GUTTER_SIZE}px;
`;

const Message = styled(SYTag11pt)`
  white-space: nowrap;
  color: ${PRIMARY_COLOR};
  font-family: 'Poppins', sans-serif;
`;

const DismissAction = styled.div`
  margin: -${GUTTER_SIZE}px -${GUTTER_SIZE}px -${GUTTER_SIZE}px 0;
  padding: 0 ${GUTTER_SIZE}px;
  cursor: pointer;
`;

const DismissIcon = styled.svg`
  width: 10px;
  height: 10px;
  
  path {
    stroke: ${PRIMARY_COLOR};
  }
`;

class SYNotification extends React.PureComponent<{
  message: string,
  onDismiss?: Function,
  position?: string,
}> {
  static defaultProps = {
    onDismiss: null,
    position: 'bottom',
  };

  render(): React$Element<*> {
    const {
      message,
      onDismiss,
      position,
    } = this.props;

    return (
      <OuterContainer position={position}>
        <InnerContainer>
          <Message>{message}</Message>
          <DismissAction onClick={onDismiss}>
            <DismissIcon as={CloseSvg} />
          </DismissAction>
        </InnerContainer>
      </OuterContainer>
    );
  }
}

export default SYNotification;
