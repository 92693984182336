// @flow
import queryString from 'query-string';
import _startsWith from 'lodash/startsWith';

const IMG_SERVICE_URL = '//images.weserv.nl';

// il=true&fit=cover&a=attention
type ParamerList = {
  url: string,
  w?: number,
  h?: number,
  il?: boolean, // interlacing for GIF / PNG and progressiveness for JPG
  dpr?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8, // Device pixel ratio
  fit?: 'inside' | 'outside' | 'cover' | 'fill' | 'contain',
  a?: 'entropy' | 'attention', // works only with fit=cover
  flop?: boolean, // horizontal mirroring
};

const makeImageUrl = ({
  url, w = undefined, h = undefined, il = true, dpr = window.devicePixelRatio || 1, a, fit, flop = false,
}: ParamerList): string => {
  if (_startsWith(url, IMG_SERVICE_URL)) {
    return url;
  }

  // extract the protocol from the url
  const tmpUrl = new URL(url);

  return `${IMG_SERVICE_URL}/?${queryString.stringify({
    url: `${tmpUrl.hostname}${tmpUrl.pathname}`,
    w,
    h,
    il,
    dpr,
    a,
    fit,
    flop,
  })}`;
};

export default makeImageUrl;
