import Slider from 'react-slick';
import styled from 'styled-components';
import Caleb01Svg from '../../assets/svg/caleb-01-icon.svg';
import Caleb02Svg from '../../assets/svg/caleb-02-icon.svg';
import Caleb03Svg from '../../assets/svg/caleb-03-icon.svg';
import Caleb04Svg from '../../assets/svg/caleb-04-icon.svg';
import {
  CSS_BREAKPOINTS,
  CSS_BREAKPOINTS_MIN,
  GUTTER_SIZE,
} from '../../config/constants';
import { TitleElement } from '../text/CohortLp/CohortLpTextElements';

const Container = styled.section`
  padding: ${GUTTER_SIZE * 2}px 0 ${GUTTER_SIZE * 4}px;

  @media ${CSS_BREAKPOINTS_MIN.sm} {
    margin: 0 -${GUTTER_SIZE * 2}px;
    padding: ${GUTTER_SIZE * 2.5}px 0 ${GUTTER_SIZE * 3.5}px;
  }
`;

const Slide = styled.div`
  position: relative;
  padding: 0 ${GUTTER_SIZE * 2}px;

  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: 26px;
    width: 17px;
  }
`;

const Headline = styled.div`
  font-family: Poppins-Medium, sans-serif;
  font-size: 16px;
  line-height: 26px;
  margin: 0;

  @media ${CSS_BREAKPOINTS_MIN.sm} {
    font-size: 16px;
    line-height: 26px;
  }
`;

const Text = styled.div`
  font-family: Poppins-Light, sans-serif;
  font-size: 15px;
  line-height: 26px;

  @media ${CSS_BREAKPOINTS_MIN.sm} {
    font-size: 16px;
    line-height: 26px;
  }
`;

const TitleContainer = styled.div`
  width: 100%;
  height: 94px;
  padding-top: 18px;
  margin-bottom: 16px;
  background-color: green;
  background: url(${(props) => props.icon}) no-repeat left / cover;
  background-size: auto 94px;
`;

/* eslint-disable max-len */
const LIST = [
  {
    id: 1,
    icon: Caleb01Svg,
    title: 'WEEK 1',
    subtitle: 'Discover Yourself',
    text: 'Here you begin your journey of conscious exploration through the ancient and integrative practice of Sattva Yoga. Discover how yoga is far more than physical fitness as Caleb guides you through a process of self-discovery using asana, pranayama (breath-work), meditation and kriya (yoga of electricity).',
  },
  {
    id: 2,
    icon: Caleb02Svg,
    title: 'WEEK 2',
    subtitle: 'Effortless Presence',
    text: 'Learn how to tap into the power of now as you let go of attachment to past pain, self-defeating patterns of thought and old stories. In this week\'s practices, you will learn to set the ‘monkey mind’ free and allow pure presence to be your dominant experience.',
  },
  {
    id: 3,
    icon: Caleb03Svg,
    title: 'WEEK 3',
    subtitle: 'Experience Your Essential Nature',
    text: 'This week\'s practices will help you to transcend bio-memory, memories stored on a mind/body level - often associated with wounding from early years. Through the power of Sattva Yoga, unlock stuck energy, release toxins and in turn, experience the existential joy that is your essential nature.',
  },
  {
    id: 4,
    icon: Caleb04Svg,
    title: 'WEEK 4',
    subtitle: 'Your Practice Is You',
    text: 'How you practice on the mat is how you live your life. For your final week in the series, Caleb helps you to integrate all of the tools you’ve learned and apply them to your daily life in order to cultivate greater connection, clarity of mind and realise your ultimate potential.',
  },
];
/* eslint-enable max-len */

const CalebCoursePlan = () => (
  <Container>
    <TitleElement>Course plan</TitleElement>

    <Slider
      arrows={false}
      autoplay
      autoplaySpeed={5000}
      centerMode
      // rem is not an option here, because the slider library doesn't support rem
      centerPadding="0px"
      className="center"
      dots={false}
      slidesToShow={4}
      slidesToScroll={4}
      speed={500}
      responsive={[
        {
          breakpoint: CSS_BREAKPOINTS.sm,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: true,
          },
        },
      ]}
    >
      {
      LIST.map((
        {
          id, icon, title, subtitle, text,
        },
      ) => (
        <Slide key={`slide-cv-${id}`}>
          <TitleContainer icon={icon}>
            <Headline>{title}</Headline>
            <Headline>{subtitle}</Headline>
          </TitleContainer>
          <Text>{text}</Text>
        </Slide>
      ))
      }
    </Slider>
  </Container>
);

export default CalebCoursePlan;
