// @flow

/**
 * A plan is a program that contains workouts
 * (and is active as a program for the user most of the time)
 */

import _isEmpty from 'lodash/isEmpty';
import _find from 'lodash/find';

import type { Goal } from '../types/Goal';
import { EXPERIENCE_LEVELS, GOALS } from '../config/constants';
import type { GoalId } from '../types/GoalId';
import type { ExperienceId } from '../types/ExperienceId';
import type { Experience } from '../types/Experience';
import Workout from './Workout';
import notEmpty from './notEmpty';
import makeImageUrl from './makeImageUrl';

type PlanData = {|
  count: number,
  description: string,
  goalId: GoalId,
  id: number,
  imageM: string,
  introVideo: string | null,
  levelId: ExperienceId,
  name: string,
  shortDescription: string,
  userHistoryId: number | null,
  workouts: Array<any>,
  nextWorkout: any,
  externalContentSlug?: string,
|};

class Plan {
  planData: PlanData;

  constructor(planData: PlanData) {
    this.planData = planData;

    if (notEmpty(this.planData.workouts)) {
      this.planData.workouts = planData.workouts.map((workout) => new Workout(workout));
    }
  }

  get id(): number {
    return this.planData.id;
  }

  get name(): string {
    return this.planData.name;
  }

  get userHistoryId(): number | null {
    return this.planData.userHistoryId;
  }

  get goal(): Goal | null {
    return _find(GOALS, ({ id }) => id === this.planData.goalId) || null;
  }

  get goalId(): GoalId | null {
    return this.planData.goalId;
  }

  get workouts(): Array<any> {
    return this.planData.workouts || [];
  }

  set workouts(workouts: Array<any>) {
    this.planData.workouts = workouts;
  }

  get level(): Experience | null {
    return _find(EXPERIENCE_LEVELS, ({ id }) => id === this.planData.levelId) || null;
  }

  get externalContentSlug(): string {
    return this.planData?.externalContentSlug ?? "";
  }

  get levelId(): number {
    return this.planData.levelId;
  }

  get imageM(): string {
    return makeImageUrl({ url: this.planData.imageM });
  }

  get count(): number {
    return this.planData.count || this.planData.workouts.length;
  }

  get description(): string {
    return this.planData.description;
  }

  get shortDescription(): string {
    return this.planData.shortDescription;
  }

  get nextWorkout(): Workout | null {
    const { workouts } = this.planData;

    if (_isEmpty(workouts)) {
      return null;
    }

    const workout = _find(workouts, (w) => w.done === false);

    if (!workout) {
      return workouts[0];
    }

    return workout;
  }

  set nextWorkout(nextWorkout: any): void {
    this.planData.nextWorkout = nextWorkout;
  }

  get isDone(): boolean {
    return !_find(this.workouts, (w: Workout) => w.done === false);
  }

  get introVideo(): string | null {
    return this.planData.introVideo;
  }
}

export default Plan;
