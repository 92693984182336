// @flow
import Plan from "./Plan";
import type { ApiResponsePlan } from "../types/ApiResponsePlan";
import _isEmpty from "lodash/isEmpty";
import _find from "lodash/find";

const getNextWorkout = (planResponse: ApiResponsePlan) => {
  const workouts = planResponse?.workouts;

  if (_isEmpty(workouts)) {
    return null;
  }

  const workout = _find(workouts, (w) => w.done == false);

  if (!workout) {
    return workouts[0];
  }

  return workout;
};

const makeProgramWithWorkoutsFromApiResponse = (
  planResponse: ApiResponsePlan
): Plan =>
  new Plan({
    count: planResponse.count || 0,
    id: planResponse.id,
    goalId: planResponse.goalId || 1,
    userHistoryId: planResponse.userHistoryId || null,
    levelId: planResponse.levelId || 1,
    imageM: planResponse.imageM || "",
    description: planResponse.description || "",
    shortDescription: planResponse.shortDescription || "",
    name: planResponse.name || "",
    introVideo: planResponse.introVideo,
    workouts: planResponse.workouts || [],
    externalContentSlug: planResponse.externalContentSlug || [],
    nextWorkout: getNextWorkout(planResponse),
  });

export default makeProgramWithWorkoutsFromApiResponse;


// // @flow
// import Plan from "./Plan";
// import type { ApiResponsePlan } from "../types/ApiResponsePlan";
// import _isEmpty from "lodash/isEmpty";
// import _find from "lodash/find";

// const getNextWorkout = (planResponse: ApiResponsePlan, responseProgressPlan) => {
//   const workouts = planResponse?.workouts;
//   if (_isEmpty(workouts)) {
//     return null;
//   }
//   const dataPlanProgress = responseProgressPlan?.filter(item => item.id === planResponse.id);
  
//   return workouts[dataPlanProgress?.progress ?? 0];
// };

// const makeProgramWithWorkoutsFromApiResponse = (
//   planResponse: ApiResponsePlan,
//   responseProgressPlan: { progress: Number; completed: boolean }
// ): Plan =>
//   new Plan({
//     count: planResponse.count || 0,
//     id: planResponse.id,
//     goalId: planResponse.goalId || 1,
//     userHistoryId: planResponse.userHistoryId || null,
//     levelId: planResponse.levelId || 1,
//     imageM: planResponse.imageM || "",
//     description: planResponse.description || "",
//     shortDescription: planResponse.shortDescription || "",
//     name: planResponse.name || "",
//     introVideo: planResponse.introVideo,
//     workouts: planResponse.workouts || [],
//     externalContentSlug: planResponse.externalContentSlug || [],
//     nextWorkout: getNextWorkout(planResponse, responseProgressPlan),
//   });

// export default makeProgramWithWorkoutsFromApiResponse;

