// @flow

import type Moment from 'moment-timezone';
import moment from 'moment-timezone';
import _find from 'lodash/find';

import type { MarketingCampaignType } from '../types/MarketingCampaignType';

const CAMPAIGNS: $ReadOnlyArray<MarketingCampaignType> = [
  {
    id: 1,
    code: 'endofwinter23',
    start: moment.tz('2022-12-26 00:00:00', 'Europe/Berlin'),
    end: moment.tz('2023-12-23 23:59:59', 'Europe/Berlin'),
    slug: 'Summer Sale | 30% off',
    showCountdown: true,
  },
];

class MarketingBannerService {
  get userTime(): Moment {
    return moment().tz(moment.tz.guess());
  }

  get campaigns(): $ReadOnlyArray<MarketingCampaignType> {
    return CAMPAIGNS;
  }

  get active(): MarketingCampaignType | null {
    return _find(this.campaigns, (campaign: MarketingCampaignType) => this.userTime.isBetween(campaign.start, campaign.end)) || null;
  }

  get code(): string | null {
    const activeCampaign = this.active;

    return (activeCampaign && activeCampaign.code) || null;
  }

  get slugCards(): string | null {
    const activeCampaign = this.active;

    return (activeCampaign && activeCampaign.slugCards) || null;
  }
}

// eslint-disable-next-line import/prefer-default-export
export const marketingCampaignService = new MarketingBannerService();
