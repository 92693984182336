// @flow

import withStyles, { WithStyles } from 'react-jss';
import React from 'react';
import styles from './GenericCardBlack.styles';

type Props = WithStyles & {
  children?: React$Node,
  title: string,
}

const GenericCardBlack = ({ children, classes }: Props) => (
  <div className={classes.skillCoachCard}>
    <div className={classes.children}>
      {children}
    </div>
  </div>
);

export default withStyles(styles, { withTheme: true })(GenericCardBlack);
