// @flow
import _includes from 'lodash/includes';
import _trim from 'lodash/trim';

import * as types from '../actions/types';
import type { UserOnboarding } from '../../types/UserOnboarding';
import type { WeekdayId } from '../../types/WeekdayId';

const makeDefaultUserState = (): UserOnboarding => ({
  email: '',
  experienceId: null,
  firstName: '',
  genderId: null,
  goalId: null,
  journeyMailing: false,
  lastName: '',
  newsletter: false,
  password: '',
  sportId: null,
  trainingDuration: 1,
  weekdays: [],
});

export default function onboardingReducer(state: UserOnboarding = makeDefaultUserState(), action: Object) {
  const { type, payload } = action;

  switch (type) {
    case types.ONBOARDING_SET_EXPERIENCE_ID:
      return { ...state, experienceId: payload };

    case types.ONBOARDING_SET_GOAL_ID:
      return { ...state, goalId: payload };

    case types.ONBOARDING_SET_SPORT_ID:
      return { ...state, sportId: payload };

    case types.ONBOARDING_SET_GENDER_ID:
      return { ...state, genderId: payload };

    case types.ONBOARDING_SET_USER_ONBOARDING: 
      return { ...state, ...payload};

    case types.ONBOARDING_POP_WEEKDAY: {
      const { weekdays } = state;

      if (!_includes(weekdays, payload)) {
        return state;
      }

      // $FlowFixMe
      const newWeekdays = ([...weekdays].filter((w) => w !== payload): WeekdayId[]).sort();
      return { ...state, weekdays: newWeekdays };
    }

    case types.ONBOARDING_PUSH_WEEKDAY: {
      const { weekdays } = state;

      if (_includes(weekdays, payload)) {
        return state;
      }

      // $FlowFixMe
      const newWeekdays: Array<WeekdayId> = ([...weekdays, (payload: any)]: WeekdayId[]).sort();
      return { ...state, weekdays: newWeekdays };
    }

    case types.ONBOARDING_SET_FIRST_NAME: {
      return { ...state, firstName: _trim(payload.target.value) };
    }

    case types.ONBOARDING_SET_EMAIL: {
      return { ...state, email: _trim(payload.target.value).toLowerCase() };
    }

    case types.ONBOARDING_SET_PASSWORD: {
      return { ...state, password: _trim(payload.target.value) };
    }

    case types.ONBOARDING_SET_NEWSLETTER: {
      return { ...state, newsletter: payload };
    }

    case types.ONBOARDING_SET_JOURNEY_MAILING: {
      return { ...state, journeyMailing: payload };
    }

    default:
      return state;
  }
}
