import styled from 'styled-components';
import { SubTitleElement, TitleElement } from '../text/CohortLp/CohortLpTextElements';
import { CSS_BREAKPOINTS_MIN, GUTTER_SIZE, PAPER_WHITE_COLOR } from '../../config/constants';
import SYBody16pt from '../SYBody16pt';
import SelfMasterySvg from '../../assets/svg/caleb-self-mastery.svg';
import CalebIconSvg from '../../assets/svg/caleb-icon.svg';
import CalebIconLearningSvg from '../../assets/svg/caleb-icon-learning.svg';

const Container = styled.section`
  text-align: center;
  padding-top: 40px;
  padding-bottom: 40px;

  @media ${CSS_BREAKPOINTS_MIN.md} {
    display: grid;
    grid-column-gap: 2em;
    grid-template-columns: repeat(3, 1fr);
  }

  @media ${CSS_BREAKPOINTS_MIN.lg} {
    grid-column-gap: 4em;
  }
`;

const Text = styled(SYBody16pt)`
  text-align: center;
  color: ${PAPER_WHITE_COLOR};
  padding-bottom: ${GUTTER_SIZE * 2}px;
`;

const ImageSelfMastery = styled.div`
  img {
    width: 72px;
    margin-bottom: 20px;

    @media ${CSS_BREAKPOINTS_MIN.md} {
      width: 110px;
      margin-bottom: 30px;
    }
  }
`;

const ImageCaleb = styled.div`
  img {
    width: 72px;
    margin-bottom: 20px;

    @media ${CSS_BREAKPOINTS_MIN.md} {
      width: 110px;
      margin-bottom: 30px;
    }
  }
`;

const ImageLearning = styled.div`
  img {
    width: 72px;
    margin-bottom: 20px;

    @media ${CSS_BREAKPOINTS_MIN.md} {
      width: 110px;
      margin-bottom: 30px;
    }
  }
`;

const Benefit = styled.div`
  margin-bottom: ${GUTTER_SIZE * 2}px;

  @media ${CSS_BREAKPOINTS_MIN.md} {
    display: contents;

    &:first-child ${ImageSelfMastery} {
      grid-row: 1;
      grid-column: 1;
    }

    &:nth-child(2) ${ImageCaleb} {
      grid-row: 1;
      grid-column: 2;
    }

    &:last-child ${ImageLearning} {
        grid-row: 1;
        grid-column: 3;
    }

    &:first-child ${SubTitleElement} {
      grid-row: 2;
      grid-column: 1;
    }

    &:nth-child(2) ${SubTitleElement} {
      grid-row: 2;
      grid-column: 2;
    }

    &:last-child ${SubTitleElement} {
      grid-row: 2;
      grid-column: 3;
    }

    &:first-child ${Text} {
      grid-row: 3;
      grid-column: 1;
    }

    &:nth-child(2) ${Text} {
      grid-row: 3;
      grid-column: 2;
    }

    &:last-child ${Text} {
      grid-row: 3;
      grid-column: 3;
    }
  }
`;

const CalebBenefits = () => (
  <>
    <TitleElement>Train anytime, anywhere</TitleElement>
    <Container>
      <Benefit>
        <ImageSelfMastery>
          <img src={SelfMasterySvg} alt="Self mastery SVG" />
        </ImageSelfMastery>
        <SubTitleElement>Powerful Path to Self Mastery</SubTitleElement>
        <Text>
          Learn how to apply ancient yogic practices to build strength, mental clarity and emotional fitness.
        </Text>
      </Benefit>

      <Benefit>
        <ImageCaleb>
          <img src={CalebIconSvg} alt="Caleb Icon" />
        </ImageCaleb>

        <SubTitleElement>Practice with Caleb Jude Packham</SubTitleElement>

        <Text>
          The UK&apos;s Wellness Warrior is one of the world&apos;s most influential teachers of Sattva Yoga with years
          of experience that he&apos;s making exclusively available to you.
        </Text>
      </Benefit>

      <Benefit>
        <ImageLearning>
          <img src={CalebIconLearningSvg} alt="Learning" />
        </ImageLearning>

        <SubTitleElement>Active learning with life-changing results</SubTitleElement>

        <Text>
          Follow a structured program, ask questions, collaborate with your peers and Caleb.
          After the course, get access to the Skill Yoga app to build on your new skills.
        </Text>
      </Benefit>
    </Container>
  </>
);

export default CalebBenefits;
