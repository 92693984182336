// @flow

import styled from 'styled-components';
import { CSS_BREAKPOINTS_MIN, WHITE_COLOR } from '../config/constants';

const SYHeadlineThree = styled.h3`
  font-family: Poppins-Medium, sans-serif;
  color: ${WHITE_COLOR};
  font-size: 22px;
  line-height: 1.41;
  letter-spacing: 0.11px;
  
  @media ${CSS_BREAKPOINTS_MIN.sm} {
    font-size: 30px;
    line-height: 1.47;
    letter-spacing: 0.75px;
  }
`;

export default SYHeadlineThree;
