/* eslint max-len: 0 */
// @flow
import hexToRgba from 'hex-to-rgba';
import type { Goal } from '../types/Goal';
import type { Experience } from '../types/Experience';
import type { Gender } from '../types/Gender';
import type { Weekday } from '../types/Weekday';
import type { TrainingHabit } from '../types/TrainingHabit';
import urls, { externalUrls } from './urls';
import type { MenuLink } from '../types/MenuLink';
import type { Sport } from '../types/Sport';
import beginnerSvg from '../assets/svg/tag-icon-beginner.svg';
import strengthSvg from '../assets/svg/tag-icon-strength.svg';
import mobilitySvg from '../assets/svg/tag-icon-mobility.svg';
import mindfulnessSvg from '../assets/svg/tag-icon-mindfulness.svg';
import skillsSvg from '../assets/svg/tag-icon-skills.svg';
import singlesSvg from '../assets/svg/tag-icon-singles.svg';
import knowledgeSvg from '../assets/svg/tag-icon-knowledge.svg';
import type { SocialLoginType } from '../types/SocialLoginType';
import type { Intensity } from '../types/Intensity';
import type { Enjoyment } from '../types/Enjoyment';
import { themeSkillYoga } from '../styles/themeSkillYoga';

export const API_GATEWAY_NAME = 'APIGateway';
export const SUBSCRIPTION_PROVIDER_WEB = 'STRIPE';
export const DATE_FORMAT = 'DD.MM.YYYY';
export const CLOUDFRONT_STATIC = 'https://d3i3csyu9g9snt.cloudfront.net';
export const REFERRAL_CODE = '7c18d4d6_ef43_4a21_ab0e_aad157d53f7c';
export const DEFAULT_LANGUAGE_TAG = 'en-US';
export const DEFAULT_PRODUCTS_EXTERNAL_IDS = ['12m-web-plan', '1m-web-plan'];
export const GOOGLE_CAPTCHA_V3_SITE_KEY = '6LesT-UUAAAAAIvrzHNLKroMF-nEF17Vxl5MjtEh';
export const USERNAME_EXIST_EXCEPTION = 'UsernameExistsException';
export const TESTIMONIALS = 'testimonials';
export const MASTER_COURSES = 'master_courses';
export const LESSON = 'lesson';
export const AUTHORS = 'authors';
export const CATEGORIES = 'categories';
export const BANNER = 'marketing_banner';
export const MODE_PAYMENT = 1;
export const MODE_SUBSCRIPTION = 0;

/**
 * View Names (see AbstractView.js)
 */

export const DASHBOARD_VIEW_NAME = 'DashboardView';
export const INVITE_FRIENDS_VIEW_NAME = 'InviteFriendsView';
export const REFERRAL_VIEW_NAME = 'ReferralView';
export const WORKOUT_VIEW_NAME = 'WorkoutView';
export const WORKOUT_VIDEO_MODAL_VIEW_NAME = 'WorkoutVideoModalView';
export const WORKOUT_COMPLETED_VIEW_NAME = 'WorkoutCompletedView';
export const WORKOUT_FEEDBACK_STEP_1_VIEW_NAME = 'WorkoutFeedbackStep1View';
export const WORKOUT_FEEDBACK_STEP_2_VIEW_NAME = 'WorkoutFeedbackStep2View';
export const LIBRARY_VIEW_NAME = 'LibraryView';
export const ONBOARDING_GOAL_SELECTION_VIEW_NAME = 'OnboardingGoalSelectionView';
export const ONBOARDING_SPORT_SELECTION_VIEW_NAME = 'OnboardingSportSelectionView';
export const ONBOARDING_EXPERIENCE_SELECTION_VIEW_NAME = 'OnboardingExperienceSelectionView';
export const ONBOARDING_GENDER_SELECTION_VIEW_NAME = 'OnboardingGenderSelectionView';
export const ONBOARDING_EMAIL_ENTRY_VIEW_NAME = 'OnboardingEmailEntryView';
export const ONBOARDING_TERMS_VIEW_NAME = 'OnboardingTermsView';
export const ONBOARDING_MAILING_OPTIN_VIEW_NAME = 'OnboardingMailingOptinView';
export const ONBOARDING_PLAN_CREATION_VIEW_NAME = 'OnboardingPlanCreationView';
export const ONBOARDING_PLAN_PRESENTATION_VIEW_NAME = 'OnboardingPlanPresentationView';
export const ALPHA_M_LANDING_PAGE_VIEW = 'AlphaMLandingPageView';
export const PROGRAMS_OVERVIEW_VIEW_NAME = 'ProgramsOverViewView';
export const PAYMENT_VIEW_NAME = 'PaymentView';
export const VOUCHER_VIEW_NAME = 'VoucherView';
export const LOGIN_VIEW_NAME = 'LoginView';
export const FORGOT_PASSWORD_VIEW_NAME = 'ForgotPasswordView';
export const CHANGE_PASSWORD_VIEW_NAME = 'ChangePasswordView';
export const ACCOUNT_VIEW_NAME = 'AccountView';
export const IMPRINT_VIEW_NAME = 'ImprintView';
export const FIRST_TRAINING_PRESENTATION_VIEW_NAME = 'FirstTrainingPresentationView';
export const START_PAGE_VIEW_NAME = 'StartPageView';
export const PAYMENT_SUCCESS_VIEW_NAME = 'PaymentSuccessView';
export const PROGRAM_DETAIL_VIEW_NAME = 'ProgramDetailView';
export const PRODUCT_OVERVIEW_PUBLIC_VIEW_NAME = 'ProductOverviewPublicView';
export const TYLER_SEGIUN_LANDINGPAGE_VIEW = 'TylerSeguinLandingPageView';
export const USER_INVOICES_VIEW = 'UserInvoicesView';
export const APP_DOWNLOAD_VIEW = 'AppDownloadView';
export const COHORT_TRAINING_LANDINGPAGE_VIEW = 'CohortTrainingView';
export const CALEB_TRAINING_LANDINGPAGE_VIEW = 'CalebTrainingView';
export const ADAM_TRAINING_LANDINGPAGE_VIEW = 'AdamTrainingView';
export const MARLENE_TRAINING_LANDINGPAGE_VIEW = 'MarleneTrainingView';
export const COURSE_OVERVIEW_PAGE_VIEW = 'CourseOverviewPage';
export const MASTER_CLASS_PAYWALL_VIEW = 'PaywallView';
export const SIGNUP_MASTER_CLASS_PLAN_CREATION_VIEW_NAME = 'SignUpMasterClassPlanCreationView';
export const SIGNUP_VIEW_NAME = 'SignUpView';
export const MASTER_CLASS_WORKOUT_VIEW_NAME = 'MasterClassWorkoutView';
export const MASTER_CLASS_WORKOUT_VIDEO_MODAL_VIEW_NAME = 'MasterClassWorkoutVideoModalView';

/*
 * Colors - deprecated
 */
export const {
  BACKGROUND_COLOR,
  BLACK_COLOR,
  BLACK_COLOR_SEMI_TRANS,
  DARK_GREY_COLOR,
  ERROR_COLOR,
  GREY_01_COLOR,
  GREY_02_COLOR,
  GREY_03_COLOR,
  LIGHT_GREY_02,
  MAIN_ORANGE_DARK_COLOR,
  MAIN_ORANGE_LIGHT_COLOR,
  PAPER_WHITE_COLOR,
  PRIMARY_COLOR,
  WHITE_COLOR,
  FACEBOOK_COLOR,
  MESSENGER_COLOR,
  GREY_04_COLOR,
  GREY_05_COLOR
} = themeSkillYoga.colors;

// sizes
export const { GUTTER_SIZE, DEFAULT_OPACITY } = themeSkillYoga.constants;
export const SPACING_BASE = 5;
export const SPACING_NEXT_ELEMENT = 120;
export const SPACING_NEXT_ELEMENT_MOBILE = 80;
export const PAGE_PADDING_LEFT_RIGHT = 139;
export const PAGE_CONTENT_WIDTH = 1400;
export const PAGE_CONTENT_WIDTH_SMALL = PAGE_CONTENT_WIDTH - (PAGE_PADDING_LEFT_RIGHT * 2);
export const PAGE_PADDING_TOP_MOBILE = 25;
export const PAGE_PADDING_TOP_DESKTOP = 95;
export const OFFSET_MARGIN_SLIDES = 15;
export const PADDING_SLIDER_MOBILE = 80;

// lengths
export const PASSWORD_MIN_LEN = 6;
export const MAX_TRAINING_DAYS = 5;
export const MIN_TRAINING_DAYS = 2;
export const MONTH_LENGTH_IN_DAYS = 30;

// timings
export const TRANSITION_DEFAULT_LEN = 0.25;

// CSS Classes
export const CSS_ACTIVE_CLASS = 'sy-is-active';

// CSS breakpoints
// Small devices (landscape phones, 576px and up)
export const CSS_BREAKPOINTS = {
  xxs: 320,
  xs: 576,
  sm: 768,
  md: 992,
  lg: 1200,
  maxlg: 1600,
};

export const CSS_BREAKPOINTS_MIN = {
  xxs: `(min-width: ${CSS_BREAKPOINTS.xxs}px)`,
  xs: `(min-width: ${CSS_BREAKPOINTS.xs}px)`,
  sm: `(min-width: ${CSS_BREAKPOINTS.sm}px)`,
  md: `(min-width: ${CSS_BREAKPOINTS.md}px)`,
  lg: `(min-width: ${CSS_BREAKPOINTS.lg}px)`,
  maxlg: `(min-width: ${CSS_BREAKPOINTS.maxlg}px)`,
};

export const REACT_MODAL_STYLE = {
  overlay: {
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundColor: `${hexToRgba(BLACK_COLOR, 0.9)}`,
    zIndex: 100,
  },
};

export const EXPERIENCE_LEVELS: $ReadOnlyArray<Experience> = [
  {
    id: 1,
    key: 'BEGINNER',
    label: 'Beginner',
  },
  {
    id: 2,
    key: 'INTERMEDIATE',
    label: 'Intermediate',
  },
  {
    id: 3,
    key: 'ADVANCED',
    label: 'Advanced',
  },
];

export const GOALS: $ReadOnlyArray<Goal> = [
  {
    id: 1,
    key: 'FUNCTIONAL_STRENGTH',
    label: 'Functional Strength',
    strengthPercentage: 100,
    mobilityPercentage: 60,
    mindfulnessPercentage: 20,
    description: 'If you want to get stronger and put on some muscle, this program is for you. A smart combination of dynamic movements, static holds and pose progressions will take your strength to the next level.',
  },
  {
    id: 2,
    key: 'MOBILITY',
    label: 'Athletic Mobility',
    strengthPercentage: 40,
    mobilityPercentage: 100,
    mindfulnessPercentage: 90,
    description: 'Combining flexibility and strength work, this program is designed to increase your active range of motion, eliminate deficiencies in your body and make your body work like a well-oiled machine.',
  },
  {
    id: 3,
    key: 'SKILLS_TECHNIQUE',
    label: 'Skills & Techniques',
    strengthPercentage: 50,
    mobilityPercentage: 60,
    mindfulnessPercentage: 60,
    description: 'This program will teach you the most highly-coveted arm balances in Yoga! Each workout teaches you a step-by-step movement progression to learn the technique and skills required for advanced yoga poses.',
  },
];

export const GENDERS: $ReadOnlyArray<Gender> = [
  {
    id: 1,
    key: 'MALE',
    label: 'Male',
  },
  {
    id: 3,
    key: 'OTHER',
    label: 'Other',
  },
  {
    id: 2,
    key: 'FEMALE',
    label: 'Female',
  },
];

export const WEEKDAYS: $ReadOnlyArray<Weekday> = [
  {
    id: 1,
    key: 'MONDAY',
    label: 'Monday',
    shortLabel: 'Mo',
    letter: 'M',
  },
  {
    id: 2,
    key: 'TUESDAY',
    label: 'Tuesday',
    shortLabel: 'Tu',
    letter: 'T',
  },
  {
    id: 3,
    key: 'WEDNESDAY',
    label: 'Wednesday',
    shortLabel: 'We',
    letter: 'W',
  },
  {
    id: 4,
    key: 'THURSDAY',
    label: 'Thursday',
    shortLabel: 'Th',
    letter: 'T',
  },
  {
    id: 5,
    key: 'FRIDAY',
    label: 'Friday',
    shortLabel: 'Fr',
    letter: 'F',
  },
  {
    id: 6,
    key: 'SATURDAY',
    label: 'Saturday',
    shortLabel: 'Sa',
    letter: 'S',
  },
  {
    id: 7,
    key: 'SUNDAY',
    label: 'Sunday',
    shortLabel: 'Su',
    letter: 'S',
  },
];

// 3 = After waking up
// 4 = After eating breakfast
// 5 = After brushing teeth
// 6 = After drinking coffee
// 7 = After getting home
// 8 = After eating dinner
// 9 = After brushing teeth
// 10 = After taking a shower
export const TRAINING_HABITS: $ReadOnlyArray<TrainingHabit> = [
  {
    id: 3,
    parentId: 1,
    label: 'After waking up',
  },
  {
    id: 4,
    parentId: 1,
    label: 'After eating breakfast',
  },
  {
    id: 5,
    parentId: 1,
    label: 'After brushing teeth',
  },
  {
    id: 6,
    parentId: 1,
    label: 'After drinking coffee',
  },
  {
    id: 7,
    parentId: 2,
    label: 'After getting home',
  },
  {
    id: 8,
    parentId: 2,
    label: 'Before eating dinner',
  },
  {
    id: 9,
    parentId: 2,
    label: 'After brushing teeth',
  },
  {
    id: 10,
    parentId: 2,
    label: 'After taking a shower',
  },
];

export const MENU_LINKS_LOGGED_IN: $ReadOnlyArray<MenuLink> = [
  {
    id: 1,
    name: 'Subscribe',
    destination: urls.PAYMENT,
    hideWhenPremiumUser: true,
  },
  {
    id: 2,
    name: 'Training',
    destination: urls.TRAINING,
  },
  {
    id: 3,
    name: 'Library',
    destination: urls.LIBRARY,
  },
  {
    id: 5,
    name: 'Master Courses',
    destination: urls.MASTER_COURSE_FREE_LIBRARY,
    isNew: true,
  },
  {
    id: 7,
    name: 'Shop',
    destination: urls.SHOP,
    external: true,
  },
  {
    id: 4,
    name: 'Account',
    destination: urls.ACCOUNT,
  },
  {
    id: 6,
    name: 'Logout',
    destination: urls.SIGNOUT,
  },
];

export const MENU_LINKS_LOGGED_OUT: $ReadOnlyArray<MenuLink> = [
  {
    id: 1,
    name: 'Home',
    destination: urls.ROOT,
  },
  {
    id: 4,
    name: 'Master Courses',
    destination: urls.MASTER_COURSE_FREE_LIBRARY,
    isNew: false,
  },
  {
    id: 3,
    name: 'Blog',
    destination: externalUrls.get('blog'),
    external: true,
  },
  {
    id: 6,
    name: 'Shop',
    destination: urls.SHOP,
    external: true,
  },
  {
    id: 2,
    name: 'Log in',
    destination: urls.SIGNIN,
  },
  {
    id: 5,
    name: 'Start now',
    destination: urls.SIGNIN,
    block: true,
  },
];

export const SPORTS: $ReadOnlyArray<Sport> = [
  {
    id: 1,
    key: 'RUNNING',
    label: 'Running',
  },
  {
    id: 2,
    key: 'GYM',
    label: 'Gym',
  },
  {
    id: 3,
    key: 'FOOTBALL',
    label: 'Football',
  },
  {
    id: 4,
    key: 'BASEBALL',
    label: 'Baseball',
  },
  {
    id: 5,
    key: 'CLIMBING',
    label: 'Climbing',
  },
  {
    id: 6,
    key: 'TENNIS',
    label: 'Tennis',
  },
  {
    id: 7,
    key: 'SOCCER',
    label: 'Soccer',
  },
  {
    id: 8,
    key: 'MARTIAL ARTS',
    label: 'Martial arts',
  },
  {
    id: 9,
    key: 'CROSSFIT',
    label: 'Crossfit',
  },
  {
    id: 10,
    key: 'BASKETBALL',
    label: 'Basketball',
  },
  {
    id: 11,
    key: 'SURFING',
    label: 'Surfing',
  },
  {
    id: 12,
    key: 'NONE',
    label: 'Don\'t have one',
  },
  {
    id: 13,
    key: 'OTHER',
    label: 'Other',
  },
];

export const MENU_LINKS_ONBOARDING_LOGGED_IN: $ReadOnlyArray<MenuLink> = [
  {
    id: 1,
    name: 'Logout',
    destination: urls.SIGNOUT,
  },
];

export const MENU_LINKS_ONBOARDING_LOGGED_OUT: $ReadOnlyArray<MenuLink> = [
  {
    id: 1,
    name: 'Login',
    destination: urls.SIGNIN,
  },
];

export const INFLUENCER_VOUCHER_CODES = {
  alpham: 'alpham25off',
};

// see http://www.rfc-editor.org/rfc/bcp/bcp47.txt
export const SUPPORTED_LANGUAGE_TAGS: $ReadOnlyArray<string> = [
  DEFAULT_LANGUAGE_TAG, 'de-DE', 'en-GB',
];

export const WORKOUT_FILTER_TAG_IDS: $ReadOnlyArray<number> = [
  1, // Beginner
  2, // Strength
  3, // Mobility
  4, // Mindfulness
  5, // Skills
  6, // Singles
];

export const TAG_ICONS_MAP: any = new Map([
  [1, beginnerSvg],
  [2, strengthSvg],
  [3, mobilitySvg],
  [4, mindfulnessSvg],
  [5, skillsSvg],
  [6, singlesSvg],
  [7, knowledgeSvg],
]);

export const SOCIAL_LOGIN_TYPES: $ReadOnlyArray<SocialLoginType> = [
  'APPLE_SIGN_IN',
];

export const debugSettings = Object.freeze({
  ENABLE_REDUX_LOGGER: false,
  ENABLE_TRACKING_LOGGER: false,
});

export const cssSettings = Object.freeze({
  ABOVE_FOLD_MOBILE_HEIGHT_TRAINING_HOME: 'calc(70vh - 60px)',
  ABOVE_FOLD_MOBILE_HEIGHT_LANDING_PAGE: 'calc(85vh - 10px)',
  ABOVE_FOLD_MOBILE_HEIGHT: 'calc(100vh - 180px)',
  ABOVE_FOLD_DESKTOP_HEIGHT: 'calc(100vh - 65px)',
});

export const INTENSITIES: $ReadOnlyArray<Intensity> = [
  {
    id: 1,
    key: 'TOO EASY',
    label: 'Too Easy',
    apiFeedbackId: 3,
  },
  {
    id: 2,
    key: 'EASY',
    label: 'Easy',
    apiFeedbackId: 9,
  },
  {
    id: 3,
    key: 'PERFECT',
    label: 'Perfect',
    apiFeedbackId: 4,
  },
  {
    id: 4,
    key: 'HARD',
    label: 'Hard',
    apiFeedbackId: 10,
  },
  {
    id: 5,
    key: 'TOO HARD',
    label: 'Too Hard',
    apiFeedbackId: 5,
  },
];

export const ENJOYMENTS: $ReadOnlyArray<Enjoyment> = [
  {
    id: 1,
    key: 'NOT AT ALL',
    label: 'Not at all',
    apiFeedbackId: 6,
  },
  {
    id: 2,
    key: 'NOT REALLY',
    label: 'Not really',
    apiFeedbackId: 11,
  },
  {
    id: 3,
    key: 'IT WAS OK',
    label: 'It was OK',
    apiFeedbackId: 7,
  },
  {
    id: 4,
    key: 'IT WAS GOOD',
    label: 'It was good',
    apiFeedbackId: 12,
  },
  {
    id: 5,
    key: 'LOVED IT',
    label: 'Loved it',
    apiFeedbackId: 8,
  },
];
