// @flow
import envUtils from '../utils/env.utils';

class StripeService {
  stripeInstance: Object = null;

  constructor() {
    this.initStripe(() => {
      this.stripeInstance = window.Stripe(StripeService.getStripeKey());
    });
  }

  checkoutWithSessionId(sessionId: string) {
    return this.stripeInstance.redirectToCheckout({ sessionId });
  }

  initStripe(loadedCallback: Function) {
    if (!window.Stripe) {
      const script = document.createElement('script');

      script.onload = function onload() {
        loadedCallback();
      };

      script.src = 'https://js.stripe.com/v3';

      // $FlowFixMe
      document.body.appendChild(script);
    } else {
      loadedCallback();
    }
  }

  static getStripeKey() {
    if (envUtils.isLocalOrTestEnvironment()) {
      return 'pk_test_vKlnwHCecZAYIKSddOxyR3PQ';
    }
    return 'pk_live_DiQToynmvsxhuaTzJQKrXRmP';
  }

  getStripeSubcriptionIdKey() {
    if (envUtils.isLocalOrTestEnvironment()) {
      return 'data.dev_stripe_subscription_price_id';
    }
    return 'data.stripe_subscription_price_id';
  }

  getStripeProductIdKey() {
    if (envUtils.isLocalOrTestEnvironment()) {
      return 'data.dev_stripe_product_price_id';
    }
    return 'data.stripe_product_price_id';
  }
}

// eslint-disable-next-line import/prefer-default-export
export const stripeService = new StripeService();
