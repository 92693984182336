// @flow
/* eslint max-len: 0 */
import React from 'react';
import {
  BrowserRouter, Route as PublicRoute, Switch,
} from 'react-router-dom';
import { connect } from 'react-redux';
import queryString from 'query-string';
import _get from 'lodash/get';
import Cookies from 'js-cookie';
import Helmet from 'react-helmet';
import { SnackbarProvider, wrapComponent as snackBarWrapper } from 'react-snackbar-alert';
import loadable from '@loadable/component';
import withStyles, { ThemeProvider, JssProvider } from 'react-jss';

import {
  Imprint, PageNotFound,
  PrivacyPolicy, TermsOfUse,
} from './pages';

import CookieBanner from './components/banners/cookie/CookieBanner';
import urls from './config/urls';
import PrivateRoute from './PrivateRoute';
import GlobalStyle from './components/GlobalStyle';
import TopMenu from './components/TopMenu';
import SYFooter from './components/SYFooter';
import SidebarMenu from './components/SidebarMenu';
import withUserDataLoader from './hoc/withUserDataLoader';
import SYNotification from './components/SYNotification';
import { CSS_BREAKPOINTS, DEFAULT_LANGUAGE_TAG, SUPPORTED_LANGUAGE_TAGS } from './config/constants';
import MarketingBanner from './components/MarketingBanner';
import { marketingCampaignService } from './services/MarketingBannerService';
import queryParamList from './config/query-parameter-keys';
import { LOCALSTORAGE_KEYS } from './localStorageKeys';
import { currencyConverter } from './modules/CurrencyConverter';
import { onboardingSteps } from './modules/OnboardingSteps';
import type { OnboardingOrderStep } from './types/OnboardingOrderStep';
import LoadingAnimation from './components/LoadingAnimation';
import { themeSkillYoga } from './styles/themeSkillYoga';
import EnvironmentUtils from './utils/env.utils';
import globalStyles from './styles/globalStyles';
import CohortTrainingView from './views/LoggedOut/LandingPageView/CohortTrainingView';
import CalebTrainingView from './views/LoggedOut/LandingPageView/CalebTraingView';
import CourseOverviewPage from './views/LoggedOut/CourseOverviewPage/CourseOverviewPage';
import AdamTrainingView from './views/LoggedOut/LandingPageView/AdamTrainingView';
import MarleneTrainingView from './views/LoggedOut/LandingPageView/MarleneTrainingView';

const LoadablePaymentFailedView = loadable(() => import('./views/LoggedIn/PaymentView/PaymentFailedView'), { fallback: <LoadingAnimation /> });
const LoadablePaymentSuccess = loadable(() => import('./views/LoggedIn/PaymentView/PaymentSuccessView'), { fallback: <LoadingAnimation /> });
const LoadableLibraryView = loadable(() => import('./views/LoggedIn/LibraryView/LibraryView'), { fallback: <LoadingAnimation /> });
const LoadableFirstTrainingPresentationView = loadable(() => import('./views/LoggedIn/FirstTrainingPresentationView/FirstTrainingPresentationView'), { fallback: <LoadingAnimation /> });
const LoadableVoucherView = loadable(() => import('./views/LoggedIn/VoucherView/VoucherView'), { fallback: <LoadingAnimation /> });
const LoadableLogInView = loadable(() => import('./views/LoggedOut/LogInView/LogInView'), { fallback: <LoadingAnimation /> });
const LoadableLogOutView = loadable(() => import('./views/LoggedIn/LogOutView/LogOutView'), { fallback: <LoadingAnimation /> });
const LoadableDashboardView = loadable(() => import('./views/LoggedIn/DashboardView/DashboardView'), { fallback: <LoadingAnimation /> });
const LoadableInviteFriendsView = loadable(() => import('./views/LoggedIn/InviteFriendsView/InviteFriendsView'), { fallback: <LoadingAnimation /> });
const LoadableReferralView = loadable(() => import('./views/LoggedOut/ReferralView/ReferralView'), { fallback: <LoadingAnimation /> });
const LoadableWorkoutView = loadable(() => import('./views/LoggedIn/WorkoutView/WorkoutView'), { fallback: <LoadingAnimation /> });
const LoadableAccountView = loadable(() => import('./views/LoggedIn/AccountView/AccountView'), { fallback: <LoadingAnimation /> });
const LoadablePaymentView = loadable(() => import('./views/LoggedIn/PaymentView/PaymentView'), { fallback: <LoadingAnimation /> });
const LoadableTrainingHomeMasterClassView = loadable(() => import('./views/LoggedIn/TrainingHomeMasterClassView/TrainingHomeMasterClassView'), { fallback: <LoadingAnimation /> });
const LoadableAlphaMLandingPageView = loadable(() => import('./views/LoggedOut/LandingPageView/AlphaMLandingPageView'), { fallback: <LoadingAnimation /> });
const LoadableTylerSeguinLandingPageView = loadable(() => import('./views/LoggedOut/LandingPageView/TylerSeguinLandingPageView'), { fallback: <LoadingAnimation /> });
const LoadableProgramsOverviewView = loadable(() => import('./views/LoggedIn/ProgramsOverviewView/ProgramsOverviewView'), { fallback: <LoadingAnimation /> });
const LoadableForgotPasswordView = loadable(() => import('./views/LoggedOut/ForgotPasswordView/ForgotPasswordView'), { fallback: <LoadingAnimation /> });
const LoadableChangePasswordView = loadable(() => import('./views/LoggedOut/ChangePasswordView/ChangePasswordView'), { fallback: <LoadingAnimation /> });
const LoadableProgramDetailView = loadable(() => import('./views/LoggedIn/ProgramDetailView/ProgramDetailView'), { fallback: <LoadingAnimation /> });
const LoadableProductOverviewPublicView = loadable(() => import('./views/LoggedOut/LandingPageView/ProductOverviewPublicView'), { fallback: <LoadingAnimation /> });
const LoadableConfirmMessagePurchaseView = loadable(() => import('./views/LoggedIn/ConfirmMessageView/ConfirmMessagePurchaseView'), { fallback: <LoadingAnimation /> });
const LoadableConfirmMessageSubscriptionView = loadable(() => import('./views/LoggedIn/ConfirmMessageView/ConfirmMessageSubscriptionView'), { fallback: <LoadingAnimation /> });
const LoadableUserInvoicesView = loadable(() => import('./views/LoggedIn/UserInvoicesView/UserInvoicesView'));
const LoadableAppDownloadView = loadable(() => import('./views/LoggedOut/AppDownloadView/AppDownloadView'));
const LoadableHomeViewView = loadable(() => import('./views/LoggedOut/StartPageView/HomeView'));
const LoadableLandingPage = loadable(() => import('./views/LoggedOut/LandingPageView/LandingPageView'), { fallback: <LoadingAnimation /> });
const SignUpPage = loadable(() => import('./views/LoggedOut/SignUpView/SignUpView'));
const FirstTimeTrainingModal = loadable(() => import('./views/LoggedIn/FirstTimeTrainingModal/FirstTimeTrainingModal'));
const ProgressModal = loadable(() => import('./views/LoggedIn/ProgressModal/ProgressModal'), { fallback: <LoadingAnimation /> });
const MasterClassWorkoutDetail = loadable(() => import('./views/LoggedIn/MasterClassWorkoutDetail/MasterClassWorkoutDetail'), { fallback: <LoadingAnimation /> });
const PayWallView = loadable(() => import('./views/LoggedIn/PayWallView/PayWallView'), { fallback: <LoadingAnimation /> });
const SignUpMasterClassPlanCreationView = loadable(() => import('./views/LoggedOut/SignUpView/SignUpMasterClassPlanCreationView'), { fallback: <LoadingAnimation /> });
const SwitchModalView = loadable(() => import('./views/LoggedIn/SwitchModalView/SwitchModalView'), { fallback: <LoadingAnimation /> });
const CourseCompletionView = loadable(() => import('./views/LoggedIn/CourseCompletionView/CourseCompletionView'), { fallback: <LoadingAnimation /> });
const RecommendationView = loadable(() => import('./views/LoggedIn/RecommendationView/RecommendationView'), { fallback: <LoadingAnimation /> });
const CourseDetailFTUE = loadable(() => import('./views/LoggedIn/CourseDetailFTUE/CourseDetailFTUE'), { fallback: <LoadingAnimation /> });
const MasterCourseFreeLibrary = loadable(() => import('./views/LoggedIn/MasterCourseFreeLibrary/MasterCourseFreeLibrary'), { fallback: <LoadingAnimation /> });
const PaymentConfirmation = loadable(() => import('./views/LoggedIn/PaymentConfirmation/PaymentConfirmation'), { fallback: <LoadingAnimation /> });

/**
 * We need to wrap the components first,
 * otherwise there's unnecessary state reloads
 */
const AccountViewEnriched = withUserDataLoader(LoadableAccountView);
const PaymentSuccessEnriched = withUserDataLoader(LoadablePaymentSuccess);
const PaymentFailedViewEnriched = withUserDataLoader(LoadablePaymentFailedView);
const PaymentViewEnriched = withUserDataLoader(LoadablePaymentView);
const DashboardViewEnriched = withUserDataLoader(LoadableDashboardView);
const InviteFriendsViewEnriched = withUserDataLoader(snackBarWrapper(LoadableInviteFriendsView));
const FirstTrainingPresentationViewEnriched = withUserDataLoader(LoadableFirstTrainingPresentationView);
const LibraryViewEnriched = withUserDataLoader(LoadableLibraryView);
const VoucherViewEnriched = withUserDataLoader(LoadableVoucherView);
const WorkoutViewEnriched = withUserDataLoader(snackBarWrapper(LoadableWorkoutView));
const ProgramsOverviewViewEnriched = withUserDataLoader(LoadableProgramsOverviewView);
const ProgramDetailViewEnriched = withUserDataLoader(LoadableProgramDetailView);
const AlphaMLandingPageViewEnriched = withUserDataLoader(LoadableAlphaMLandingPageView);
const TylerSeguinLandingPageViewEnriched = withUserDataLoader(LoadableTylerSeguinLandingPageView);
const ProductOverviewPublicViewEnriched = withUserDataLoader(LoadableProductOverviewPublicView);
const LoadableUserInvoicesViewEnriched = withUserDataLoader(LoadableUserInvoicesView);
const HomeViewEnriched = withUserDataLoader(LoadableHomeViewView);
const LoadableLandingPageEnriched = withUserDataLoader(LoadableLandingPage);
const SignUpPageEnriched = withUserDataLoader(SignUpPage);
const PayWallViewEnriched = withUserDataLoader(PayWallView);
const FirstTimeTrainingModalEnriched = withUserDataLoader(FirstTimeTrainingModal);
const ProgressModalEnriched = withUserDataLoader(ProgressModal);
const MasterClassWorkoutDetailEnriched = withUserDataLoader(snackBarWrapper(MasterClassWorkoutDetail));
const LoadableConfirmMessagePurchaseEnriched = withUserDataLoader(LoadableConfirmMessagePurchaseView);
const LoadableConfirmMessageSubscriptionEnriched = withUserDataLoader(LoadableConfirmMessageSubscriptionView);
const LoadableTrainingHomeMasterClassEnriched = withUserDataLoader(LoadableTrainingHomeMasterClassView);
const LoadableSignUpMasterClassPlanCreationViewEnriched = withUserDataLoader(SignUpMasterClassPlanCreationView);
const SwitchModalViewEnriched = withUserDataLoader(SwitchModalView);
const CourseCompletionViewEnriched = withUserDataLoader(CourseCompletionView);
const RecommendationViewEnriched = withUserDataLoader(RecommendationView);
const CourseDetailFTUEEnriched = withUserDataLoader(CourseDetailFTUE);
const MasterCourseFreeLibraryEnriched = withUserDataLoader(MasterCourseFreeLibrary);
const PaymentConfirmationEnriched = withUserDataLoader(PaymentConfirmation);

class ApplicationRouting extends React.Component<{
  page: Object,
  isLoggedIn: boolean,
}, {
  isSideBarOpen: boolean,
  windowWidth: number,
}> {
  state = {
    isSideBarOpen: false,
    windowWidth: window.innerWidth,
  };

  componentDidMount() {
    const {
      location: {
        search,
      },
    } = window;
    const searchQueryParameter = queryString.parse(search);
    const explicitLanguageCode = _get(searchQueryParameter, queryParamList.LANGUAGE_SWITCH);
    const cachedLanguageCode = Cookies.get(LOCALSTORAGE_KEYS.languageCode);

    if (!cachedLanguageCode) {
      Cookies.set(LOCALSTORAGE_KEYS.languageCode, DEFAULT_LANGUAGE_TAG);
    }

    if (explicitLanguageCode && SUPPORTED_LANGUAGE_TAGS.indexOf(explicitLanguageCode) > -1) {
      Cookies.set(LOCALSTORAGE_KEYS.languageCode, explicitLanguageCode);
    }

    currencyConverter.init(Cookies.get(LOCALSTORAGE_KEYS.languageCode));

    window.scrollTo(0, 0);
  }

  onSidebarStateChange = ({ isOpen }: any) => {
    this.setState({ isSideBarOpen: isOpen });
  };

  onOpenCloseClick = () => {
    const { isSideBarOpen } = this.state;
    this.setState({ isSideBarOpen: !isSideBarOpen });
  };

  render() {
    const { page } = this.props;
    const { isSideBarOpen, windowWidth } = this.state;

    return (
      <BrowserRouter>
        <JssProvider id={{ minify: EnvironmentUtils.isProduction() }}>
          <ThemeProvider theme={themeSkillYoga}>
            <SnackbarProvider position={windowWidth >= CSS_BREAKPOINTS.sm ? 'top' : 'bottom'} progressBar={false} component={SYNotification} timeout={3000}>
              <Helmet
                bodyAttributes={{
                  class: `${page.location} ${isSideBarOpen ? 'sidebar--open' : ''}`,
                }}
              />
              <GlobalStyle />
              <MarketingBanner campaign={marketingCampaignService.active} />
              <TopMenu isSidebarOpen={isSideBarOpen} onOpenCloseClick={this.onOpenCloseClick} />
              <SidebarMenu
                right
                isOpen={isSideBarOpen}
                onStateChange={this.onSidebarStateChange}
              />

              <main page={page.location}>
                <Switch>
                  <PrivateRoute path={urls.ACCOUNT} component={AccountViewEnriched} />
                  <PrivateRoute path={urls.PAYMENT_SUCCESS} component={PaymentSuccessEnriched} />
                  <PrivateRoute path={urls.PAYMENT_FAILED} component={PaymentFailedViewEnriched} />
                  <PrivateRoute path={urls.PAYMENT} component={PaymentViewEnriched} />
                  <PrivateRoute path={urls.PREMIUM} component={PaymentViewEnriched} />
                  <PrivateRoute path={urls.GET_SKILLED} component={PaymentViewEnriched} />
                  <PrivateRoute path={urls.MARKETING_VIEW} component={PaymentViewEnriched} />
                  <PrivateRoute path={urls.TRAINING} component={DashboardViewEnriched} />
                  <PrivateRoute path={urls.REFERRAL} component={InviteFriendsViewEnriched} />
                  <PrivateRoute path={urls.JOURNEY} component={FirstTrainingPresentationViewEnriched} />
                  <PrivateRoute path={urls.LIBRARY} component={LibraryViewEnriched} />
                  <PrivateRoute path={urls.VOUCHER_PAGE} component={VoucherViewEnriched} />
                  <PrivateRoute path={`${urls.WORKOUT_DETAIL}/:id`} component={WorkoutViewEnriched} />
                  <PrivateRoute path={`${urls.PROGRAM_DETAIL}/:id`} component={ProgramDetailViewEnriched} />
                  <PrivateRoute path={urls.PROGRAMS_OVERVIEW} component={ProgramsOverviewViewEnriched} />
                  <PrivateRoute path={urls.USER_INVOICES_VIEW} component={LoadableUserInvoicesViewEnriched} />
                  <PrivateRoute path={urls.TRAINING_HOME} component={LoadableTrainingHomeMasterClassEnriched} />
                  <PrivateRoute path={urls.FIRST_TIME_TRAINING_MODAL} component={FirstTimeTrainingModalEnriched} />
                  <PrivateRoute path={urls.PROGRESS_MODAL} component={ProgressModalEnriched} />
                  <PrivateRoute path={urls.SWITCH_MODAL} component={SwitchModalViewEnriched} />
                  <PrivateRoute path={urls.COURSE_COMPLETION} component={CourseCompletionViewEnriched} />
                  <PrivateRoute path={urls.RECOMMENDATION} component={RecommendationViewEnriched} />
                  <PrivateRoute exact={false} path={urls.PAYMENT_CONFIRMATION} component={PaymentConfirmationEnriched} />
                  <PrivateRoute exact={false} path={urls.COURSE_DETAIL_FTUE} component={CourseDetailFTUEEnriched} />
                  <PrivateRoute exact={false} path={urls.CONFIRM_MESSAGE_PURCHASE} component={LoadableConfirmMessagePurchaseEnriched} />
                  <PrivateRoute exact={false} path={urls.CONFIRM_MESSAGE_SUBSCRIPTION} component={LoadableConfirmMessageSubscriptionEnriched} />
                  <PrivateRoute exact={false} path={urls.MASTER_CLASS_WORKOUT_DETAIL} component={MasterClassWorkoutDetailEnriched} />
                  <PrivateRoute exact={false} path={urls.PAYWALL} component={PayWallViewEnriched} />

                  <PublicRoute exact path={urls.ROOT} component={HomeViewEnriched} />
                  <PublicRoute exact path={urls.SIGNOUT} component={LoadableLogOutView} />
                  <PublicRoute exact path={`${urls.REFERRAL_EXTERN}/:rid`} component={LoadableReferralView} />
                  <PublicRoute exact path={urls.SIGNIN} component={LoadableLogInView} />
                  <PublicRoute exact path={urls.IMPRINT} component={Imprint} />
                  <PublicRoute exact path={urls.PRIVACY_POLICY} component={PrivacyPolicy} />
                  <PublicRoute exact path={urls.TERMS} component={TermsOfUse} />
                  <PublicRoute exact path={urls.COHORT_TRAINING_LANDINGPAGE} component={CohortTrainingView} />
                  <PublicRoute exact path={urls.CALEB_TRAINING_LANDINGPAGE} component={CalebTrainingView} />
                  <PublicRoute exact path={urls.ADAM_TRAINING_LANDINGPAGE} component={AdamTrainingView} />
                  <PublicRoute exact path={urls.MARLENE_TRAINING_LANDINGPAGE} component={MarleneTrainingView} />
                  <PublicRoute exact path={urls.MASTER_COURSE_FREE_LIBRARY} component={MasterCourseFreeLibraryEnriched} />

                  <PublicRoute
                    exact
                    path={urls.SIGNUP}
                    render={(routeProps: Object) => {
                      const firstOnboardingStep = onboardingSteps.getFirstStep();

                      return (
                        <firstOnboardingStep.component
                          {...routeProps}
                          step={firstOnboardingStep.id}
                          totalSteps={onboardingSteps.count}
                          nextStep={onboardingSteps.getNextStep(firstOnboardingStep.id)}
                          firstStep={firstOnboardingStep}
                        />
                      );
                    }}
                  />

                  {onboardingSteps.order.map((step: OnboardingOrderStep) => (
                    <PublicRoute
                      exact
                      path={step.url}
                      render={(routeProps: Object) => (
                        <step.component
                          {...routeProps}
                          step={step.id}
                          totalSteps={onboardingSteps.count}
                          nextStep={onboardingSteps.getNextStep(step.id)}
                          firstStep={onboardingSteps.getFirstStep()}
                        />
                      )}
                      key={`onboarding-route-${step.id}`}
                    />
                  ))}

                  <PublicRoute exact path={urls.PASSWORD_FORGOT} component={LoadableForgotPasswordView} />
                  <PublicRoute exact path={urls.PASSWORD_CHANGE} component={LoadableChangePasswordView} />

                  {/* Landing pages */}
                  <PublicRoute exact path="/alpham" component={AlphaMLandingPageViewEnriched} />
                  <PublicRoute exact path="/tylerseguin" component={TylerSeguinLandingPageViewEnriched} />
                  <PublicRoute exact path={urls.PRODUCT_OVERVIEW_PUBLIC} component={ProductOverviewPublicViewEnriched} />
                  <PublicRoute exact path="/athlete-assessment" component={LoadableAppDownloadView} />
                  <PublicRoute exact path={urls.APP_DOWNLOAD_VIEW} component={LoadableAppDownloadView} />
                  <PublicRoute exact path={urls.CREATE_PLAN} component={LoadableSignUpMasterClassPlanCreationViewEnriched} />
                  <PublicRoute path={urls.SIGNUP_PAGE} component={SignUpPageEnriched} />
                  <PublicRoute exact={false} path={urls.LANDING_PAGE} component={LoadableLandingPageEnriched} />

                  {/* 404 MUST BE LAST */}
                  <PublicRoute component={PageNotFound} />
                </Switch>
              </main>

              <CookieBanner />
              <SYFooter />
            </SnackbarProvider>
          </ThemeProvider>
        </JssProvider>
      </BrowserRouter>
    );
  }
}

const mapStateToProps = ({ page, auth }) => ({
  page,
  user: auth.user,
  isLoggedIn: auth.isLoggedIn,
});

export default withStyles(globalStyles)(connect(mapStateToProps, null)(ApplicationRouting));
