import Rollbar from 'rollbar';
import envUtils from '../utils/env.utils';

const environment = envUtils.isProduction() ? 'production' : 'test';

const rollbarConfig = {
  enabled: true, // we can set to true to work with errors locally as well (and pipe the errors to development env in rollbar)
  environment,
  accessToken: '95bdeca294d44095a80794be12fb4cad',
  captureUncaught: true, // we might need to set to false due to quota later
  captureUnhandledRejections: true, // we might need to set to false due to quota later
};

const rollbar = new Rollbar(rollbarConfig);

export default rollbar;
