import Slider from 'react-slick';
import styled from 'styled-components';
import ThunderSvg from '../assets/svg/thunder.svg';
import { CSS_BREAKPOINTS, CSS_BREAKPOINTS_MIN, GUTTER_SIZE } from '../config/constants';
import { TitleElement } from './text/CohortLp/CohortLpTextElements';

const Container = styled.section`
  padding: ${GUTTER_SIZE * 2}px 0 ${GUTTER_SIZE * 4}px;

  @media ${CSS_BREAKPOINTS_MIN.sm} {
    margin: 0 -${GUTTER_SIZE * 2}px;
    padding: ${GUTTER_SIZE * 2.5}px 0 ${GUTTER_SIZE * 3.5}px;
  }
`;

const Slide = styled.div`
  position: relative;
  padding: 0 ${GUTTER_SIZE * 2}px;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 26px;
    width: 17px;
    background: url(${ThunderSvg}) no-repeat center / cover;
  }
`;

const Headline = styled.div`
  font-family: Poppins-Medium, sans-serif;
  font-size: 15px;
  line-height: 26px;
  margin: 0 0 ${GUTTER_SIZE / 2}px;

  @media ${CSS_BREAKPOINTS_MIN.sm} {
    font-size: 16px;
    line-height: 26px;
  }
`;

const Text = styled.div`
  font-family: Poppins-Light, sans-serif;
  font-size: 15px;
  line-height: 26px;

  @media ${CSS_BREAKPOINTS_MIN.sm} {
    font-size: 16px;
    line-height: 26px;
  }
`;

/* eslint-disable max-len */
const LIST = [
  {
    id: 1, title: 'Week 1: Ground Yourself', text: 'The focus of the week will be to establish an understanding of your patterns: physical and (hopefully) mental. A lot of the time we carry beliefs we never questioned. Are you really stiff? How good is your concentration? The answer to all the above lies in how open your hips are, so get ready to wake up the Jean-Claude Van Damme sleeping inside you.',
  },
  {
    id: 2, title: 'Week 2: Elevate Yourself', text: 'Are you keen on developing skills you currently don\'t have or have a go at poses that may seem supernatural? The focus of this week is to practice asanas and breathing techniques that may challenge us or at least they may seem so initially. You will be encouraged to participate to the extent that you feel comfortable. Have you thought of the arm-balance you want to achieve in 2022?',
  },
  {
    id: 3, title: 'Week 3: Lift Yourself', text: 'Your perspective depends on your point of view. Are you ready to see the world upside down? This week\'s focus is to perform asanas and pranayama upside down. Is that normal? Are you up for it?',
  },
  {
    id: 4, title: 'Week 4: Open Yourself', text: 'If you are living in a westernized country chances are you have a westernized lifestyle and some westernized symptoms. What does this mean for the way you move and breathe? Breaking these patterns may start on the mat but will have a carryover in other aspects of life.',
  },
];
/* eslint-enable max-len */

const CohortCurriculum = () => (
  <Container>
    <TitleElement>Curriculum</TitleElement>

    <Slider
      arrows={false}
      autoplay
      autoplaySpeed={5000}
      centerMode
      // rem is not an option here, because the slider library doesn't support rem
      centerPadding="0px"
      className="center"
      dots={false}
      slidesToShow={4}
      slidesToScroll={4}
      speed={500}
      responsive={[
        {
          breakpoint: CSS_BREAKPOINTS.sm,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: true,
          },
        },
      ]}
    >
      {LIST.map(({ id, title, text }) => (
        <Slide key={`slide-cv-${id}`}>
          <Headline>{title}</Headline>
          <Text>{text}</Text>
        </Slide>
      ))}
    </Slider>
  </Container>
);

export default CohortCurriculum;
