// @flow
import styled from 'styled-components';
import { CSS_BREAKPOINTS_MIN, WHITE_COLOR } from '../config/constants';

const SYTag11pt = styled.p`
  color: ${WHITE_COLOR};
  font-size: 9px;
  line-height: 1.67;
  letter-spacing: 1.08px;
  
  @media ${CSS_BREAKPOINTS_MIN.sm} {
    font-size: 11px;
    line-height: 1.36;
    letter-spacing: 1.32px;
  }
`;

export default SYTag11pt;
