// @flow

import styled from 'styled-components';
import { createRef } from 'react';
// import _get from 'lodash/get';
import AbstractView from '../../AbstractView';
import {
  BLACK_COLOR_SEMI_TRANS,
  CLOUDFRONT_STATIC, CALEB_TRAINING_LANDINGPAGE_VIEW, CSS_BREAKPOINTS_MIN, GUTTER_SIZE,
} from '../../../config/constants';
import makeImageUrl from '../../../modules/makeImageUrl';
import { TitleElement } from '../../../components/text/CohortLp/CohortLpTextElements';
import SYPrimaryButton from '../../../components/SYPrimaryButton';
import PageElementContainer from '../../../components/PageElementContainer';
import { paymentPageElementCss } from '../../LoggedIn/PaymentView/PaymentView';
import CalebBenefits from '../../../components/Caleb/CalebBenefits';
import CalebTrainWithBest from '../../../components/Caleb/CalebTrainWithBest';
import CalebCoursePlan from '../../../components/Caleb/CalebCoursePlan';
import CalebHowItWorks from '../../../components/Caleb/CalebHowItWorks';
import CalebMeetTheCoach from '../../../components/Caleb/CalebMeetTheCoach';
import CalebPriceOverview from '../../../components/Caleb/CalebPriceOverview';
// import CalebJoinEmailList from '../../../components/Caleb/CalebJoinEmailList';
import CalebImageTextBanner from '../../../components/Caleb/CalebImageTextBanner';
import LifetimeSVG from '../../../assets/svg/lifetime.svg';
import urls from '../../../config/urls';
import { Redirect } from 'react-router-dom';

const Container = styled.div`
  max-width: none;
  margin: 0 auto;
  padding: 0;

  @media ${CSS_BREAKPOINTS_MIN.md} {
    padding: 0;
  }

  @media ${CSS_BREAKPOINTS_MIN.lg} {
    padding: 0;
  }
`;

const CTA = styled(SYPrimaryButton)`
  width: 100%;
  max-width: 300px;
`;

const BottomCTAContainer = styled.div`
  padding: ${GUTTER_SIZE * 2}px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media ${CSS_BREAKPOINTS_MIN.sm} {
    padding: ${GUTTER_SIZE * 2.5}px 0 ${GUTTER_SIZE * 3.5}px;
  }
`;

const HeroContainer = styled.div`
  min-height: 60vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: ${GUTTER_SIZE}px;
  background: url(${makeImageUrl({ url: `${CLOUDFRONT_STATIC}/img/pages/caleb-lp/caleb-bg-top-mobile.jpg` })}) no-repeat center / cover;

  @media ${CSS_BREAKPOINTS_MIN.sm} {
    background-image: url(${makeImageUrl({ url: `${CLOUDFRONT_STATIC}/img/pages/caleb-lp/caleb-bg-top-desktop.jpg` })});
  }

  @media ${CSS_BREAKPOINTS_MIN.md} {
    min-height: 80vh;
    align-items: flex-start;
    margin-bottom: ${GUTTER_SIZE * 2}px;
  }
`;

const HeroTitle = styled.h2`
  margin-bottom: ${GUTTER_SIZE * 2}px;
  font-family: 'Poppins-Medium', sans-serif;
  font-size: 28px;
  line-height: 36px;
  text-align: center;
  letter-spacing: 0.81px;

  @media ${CSS_BREAKPOINTS_MIN.md} {
    margin-bottom: ${GUTTER_SIZE * 2.5}px;
    font-size: 54px;
    line-height: 81px;
    text-align: left;
  }
`;

const HeroSubTitle = styled.h3`
  font-family: 'Poppins-Medium', sans-serif;
  font-size: 22px;
  line-height: 26px;
  text-align: center;
  width: 100%;

  @media ${CSS_BREAKPOINTS_MIN.md} {
    max-width: 580px;
    font-size: 32px;
    line-height: 48px;
    text-align: left;
  }
`;

const HeroCTAWrapper = styled.div`
  width: 100%;
`;

const HeroCTA = styled(SYPrimaryButton)`
  width: 100%;
  margin-top: ${GUTTER_SIZE * 5}px;

  @media ${CSS_BREAKPOINTS_MIN.sm} {
    width: auto;
    min-width: 320px;
  }

  @media ${CSS_BREAKPOINTS_MIN.md} {
    margin-top: ${GUTTER_SIZE * 7}px;
  }
`;

const CalebPageElement = styled.section`
  ${paymentPageElementCss}

  ${({ bg }) => (bg ? `background-color: ${BLACK_COLOR_SEMI_TRANS};` : '')}
`;

const FullWidthElement = styled.div`
  ${({ bg }) => (bg ? `background-color: ${BLACK_COLOR_SEMI_TRANS};` : '')}
`;

/*
const BannerContainer = styled.div`
  background-color: ${PRIMARY_COLOR};
  padding: 20px;
`;

const BannerText = styled.p`
  width: 100%;
  max-width: 800px;
  color: #ffffff;
  margin: 0 auto;
  text-align: center;
  font-family: 'Poppins-Medium', sans-serif;
`;
*/

const OverviewWrapper = styled(FullWidthElement)`
  background: url(${makeImageUrl({ url: `${CLOUDFRONT_STATIC}/img/pages/caleb-lp/caleb-bg-bottom-mobile.jpg` })}) no-repeat center top / cover;

  @media ${CSS_BREAKPOINTS_MIN.md} {
    background: url(${makeImageUrl({ url: `${CLOUDFRONT_STATIC}/img/pages/caleb-lp/caleb-bg-bottom-desktop.jpg` })}) no-repeat center top / cover;
  }
`;

const CTAContainer = styled.div`
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 8px;

  @media ${CSS_BREAKPOINTS_MIN.sm} {
    width: auto;
    min-width: 320px;
    display: flex;
  }

  @media ${CSS_BREAKPOINTS_MIN.md} {
    width: auto;
    display: inline-flex;
  }
`;

const LifetimeAccessInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0;
  gap: 8px;
`;

const CTATitle = 'SIGN UP';

class CalebTrainingView extends AbstractView<{}> {
  +viewName: string = CALEB_TRAINING_LANDINGPAGE_VIEW;

  constructor(props) {
    super(props);
    this.mailingListref = createRef();
    this.onCTAClick = this.onCTAClick.bind(this); // strangely this is needed here explicitly
  }

  onCTAClick() {
    window.open('https://skillyoga.theworkademy.com/signin/?register=true', '_blank');
    /* window.scrollTo({
      behavior: 'smooth',
      left: 0,
      top: _get(this.mailingListref, 'current.offsetTop', 4800),
    }); */
  }

  render() {
    if (true) {
      return <Redirect to={urls.LANDING_PAGE.replace(':slug', 'awaken-your-true-self')} />;
    }
    return (
      <Container>
        <HeroContainer>
          <PageElementContainer>
            <HeroTitle>
              Awaken Your True Self
              <br />
              with Caleb Jude Packham
            </HeroTitle>
            <HeroSubTitle>Transform Your Life Through the Power of Sattva Yoga</HeroSubTitle>

            <HeroCTAWrapper>
              <CTAContainer>
                <HeroCTA title={CTATitle} onClick={this.onCTAClick} />
                <LifetimeAccessInfo>
                  <img src={LifetimeSVG} alt="" />
                  <p>Lifetime access for only $40</p>
                </LifetimeAccessInfo>
              </CTAContainer>
            </HeroCTAWrapper>
          </PageElementContainer>
        </HeroContainer>

        <CalebPageElement>
          <CalebBenefits />
        </CalebPageElement>

        <FullWidthElement bg>
          <CalebPageElement bg>
            <CalebTrainWithBest onCTAClick={this.onCTAClick} ctaTitle={CTATitle} />
          </CalebPageElement>
        </FullWidthElement>

        <CalebPageElement>
          <CalebCoursePlan />
        </CalebPageElement>

        <FullWidthElement style={{ backgroundColor: '#000000' }}>
          <CalebPageElement>
            <CalebImageTextBanner />
          </CalebPageElement>
        </FullWidthElement>

        <FullWidthElement bg>
          <CalebPageElement>
            <CalebHowItWorks onCTAClick={this.onCTAClick} ctaTitle={CTATitle} />
          </CalebPageElement>
        </FullWidthElement>

        <CalebPageElement>
          <CalebMeetTheCoach />
        </CalebPageElement>

        <OverviewWrapper>
          <CalebPageElement>
            <CalebPriceOverview onCTAClick={this.onCTAClick} ctaTitle={CTATitle} />
          </CalebPageElement>
        </OverviewWrapper>

        <FullWidthElement bg>
          <CalebPageElement>
            <BottomCTAContainer>
              <TitleElement>Join Skill Yoga Master Courses to start training</TitleElement>
              <CTA title={CTATitle} onClick={this.onCTAClick} />
            </BottomCTAContainer>
          </CalebPageElement>
        </FullWidthElement>
      </Container>
    );
  }
}

export default CalebTrainingView;
