import styled from 'styled-components';
import { TitleElement } from '../text/CohortLp/CohortLpTextElements';

import CalendarSvg from '../../assets/svg/caleb-calendar-icon.svg';
import BreathSvg from '../../assets/svg/caleb-breath-icon.svg';
import ConnectSvg from '../../assets/svg/caleb-connect-icon.svg';
import { CSS_BREAKPOINTS_MIN, GUTTER_SIZE } from '../../config/constants';
import SYPrimaryButton from '../SYPrimaryButton';
import LifetimeSVG from '../../assets/svg/lifetime.svg';

const Container = styled.section`
  padding: ${GUTTER_SIZE * 2}px 0;

  @media ${CSS_BREAKPOINTS_MIN.sm} {
    padding: ${GUTTER_SIZE * 2.5}px 0 ${GUTTER_SIZE * 3.5}px;
  }
`;

const Svg = styled.img`
  height: 90px;
  margin: 0 0 ${GUTTER_SIZE / 2}px;

  @media ${CSS_BREAKPOINTS_MIN.sm} {
    height: 110px;
  }
`;

const Row = styled.div`
  text-align: center;

  @media ${CSS_BREAKPOINTS_MIN.sm} {
    display: flex;
  }
`;

const Column = styled.div`
  margin: 0 0 ${GUTTER_SIZE * 2}px;

  @media ${CSS_BREAKPOINTS_MIN.sm} {
    flex: 1;

    &:nth-child(2n) {
      padding: 0 ${GUTTER_SIZE * 4}px;
    }
  }
`;

const Text = styled.p`
  font-font: Poppins-Light, sans-serif;
  font-size: 15px;
  line-height: 26px;

  text-align: center;
  letter-spacing: 0.075px;

  @media ${CSS_BREAKPOINTS_MIN.sm} {
    font-size: 16px;
    line-height: 26px;
  }
`;

const CTAWrapper = styled.div`
  padding: 0 ${GUTTER_SIZE}px;

  @media ${CSS_BREAKPOINTS_MIN.sm} {
    text-align: center;
  }
`;

const CTA = styled(SYPrimaryButton)`
  width: 100%;

  @media ${CSS_BREAKPOINTS_MIN.sm} {
    width: auto;
    min-width: 320px;
  }
`;

const CTAContainer = styled.div`
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 8px;

  @media ${CSS_BREAKPOINTS_MIN.sm} {
    width: auto;
    min-width: 320px;
    display: flex;
  }

  @media ${CSS_BREAKPOINTS_MIN.md} {
    width: auto;
    display: inline-flex;
  }
`;

const LifetimeAccessInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0;
  gap: 8px;
`;

const CalebHowItWorks = ({ onCTAClick, ctaTitle = 'ENROLL NOW' }) => (
  <Container>
    <TitleElement>How the course works</TitleElement>

    <Row>
      <Column>
        <Svg src={CalendarSvg} alt="Calendar SVG" />
        <Text>
          Once the course opens and launches, you will practise 3 times per week, as guided by Caleb.
        </Text>
      </Column>

      <Column>
        <Svg src={BreathSvg} alt="Breath SVG" />
        <Text>
          Together we&apos;ll learn and practise all the branches of yoga, including Hatha (the postures),
          Kriya (the yoga of electricity), Pranayama (breath-work and meditation) – all guided by Caleb.
        </Text>
      </Column>

      <Column>
        <Svg src={ConnectSvg} alt="Connect SVG" />
        <Text>
          Through a chat you can connect with a group of people who are in the same situation as you are.
          We are creating a safe space to share experiences, ideas and perspectives. Because when you share, you grow.
        </Text>
      </Column>
    </Row>

    <CTAWrapper>
      <CTAContainer>
        <CTA title={ctaTitle} onClick={onCTAClick} />
        <LifetimeAccessInfo>
          <img src={LifetimeSVG} alt="" />
          <p>Lifetime access for only $40</p>
        </LifetimeAccessInfo>
      </CTAContainer>
    </CTAWrapper>
  </Container>
);

export default CalebHowItWorks;
