// @flow
import styled from 'styled-components';

import SocialLink from './SocialLink';
import {
  CSS_BREAKPOINTS_MIN,
  PRIMARY_COLOR,
  SPACING_BASE,
  TRANSITION_DEFAULT_LEN,
  WHITE_COLOR,
} from '../config/constants';

const NavigationLinkExternal = styled(SocialLink)`
  position: relative;
  display: none;
  margin-left: ${SPACING_BASE * 3}px;
  padding: 0;
  letter-spacing: 3px;
  font-size: 12px;
  line-height: 1.67;
  text-decoration: none;
  text-transform: uppercase;
  color: ${WHITE_COLOR};
  outline: none;
  transition: color ${TRANSITION_DEFAULT_LEN}s;

  &:hover {
    color: ${PRIMARY_COLOR};
    text-decoration: none;
  }

  @media ${CSS_BREAKPOINTS_MIN.md} {
    display: block;
    margin-left: ${SPACING_BASE * 9}px;
  }
`;

export default NavigationLinkExternal;
