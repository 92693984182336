// @flow

import React from 'react';
import _toLower from 'lodash/toLower';
import TextInput from './input/TextInput';

type EmailInputProps = {|
  onChange: (e: SyntheticEvent<HTMLInputElement>) => void,
  onKeyDown?: (e: SyntheticEvent<HTMLInputElement>) => void,
  onBlur?: (e: SyntheticEvent<HTMLInputElement>) => void,
  label?: string,
  type?: string,
  name?: string,
  value?: string,
  error?: string,
  active?: boolean,
|}

const EmailInput = ({
  onChange,
  label,
  type,
  name,
  value,
  ...rest
}: EmailInputProps) => (
  <TextInput
    {...rest}
    type={type || 'email'}
    label={label || 'Email'}
    name={name || 'username'}
    value={value || ''}
    onChange={(e) => {
      e.target.value = _toLower(e.target.value);
      onChange(e);
    }}
  />
);

EmailInput.defaultProps = {
  label: 'Email',
  type: 'email',
  name: 'username',
  value: undefined,
  error: undefined,
  onKeyDown: undefined,
  onBlur: undefined,
};

export default EmailInput;
