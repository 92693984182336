import styled from 'styled-components';
import { CLOUDFRONT_STATIC, CSS_BREAKPOINTS_MIN, GUTTER_SIZE } from '../../config/constants';
import AspectRatio from '../AspectRatio';
import { TitleElement } from '../text/CohortLp/CohortLpTextElements';
import InstagramSvg from '../../assets/svg/instagram-logo.svg';
// import FacebookSvg from '../../assets/svg/facebook-logo.svg';
// import YouTubeSvg from '../../assets/svg/youtube-logo.svg';
import SocialLink from '../SocialLink';
import makeImageUrl from '../../modules/makeImageUrl';

const Container = styled.div`
  padding: ${GUTTER_SIZE * 2}px 0;

  @media ${CSS_BREAKPOINTS_MIN.sm} {
    padding: ${GUTTER_SIZE * 2.5}px 0 ${GUTTER_SIZE * 3.5}px;
  }
`;

const Content = styled.div`
  @media ${CSS_BREAKPOINTS_MIN.sm} {
    display: flex;
    flex-direction: row;
  }
`;

const ImageWrapper = styled.div`
  margin: 0 0 ${GUTTER_SIZE * 2}px;

  @media ${CSS_BREAKPOINTS_MIN.sm} {
    flex: 0 0 40%;
    margin: 0;
    padding-right: 5%;
  }
`;

const TrainerName = styled.h4`
  font-size: 20px;
  line-height: 25px;
  letter-spacing: 0.4px;

  @media ${CSS_BREAKPOINTS_MIN.sm} {
    font-size: 30px;
    line-height: 44px;
    letter-spacing: 0.75px;
  }
`;

const ImageElement = styled(AspectRatio)`
  background: url(${makeImageUrl({ url: `${CLOUDFRONT_STATIC}/img/pages/marlene-lp/marlene-trainer-image.jpg` })}) no-repeat center / cover;
`;

const SocialLinks = styled.div`
  ${SocialLink} {
    &:first-child {
      padding-left: 0;
    }
  }

  @media ${CSS_BREAKPOINTS_MIN.sm} {
    margin-bottom: ${GUTTER_SIZE}px;
  }
`;

const SocialImg = styled.img`
  height: 21px;

  @media ${CSS_BREAKPOINTS_MIN.sm} {
    height: 31px;
  }
`;

const Text = styled.p`
  margin: 0;
  font-family: Poppins-Light, sans-serif;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: 0.3px;

  &:last-child {
    margin: 0;
  }

  @media ${CSS_BREAKPOINTS_MIN.sm} {
    font-size: 16px;
    line-height: 22px;
  }
`;

const MarleneMeetTheCoach = () => (
  <Container>
    <TitleElement>Meet Your Coach</TitleElement>

    <Content>
      <ImageWrapper>
        <ImageElement ratio={1} />
      </ImageWrapper>

      <div>
        <TrainerName>Marlene Schmitt</TrainerName>

        <SocialLinks>
          <SocialLink href="//www.instagram.com/marlalene" title="Link to Instagram" target="_blank" rel="noreferrer">
            <SocialImg src={InstagramSvg} alt="Instagram Account" />
          </SocialLink>
        </SocialLinks>

        <Text>
          Berlin-based Marlene Schmitt is an internationally trained yoga teacher with over two decades of bodywork experience.
          It is her vision to share yoga in an undogmatic and open way accessible to all. In 2017 she co-founded Yoga on the Move,
          a yoga collective offering yoga studio classes, pop-up events, online courses, retreats and trainings. For Marlene, sharing yoga
          means creating a space where everyone is allowed to simply BE, in the here and now, connected to body and mind through the breath.
          With her powerful and creative Vinyasa sequences, she allows us to dive into deeper levels of consciousness, strengthening
          our body-mind connection and growing beyond habitual thought patterns and beliefs.
        </Text>
      </div>
    </Content>
  </Container>
);

export default MarleneMeetTheCoach;
