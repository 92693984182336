import _get from "lodash/get";
import _findIndex from "lodash/findIndex";
import _find from "lodash/find";
import _isEmpty from "lodash/isEmpty";
import { LOCALSTORAGE_KEYS } from "../localStorageKeys";

interface MasterCourseViewing {
  masterCourseId: string;
  masterCourseSlug: string;
  workoutSlug: string;
  workoutId: string;
}

class UserUtils {
  static getGenderMap() {
    return {
      1: "male",
      2: "female",
      3: "other",
    };
  }

  static getGenderStringById(id) {
    return this.getGenderMap()[id] || null;
  }

  static getDataMasterCourse(masterCourse, key) {
    if (!masterCourse) {
      return "";
    }
    return _get(masterCourse, key);
  }

  static getPositionWorkoutInArray = (newActivePlan) => {
    if (!newActivePlan) {
      return -1;
    }
    return (
      (_findIndex(newActivePlan?.workouts, {
        workoutData: { id: newActivePlan?.nextWorkout?.id },
      }) ?? 0) + 1
    );
  };

  static getNextWorkoutByWorkouts = (workouts: any[]) => {
    if (_isEmpty(workouts)) {
      return null;
    }

    const workout = _find(workouts, (w) => w.done == false);

    if (!workout) {
      return workouts[0].workoutData;
    }

    return workout.workoutData;
  };

  static getVideoVimeoUrl = (videoUrl: String) => {
    if (!videoUrl) return "";
    if (
      videoUrl.includes("vimeo.com") ||
      videoUrl.includes("player.vimeo.com")
    ) {
      const videoId = videoUrl.split("/").pop();
      console.log({ videoId });
      if (videoId.includes("?")) {
        return `//player.vimeo.com/video/${videoId}&portrait=0&muted=1&autoplay=1`;
      }
      return `//player.vimeo.com/video/${videoId}?portrait=0&muted=1&autoplay=1`;
    }
    return "";
  };

  static setCurrentMasterCourseViewing({
    masterCourseId,
    workoutSlug,
    masterCourseSlug,
    workoutId
  }) {
    const masterCourse = {
      masterCourseId: masterCourseId,
      masterCourseSlug: masterCourseSlug,
      workoutSlug: workoutSlug,
      workoutId: workoutId,
    };
    console.log({ masterCourseViewing: masterCourse });
    Cookies.set(LOCALSTORAGE_KEYS.masterCourse, JSON.stringify(masterCourse));
  }

  static getCurrentMasterCourseViewing(): MasterCourseViewing | undefined {
    if (!!Cookies.get(LOCALSTORAGE_KEYS.masterCourse)) {
      return JSON.parse(Cookies.get(LOCALSTORAGE_KEYS.masterCourse));
    }

    return;
  }

  static getFirstName(user) {
    if (!!user?.firstName) {
      return user?.firstName;
    }
    if (!!user?.lastName) {
      return user?.lastName;
    }

    const name = user?.email?.split("@");
    return name?.[0] ?? "";
  }

  static checkFreeleticsTrial(code) {
    if (code == "freeleticsn4b223") {
      return true;
    }
    return false;
  }
}

export default UserUtils;
