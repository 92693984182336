// @flow
import styled from 'styled-components';

import {
  CSS_BREAKPOINTS_MIN, ERROR_COLOR, GREY_01_COLOR, PAPER_WHITE_COLOR, SPACING_BASE,
} from '../../config/constants';
import InputTextStyleCSS from '../shared-styles/InputTextStyleCSS';

const SYInputErrorMessage = styled.p`
  margin: ${SPACING_BASE * 2}px 0 0;
  padding: 0;
  color: ${ERROR_COLOR}; 
  ${InputTextStyleCSS};
`;

const SYInputWrapper = styled.div`
  ${(props) => props.css};
  position: relative;
  display: flex;
  padding: 0 ${SPACING_BASE * 2}px;
  align-items: flex-start;
  flex-direction: column;

  @media ${CSS_BREAKPOINTS_MIN.sm} {
    padding: 0 ${SPACING_BASE * 3}px;
  }
`;

const SYInputLabel = styled.label`
  display: flex;
  align-items: flex-start;
  color: ${(props) => (props.checked ? PAPER_WHITE_COLOR : GREY_01_COLOR)};
  cursor: pointer;
`;

const SYStyledInputLabelElement = styled(SYInputLabel)`
  &:before {
    content: '';
    position: relative;
    display: inline-flex;
    flex-shrink: 0;
    width: 25px;
    height: 25px;
    margin: 0 ${SPACING_BASE * 3}px 0 0;
    background-color: transparent;
    border: 1px solid ${(props) => (props.checked ? PAPER_WHITE_COLOR : GREY_01_COLOR)};
    transition: border .15s;
    
    @media ${CSS_BREAKPOINTS_MIN.sm} {
      margin: 0 ${SPACING_BASE * 2}px 0 0;
    }
  }
`;

export {
  SYInputErrorMessage,
  SYInputLabel,
  SYInputWrapper,
  SYStyledInputLabelElement,
};
