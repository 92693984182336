// @flow
/* eslint max-len: 0 */
import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import _isEmpty from 'lodash/isEmpty';
import styled, { css } from 'styled-components';
import Cookies from 'js-cookie';
import moment from 'moment-timezone';
import hexToRgba from 'hex-to-rgba';
import loadable from '@loadable/component';
import * as paymentActionCreators from '../../../store/actions/payment';
import type { User } from '../../../types/User';
import hasFullAccess from '../../../modules/hasFullAccess';
import {
  BLACK_COLOR_SEMI_TRANS, CLOUDFRONT_STATIC,
  CSS_BREAKPOINTS_MIN, debugSettings, GUTTER_SIZE, PAGE_CONTENT_WIDTH, PAGE_PADDING_LEFT_RIGHT, PAYMENT_VIEW_NAME,
  SPACING_BASE, WHITE_COLOR,
} from '../../../config/constants';
import Product from '../../../modules/Product';
import { stripeService } from '../../../services/Stripe';
import { LOCALSTORAGE_KEYS } from '../../../localStorageKeys';
import { marketingCampaignService } from '../../../services/MarketingBannerService';
import AbstractView from '../../AbstractView';
import type { ViewInterface } from '../../AbstractView';
import { googleTrackingService } from '../../../services/GoogleTrackingProvider';
import { getRawPriceWithPrecision } from '../../../modules/formatPrice';
import type { PaymentState } from '../../../types/PaymentState';
import makeImageUrl from '../../../modules/makeImageUrl';
import LoadingAnimation from '../../../components/LoadingAnimation';
import PostLoginContainer from '../../../components/PostLoginContainer';
import SYPrimaryButton from '../../../components/SYPrimaryButton';
import { adjustTrackingProvider } from '../../../services/AdjustTrackingProvider';
import { SUBSCRIPTION_PURCHASE_INITIATED_EVENT } from '../../../config/trackingEventNames';
import { apiService } from '../../../services/APIService';
import urls from '../../../config/urls';
import { logger } from '../../../modules/Logger';
import initFacebookSDK from '../../../modules/initFacebookSDK';
import CouponEntryLink from '../../../components/CouponEntryLink';
import MoneyBackStatement from '../../../components/MoneyBackStatement';

const GenericError = loadable(() => import('../../../components/error/GenericError'), { fallback: <LoadingAnimation /> });
const WhatYouGet = loadable(() => import('../../../components/WhatYouGet'), { fallback: <LoadingAnimation /> });
const Testimonials = loadable(() => import('../../../components/Testimonials'), { fallback: <LoadingAnimation /> });
const FAQCollapse = loadable(() => import('../../../components/FAQCollapse'), { fallback: <LoadingAnimation /> });
const ProductsOverview = loadable(() => import('../../../components/ProductsOverview'), { fallback: <LoadingAnimation /> });

const PaymentViewContainer = styled(PostLoginContainer)`
  max-width: none;
  margin: 0 auto;
  padding: 0;

  @media ${CSS_BREAKPOINTS_MIN.md} {
    padding: 0;
  }

  @media ${CSS_BREAKPOINTS_MIN.lg} {
    padding: 0;
  }
`;

export const paymentPageElementCss = css`
  max-width: ${PAGE_CONTENT_WIDTH}px;
  padding: 0 ${SPACING_BASE * 3}px;
  margin: 0 auto;

  @media ${CSS_BREAKPOINTS_MIN.sm} {
    width: 100%;
  }

  @media ${CSS_BREAKPOINTS_MIN.lg} {
    padding-left: ${PAGE_PADDING_LEFT_RIGHT}px;
    padding-right: ${PAGE_PADDING_LEFT_RIGHT}px;
  }
`;

export const PaymentPageElement = styled.div`
  ${(props) => (props.bg ? `
    margin-left: ${SPACING_BASE * 3}px;
    margin-right: ${SPACING_BASE * 3}px;
    background-color: ${BLACK_COLOR_SEMI_TRANS};

    @media ${CSS_BREAKPOINTS_MIN.sm} {
      margin-left: 0;
      margin-right: 0;
    }
  ` : '')}
`;

export const SubscriptionElement = styled.div`
  background: url(${makeImageUrl({ url: `${CLOUDFRONT_STATIC}/img/pages/payment/2021-08/top-576-min.jpg` })}) no-repeat center / cover;

  @media ${CSS_BREAKPOINTS_MIN.xs} {
    background: url(${makeImageUrl({ url: `${CLOUDFRONT_STATIC}/img/pages/payment/2021-08/top-768-min.jpg` })}) no-repeat center / cover;
  }

  @media ${CSS_BREAKPOINTS_MIN.sm} {
    background: url(${makeImageUrl({ url: `${CLOUDFRONT_STATIC}/img/pages/payment/2021-08/top-992-min.jpg` })}) no-repeat center / cover;
  }

  @media ${CSS_BREAKPOINTS_MIN.md} {
    background: url(${makeImageUrl({ url: `${CLOUDFRONT_STATIC}/img/pages/payment/2021-08/top-1200-min.jpg` })}) no-repeat center / cover;
  }

  @media ${CSS_BREAKPOINTS_MIN.lg} {
    background: url(${makeImageUrl({ url: `${CLOUDFRONT_STATIC}/img/pages/payment/2021-08/top-1440-min.jpg` })}) no-repeat center / cover;
  }
`;

export const LastSubscriptionElement = styled(SubscriptionElement)`
  margin-top: ${SPACING_BASE * 4}px;
  margin-bottom: 0;
  background: url(${makeImageUrl({ url: `${CLOUDFRONT_STATIC}/img/pages/payment/2021-08/bottom-576-min.jpg` })}) no-repeat center / cover;

  @media ${CSS_BREAKPOINTS_MIN.xs} {
    background: url(${makeImageUrl({ url: `${CLOUDFRONT_STATIC}/img/pages/payment/2021-08/bottom-768-min.jpg` })}) no-repeat center / cover;
  }

  @media ${CSS_BREAKPOINTS_MIN.sm} {
    margin-top: 0;
    margin-bottom: 0;
    background: url(${makeImageUrl({ url: `${CLOUDFRONT_STATIC}/img/pages/payment/2021-08/bottom-992-min.jpg` })}) no-repeat center / contain;
  }

  @media ${CSS_BREAKPOINTS_MIN.md} {
    background: url(${makeImageUrl({ url: `${CLOUDFRONT_STATIC}/img/pages/payment/2021-08/bottom-1200-min.jpg` })}) no-repeat center / cover;
  }

  @media ${CSS_BREAKPOINTS_MIN.lg} {
    background: url(${makeImageUrl({ url: `${CLOUDFRONT_STATIC}/img/pages/payment/2021-08/bottom-1440-min.jpg` })}) no-repeat center / cover;
  }
`;

export const ScrollTopButtonWrapper = styled.div`
  margin: ${SPACING_BASE * 5}px ${SPACING_BASE * 3}px 0;

  @media ${CSS_BREAKPOINTS_MIN.sm} {
    display: flex;
    justify-content: center;
  }
`;

export const ScrollTopButton = styled(SYPrimaryButton)`
  width: 100%;

  @media ${CSS_BREAKPOINTS_MIN.sm} {
    width: auto;
    min-width: 350px;
  }
`;

/**
 * next exports are needed for the ProductOverviewPublicView.js as well
 */

export const ProductsMoneyBackStatement = styled(MoneyBackStatement)`
  font-size: 13px;
`;

export const MoneyBackCodeEntryDivider = styled.div`
  margin: ${GUTTER_SIZE}px 0;
  border-bottom: solid 1px ${hexToRgba(WHITE_COLOR, 0.6)};
  box-shadow: 0 1px 0 0 ${hexToRgba(WHITE_COLOR, 0.1)};
`;

export const TopCodeEntryElement = styled.div`
  padding: ${GUTTER_SIZE}px 0 ${GUTTER_SIZE * 2.5}px;

  @media ${CSS_BREAKPOINTS_MIN.sm} {
    background-color: ${BLACK_COLOR_SEMI_TRANS};
  }
`;

class PaymentView extends AbstractView<{
  payment: PaymentState,
  paymentActions: Object,
  user: User,
}, {|
  error: Object,
  isFetching: boolean,
  redirectToTraining: boolean,
|}> implements ViewInterface {
  +viewName: string = PAYMENT_VIEW_NAME;

  state = {
    error: null,
    isFetching: true,
    redirectToTraining: false,
  };

  async componentDidMount(): any {
    const {
      payment: {
        defaultProducts,
        selectedProduct,
      },
      paymentActions: {
        fetchProducts,
        redeemCoupon,
        fetchProductsByPromoCode,
        clearSelectedProduct,
      },
      user,
    } = this.props;

    super.componentDidMount();

    googleTrackingService.trackEvent('funnel', {
      event_name: 'Step 0 - Init',
      event_category: 'Payment',
      event_label: 'starting',
    });

    this.setState({ isFetching: true, error: null });

    if (hasFullAccess(user)) {
      this.setState({ isFetching: true, error: null, redirectToTraining: true });
      return;
    }

    if (selectedProduct) {
      // Stripe might not have been initiated already
      stripeService.initStripe(() => {
        this.startStripe(selectedProduct);
      });

      // just forward the user to Stripe once! Therefore, we will
      // clear the selected product (from any landing page)
      clearSelectedProduct();
      return;
    }

    if (_isEmpty(defaultProducts)) {
      try {
        await fetchProducts();
      } catch (error) {
        logger.error(error, [
          {
            fileName: 'PaymentView.js',
            className: 'PaymentView',
            functionName: 'componentDidMount()',
          },
        ]);
        this.setError(error);
      }
    }

    const activeCampaign = marketingCampaignService.active;
    const activeCampaignCode = activeCampaign && activeCampaign.code;
    const cachedCodeInBrowser = Cookies.get(LOCALSTORAGE_KEYS.codeToApplyOnCheckout);

    if (!cachedCodeInBrowser && activeCampaignCode) {
      Cookies.set(LOCALSTORAGE_KEYS.codeToApplyOnCheckout, activeCampaignCode, { expires: moment().add(1, 'hour').toDate() });
    }

    const codeToApply = cachedCodeInBrowser || activeCampaignCode;

    if (codeToApply) {
      try {
        await redeemCoupon(codeToApply);
        await fetchProductsByPromoCode(codeToApply);
      } catch (error) {
        logger.error(error, [
          {
            fileName: 'PaymentView.js',
            className: 'PaymentView',
            functionName: 'componentDidMount()',
          },
        ]);
        console.warn('Error catching coupon information', error);
      }
    }

    this.setState({
      isFetching: false,
    });
  }

  setError = (error: Object) => {
    this.setState({ error });
  };

  // Facebook event tracking "InitiateCheckout"
  trackInitiateCheckoutOnFacebook = (subscription: Product) => {
    if (typeof window.fbq !== 'undefined') {
      window.fbq('track', 'InitiateCheckout', {
        content_category: subscription.id,
        content_ids: [subscription.id],
        contents: [{ 'id': subscription.id, 'quantity': 1 }],
        currency: subscription.currency,
        num_items: 1,
        value: getRawPriceWithPrecision(subscription.price),
      });
      if (debugSettings.ENABLE_TRACKING_LOGGER) {
        console.group('FacebookTrackingService logEvent');
        console.log('eventName: "InitiateCheckout"');
        console.log('eventParams', {
          content_category: subscription.id,
          content_ids: [subscription.id],
          contents: [{ 'id': subscription.id, 'quantity': 1 }],
          currency: subscription.currency,
          num_items: 1,
          value: getRawPriceWithPrecision(subscription.price),
        });
        console.groupEnd();
      }
    }
  };

  startStripe = async (subscription: Product) => {
    const {
      paymentActions,
      user,
    } = this.props;
    const coupon = subscription.hasStripeCoupon ? Cookies.get(LOCALSTORAGE_KEYS.codeToApplyOnCheckout) : undefined;

    paymentActions.resetError();

    try {
      this.setState({ isFetching: true });
      const sessionObject = await apiService.createStripeSession({
        cancelUrl: `${window.location.origin}${urls.PAYMENT}`,
        coupon,
        items: [{ price: subscription.id, quantity: 1 }],
        successUrl: `${window.location.origin}${urls.PAYMENT_SUCCESS}`,
        trialInDays: subscription.trialDays,
      });

      if (!window.FB) {
        initFacebookSDK(() => this.trackInitiateCheckoutOnFacebook(subscription));
      } else {
        this.trackInitiateCheckoutOnFacebook(subscription);
      }

      googleTrackingService.trackEvent('funnel', {
        event_name: 'Step 1 - Add To Cart',
        event_category: 'Payment',
        event_label: subscription.title,
      });

      googleTrackingService.trackEvent('initiate_checkout_web', {
        value: getRawPriceWithPrecision(subscription.price),
        currency: subscription.currency,
        items: [{
          id: subscription.id,
          name: subscription.title,
          category: 'subscription',
          quantity: 1,
          price: getRawPriceWithPrecision(subscription.price),
        }],
        userId: user.id,
      });

      adjustTrackingProvider.trackEvent(SUBSCRIPTION_PURCHASE_INITIATED_EVENT, {
        subscriptionId: subscription.id,
      });

      stripeService.checkoutWithSessionId(sessionObject.id);
    } catch (error) {
      this.setState({ error, isFetching: false });
      logger.error(error, [
        {
          fileName: 'PaymentView.js',
          className: 'PaymentView',
          functionName: 'startStripe()',
        },
      ]);
    }
  };

  onRemoveCodeClick = () => {
    const {
      paymentActions,
    } = this.props;

    paymentActions.resetCoupon();
    Cookies.remove(LOCALSTORAGE_KEYS.codeToApplyOnCheckout);
  };

  scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  renderScrollTopButton = () => (
    <ScrollTopButtonWrapper>
      <ScrollTopButton
        title="START NOW"
        onClick={this.scrollToTop}
      />
    </ScrollTopButtonWrapper>
  );

  render() {
    const {
      error, isFetching, redirectToTraining,
    } = this.state;

    const {
      payment: {
        promotion,
        defaultProducts,
      },
      user,
    } = this.props;

    if (redirectToTraining) {
      return (<Redirect to="/training" />);
    }

    if (isFetching) {
      return (
        <PostLoginContainer>
          <LoadingAnimation />
        </PostLoginContainer>
      );
    }

    if (error) {
      return (
        <PostLoginContainer>
          <GenericError error={error} />
        </PostLoginContainer>
      );
    }

    const products = (promotion && promotion.products) || defaultProducts;

    return (
      <PaymentViewContainer>
        <SubscriptionElement>
          <ProductsOverview
            productsToDisplay={products}
            onSubscribeClick={this.startStripe}
            css={paymentPageElementCss}
          >
            <ProductsMoneyBackStatement />
          </ProductsOverview>
        </SubscriptionElement>

        <TopCodeEntryElement>
          <CouponEntryLink
            isCodeActive={!!(promotion && promotion.code)}
            onRemoveCodeClick={this.onRemoveCodeClick}
          />
        </TopCodeEntryElement>

        <PaymentPageElement bg>
          <WhatYouGet css={paymentPageElementCss} />
        </PaymentPageElement>

        <PaymentPageElement>
          <Testimonials css={paymentPageElementCss} />
          {this.renderScrollTopButton()}
        </PaymentPageElement>

        <PaymentPageElement bg>
          <FAQCollapse css={paymentPageElementCss} />
        </PaymentPageElement>

        <LastSubscriptionElement>
          <ProductsOverview
            productsToDisplay={products}
            onSubscribeClick={this.startStripe}
            user={user}
            showClose={false}
            css={paymentPageElementCss}
          >
            <>
              <ProductsMoneyBackStatement />
              <MoneyBackCodeEntryDivider />
              <CouponEntryLink
                isCodeActive={!!(promotion && promotion.code)}
                onRemoveCodeClick={this.onRemoveCodeClick}
              />
            </>
          </ProductsOverview>
        </LastSubscriptionElement>
      </PaymentViewContainer>
    );
  }
}

const mapStateToProps = ({ payment, auth }) => ({
  payment,
  user: auth.user,
});

const mapDispatchToProps = (dispatch) => ({
  paymentActions: bindActionCreators(paymentActionCreators, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentView);
