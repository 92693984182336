// @flow
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import _isEmpty from 'lodash/isEmpty';

import * as authActionsCreators from '../store/actions/auth';
import * as planActionsCreators from '../store/actions/plan';
import LoadingAnimation from '../components/LoadingAnimation';
import PostLoginContainer from '../components/PostLoginContainer';
import GenericError from '../components/error/GenericError';
import type { User } from '../types/User';
import Plan from '../modules/Plan';
import { authService } from '../services/AuthService';
import { logger } from '../modules/Logger';

const withUserDataLoader = (BaseComponent) => {
  class EnhancedComponent extends React.PureComponent<{|
    activePlan: Plan,
    authActions: Object,
    isLoggedIn: boolean,
    planActions: Object,
    user: User,
  |}, {|
    isFetching: boolean,
    error?: Error,
  |}> {
    state = {
      isFetching: true,
      error: undefined,
    };

    isComponentMounted: boolean = false;

    async componentDidMount() {
      /* eslint react/destructuring-assignment: 0 */
      const {
        activePlan, authActions, planActions, isLoggedIn,
      } = this.props;

      this.isComponentMounted = true;

      try {
        if (!await authService.isAuthenticated()) {
          this.setState({ isFetching: false });
          return;
        }

        if (!isLoggedIn) {
          await authActions.fetchMe();
        }

        if (this.isComponentMounted) {
          if (_isEmpty(activePlan)) {
            await planActions.fetchActivePlan(this.props.user.id);
          }

          this.setState({ isFetching: false, error: undefined });
        }
      } catch (error) {
        if (this.isComponentMounted) {
          this.setState({ isFetching: false, error });
        }
        logger.error(error, [
          {
            fileName: 'withUserDataLoader.js',
            className: 'withUserDataLoader',
            functionName: 'componentDidMount()',
          },
        ]);
      }
    }

    componentWillUnmount() {
      // this prevents state updates on unmounted components
      this.isComponentMounted = false;
    }

    render(): React$Element<*> {
      const { isFetching, error } = this.state;

      if (isFetching) {
        return (
          <LoadingAnimation />
        );
      }

      if (error) {
        return (
          <PostLoginContainer>
            <GenericError error={error} />
          </PostLoginContainer>
        );
      }

      const {
        activePlan,
        authActions,
        isLoggedIn,
        planActions,
        user,
        ...rest
      } = this.props;

      return (
        <BaseComponent {...rest} />
      );
    }
  }

  return EnhancedComponent;
};

const mapStateToProps = ({ activePlan, auth }) => ({
  activePlan,
  user: auth.user,
  isLoggedIn: auth.isLoggedIn,
});

const mapDispatchToProps = (dispatch) => ({
  authActions: bindActionCreators(authActionsCreators, dispatch),
  planActions: bindActionCreators(planActionsCreators, dispatch),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withUserDataLoader,
);
