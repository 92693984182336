// @flow
import axios from 'axios';
import moment from 'moment-timezone';
import _get from 'lodash/get';

import apiKeys from '../config/api-keys';
import api from '../config/api';
import userUtils from '../utils/user.utils';
import type { User } from '../types/User';
import type { UserOnboarding } from '../types/UserOnboarding';

/**
 * Autopilot attributes names for API calls
 *
 * @see https://autopilot.docs.apiary.io/#reference/custom-field-data-types
 */

const apAttributes = {
  ACTIVE_PROGRAM: 'string--Active--Program',
  COHORT_WAITING_LIST: 'boolean--Cohort--Wait--List--User',
  CALEB_WAITING_LIST: 'boolean--Caleb--Wait--List--User',
  ADAM_WAITING_LIST: 'boolean--Adam--Wait--List--User',
  MARLENE_WAITING_LIST: 'boolean--Marlene--Wait--List--User',
  EMAIL: 'Email',
  FIRST_NAME: 'FirstName',
  FIRST_VIDEO_AT: 'date--First--Video--Watched--At',
  FIRST_VIDEO_TYPE: 'string--First--Video--Type',
  GENDER: 'string--Gender',
  LAST_NAME: 'LastName',
  LAST_PROGRAM_WOROUT_TITLE: 'string--Last--Program--Workout--Title',
  LAST_VIDEO_AT: 'date--Last--Video--Watched--At',
  LOCALE: 'string--Locale',
  NEWSLETTER: 'boolean--Newsletter-OptIn',
  PAYING_USER: 'boolean--Paying--User',
  SIGNUP_DATE: 'date--Signup--Date',
  SKILL_LEVEL: 'integer--Skill--Level',
  SUB_START_DATE: 'date--Subscription--Start--Date',
  TOTAL_VIDEOS: 'integer--Total--Videos--Watched',
  TRAINING_REMINDERS: 'boolean--Training--Reminders',
  UNSUBSCRIBED: 'unsubscribed',
  VIDEO_QUITTED_AT: 'date--Video--Quitted--At',
};

const autopilotRequest = axios.create({
  baseURL: api.AUTOPILOT_PROD,
  timeout: 3000,
  headers: {
    autopilotapikey: apiKeys.AUTOPILOT,
    'Content-Type': 'application/json',
  },
});

const isUserTrackable = (user: User | UserOnboarding): boolean => user.newsletter;

class AutopilotAPI {
  static create(user: UserOnboarding) {
    if (!isUserTrackable(user)) {
      return false;
    }

    return autopilotRequest.post('/contact', {
      contact: {
        [apAttributes.EMAIL]: user.email,
        [apAttributes.FIRST_NAME]: user.firstName,
        [apAttributes.LAST_NAME]: user.lastName || '',
        custom: {
          [apAttributes.SIGNUP_DATE]: moment.utc(),
          [apAttributes.SKILL_LEVEL]: user.experienceId,
          [apAttributes.GENDER]: userUtils.getGenderStringById(user.genderId),
          [apAttributes.LOCALE]: 'en',
          [apAttributes.PAYING_USER]: false,
          [apAttributes.NEWSLETTER]: user.newsletter || user.journeyMailing,
        },
      },
    });
  }

  static lastVideoWatched(user: User, programWorkoutTitle?: string) {
    if (!isUserTrackable(user)) {
      return false;
    }

    const custom = {
      [apAttributes.LAST_VIDEO_AT]: moment.utc(),
      [apAttributes.SKILL_LEVEL]: user.experienceId,
      [apAttributes.PAYING_USER]: (!!user.isPremium),
    };

    // append the last watched program workout title
    const workoutTitle = programWorkoutTitle ? {
      [apAttributes.LAST_PROGRAM_WOROUT_TITLE]: programWorkoutTitle,
    } : undefined;

    return autopilotRequest.post('/contact', {
      contact: {
        [apAttributes.EMAIL]: user.email,
        [apAttributes.FIRST_NAME]: user.firstName,
        [apAttributes.LAST_NAME]: user.lastName,
        custom: {
          ...custom,
          ...workoutTitle,
        },
      },
    });
  }

  static setPayingUser(user: User, isPaying?: boolean) {
    if (!isUserTrackable(user)) {
      return false;
    }

    return autopilotRequest.post('/contact', {
      contact: {
        [apAttributes.EMAIL]: user.email,
        [apAttributes.FIRST_NAME]: user.firstName,
        [apAttributes.LAST_NAME]: user.lastName,
        custom: {
          [apAttributes.PAYING_USER]: isPaying || true,
          [apAttributes.SKILL_LEVEL]: user.experienceId,
        },
      },
    });
  }

  static setSubscriptionDate(user: User, time: any) {
    if (!isUserTrackable(user)) {
      return false;
    }

    return autopilotRequest.post('/contact', {
      contact: {
        [apAttributes.EMAIL]: user.email,
        [apAttributes.FIRST_NAME]: user.firstName,
        [apAttributes.LAST_NAME]: user.lastName,
        custom: {
          [apAttributes.SUB_START_DATE]: time || moment.utc(),
          [apAttributes.PAYING_USER]: (!!user.isPremium),
        },
      },
    });
  }

  static setTotalVideosWatched(user: User, count: number) {
    if (!isUserTrackable(user)) {
      return false;
    }

    return autopilotRequest.post('/contact', {
      contact: {
        [apAttributes.EMAIL]: user.email,
        [apAttributes.FIRST_NAME]: user.firstName,
        [apAttributes.LAST_NAME]: user.lastName,
        custom: {
          [apAttributes.TOTAL_VIDEOS]: count,
          [apAttributes.PAYING_USER]: (!!user.isPremium),
        },
      },
    });
  }

  static trackPageVisit() {
    const sessionId = _get(window.AutopilotAnywhere, 'sessionId');

    if (sessionId) {
      return autopilotRequest.post('/pagevisit/visit', {
        sessionId,
        url: window.location.href,
      });
    }

    return null;
  }

  static update(user: User, custom?: Object) {
    if (!isUserTrackable(user)) {
      return false;
    }

    return autopilotRequest.post('/contact', {
      contact: {
        [apAttributes.EMAIL]: user.email,
        [apAttributes.FIRST_NAME]: user.firstName || null,
        [apAttributes.LAST_NAME]: user.lastName || null,
        custom,
      },
    });
  }

  static videoQuittedAt(user: User, time: any) {
    if (!isUserTrackable(user)) {
      return false;
    }

    return autopilotRequest.post('/contact', {
      contact: {
        [apAttributes.EMAIL]: user.email,
        [apAttributes.FIRST_NAME]: user.firstName,
        [apAttributes.LAST_NAME]: user.lastName,
        custom: {
          [apAttributes.VIDEO_QUITTED_AT]: time || moment.utc(),
          [apAttributes.PAYING_USER]: (!!user.isPremium),
        },
      },
    });
  }

  static setActivePlanName(user: User, name: string) {
    if (!isUserTrackable(user)) {
      return false;
    }

    return autopilotRequest.post('/contact', {
      contact: {
        [apAttributes.EMAIL]: user.email,
        [apAttributes.FIRST_NAME]: user.firstName || null,
        [apAttributes.LAST_NAME]: user.lastName || null,
        custom: {
          [apAttributes.ACTIVE_PROGRAM]: name,
          [apAttributes.PAYING_USER]: (!!user.isPremium),
        },
      },
    });
  }

  static addEmailToCohortWaitingList(email: string) {
    return autopilotRequest.post('/contact', {
      contact: {
        [apAttributes.EMAIL]: email,
        [apAttributes.UNSUBSCRIBED]: false, // re-subscribe in case they unsubscribes
        custom: {
          [apAttributes.COHORT_WAITING_LIST]: true,
        },
      },
    });
  }

  static addEmailToCalebWaitingList(email: string) {
    return autopilotRequest.post('/contact', {
      contact: {
        [apAttributes.EMAIL]: email,
        [apAttributes.UNSUBSCRIBED]: false, // re-subscribe in case they unsubscribes
        custom: {
          [apAttributes.CALEB_WAITING_LIST]: true,
        },
      },
    });
  }

  static addEmailToAdamWaitingList(email: string) {
    return autopilotRequest.post('/contact', {
      contact: {
        [apAttributes.EMAIL]: email,
        [apAttributes.UNSUBSCRIBED]: false, // re-subscribe in case they unsubscribes
        custom: {
          [apAttributes.ADAM_WAITING_LIST]: true,
        },
      },
    });
  }

  static addEmailToMarleneWaitingList(email: string) {
    return autopilotRequest.post('/contact', {
      contact: {
        [apAttributes.EMAIL]: email,
        [apAttributes.UNSUBSCRIBED]: false, // re-subscribe in case they unsubscribes
        custom: {
          [apAttributes.MARLENE_WAITING_LIST]: true,
        },
      },
    });
  }
}

export default AutopilotAPI;
