// @flow

export const ACTIVATE_PLAN_SUCCESS = 'ACTIVATE_PLAN_SUCCESS';
export const AUTH_SIGNIN_FAILURE = 'AUTH_SIGNIN_FAILURE';
export const AUTH_SIGNIN_START = 'AUTH_SIGNIN_START';
export const AUTH_SIGNIN_SUCCESS = 'AUTH_SIGNIN_SUCCESS';
export const AUTH_SIGNOUT = 'AUTH_SIGNOUT';
export const AUTH_SIGNOUT_SUCCESS = 'AUTH_SIGNOUT_SUCCESS';
export const AUTH_SIGNOUT_FAILURE = 'AUTH_SIGNOUT_FAILURE';
export const AUTH_FETCHME = 'AUTH_FETCHME';
export const AUTH_FETCHME_SUCCESS = 'AUTH_FETCHME_SUCCESS';
export const AUTH_FETCHME_FAILURE = 'AUTH_FETCHME_FAILURE';
export const COUPON_REDEEM_START = 'COUPON_REDEEM_START';
export const COUPON_REDEEM_FAILED = 'COUPON_REDEEM_FAILED';
export const COUPON_REDEEM_RESET = 'COUPON_REDEEM_RESET';
export const COUPON_REDEEM_SUCCESS = 'COUPON_REDEEM_SUCCESS';
export const LOAD_ACTIVE_PLAN_SUCCESS = 'LOAD_ACTIVE_PLAN_SUCCESS';
export const LOAD_PLAN_FAILED = 'LOAD_PLAN_FAILED';
export const LOAD_PLAN_SUCCESS = 'LOAD_PLAN_SUCCESS';
export const LOAD_MASTER_COURSE_SUCCESS = 'LOAD_MASTER_COURSE_SUCCESS';
export const LOAD_MASTER_COURSE_FAILED = 'LOAD_MASTER_COURSE_FAILED';
export const SET_NEXT_WORKOUT = 'SET_NEXT_WORKOUT';

export const PAGE_UPDATE = 'PAGE_UPDATE';
export const PAGE_UPDATE_GET = 'PAGE_UPDATE_GET';
export const PAYMENT_ACTION_STARTED = 'PAYMENT_ACTION_STARTED';
export const PAYMENT_CANCEL_SUBSCRIPTION = 'PAYMENT_CANCEL_SUBSCRIPTION';
export const PAYMENT_ERROR_RESET = 'PAYMENT_ERROR_RESET';

export const PAYMENT_REQUEST_MASTER_COURSE_SUCCESS = 'PAYMENT_REQUEST_MASTER_COURSE_SUCCESS';
export const PAYMENT_GET_ACTIVE_MASTER_COURSE_SUCCESS = 'PAYMENT_GET_ACTIVE_MASTER_COURSE_SUCCESS';
export const PAYMENT_CANCEL_MASTER_COURSE_SUBSCRIPTION = 'PAYMENT_CANCEL_MASTER_COURSE_SUBSCRIPTION';
export const UPDATE_MASTER_COURSE_SUBSCRIPTION_SUCCESS = 'UPDATE_MASTER_COURSE_SUBSCRIPTION_SUCCESS';

export const PAYMENT_GET_ACTIVE_SUCCESS = 'PAYMENT_GET_ACTIVE_SUCCESS';
export const PAYMENT_GET_DEFAULT_PRODUCTS_SUCCESS = 'PAYMENT_GET_DEFAULT_PRODUCTS_SUCCESS';
export const PAYMENT_GET_PRODUCTS_BY_COUPON_SUCCESS = 'PAYMENT_GET_PRODUCTS_BY_COUPON_SUCCESS';
export const GET_PRODUCTS_BY_STRIPE_IDS_SUCCESS = 'GET_PRODUCTS_BY_STRIPE_IDS_SUCCESS';
export const PAYMENT_REQUEST_FAILED = 'PAYMENT_REQUEST_FAILED';
export const PAYMENT_REQUEST_START = 'PAYMENT_REQUEST_START';
export const PAYMENT_REQUEST_SUCCESS = 'PAYMENT_REQUEST_SUCCESS';
export const PAYMENT_SUCCESS_RESET = 'PAYMENT_SUCCESS_RESET';
export const PLAN_STATUS_UPDATE_SUCCESS = 'PLAN_STATUS_UPDATE_SUCCESS';
export const PLAN_WORKOUT_STATUS_UPDATE_SUCCESS = 'PLAN_WORKOUT_STATUS_UPDATE_SUCCESS';
export const UPDATE_SUBSCRIPTION_SUCCESS = 'UPDATE_SUBSCRIPTION_SUCCESS';

export const ONBOARDING_PUSH_WEEKDAY = 'onboarding/PUSH_WEEKDAY';
export const ONBOARDING_POP_WEEKDAY = 'onboarding/POP_WEEKDAY';
export const ONBOARDING_SET_GOAL_ID = 'onboarding/SET_GOAL_ID';
export const ONBOARDING_SET_SPORT_ID = 'onboarding/SET_SPORT_ID';
export const ONBOARDING_SET_EXPERIENCE_ID = 'onboarding/SET_EXPERIENCE_ID';
export const ONBOARDING_SET_GENDER_ID = 'onboarding/SET_GENDER_ID';
export const ONBOARDING_SET_FIRST_NAME = 'onboarding/SET_FIRST_NAME';
export const ONBOARDING_SET_EMAIL = 'onboarding/SET_EMAIL';
export const ONBOARDING_SET_PASSWORD = 'onboarding/SET_PASSWORD';
export const ONBOARDING_SET_NEWSLETTER = 'onboarding/SET_NEWSLETTER';
export const ONBOARDING_SET_JOURNEY_MAILING = 'onboarding/SET_JOURNEY_MAILING';
export const ONBOARDING_SET_USER_ONBOARDING = 'onboarding/SET_USER_ONBOARDING';

export const FETCH_FREEMIUM_WORKOUTS_ACTION = 'workouts/FETCH_FREEMIUM';
export const FETCH_FREEMIUM_WORKOUTS_SUCCESS = 'workouts/FETCH_FREEMIUM_SUCCESS';
export const FETCH_FREEMIUM_WORKOUTS_FAILED = 'workouts/FETCH_FREEMIUM_FAILED';

export const FETCH_RECOMMENDED_WORKOUTS_ACTION = 'workouts/FETCH_RECOMMENDED_WORKOUTS';
export const FETCH_RECOMMENDED_WORKOUTS_SUCCESS = 'workouts/FETCH_RECOMMENDED_WORKOUTS_SUCCESS';
export const FETCH_RECOMMENDED_WORKOUTS_FAILED = 'workouts/FETCH_RECOMMENDED_WORKOUTS_FAILED';

export const FETCH_IS_VALID_REFERRER_ACTION = 'referrer/FETCH_IS_VALID_REFERRER_ACTION';
export const FETCH_IS_VALID_REFERRER_SUCCESS = 'referrer/FETCH_IS_VALID_REFERRER_SUCCESS';
export const FETCH_IS_VALID_REFERRER_FAILED = 'referrer/FETCH_IS_VALID_REFERRER_FAILED';

export const MARK_WORKOUT_STARTED = 'workouts/MARK_WORKOUT_STARTED';
export const MARK_WORKOUT_STARTED_SUCCESS = 'workouts/MARK_WORKOUT_STARTED_SUCCESS';
export const MARK_WORKOUT_STARTED_FAILED = 'workouts/MARK_WORKOUT_STARTED_FAILED';

export const MARK_WORKOUT_DONE = 'workouts/MARK_WORKOUT_DONE';
export const MARK_WORKOUT_DONE_SUCCESS = 'workouts/MARK_WORKOUT_DONE_SUCCESS';
export const MARK_WORKOUT_DONE_FAILED = 'workouts/MARK_WORKOUT_DONE_FAILED';

export const FETCH_ALL_WORKOUTS_STARTED = 'workouts/FETCH_ALL_WORKOUTS_STARTED';
export const FETCH_ALL_WORKOUTS_SUCCESS = 'workouts/FETCH_ALL_WORKOUTS_SUCCESS';
export const FETCH_ALL_WORKOUTS_FAILED = 'workouts/MARK_WORKOUT_FAILED';

export const FILTER_ALL_WORKOUTS_STARTED = 'workouts/FILTER_ALL_WORKOUTS_STARTED';
export const FILTER_ALL_WORKOUTS_SUCCESS = 'workouts/FILTER_ALL_WORKOUTS_SUCCESS';
export const FILTER_ALL_WORKOUTS_FAILED = 'workouts/FILTER_ALL_WORKOUTS_FAILED';
export const FILTER_ALL_WORKOUTS_RESET = 'workouts/FILTER_ALL_WORKOUTS_RESET';

export const SET_USER_EMAIL = 'user/SET_EMAIL';
export const SET_AUTH_TYPE = 'auth/SET_AUTH_TYPE';
export const SET_SOCIAL_LOGIN = 'auth/SET_SOCIAL_LOGIN';

export const SET_SELECTED_PRODUCT_LANDINGPAGE_ACTION = 'payment/SET_SELECTED_PRODUCT_LANDINGPAGE_ACTION';
export const CLEAR_SELECTED_PRODUCT_LANDINGPAGE_ACTION = 'payment/CLEAR_SELECTED_PRODUCT_LANDINGPAGE_ACTION';

export const SET_USER_ACCEPTED_COOKIES_ACTION = 'user/SET_USER_ACCEPTED_COOKIES_ACTION';

export const GET_PACKAGES = 'user/GET_PACKAGES';
export const LOAD_PACKAGES_FAILED = 'user/LOAD_PACKAGES_FAILED';
export const LOAD_PACKAGES_SUCCESS = 'user/LOAD_PACKAGES_SUCCESS';
