// @flow
import _sortBy from 'lodash/sortBy';
import _get from 'lodash/get';
import _omit from 'lodash/omit';
import moment from 'moment-timezone';
import * as types from '../actions/types';
import Product from '../../modules/Product';
import makeProductFromApiResponse from '../../modules/makeProductFromApiResponse';
import type { PaymentState } from '../../types/PaymentState';
import {GET_PRODUCTS_BY_STRIPE_IDS_SUCCESS} from "../actions/types";

const defaultState: PaymentState = {
  activeSubscription: null,
  activeMasterCourseSubscription: null,
  defaultProducts: [],
  loading: false,
  payment: {},
  promotion: undefined,
  selectedProduct: undefined,
  stripeProducts: [],
};

const NEW_STRIPE_COUPON_IMPLEMENTATION_RELEASE_DATE = '2020-11-11T23:00:00.000Z';

export default function paymentReducer(state: PaymentState = defaultState, action: Object) {
  const { payload, type } = action;

  switch (type) {
    case types.COUPON_REDEEM_FAILED:
    case types.COUPON_REDEEM_RESET: {
      return {
        ...state,
        promotion: undefined,
      };
    }

    case types.COUPON_REDEEM_SUCCESS: {
      return {
        ...state,
        promotion: {
          ...state.promotion,
          code: payload,
        },
      };
    }

    case types.COUPON_REDEEM_START:
    case types.PAYMENT_REQUEST_START:
    case types.PAYMENT_ACTION_STARTED: {
      return { ...state, loading: true };
    }

    case types.PAYMENT_REQUEST_FAILED: {
      return {
        ...state, request: action.payload, loading: false, success: false,
      };
    }

    case types.PAYMENT_ERROR_RESET:
    case types.PAYMENT_SUCCESS_RESET: {
      return {
        ...state, request: null, loading: false, success: false,
      };
    }

    case types.PAYMENT_GET_DEFAULT_PRODUCTS_SUCCESS: {
      const products = payload.map(makeProductFromApiResponse);
      const defaultProducts = _sortBy(products, ['weight']);

      return {
        ...state,
        defaultProducts,
        loading: false,
      };
    }


    case types.GET_PRODUCTS_BY_STRIPE_IDS_SUCCESS: {
      const products = payload.map(makeProductFromApiResponse);
      const sortedProducts = _sortBy(products, ['weight']);

      return {
        ...state,
        stripeProducts: sortedProducts,
        loading: false,
      };
    }

    case types.PAYMENT_GET_PRODUCTS_BY_COUPON_SUCCESS: {
      // const { defaultProducts } = state;
      const promoCodeFromDb = _get(state, 'promotion.code');
      let productsInput = payload;

      // for products that have been release before the Stripe update that contains the updated
      // coupon logic, we will remove the coupon attribute because the prices are already reduced
      const isOldStripeProductWithReducedPrice = promoCodeFromDb && moment(promoCodeFromDb.createdAt).isBefore(NEW_STRIPE_COUPON_IMPLEMENTATION_RELEASE_DATE);
      // trial code coupons will also be removed, because trial is part of the Stripe product
      const isTrialCode = promoCodeFromDb && promoCodeFromDb.type === 'TRIAL';

      if (isOldStripeProductWithReducedPrice || isTrialCode) {
        productsInput = productsInput.map((product) => _omit(product, 'coupon'));
      }

      const products: Array<Product> = productsInput.map(makeProductFromApiResponse);

      return {
        ...state,
        promotion: {
          ...state.promotion,
          products,
        },
      };
    }

    case types.PAYMENT_REQUEST_SUCCESS:
    case types.PAYMENT_GET_ACTIVE_SUCCESS:
    case types.PAYMENT_CANCEL_SUBSCRIPTION:
    case types.UPDATE_SUBSCRIPTION_SUCCESS: {
      return { ...state, activeSubscription: action.payload };
    }


    case types.PAYMENT_REQUEST_MASTER_COURSE_SUCCESS:
    case types.PAYMENT_GET_ACTIVE_MASTER_COURSE_SUCCESS:
    case types.PAYMENT_CANCEL_MASTER_COURSE_SUBSCRIPTION:
    case types.UPDATE_MASTER_COURSE_SUBSCRIPTION_SUCCESS: {
      return { ...state, activeMasterCourseSubscription: action.payload };
    }

    case types.SET_SELECTED_PRODUCT_LANDINGPAGE_ACTION: {
      return { ...state, selectedProduct: action.payload };
    }

    case types.CLEAR_SELECTED_PRODUCT_LANDINGPAGE_ACTION: {
      return { ...state, selectedProduct: undefined };
    }

    default:
      return state;
  }
}
