// @flow
import { createGlobalStyle } from 'styled-components';

import {
  BLACK_COLOR, PRIMARY_COLOR, WHITE_COLOR,
} from '../config/constants';
import SideBarPropsCSS from './shared-styles/SideBarPropsCSS';
import WebkitScrollBarCSS from './shared-styles/WebkitScrollBarCSS';
import RCSliderCSS from './shared-styles/RCSliderCSS';

const GlobalStyle = createGlobalStyle`
  *,
  *:after,
  *:before {
    box-sizing: border-box;
  }

  * {
    /* fixes flickering of menu */
    -webkit-backface-visibility: hidden;
  }

  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }

  html {
    line-height: 1.15;
    background-color: ${BLACK_COLOR};
    -webkit-text-size-adjust: 100%;
  }

  body {
    position: relative;
    min-height: 100vh;
    margin: 0;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: 'Poppins-Light';
    font-size: 16px;
    line-height: 1.55;
    letter-spacing: 0.45px;
    color: ${WHITE_COLOR};
    overflow-y: scroll;
    background: ${BLACK_COLOR};

    &.sidebar--open {
      position: fixed;
      width: 100%;
    }
  }

  a {
    background-color: transparent;
    color: ${PRIMARY_COLOR}
  }

  ol, ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  img {
    max-width: 100%;
    max-height: 100%;
    border-style: none;
  }

  button,
  input,
  optgroup,
  select,
  textarea {
    font-family: inherit;
    font-size: 100%;
    line-height: 1.15;
    margin: 0;
  }

  button,
  select {
    text-transform: none;
  }

  button,
  [type="button"],
  [type="reset"],
  [type="submit"] {
    -webkit-appearance: button;
    border-style: solid;
  }

  button::-moz-focus-inner,
  [type="button"]::-moz-focus-inner,
  [type="reset"]::-moz-focus-inner,
  [type="submit"]::-moz-focus-inner {
    border-style: none;
    padding: 0;
  }

  button:-moz-focusring,
  [type="button"]:-moz-focusring,
  [type="reset"]:-moz-focusring,
  [type="submit"]:-moz-focusring {
    outline: 1px dotted ButtonText;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active  {
    transition: background-color 5000s;
    -webkit-text-fill-color: #fff !important;
  }

  .ReactModal__Body--open {
    overflow: hidden;
  }

  .grecaptcha-badge {
    display: none!important;
    z-index: -9999;
    height: 0;
    width: 0;
  }

  ${WebkitScrollBarCSS}
  ${SideBarPropsCSS}
  ${RCSliderCSS}
`;

export default GlobalStyle;
