// @flow
import moment from 'moment-timezone';
import _get from 'lodash/get';
import _set from 'lodash/set';
import _isEmpty from 'lodash/isEmpty';

import * as types from './types';
import { apiService } from '../../services/APIService';
import type { CancelStripeSubscriptionPayload } from '../../types/CancelStripeSubscriptionPayload';
import {COUPON_REDEEM_START, GET_PRODUCTS_BY_STRIPE_IDS_SUCCESS, PAYMENT_GET_PRODUCTS_BY_COUPON_SUCCESS} from './types';
import Product from '../../modules/Product';

const actionStarted = () => ({ type: types.PAYMENT_ACTION_STARTED });

const cancelSubscriptionSuccess = (response) => ({
  type: types.PAYMENT_CANCEL_SUBSCRIPTION,
  payload: response,
});

const cancelMasterCourseSubscriptionSuccess = (response) => ({
  type: types.PAYMENT_CANCEL_MASTER_COURSE_SUBSCRIPTION,
  payload: response,
});

const getSubscriptionsSuccess = (response) => ({
  type: types.PAYMENT_GET_DEFAULT_PRODUCTS_SUCCESS,
  payload: response,
});

const getActiveSubscriptionsSuccess = (response) => ({
  type: types.PAYMENT_GET_ACTIVE_SUCCESS,
  payload: response,
});

const getActiveMasterCourseSubscriptionsSuccess = (response) => ({
  type: types.PAYMENT_GET_ACTIVE_MASTER_COURSE_SUCCESS,
  payload: response,
});

const paymentFailure = (response) => ({
  type: types.PAYMENT_REQUEST_FAILED,
  payload: response,
});

const redeemCouponFailed = (response) => ({
  type: types.COUPON_REDEEM_FAILED,
  payload: response,
});

const redeemCouponSuccess = (response) => ({
  type: types.COUPON_REDEEM_SUCCESS,
  payload: response,
});

export const cancelSubscription = (payload: CancelStripeSubscriptionPayload) => async (dispatch: Function) => {
  try {
    const response = await apiService.cancelSubscription(payload);
    dispatch(cancelSubscriptionSuccess(response));
  } catch (error) {
    throw new Error('Error cancelling subscription');
  }
};

export const cancelMasterCourseSubscription = (payload: CancelStripeSubscriptionPayload) => async (dispatch: Function) => {
  try {
    const response = await apiService.cancelSubscription(payload);
    dispatch(cancelMasterCourseSubscriptionSuccess(response));
  } catch (error) {
    throw new Error('Error cancelling subscription');
  }
};

export const getActiveSubscription = () => async (dispatch: Function) => {
  try {
    const subscription = await apiService.fetchActiveSubscription();

    if (subscription && subscription.provider === 'STRIPE') {
      const invoices = await apiService.fetchLastInvoices();

      if (!_isEmpty(invoices)) {
        // Modify subscription object with actual price(i.e discounted price if applicable)
        _set(subscription, 'subscription.plan.amount', invoices[0].total);
      }
    }

    dispatch(getActiveSubscriptionsSuccess(subscription));
  } catch (error) {
    console.warn('Error fetching active subscription for user', error);
    throw new Error('Error fetching active subscription');
  }
};

export const getActiveMasterCourseSubscription = () => async (dispatch: Function) => {
  try {
    const subscription = await apiService.fetchActiveMasterCourseSubscription();

    if (subscription && subscription?.provider === 'STRIPE') {
      const invoices = await apiService.fetchLastInvoices();

      if (!_isEmpty(invoices)) {
        // Modify subscription object with actual price(i.e discounted price if applicable)
        _set(subscription, 'subscription.plan.amount', invoices[0].total);
      }
    }

    dispatch(getActiveMasterCourseSubscriptionsSuccess(subscription));
  } catch (error) {
    console.warn('Error fetching active subscription for user', error);
    throw new Error('Error fetching active subscription');
  }
};

export const fetchProducts = () => async (dispatch: Function) => {
  dispatch(actionStarted());

  try {
    const response = await apiService.fetchDefaultProducts();
    dispatch(getSubscriptionsSuccess(response));
  } catch (error) {
    dispatch(paymentFailure(error));
    throw error;
  }
};

export const fetchProductsByStripeIds = (stripeIds) => async (dispatch: Function) => {
  dispatch(actionStarted());

  try {
    const response = await apiService.fetchProductsByStripeIds(stripeIds);
    dispatch({
      type: GET_PRODUCTS_BY_STRIPE_IDS_SUCCESS,
      payload: response
    })
  } catch (error) {
    dispatch(paymentFailure(error));
    throw error;
  }
};

export const fetchProductsByPromoCode = (code: string) => async (dispatch: Function) => {
  dispatch(actionStarted());

  try {
    const response = await apiService.fetchProductsByPromoCode(code);
    dispatch({
      type: PAYMENT_GET_PRODUCTS_BY_COUPON_SUCCESS,
      payload: response,
    });
  } catch (error) {
    dispatch(paymentFailure(error));
    throw error;
  }
};

export const redeemCoupon = (payload: string) => async (dispatch: Function) => {
  dispatch({ type: COUPON_REDEEM_START });

  try {
    const response = await apiService.validateCoupon(payload);
    const validTo = _get(response, 'validTo');
    const validFrom = _get(response, 'validFrom');

    const isOutOfDateRange = () => {
      if (validTo && moment(validTo).isBefore(moment())) {
        return true;
      }

      return !!(validFrom && moment(validFrom).isAfter(moment()));
    };

    // voucher code is not valid anymore
    if (isOutOfDateRange()) {
      const error = {
        type: 'StripeInvalidRequestError',
        message: 'Coupon outdated',
      };

      dispatch(redeemCouponFailed(error));
      throw error;
    } else {
      dispatch(redeemCouponSuccess(response));
    }
  } catch (error) {
    dispatch(redeemCouponFailed(error));
    throw error;
  }
};

export const resetCoupon = () => (dispatch: Function) => {
  dispatch({
    type: types.COUPON_REDEEM_RESET,
  });
};

export function resetError() {
  return {
    type: types.PAYMENT_ERROR_RESET,
  };
}

export function resetPaymentSuccess() {
  return {
    type: types.PAYMENT_SUCCESS_RESET,
  };
}

export const setSelectedProduct = (payload: Product) => (dispatch: Function) => {
  dispatch({
    type: types.SET_SELECTED_PRODUCT_LANDINGPAGE_ACTION,
    payload,
  });
};

export const clearSelectedProduct = () => (dispatch: Function) => {
  dispatch({
    type: types.CLEAR_SELECTED_PRODUCT_LANDINGPAGE_ACTION,
  });
};
