// @flow

import styled from 'styled-components';
import { CSS_BREAKPOINTS_MIN, WHITE_COLOR } from '../config/constants';

const SYHeadlineFour = styled.h4`
  font-family: Poppins-Medium;
  color: ${WHITE_COLOR};
  font-size: 20px;
  line-height: 1.25;
  letter-spacing: 0.4px;
  text-align: ${(props) => (props.align ? props.align : 'left')};
  
  @media ${CSS_BREAKPOINTS_MIN.sm} {
    font-size: 24px;
    line-height: 1.5;
    letter-spacing: 0.6px;
  }
`;

export default SYHeadlineFour;
