// @flow
import _truncate from 'lodash/truncate';
import _cloneDeep from 'lodash/cloneDeep';
import type { TrackingServiceInterface } from '../types/TrackingServiceInterface';
import type { DataLayer } from '../types/DataLayer';
import type { User } from '../types/User';
import { debugSettings } from '../config/constants';

type VirtualPageViewProps = {
  pageUrl: string,
};

class GoogleTrackingProvider implements TrackingServiceInterface {
  dataLayer: DataLayer = window.dataLayer || [];

  // eslint-disable-next-line no-unused-vars
  setUserContext(user: User) {
    //
  }

  async init() {
    //
  }

  clearUserContext() {
  }

  async trackView(viewName: string, viewParams?: VirtualPageViewProps = {}): Promise<void> {
    this.trackEvent('virtualPageView', {
      ...viewParams,
      pageTitle: viewName,
    });
  }

  async trackEvent(eventName: string, eventParams?: Object = {}): Promise<void> {
    // https://support.google.com/firebase/answer/9237506?hl=en
    const eventNameTruncated = _truncate(eventName, {
      length: 40,
      omission: '',
    });

    // parameters are weirdly be deleted in TrackingService map() function
    const clonedEventParameter = _cloneDeep(eventParams);
    const dataLayerObject = {
      event: eventNameTruncated, // old version
      event_name: eventNameTruncated, // new version
      ...clonedEventParameter,
    };

    if (debugSettings.ENABLE_TRACKING_LOGGER) {
      console.group('GoogleTrackingService.trackEvent');
      console.log(`passedEventName: "${eventName}"`);
      console.log(`eventName: "${eventNameTruncated}"`);
      console.log('event object', dataLayerObject);
      console.groupEnd();
    }

    this.dataLayer.push(dataLayerObject);
  }
}

export const googleTrackingService = new GoogleTrackingProvider();

export default GoogleTrackingProvider;
