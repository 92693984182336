import React from 'react';
import ReactDOM from 'react-dom';
import ReactModal from 'react-modal';
import './font-files.css';

/**
 * Some polyfills we need to ensure support for IE
 */
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import './polyfill-custom';
import 'whatwg-fetch';
import { unregister } from './registerServiceWorker';
import App from  './App.js';

import { PrismicProvider } from "@prismicio/react";
import { client } from "./prismic";

// important for our modal overlays
ReactModal.setAppElement('#root');

if ('scrollRestoration' in window.history) {
  // do not restore the scroll position!
  window.history.scrollRestoration = 'manual';
}

ReactDOM.render(
    <PrismicProvider client={client}>
      <App />,
    </PrismicProvider>,
  document.getElementById("root")
);

unregister();
