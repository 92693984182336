import styled from 'styled-components';
import { useState } from 'react';
import { TitleElement } from '../text/CohortLp/CohortLpTextElements';
import SYPrimaryButton from '../SYPrimaryButton';
import { CLOUDFRONT_STATIC, CSS_BREAKPOINTS_MIN, GUTTER_SIZE } from '../../config/constants';
import AspectRatio from '../AspectRatio';
import VideoJsPlayer from '../VideoJsPlayer';
import {
  CloseIcon, CloseIconWrapper, SYModal, SYModalContent,
} from '../modals/SYModalElements';
import makeImageUrl from '../../modules/makeImageUrl';
import { ReactComponent as PlayMediaSvg } from '../../assets/svg/media-play-button.svg';
import LifetimeSVG from '../../assets/svg/lifetime.svg';

const Container = styled.div`
  padding: ${GUTTER_SIZE * 2}px 0;

  @media ${CSS_BREAKPOINTS_MIN.sm} {
    padding: ${GUTTER_SIZE * 2.5}px 0 ${GUTTER_SIZE * 3.5}px;
  }
`;

const Content = styled.div`
  @media ${CSS_BREAKPOINTS_MIN.sm} {
    display: flex;
    flex-direction: row;
  }
`;

const VideoWrapper = styled.div`
  margin: 0 0 ${GUTTER_SIZE * 2}px;
  cursor: pointer;

  @media ${CSS_BREAKPOINTS_MIN.sm} {
    flex: 0 0 40%;
    margin: 0;
    padding-right: 5%;
  }
`;

const VideoElement = styled(AspectRatio)`
  background: url(${makeImageUrl({ url: `${CLOUDFRONT_STATIC}/img/pages/caleb-lp/caleb-video-thumbnail.jpg` })}) no-repeat center / cover;
`;

const ListWrapper = styled.div``;

const List = styled.ul`
  padding-left: ${GUTTER_SIZE}px;
  list-style: disc;
`;

const ListItem = styled.li`
  font-family: Poppins-Light, sans-serif;
  font-size: 15px;
  line-height: 26px;
  margin: 0 0 ${GUTTER_SIZE}px;

  strong {
    font-weight: 600;
  }

  @media ${CSS_BREAKPOINTS_MIN.sm} {
    font-size: 16px;
    line-height: 22px;
    margin: 0 0 ${GUTTER_SIZE * 2.5}px;
  }
`;

const CTAWrapper = styled.div`
  padding: 0 ${GUTTER_SIZE}px;
`;

const CTA = styled(SYPrimaryButton)`
  width: 100%;

  @media ${CSS_BREAKPOINTS_MIN.sm} {
    width: auto;
    min-width: 320px;
  }
`;

const PreviewVideoModal = styled(SYModal)`
  background: none;
  padding: 0;
`;

const PreviewVideoContainer = styled(SYModalContent)`
  background: none;
  padding: 0;
`;

const CloseIconContainer = styled(CloseIconWrapper)`
  position: absolute;
  top: -50px;
  right: 0;

  @media ${CSS_BREAKPOINTS_MIN.sm} {
    top: -70px
  }
`;

const PlayMediaIcon = styled.svg.attrs({ as: PlayMediaSvg })`
  position: absolute;
  left: 50%;
  top: 50%;
  width: 50px;
  height: 50px;
  margin-right: ${GUTTER_SIZE / 2}px;
  transform: translate(-50%, -50%);

  @media ${CSS_BREAKPOINTS_MIN.sm} {
    width: 60px;
    height: 60px;
  }
`;

const CTAContainer = styled.div`
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 8px;

  @media ${CSS_BREAKPOINTS_MIN.sm} {
    width: auto;
    min-width: 320px;
    display: flex;
  }

  @media ${CSS_BREAKPOINTS_MIN.md} {
    width: auto;
    display: inline-flex;
  }
`;

const LifetimeAccessInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0;
  gap: 8px;
`;

const VIDEO_URL = 'https://d2jyzdmyoz9h0c.cloudfront.net/caleb-training-transcoded/caleb-training-trailer/caleb_teaser_training_page.m3u8';

const CalebTrainWithBest = ({ onCTAClick, ctaTitle = 'ENROLL NOW' }) => {
  const [showVideoModal, setShowVideoModal] = useState(false);

  const onVideoStartClick = () => {
    setShowVideoModal(true);
  };

  const onVideoEndCallback = () => {
    setShowVideoModal(false);
  };

  return (
    <Container>
      <TitleElement>Train with the best</TitleElement>

      <Content>
        <VideoWrapper>
          <VideoElement ratio={1} onClick={onVideoStartClick}>
            <PlayMediaIcon />
          </VideoElement>
        </VideoWrapper>

        <ListWrapper>
          <List>
            <ListItem>
              Practice with Caleb Jude Packham—every session, every week
            </ListItem>
            <ListItem>
              Deep dive into the integrative practice of Sattva Yoga, the most complete yoga practice there is
            </ListItem>
            <ListItem>
              Expert-guided sessions of asana (postures), pranayama (breath-work), meditation and kriya (yoga of electricity)
            </ListItem>
            <ListItem>
              Weekly yoga journeys set to music with Caleb Jude Packham
            </ListItem>
            <ListItem>
              Inspirational talks sharing invaluable yogic wisdom
            </ListItem>
            <ListItem>
              Experience radical transformation and learn how to access an awakened state of living
            </ListItem>
          </List>

          <CTAWrapper>
            <CTAContainer>
              <CTA title={ctaTitle} onClick={onCTAClick} />
              <LifetimeAccessInfo>
                <img src={LifetimeSVG} alt="" />
                <p>Lifetime access for only $40</p>
              </LifetimeAccessInfo>
            </CTAContainer>

          </CTAWrapper>
        </ListWrapper>

        {showVideoModal && (
          <PreviewVideoModal isOpen={showVideoModal} onRequestClose={onVideoEndCallback}>
            <PreviewVideoContainer>
              <CloseIconContainer onClick={onVideoEndCallback}>
                <CloseIcon />
              </CloseIconContainer>
              <VideoJsPlayer videoUrl={VIDEO_URL} onVideoEnd={onVideoEndCallback} />
            </PreviewVideoContainer>
          </PreviewVideoModal>
        )}
      </Content>
    </Container>
  );
};

export default CalebTrainWithBest;
