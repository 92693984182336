// @flow

import React from 'react';
import styled from 'styled-components';
import { CSS_BREAKPOINTS_MIN, SPACING_BASE } from '../config/constants';
import CurrencyIconSvg from '../assets/svg/currency-icon-border-round.svg';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  line-height: 1.5;
  letter-spacing: 0.65px;
  font-family: Poppins;

  strong {
    font-weight: 500;
  }

  img {
    margin-right: ${SPACING_BASE}px;
    width: 20px;
  }

  @media ${CSS_BREAKPOINTS_MIN.sm} {
    margin: 0;
    font-size: 16px;
    letter-spacing: 0.8px;
    box-shadow: none;
    border-bottom: 0;

    img {
      width: auto;
    }
  }
`;

// rest needed for copying styles (CSS classes) over for styled components
const MoneyBackStatement = ({ ...rest }) => (
  <Container {...rest}>
    <img src={CurrencyIconSvg} alt="Currency icon" />

    <strong style={{ marginLeft: 3 }}>
      14-day money-back guarantee
    </strong>
  </Container>
);

export default MoneyBackStatement;
