import React from 'react';
import styled from 'styled-components';
import hexToRgba from 'hex-to-rgba';
import { GREY_01_COLOR, PRIMARY_COLOR } from '../config/constants';

const DIMENSION = 50;
const STROKE_WIDTH = 2;

const LoadingChild = styled.div`
  position: absolute;
  width: ${(props) => (props.dimension ? `${props.dimension}px` : `${DIMENSION}px`)};
  height: ${(props) => (props.dimension ? `${props.dimension}px` : `${DIMENSION}px`)};
  border-radius: 50%;
  background-color: transparent;
  border: ${(props) => props.width || STROKE_WIDTH}px solid transparent;
  border-top: ${(props) => props.width || STROKE_WIDTH}px solid ${hexToRgba(GREY_01_COLOR, 0.5)};
  border-left: ${(props) => props.width || STROKE_WIDTH}px solid ${hexToRgba(GREY_01_COLOR, 0.5)};
  animation: 1.8s spin linear infinite;
  
  &:last-child {
    border: ${(props) => props.width || STROKE_WIDTH}px solid transparent;
    border-right: ${(props) => props.width || STROKE_WIDTH}px solid ${PRIMARY_COLOR};
    border-bottom: ${(props) => props.width || STROKE_WIDTH}px solid ${PRIMARY_COLOR};
    animation: 0.9s spin linear infinite;
  }
  
  @keyframes spin {
    from {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`;

const LoadingWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: ${(props) => (props.inline ? 'auto' : '40vh')};
`;


const LoadingAnimation = (props) => {
  const {
    dimension,
    width,
    ...rest
  } = props;

  return (
    <LoadingWrapper {...rest}>
      <LoadingChild dimension={dimension} width={width} />
      <LoadingChild dimension={dimension} width={width} />
    </LoadingWrapper>
  );
};

export default LoadingAnimation;
