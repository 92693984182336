// @flow
import React from 'react';
import styled, { StyledFunction } from 'styled-components';

const OuterWrapper: StyledFunction<{ratio: number}> = styled.div`
  position: relative;
  width: 100%;
  height: 0;
  /**
   * For human readability, the ratio is expressed as
   * width / height, so we need to invert it.
   */
  padding-bottom: ${(props) => (1 / props.ratio) * 100}%;

  ${(props) => props.stopPoint && `
    @media ${props.stopPoint} {
      position: inherit;
      width: auto;
      height: auto;
      padding: 0;
    }
  `}
`;

const InnerWrapper: StyledFunction<{}> = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  ${(props) => props.stopPoint && `
    @media ${props.stopPoint} {
      position: static;
      top: auto;
      right: auto;
      bottom: auto;
      left: auto;
    }
  `}
`;

/**
 * The width divided by the height. This ratio can be passed in
 * using JavaScript division syntax. So, to get a 16:9 ratio,
 * simply pass `ratio={16/9}`.
 */

type ElementProps = {
  className?: ?string,
  children?: any,
  ratio: number,
  stopPoint?: ?string,
};

// the stopPoint is the min-width where the settings are reset
const AspectRatio = ({
  className, children, ratio, stopPoint, ...rest
}: ElementProps) => (
  <OuterWrapper {...rest} className={className} ratio={ratio} stopPoint={stopPoint}>
    <InnerWrapper stopPoint={stopPoint}>
      {children}
    </InnerWrapper>
  </OuterWrapper>
);

AspectRatio.defaultProps = {
  children: null,
  className: null,
  stopPoint: null,
};

export default AspectRatio;
