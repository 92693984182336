/* eslint max-len: 0 */
// @flow
import React from 'react';
import SYHeadlineOne from '../components/SYHeadlineOne';
import SYBody18pt from '../components/SYBody18pt';
import LegalPage from './LegalPage';
import SYHeadlineTwo from '../components/SYHeadlineTwo';
import SYHeadlineThree from '../components/SYHeadlineThree';
import SYHeadlineFour from '../components/SYHeadlineFour';
import SYUnorderedList from '../components/SYUnorderedList';
import SYBodyTextLink from '../components/SYBodyTextLink';

class PrivacyPolicy extends React.PureComponent<{}> {
  render() {
    return (
      <LegalPage bodyClass="privacy">
        <SYHeadlineOne>Skill Yoga Privacy Policy</SYHeadlineOne>

        <SYHeadlineTwo>PRIVACY POLICY</SYHeadlineTwo>
        <SYBody18pt>We, Skill Yoga GmbH, Planufer 93A, 10967 Berlin, are committed to protecting privacy of users of the Skill Yoga website, Skill Yoga application(s), and/or other related services (hereinafter collectively the “Skill Yoga Services” or the “Services”).</SYBody18pt>
        <SYBody18pt>For the purposes of this Privacy Policy, Skill Yoga GmbH is the “data controller” for all Skill Yoga Services.</SYBody18pt>
        <SYBody18pt>This Privacy Policy describes the types of personal and non-personal data we collect and how we use such data. This Privacy Policy is part of our General Terms and Conditions and applies to all Skill Yoga Services. Therefore, please make sure that you read and understand our Privacy Policy, as well as the General Terms and Conditions.</SYBody18pt>
        <SYBody18pt>This Privacy Policy does not apply to any third-party websites, services or applications, even if they may be accessible through the Skill Yoga Services.</SYBody18pt>
        <SYBody18pt>By using any of the Skill Yoga Services, you hereby warrant and represent that (i) you have read, understand and agree to this Privacy Policy, (ii) you are over 16 years of age (or are a parent or guardian with such authority to agree to this Privacy Policy for the benefit of an individual who is under 16 years of age). If you do not accept the terms set forth in this Privacy Policy and the consents associated therewith, please do not use our Services.</SYBody18pt>
        <SYBody18pt>Last revised: August 2019</SYBody18pt>

        <SYHeadlineThree>1. General provisions</SYHeadlineThree>
        <SYBody18pt>To be able to use our services, you must register. Already during the registration process you will provide certain personal data to us. How we use such data and what rights you have in connection therewith is explained in this Data Privacy Policy.</SYBody18pt>
        <SYBody18pt>You will also transfer certain information to us when you access our website e.g. your IP address. We will also receive data about which terminal device (computer, smartphone, tablet, etc.) you are using, which browser (Internet Explorer, Safari, Firefox, etc.) you are using, the time at which you access the website, the so-called referrer, and the data volume transferred.</SYBody18pt>
        <SYBody18pt>Such data cannot be used by us to identify the user. They are used for statistical purposes only. Such analyses help us make our services more attractive and, if necessary, to improve our services.</SYBody18pt>

        <SYHeadlineThree>2. What are personal data?</SYHeadlineThree>
        <SYBody18pt>The term “personal data” is defined by the Federal Data Protection Act (BDSG) and, after its effective date, the European General Data Protection Regulation (GDPR). You can think of your personal data as any data that allow you to be identified or that can be correlated to you. Such data include, for example, your name and contact information. We need some of this information so that we can offer you our Skill Yoga Services.</SYBody18pt>
        <SYBody18pt>On the other hand, “non-personal” data cannot be correlated to any specific user. By removing identifiable parts from and anonymizing personal data, personal data can be converted into “non-personal data.”</SYBody18pt>

        <SYHeadlineThree>3. How are personal data collected and processed?</SYHeadlineThree>
        <SYBody18pt>Personal data is collected by us if you provide such data to us on your own initiative. This happens, for example, when you contact us or other members, i.e., when you open your Skill Yoga user account or sign up for a subscription. We will use personal data you have provided to us only if and to the extent necessary for providing our services and handling of the contract or if you have consented that we may use your data for the purposes described in this Data Privacy Policy.</SYBody18pt>
        <SYBody18pt>Your personal data will be stored for as long as this is necessary for achieving the defined purposes. If you cancel your user account, we will also erase your personal data. An exception applies only if we have a legal obligation to archive data for a certain time period.</SYBody18pt>

        <SYHeadlineThree>4. What are log files?</SYHeadlineThree>
        <SYBody18pt>A log file automatically logs all or defined actions on a computer system. Such log files are important, for example, for process control and automation. In the case of databases a log file tracks changes to the database of correctly executed transactions. In the event of an error (e.g., a system crash), this allows the current dataset to be restored. Log files are also created by web servers. Inter alia, the following data are logged: the address of the accessing computer, authentication fields, date and time of access, access method, content of HTML access, status code of the web server, and information about the browser and operating system used by the client.</SYBody18pt>
        <SYBody18pt>Every time you access our website the aforementioned data will be automatically stored in log files.</SYBody18pt>

        <SYHeadlineThree>5. What are cookies and tracking pixels?</SYHeadlineThree>
        <SYBody18pt>We collect information about visitors to our website in order to improve our services. For this purpose we use different kinds of so-called cookies and tracking pixels.</SYBody18pt>
        <SYBody18pt>A cookie allows a web server to place a text file (e.g., a clear ID) on your computer or smart phone/tablet. Cookies are used, for example, to automatically recognize you the next time you visit our websites. The cookie is sent either by the web server to your browser or is generated by client-side scripting (e.g., JavaScript). Cookie data will be stored locally on your terminal device and in most cases will be effective only for a limited time period.</SYBody18pt>
        <SYBody18pt>Websites that include flash media write user-specific data to your computer and later read such data. Such files are called flash cookies or local shared objects (LSO). Such files are not managed by your browser, but rather by the flash player plug-in. Flash cookies are subject to the same rules as conventional cookies. Flash cookies, too, can only be read by the website that caused those flash cookies to be placed. However, flash cookies can store a substantially greater volume of data.</SYBody18pt>
        <SYBody18pt>Your browser offers extensive setting options to manage cookies. For example, you can deactivate cookies in your browser or limit cookies to certain websites. You can also program your browser to first notify you before a cookie is placed. You can also choose these settings on your mobile terminal devices. You can at any time manage cookies by changing the settings of your devices, delete cookies, or block cookies altogether.</SYBody18pt>
        <SYBody18pt>You can also visit our website even if you block cookies on your terminal device. If you block cookies, the display of our website may however be impaired and not all functions may be available to you.</SYBody18pt>
        <SYBody18pt>Tracking pixels are small graphics in HTML e-mails or on websites. When you access such a website, your access to the tracking pixel will be recorded in a log file. This allows statistical analyses which, in turn, can be used to improve our services. You can set your e-mail program or your browser so that HTML e-mails will be displayed as text only, thereby preventing the use of some tracking pixels.</SYBody18pt>

        <SYHeadlineThree>6. Which types of data do we collect, process, and use?</SYHeadlineThree>
        <SYBody18pt>We collect three types of data:</SYBody18pt>
        <SYUnorderedList>
          <li>data you provide to us voluntarily,</li>
          <li>data we receive when you use our services, and</li>
          <li>data we receive from third parties.</li>
        </SYUnorderedList>
        <SYBody18pt>In other words, we process and use data (including personal data) you make available to us voluntarily on the website in various situations (e.g., when you send us an e-mail). We also use data that are collected automatically on our website. Finally, we may also receive data about you from third parties, for example when another user provides us with information about you.</SYBody18pt>
        <SYBody18pt>It is therefore impossible to provide you with an exhaustive list of all possible types of data that we may possibly collect, process, and use. Typically, we collect, process and use the following data:</SYBody18pt>
        <SYUnorderedList>
          <li>your name and address;</li>
          <li>your personal contact information (e-mail</li>
          <li>your username and password;</li>
          <li>your user profile data (e.g., workout schedule);</li>
          <li>your IP address, operating system, browser type, browser version, browser configuration, name of Internet provider, and any other relevant information regarding your computer and Internet connection in order to identify the type of your device, to connect you to the website, to exchange data with your (mobile) terminal device, or to ensure proper use of the website;</li>
          <li>the URL and IP address of the website from which you access our website or from which you are transferred to our website, including date and time;</li>
          <li>any pages of our website on which you click during your visit, and any links on our website on which you click, including date and time;</li>
          <li>the entire Uniform Resource Locator (URL) clickstream regarding, through, and from the website, including date and time;</li>
          <li>your service inquiries and your orders;</li>
          <li>your transaction history, including open and completed transactions;</li>
          <li>search terms you input;</li>
          <li>information regarding your orders and payments;</li>
          <li>information collected by cookies or similar technologies (as explained below);</li>
          <li>your survey answers, critiques, evaluations, or other responses;</li>
          <li>the content of all messages sent through the website, including information uploaded to social networks through the website or otherwise shared with us and/or other users, as well as chat messages and chat logs;</li>
          <li>your newsletter subscriptions;</li>
          <li>any consents you have given us;</li>
          <li>any other information input or uploaded by you through the website e.g., information you provide when completing an online form, photos you upload); or</li>
          <li>data we receive when you log in through social media (e.g., if you log in with Facebook).</li>
        </SYUnorderedList>

        <SYHeadlineThree>7. Data storage</SYHeadlineThree>

        <SYHeadlineThree>7.1. Where is the data stored?</SYHeadlineThree>
        <SYBody18pt>We use cloud services. This means we will transfer your data to a third party – the cloud services provider – and store data on the servers of that provider. In some cases, your data may also be stored on servers outside the European Union (EU) or European Economic Area (EEA). In some cases, your data may also be processed there. We either ensure through appropriate contracts that such service providers guarantee the same level of data privacy to which you are also entitled in the European Union or we use only providers that are EU-US Privacy Shield certified (https://www.privacyshield.gov/welcome). Either alternative ensures an appropriate level data privacy.</SYBody18pt>

        <SYHeadlineThree>7.2. Duration of storage; retention periods</SYHeadlineThree>
        <SYBody18pt>We will store your data for as long as is necessary to provide our website and the associated services or as long as we have a legitimate interest in further storage. In all other cases, we will erase your personal data with the exception of data that we are required to maintain in order to comply with contractual or legal (e.g. under tax or commercial law) retention periods (e.g. invoices). Contractual retention periods may also result from contracts with third parties (e.g. holders of copyrights and ancillary copyrights).</SYBody18pt>
        <SYBody18pt>We will block any data that is subject to a retention period until the end of that period.</SYBody18pt>

        <SYHeadlineThree>8. For what purposes are data used?</SYHeadlineThree>
        <SYBody18pt>We will collect, process, and use your personal data and other data in particular for the following purposes:</SYBody18pt>
        <SYUnorderedList>
          <li>to administrate, operate, maintain, and improve the website;</li>
          <li>to allow and process orders for services placed by you through the website;</li>
          <li>to customize your experience of our website (e.g., by tailoring content and offers to your personal preferences);</li>
          <li>to assess your right to receive certain types of offers or services;</li>
          <li>to support the improvement and customization of the Skill Yoga Services;</li>
          <li>to analyze and research customer behavior;</li>
          <li>to respond to your questions and inquiries;</li>
          <li>to provide you with information about services that may be of interest to you;</li>
          <li>to communicate with you about certain concerns;</li>
          <li>to manage awards, surveys, winning games, lotteries, or other promotional activities or events;</li>
          <li>to comply with our legal obligations to prevent any unlawful use of the website, to settle disputes, and to enforce our contracts;</li>
          <li>for any other purposes to which you have consented in a particular case, or</li>
          <li>otherwise as permitted by applicable law.</li>
        </SYUnorderedList>
        <SYBody18pt>If required by law, we will ask for your consent before collecting, processing, or using your personal data for any of the aforementioned purposes.</SYBody18pt>
        <SYBody18pt>We will also notify you if we want to use your personal data for a new or different purpose. We will use your personal data for such other purposes only if and to the extent necessary or permitted by applicable law or with your consent.</SYBody18pt>

        <SYHeadlineThree>9. Registration of Skill Yoga user account</SYHeadlineThree>
        <SYBody18pt>You may create an Skill Yoga user account through our login system. Following registration, you will be able to use your user account to subscribe to all Skill Yoga Services. To register, you must provide us with at least the following information:</SYBody18pt>
        <SYUnorderedList>
          <li>name,</li>
          <li>e-mail address, and</li>
          <li>password</li>
        </SYUnorderedList>
        <SYBody18pt>Before completing the registration process, you must confirm that you have read our Data Privacy Policy and accept our General Terms and Conditions.</SYBody18pt>
        <SYBody18pt>You can also link your Skill Yoga user account to your Facebook profile. To do so, simply choose “Login with Facebook” during the registration process; you will then be transferred to Facebook. There, you will be shown to which Facebook data we will receive access. We will store your Facebook e-mail address. This is the e-mail address we will use to contact you, if necessary. We will also record the fact that you have registered through Facebook.</SYBody18pt>

        <SYHeadlineThree>10. How to contact us</SYHeadlineThree>
        <SYBody18pt>You can contact us by sending us messages at our e-mail address.</SYBody18pt>
        <SYBody18pt>If you transmit personal data to us by e-mail, we will use such data only for the purpose for which you entrust us with such data when you contact us.</SYBody18pt>
        <SYBody18pt>The same applies when you communicate with us by using a contact form.</SYBody18pt>

        <SYHeadlineThree>11. Newsletter</SYHeadlineThree>
        <SYBody18pt>You can register for our newsletter. That way, you will receive regular updates about the Skill Yoga Services. All you need to receive our newsletter is a valid e-mail address. If you are no longer interested in receiving the newsletter, you may unsubscribe at any time using the link that is included in each newsletter.</SYBody18pt>

        <SYHeadlineThree>12. Cookies and tracking pixels</SYHeadlineThree>
        <SYBody18pt>We use our own cookies and tracking pixels. As explained above, this will however allow no inferences as to your identity.</SYBody18pt>

        <SYHeadlineThree>13. Data processing – third-party services and partners</SYHeadlineThree>
        <SYBody18pt>To be able to offer you all functions and services in the most convenient way possible and to be able to continuously improve our product, we use third-party services and partners. We also use the assistance of third parties to improve our website. Finally, we use certain tools for our marketing. What tools we use and for what purposes is explained below:</SYBody18pt>

        <SYHeadlineThree>13.1. Google</SYHeadlineThree>
        <SYBody18pt>
          We use a number of different Google services (Google Inc., 1600 Amphitheater Pkwy., Mountain View, CA 94043, U.S.A. – hereinafter “Google”) for analysis and marketing purposes. These tools collect and statistically analyze data about your use of our services in different ways. We also use your data to show you personalized ads with the help of Google services. By using our website or our Skill Yoga Services, you consent that we may use your data for these purposes. In what follows, we will explain the different services and the ways in which you can to conveniently revoke your consent, and we  provide you with additional important information. Additional information about how Google handles data transmitted by us is available here:
          {' '}
          <SYBodyTextLink href="//www.google.com/intl/de/policies/privacy/partners/" title="Google Privacy Partners" target="_blank" rel="noreferrer">https://www.google.com/intl/de/policies/privacy/partners</SYBodyTextLink>
        </SYBody18pt>
        <SYBody18pt>You can find additional information about how Google uses cookies in the data privacy policy of Google (https://www.google.com/intl/de/policies/privacy/).</SYBody18pt>
        <SYBody18pt>Information generated by Google tools is generally transferred to a server of Google in the United States and stored there. Google and its subsidiaries are EU-US Privacy-Shield certified.</SYBody18pt>

        <SYHeadlineFour>13.1.1. Google Analytics</SYHeadlineFour>
        <SYBody18pt>Our website uses Google Analytics, a web analysis program of Google. Google Analytics uses cookies that are stored on your terminal device and allow an analysis of your use. We activate IP address anonymization so that IP addresses will first be truncated by Google within the European Union. On our behalf Google uses such information to analyze your use of our services as well as the use of our services by other users, and provides us with reports and other services. The IP address transmitted from your terminal device to Google Analytics will not be merged with any other data of Google. Google will transfer your data to third parties only if permitted by applicable law or in accordance with outsourced data processing agreements.</SYBody18pt>
        <SYBody18pt>
          You can prevent the collection and processing of information generated by the Google cookie by placing an opt-out cookie or deactivating Google Analytics in the menu of your terminal device. In the alternative, you can also install a browser plug-in, which you will find here
          {' '}
          <SYBodyTextLink href="//tools.google.com/dlpage/gaoptout" title="Google Analytics" target="_blank" rel="noreferrer">https://tools.google.com/dlpage/gaoptout</SYBodyTextLink>
        </SYBody18pt>

        <SYHeadlineFour>13.1.2. Google AdWords</SYHeadlineFour>
        <SYBody18pt>
          We use Google AdWords to advertise our offers. Following a search on Google, our ads will be shown in the areas designated for this purpose. Our website registers with the help of cookies how many users have found our services through our ads. We use such data to optimize our ads. A cookie is stored by Google when an ad is clicked. You can block the cookie by selecting the appropriate settings in your browser. In that case, your visit to our website will not be included in anonymous user statistics. If you wish to receive no personalized ads, you can place an opt-out cookie:
        </SYBody18pt>
        {' '}
        <SYBodyTextLink href="//www.google.de/settings/ads/onweb#display_optout" title="Google AdWords" target="blank">https://www.google.de/settings/ads/onweb#display_optout</SYBodyTextLink>

        <SYBody18pt>
          You can block cookies, for example, by installing an appropriate browser plug-in, which is available here:
        </SYBody18pt>
        {' '}
        <SYBodyTextLink href="//support.google.com/ads/answer/7395996?hl=de" title="Google support blocking Cookies" target="blank">https://support.google.com/ads/answer/7395996?hl=de</SYBodyTextLink>

        <SYHeadlineFour>13.1.3. Google Dynamic Remarketing</SYHeadlineFour>
        <SYBody18pt>In addition, we use Google Dynamic Remarketing functions on our website. This technology allows us to show automatically generated, target group-based ads following your visit to our website. The ads shown are based on products and services on which you clicked or which you viewed during your last visit to our website. Google uses cookies to generate interest-based ads. If you wish to receive no user-based ads from Google, you can deactivate ads by selecting the appropriate settings of Google.</SYBody18pt>
        <SYBody18pt>If you wish to receive no personalized ads, you can place an opt out cookie:</SYBody18pt>
        {' '}
        <SYBodyTextLink href="www.google.de/settings/ads/onweb#display_optout" title="More infos about this topic" target="blank">https://www.google.de/settings/ads/onweb#display_optout</SYBodyTextLink>
        <SYBody18pt>You can block personalized ads by installing the appropriate browser plug-in, which is available here:</SYBody18pt>
        {' '}
        <SYBodyTextLink href="//support.google.com/ads/answer/7395996?hl=de" title="More infos about this topic" target="blank">https://support.google.com/ads/answer/7395996?hl=de</SYBodyTextLink>
        <SYBody18pt>You can also block personalized ads from Google and other advertising networks by opting out on the following page:</SYBody18pt>
        {' '}
        <SYBodyTextLink href="//www.youronlinechoices.com/de/praferenzmanagement/" title="More infos about this topic" target="blank">https://www.youronlinechoices.com/de/praferenzmanagement/</SYBodyTextLink>

        <SYHeadlineFour>13.1.3. Firebase</SYHeadlineFour>
        <SYBody18pt>Firebase is a Google subsidiary with its registered office in San Francisco, CA, U.S.A. We use Firebase SDK and Google Analytics for Firebase for our Skill Yoga application(s). This tool allows us to use the same Google Analytics functions for an application that can also be used for websites. Firebase uses technologies that work similar to cookies, in particular advertising IDs. This way, we collect information about how you use the Skill Yoga application(s). We use such data for statistical analysis purposes, to test our offers (e.g., A/B testing), and to improve our offers. In addition, we use such information for personalized advertising. By using our Services you consent to our collection of such data. In addition, we use Firebase Remote Config to make changes to our Skill Yoga application(s). How you can prevent such data from being collected on your Android device is explained, for example, here:</SYBody18pt>
        {' '}
        <SYBodyTextLink href="//www.google.com/policies/technologies/ads/" title="More infos about this topic" target="blank">https://www.google.com/policies/technologies/ads/</SYBodyTextLink>
        <SYBody18pt>On your iOS device you will find the appropriate setting under Settings &gt; Data Privacy &gt; Advertising.</SYBody18pt>

        <SYHeadlineThree>13.2. Social plug-ins</SYHeadlineThree>
        <SYBody18pt>We use the following social plug-in for our website: Facebook (operator: Facebook Inc., 1601 S. California Ave, Palo Alto, CA 94304, U.S.A.)</SYBody18pt>
        <SYBody18pt>This plug-in routinely collects data from you and transfer such data to servers of the provider. Once activated, the plug-in will also record your IP address. In addition, activated social plug-ins will place a cookie with a clear ID when the relevant website is accessed. This also allows providers to create profiles of your user behavior. Such a cookie is placed whether or not you are a member of the social network. If you are a member of a social network and are logged in when you visit our website, data and information about your visit to our website may be linked to your profile on the social network. Please note that we have no control over the exact extent to which your data will be collected by social network providers. For more information about the extent, type, and purpose of data processing and about rights and settings to protect your privacy, please refer to the data privacy policy of the relevant social network provider. These are available at the following addresses:</SYBody18pt>

        <SYHeadlineThree>
          13.3. Facebook:
          {' '}
          <SYBodyTextLink href="//www.facebook.com/policy.php" title="Facebook Policy" target="_blank" rel="noreferrer">http://www.facebook.com/policy.php</SYBodyTextLink>
        </SYHeadlineThree>

        <SYHeadlineThree>13.3.1. Facebook Connect</SYHeadlineThree>
        <SYBody18pt>We use the “Facebook Connect” function, so that you can register and log in with us using your Facebook account.</SYBody18pt>
        <SYBody18pt>If you use Facebook Connect, Facebook profile data and public data from your Facebook profile will be transferred to us. Conversely, data may be transferred from us to your Facebook profile. Such data are used by us to register you on our website or to allow you to log in. For this purpose we also store and process such data.</SYBody18pt>
        <SYBody18pt>By registering on our website with the help of Facebook Connect you consent that your profile data from your Facebook profile may be transferred to us and, conversely, that we may transfer data to Facebook.</SYBody18pt>
        <SYBody18pt>Please also note that Facebook receives information through Facebook Connect about how you use our website.</SYBody18pt>
        <SYBody18pt>For information about the purpose and extent of data processing, the further processing and use of data by Facebook, and your rights and setting options to protect your privacy, please refer to the data privacy policy of Facebook: http://www.facebook.com/policy.php.</SYBody18pt>

        <SYHeadlineThree>13.3.2. Facebook Custom Audiences</SYHeadlineThree>
        <SYBody18pt>We use the remarketing function “Custom Audiences” of Facebook Inc. (1601 Willow Road, Menlo Park, California 94025). This function allows us to show visitors of our website interest-based ads when they visit Facebook (“Facebook ads”), and to analyze such Facebook ads for statistical and market research purposes, which helps us optimize future advertising. For this purpose we use the so-called Facebook pixel  on our website.</SYBody18pt>
        <SYBody18pt>Collected data are anonymous for us and allow no inferences as to your identity. Aside from us, Facebook however also stores and processes such data. Facebook may correlate such data to your user profile, and Facebook may also use such data for its own advertising purposes in accordance with the Facebook data privacy policy:</SYBody18pt>
        {' '}
        <SYBodyTextLink href="//www.facebook.com/about/privacy/" title="More infos about this topic" target="blank">https://www.facebook.com/about/privacy/</SYBodyTextLink>
        <SYBody18pt>Such data may allow Facebook and its partners to show ads on or off Facebook.</SYBody18pt>
        <SYBody18pt>Facebook generally also stores such data on servers in the United States. Facebook is EU-US Privacy Shield certified.</SYBody18pt>
        <SYBody18pt>You can generally block the placement of cookies on your computer by selecting the appropriate settings in your browser, so that cookies will no longer be placed on your computer in the future and/or so that cookies that have already been placed will be deleted. If you do so, you may no longer be able to correctly use some functions on our website.</SYBody18pt>
        <SYBody18pt>With the help of an appropriate opt-out cookie from Digital Advertising Alliance you can also completely deactivate advertising by third-party providers such as Facebook:</SYBody18pt>
        {' '}
        <SYBodyTextLink href="//www.aboutads.info/choices/" title="More infos about this topic" target="blank">https://www.aboutads.info/choices/</SYBodyTextLink>
        <SYBody18pt>In the alternative, you can deactivate the remarketing function “Custom Audiences” in your Facebook settings:</SYBody18pt>
        {' '}
        <SYBodyTextLink href="//www.facebook.com/settings/?tab=ads#_=_" title="More infos about this topic" target="blank">https://www.facebook.com/settings/?tab=ads#_=_</SYBodyTextLink>
        <SYBody18pt>To do so, you must be registered with Facebook.</SYBody18pt>

        <SYHeadlineThree>13.4. Apple</SYHeadlineThree>
        <SYBody18pt>We use  Apple services for analysis and marketing purposes like the App Store Connect app. These tools collect and statistically analyze data about your use of our services in different ways. We also use your data to show you personalized ads with the help of Apple Search Ads. By using the Skill Yoga iOS App, you consent that we may use your data for these purposes.</SYBody18pt>

        <SYHeadlineThree>13.4.1 Apple HealthKit</SYHeadlineThree>
        <SYBody18pt>You can explicitly choose to share your training data from the iOS mobile App to the Apple HealthKit. This data is only used to provide health, motion or fitness services in connection with the App; HealthKit data will not be used for marketing, advertising or use-based data mining, including by third parties.</SYBody18pt>

        <SYHeadlineThree>14. Transfer of data to third parties</SYHeadlineThree>
        <SYBody18pt>Your personal data will be transferred to third parties only if we have a legal obligation to do so, if the data transfer is necessary for performance of the contract, or if you have consented to the transfer of your data. Third-party service providers and partner companies will receive your data only if and to the extent necessary for performance of the contract or with your consent. In such cases, the extent to which data are transferred will however be kept to the absolute minimum. To the extent that our service providers come into contact with your personal data, we will make sure that they too will comply with all applicable data protection laws. Please also read the data privacy policies of such third-party providers.</SYBody18pt>

        <SYHeadlineThree>15. Data security</SYHeadlineThree>
        <SYBody18pt>Data transfers are generally subject to security gaps. It is technically impossible to protect your data 100% from access by third parties. However, we strive to minimize this risk as much as possible. We therefore maintain state-of-the-art measures to guarantee data security and to protect your data from access by third parties. In addition, we use strong SSL or TLS encryption for all data transfers. However, please make sure not to provide your login data to any third parties.</SYBody18pt>

        <SYHeadlineThree>16. Websites of third parties</SYHeadlineThree>
        <SYBody18pt>We occasionally place links to websites of third parties. Although we carefully select such third parties, we make no guarantee and assume no liability for the correctness or completeness of content or data security of any third-party websites. Nor does this Data Privacy Policy apply to linked third-party websites. We assume no responsibility for data privacy policies or content of any other websites.</SYBody18pt>

        <SYHeadlineThree>17. Changes to this Data Privacy Policy</SYHeadlineThree>
        <SYBody18pt>We may need to make changes to this Data Privacy Policy, for example if we add new functions or services. We will however notify you of any changes and ask that you read and accept such changes before they are implemented by us.</SYBody18pt>

        <SYHeadlineThree>18. Your rights: information/revocation/erasure and data controller</SYHeadlineThree>
        <SYBody18pt>You may at any time and at no cost demand information about your personal data that are processed by us, correction of any errors in your personal data, termination of processing of your personal data, or erasure of your personal data – subject to mandatory legal provisions or obligations to the contrary. To exercise these rights, simply contact us at any time by letter or e-mail at:</SYBody18pt>
        <SYBody18pt>
          Skill Yoga GmbH
          <br />
          Planufer 93A
          <br />
          Berlin 10967
          <br />
          Germany
          <br />
          Contact: https://skillyoga.atlassian.net/servicedesk/customer/portals
          <br />
          Managing directors: Gerjet Efken, Benno Mielke, Timko Linssen
          <br />
          Recorded in the Commercial Register at the Local Court of Berlin Charlottenburg under number HRB 196904 B
          <br />
          VAT ID: DE319610005
        </SYBody18pt>
      </LegalPage>
    );
  }
}

export default PrivacyPolicy;
