import * as Amplify from 'aws-amplify';
import envUtils from '../utils/env.utils';

const getConfiguration = (env = 'test') => {
  const region = 'eu-central-1';
  const authenticationFlowType = 'USER_PASSWORD_AUTH';

  return {
    test: {
      Auth: {
        authenticationFlowType,
        region,
        userPoolId: 'eu-central-1_OEL97pMFS',
        userPoolWebClientId: '5mobv3qn4ns9ntat83i9ossl3a',
      },

      API: {
        endpoints: [{
          name: 'APIGateway',
          // endpoint: 'http://localhost:3000/test',
          endpoint: 'https://uuk6c3jpa8.execute-api.eu-central-1.amazonaws.com/test',
        }],
      },
    },

    production: {
      Auth: {
        authenticationFlowType,
        region,
        userPoolId: 'eu-central-1_wbzUYtyg5',
        userPoolWebClientId: '1kcdr0akt2k33ag4unqbsst5hb',
      },

      API: {
        endpoints: [{
          name: 'APIGateway',
          endpoint: 'https://zbrejio597.execute-api.eu-central-1.amazonaws.com/production',
        }],
      },
    },
  }[env];
};

// const env = 'test';
const env = envUtils.isLocalOrTestEnvironment() ? 'test' : 'production';

Amplify.default.configure(getConfiguration(env));
Amplify.API.configure(getConfiguration(env));

export const {
  Auth, API,
} = Amplify;
