import styled from 'styled-components';
import { useState } from 'react';
import { TitleElement } from './text/CohortLp/CohortLpTextElements';
import SYPrimaryButton from './SYPrimaryButton';
import { CLOUDFRONT_STATIC, CSS_BREAKPOINTS_MIN, GUTTER_SIZE } from '../config/constants';
import AspectRatio from './AspectRatio';
import VideoJsPlayer from './VideoJsPlayer';
import {
  CloseIcon, CloseIconWrapper, SYModal, SYModalContent,
} from './modals/SYModalElements';
import makeImageUrl from '../modules/makeImageUrl';
import { ReactComponent as PlayMediaSvg } from '../assets/svg/media-play-button.svg';

const Container = styled.div`
  padding: ${GUTTER_SIZE * 2}px 0;

  @media ${CSS_BREAKPOINTS_MIN.sm} {
    padding: ${GUTTER_SIZE * 2.5}px 0 ${GUTTER_SIZE * 3.5}px;
  }
`;

const Content = styled.div`
  @media ${CSS_BREAKPOINTS_MIN.sm} {
    display: flex;
    flex-direction: row;
  }
`;

const VideoWrapper = styled.div`
  margin: 0 0 ${GUTTER_SIZE * 2}px;
  cursor: pointer;

  @media ${CSS_BREAKPOINTS_MIN.sm} {
    flex: 0 0 40%;
    margin: 0;
    padding-right: 5%;
  }
`;

const VideoElement = styled(AspectRatio)`
  background: url(${makeImageUrl({ url: `${CLOUDFRONT_STATIC}/img/pages/cohort-lp/trailer-thumbnail-min.jpg` })}) no-repeat center / cover;
`;

const ListWrapper = styled.div``;

const List = styled.ul`
  padding-left: ${GUTTER_SIZE}px;
  list-style: disc;
`;

const ListItem = styled.li`
  font-family: Poppins-Light, sans-serif;
  font-size: 15px;
  line-height: 26px;
  margin: 0 0 ${GUTTER_SIZE}px;

  strong {
    font-weight: 600;
  }

  @media ${CSS_BREAKPOINTS_MIN.sm} {
    font-size: 16px;
    line-height: 22px;
    margin: 0 0 ${GUTTER_SIZE * 2.5}px;
  }
`;

const CTAWrapper = styled.div`
  padding: 0 ${GUTTER_SIZE}px;
`;

const CTA = styled(SYPrimaryButton)`
  width: 100%;

  @media ${CSS_BREAKPOINTS_MIN.sm} {
    width: auto;
    min-width: 320px;
  }
`;

const PreviewVideoModal = styled(SYModal)`
  background: none;
  padding: 0;
`;

const PreviewVideoContainer = styled(SYModalContent)`
  background: none;
  padding: 0;
`;

const CloseIconContainer = styled(CloseIconWrapper)`
  position: absolute;
  top: -50px;
  right: 0;

  @media ${CSS_BREAKPOINTS_MIN.sm} {
    top: -70px
  }
`;

const PlayMediaIcon = styled.svg.attrs({ as: PlayMediaSvg })`
  position: absolute;
  left: 50%;
  top: 50%;
  width: 50px;
  height: 50px;
  margin-right: ${GUTTER_SIZE / 2}px;
  transform: translate(-50%, -50%);

  @media ${CSS_BREAKPOINTS_MIN.sm} {
    width: 60px;
    height: 60px;
  }
`;

const VIDEO_URL = 'https://d2jyzdmyoz9h0c.cloudfront.net/cohort-training-transcoded/cohort-training-trailer/cohort-training-trailer.m3u8';

const Cohort4WeeksProgram = ({ onCTAClick, ctaTitle = 'ENROLL NOW' }) => {
  const [showVideoModal, setShowVideoModal] = useState(false);

  const onVideoStartClick = () => {
    setShowVideoModal(true);
  };

  const onVideoEndCallback = () => {
    setShowVideoModal(false);
  };

  return (
    <Container>
      <TitleElement>How the 30 day course works</TitleElement>

      <Content>
        <VideoWrapper>
          <VideoElement ratio={1} onClick={onVideoStartClick}>
            <PlayMediaIcon />
          </VideoElement>
        </VideoWrapper>

        <ListWrapper>
          <List>
            <ListItem>
              Yoga Transformation is a 30 day
              {' '}
              <strong>structured interactive learning experience</strong>
              {' '}
              to build a yoga-based health and mindfulness routine
            </ListItem>
            <ListItem>
              Based on
              {' '}
              <strong>scientifically validated methods</strong>
              , the program will be facilitated by a
              {' '}
              <strong>certified yoga teacher</strong>
              {' '}
              beginning to end
            </ListItem>
            <ListItem>
              You will be matched with other members into
              {' '}
              <strong>small groups</strong>
              {' '}
              based on your individual needs and goals
            </ListItem>
            <ListItem>
              Each week, you will
              {' '}
              <strong>learn something new about yoga and yourself</strong>
              {' '}
              and have at least one relevant epiphany that will stay with you
            </ListItem>
          </List>

          <CTAWrapper>
            <CTA title={ctaTitle} onClick={onCTAClick} />
          </CTAWrapper>
        </ListWrapper>

        {showVideoModal && (
          <PreviewVideoModal isOpen={showVideoModal} onRequestClose={onVideoEndCallback}>
            <PreviewVideoContainer>
              <CloseIconContainer onClick={onVideoEndCallback}>
                <CloseIcon />
              </CloseIconContainer>
              <VideoJsPlayer videoUrl={VIDEO_URL} onVideoEnd={onVideoEndCallback} />
            </PreviewVideoContainer>
          </PreviewVideoModal>
        )}
      </Content>
    </Container>
  );
};

export default Cohort4WeeksProgram;
