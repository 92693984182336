// @flow

import styled from 'styled-components';
import { CSS_BREAKPOINTS_MIN, WHITE_COLOR } from '../config/constants';

const SYHeadlineOne = styled.h1`
  font-family: 'Poppins-Medium', sans-serif;
  font-size: 28px;
  line-height: 1.3;
  letter-spacing: 1px;
  color: ${WHITE_COLOR};
  text-align: ${(props) => (props.align ? props.align : 'left')};
  
  @media ${CSS_BREAKPOINTS_MIN.sm} {
    font-size: 54px;
    line-height: 65px;
    letter-spacing: 0.81px;
  }
}`;

export default SYHeadlineOne;
