// @flow
import React, { Component } from 'react';
import {
  Redirect, Route, withRouter,
} from 'react-router-dom';
import queryString from 'query-string';
import _get from 'lodash/get';
import Cookies from 'js-cookie';
import moment from 'moment-timezone';

import { Auth } from './services/Amplify';
import LoadingAnimation from './components/LoadingAnimation';
import urls from './config/urls';
import PostLoginContainer from './components/PostLoginContainer';
import queryParamList from './config/query-parameter-keys';
import { LOCALSTORAGE_KEYS } from './localStorageKeys';
import isKnownUser from './modules/isKnownUser';

class PrivateRoute extends Component<{|
  component: Object,
  location: Object,
  path?: string | null,
|}, {
  isAuthenticated: boolean,
  isInProgress: boolean,
}> {
  static defaultProps = {
    path: null,
  };

  state = {
    isAuthenticated: false,
    isInProgress: true,
  };

  async componentDidMount() {
    const { location } = this.props;
    const queryParameter = queryString.parse(location.search);
    const code = _get(queryParameter, queryParamList.VOUCHER_CODE);

    this.setState({ isInProgress: true });

    if (code) {
      Cookies.set(LOCALSTORAGE_KEYS.codeToApplyOnCheckout, code, { expires: moment().add(1, 'hour').toDate() });
    }

    try {
      await Auth.currentAuthenticatedUser();

      this.setState({ isAuthenticated: true });
      Cookies.set(LOCALSTORAGE_KEYS.isKnownUser, true, { expires: moment().add(1, 'year').toDate() });
    } catch (error) {
      this.setState({
        isAuthenticated: false,
      });
    }

    this.setState({ isInProgress: false });
  }

  render() {
    const { isAuthenticated, isInProgress } = this.state;
    const { component: PassedComponent, ...rest } = this.props;

    if (isInProgress) {
      return (
        <PostLoginContainer>
          <LoadingAnimation />
        </PostLoginContainer>
      );
    }

    return (
      <Route
        exact
        {...rest}
        render={(props) => (
          isAuthenticated === true
            ? <PassedComponent {...props} />
            : (
              <Redirect to={{
                pathname: isKnownUser() ? urls.SIGNIN : urls.ROOT,
                state: { from: props.location },
              }}
              />
            )
        )}
      />
    );
  }
}

export default withRouter(PrivateRoute);
