// @flow

import styled from 'styled-components';
import { CSS_BREAKPOINTS_MIN, PAPER_WHITE_COLOR } from '../config/constants';

const SYBody14pt = styled.p`
  font-family: 'Poppins-Light';
  font-size: 13px;
  line-height: 1.5;
  letter-spacing: normal;
  color: ${PAPER_WHITE_COLOR};
  text-align: ${(props) => (props.center && 'center') || (props.right && 'right') || 'left'};
  
  @media ${CSS_BREAKPOINTS_MIN.sm} {
    font-size: 14px;
    line-height: 1.5;
    letter-spacing: 0.35px;
  }
`;

export default SYBody14pt;
