// @flow
import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';
import Cookies from 'js-cookie';
import moment from 'moment-timezone';
import _remove from 'lodash/remove';
import _get from 'lodash/get';
import {
  BLACK_COLOR,
  CSS_BREAKPOINTS_MIN,
  MENU_LINKS_LOGGED_IN,
  MENU_LINKS_LOGGED_OUT,
  MENU_LINKS_ONBOARDING_LOGGED_IN, MENU_LINKS_ONBOARDING_LOGGED_OUT,
  PAGE_CONTENT_WIDTH,
  PAGE_PADDING_LEFT_RIGHT, PRIMARY_COLOR,
  SPACING_BASE, WHITE_COLOR,
} from '../config/constants';
import urls from '../config/urls';
import SYBurgerMenuButton from './SYBurgerMenuButton';
import type { User } from '../types/User';
import NavigationLink from './NavigationLink';
import type { MenuLink } from '../types/MenuLink';
import NavigationLinkExternal from './NavigationLinkExternal';
import filterPremiumLinks from '../modules/filterLinksForPremiumUser';
import SYPrimaryButton from './SYPrimaryButton';
import isOnboardingPath from '../modules/isOnboardingPath';
import { ReactComponent as Logo } from '../assets/svg/logos/logo-horizontal-neg-white.svg';
import { ReactComponent as ArrowLeftIcon } from '../assets/svg/arrow-left-medium.svg';
import isKnownUser from '../modules/isKnownUser';
import { googleTrackingService } from '../services/GoogleTrackingProvider';
import type { LocationObjectReactRouter } from '../types/LocationObjectReactRouter';
import { LOCALSTORAGE_KEYS } from '../localStorageKeys';
import type { PaymentState } from '../types/PaymentState';

const BackButton = styled.button`
  height: 100%;
  margin-left: -${SPACING_BASE * 3}px;
  padding: 0 ${SPACING_BASE * 3}px;
  background: none;
  border: 0;
  outline: none;
  cursor: pointer;
`;

const TopMenuContainer = styled.div`
  position: ${(props) => (props.fixed ? 'fixed' : 'relative')};
  width: 100%;
  background-color: ${BLACK_COLOR};
  z-index: 5;
`;

const TopMenuContent = styled.div`
  position: relative;
  max-width: ${PAGE_CONTENT_WIDTH}px;
  margin: 0 auto;
  display: flex;
  height: 65px;
  justify-content: space-between;
  align-items: center;
  padding: ${SPACING_BASE * 3}px;

  @media ${CSS_BREAKPOINTS_MIN.lg} {
    padding: 0 ${PAGE_PADDING_LEFT_RIGHT}px;
  }
`;

const MenuLogoLink = styled(NavLink)`
  display: flex;

  ${(props) => (props.isonboarding === 'true') && (`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  `)}
`;

const RightMenu = styled.nav`
  display: flex;
  align-items: center;
`;

const TopMenuLink = styled(NavigationLink)`
  position: relative;
  display: none;
  margin-left: ${SPACING_BASE * 3}px;
  letter-spacing: 3px;
  font-size: 12px;
  line-height: 1.67;
  text-decoration: none;
  text-transform: uppercase;

  @media ${CSS_BREAKPOINTS_MIN.md} {
    display: block;
    margin-left: ${SPACING_BASE * 9}px;
  }
`;

const StartNowBotton = styled(SYPrimaryButton)`
  @media ${CSS_BREAKPOINTS_MIN.md} {
    display: none;
  }
`;

const TopMenuBlockLink = styled(SYPrimaryButton)`

  display: none;
  margin-left: ${SPACING_BASE * 3}px;

  @media ${CSS_BREAKPOINTS_MIN.md} {
    display: block;
    margin-left: ${SPACING_BASE * 9}px;
  }
`;

const NewBadge = styled.span`
  position: absolute;
  top: -13px;
  right: -24px;
  font-size: 10px;
  line-height: 15px;
  letter-spacing: 1.5px;
  background-color: ${PRIMARY_COLOR};
  border-radius: 4px;
  padding: 0 8px;
  transition: none;
  color: ${WHITE_COLOR};
`;

class TopMenu extends React.Component<{|
  fixed?: boolean,
  isLoggedIn: boolean,
  isSidebarOpen?: boolean,
  location: LocationObjectReactRouter,
  onOpenCloseClick: Function,
  user: User,
  payment: PaymentState,
|}> {
  static defaultProps = {
    fixed: false,
    isSidebarOpen: false,
  };

  componentDidUpdate(prevProps) {
    if ((prevProps.isLoggedIn !== this.props.isLoggedIn) || (prevProps.location.pathname !== this.props.location.pathname)) {
      if (this.props.isLoggedIn) {
        if(this.props.location.pathname === '/') {
          this.loadScript();
        } else {
          this.removeScript();
        }
      }
    }
  }

  isScriptAdded = (returnScript = false) => {
    const scripts = document.getElementsByTagName('script');
    for (let i = 0; i < scripts.length; i++) {
      if (scripts[i].src === 'https://jsd-widget.atlassian.com/assets/embed.js') {
        return returnScript ? scripts[i] : true;
      }
    }
    return false;
  }

  loadScript = () => {
    this.removeScript();
    const script = document.createElement('script');
    script.src = 'https://jsd-widget.atlassian.com/assets/embed.js';
    script.async = true;
    script.setAttribute('data-jsd-embedded', '');
    script.setAttribute('data-key', '2c2382bf-5e76-4015-9cfb-1bcc277332a7');
    script.setAttribute('data-base-url', 'https://jsd-widget.atlassian.com');
    document.body.appendChild(script);

    script.addEventListener('load', (event) => {
      window.document.dispatchEvent(new Event("DOMContentLoaded", {
        bubbles: true,
        cancelable: true
      }));
    });
  }

  removeScript = () => {
    const _script = this.isScriptAdded(true);
    if(_script) {
      _script.remove();
      if(document.querySelector('iframe#jsd-widget')) {
        document.querySelector('iframe#jsd-widget').remove();
      }
      if(document.querySelector('style#support-styles')) {
        document.querySelector('style#support-styles').remove();
      }
    }
  }

  onStartButtonClick = () => {
    const {
      payment: {
        promotion,
      },
    } = this.props;
    const code = _get(promotion, 'code.code');

    if (code) {
      Cookies.set(LOCALSTORAGE_KEYS.codeToApplyOnCheckout, code, { expires: moment().add(1, 'hour').toDate() });
    }

    if (isKnownUser()) {
      return;
    }

    googleTrackingService.trackEvent('funnel', {
      event_category: 'Onboarding',
      event_name: 'Step 0 - Init',
      event_label: 'starting',
    });

    googleTrackingService.trackEvent('onboarding_started', {
      event_category: 'Onboarding',
      event_name: 'onboarding_started',
      event_label: 'starting',
    });
  };

  onMenuLinkClick = (link: MenuLink) => {
    if (link.destination === urls.SIGNUP || link.destination === urls.ONBOARDING) {
      googleTrackingService.trackEvent('funnel', {
        event_category: 'Onboarding',
        event_name: 'Step 0 - Init',
        event_label: 'starting',
      });

      googleTrackingService.trackEvent('onboarding_started', {
        event_category: 'Onboarding',
        event_name: 'onboarding_started',
        event_label: 'starting',
      });
    }
  };

  renderMenuItem = (menuLink: MenuLink) => {
    const { isLoggedIn /* , location: { pathname } */ } = this.props;

    if (menuLink.external) {
      return (
        <NavigationLinkExternal
          key={menuLink.id}
          href={menuLink.destination}
          title={`Link to ${menuLink.name}`}
          target="_blank"
          rel="noreferrer"
        >
          {menuLink.name}
          {menuLink.isNew && (
            <NewBadge>NEW</NewBadge>
          )}
        </NavigationLinkExternal>
      );
    }

    if (menuLink.block) {
      return (
        <TopMenuBlockLink
          key={menuLink.id}
          title={isLoggedIn ? 'Training' : menuLink.name}
          to={isLoggedIn ? urls.TRAINING : urls.ONBOARDING}
          small
          onClick={this.onStartButtonClick}
        />
      );
    }

    return (
      <TopMenuLink
        key={menuLink.id}
        to={menuLink.destination}
        title={menuLink.name}
      >
        {menuLink.name}
        {menuLink.isNew && (
          <NewBadge>NEW</NewBadge>
        )}
      </TopMenuLink>
    );
  }

  renderLoggedInMenu = () => {
    const { user } = this.props;

    return MENU_LINKS_LOGGED_IN
      .filter((link) => filterPremiumLinks(link, user))
      .map(this.renderMenuItem);
  };

  renderLoggedOutMenu = () => {
    const { isLoggedIn, location: { pathname } } = this.props;
    const linkList: $ReadOnlyArray<MenuLink> = MENU_LINKS_LOGGED_OUT;

    if (pathname === urls.PRODUCT_OVERVIEW_PUBLIC) {
      _remove(linkList, (entry: MenuLink) => entry.destination === urls.ONBOARDING);
    }

    return (
      <>
        <StartNowBotton
          title={isLoggedIn ? 'Training' : 'Start now'}
          to={isLoggedIn ? urls.TRAINING : urls.ONBOARDING}
          small
          onClick={this.onStartButtonClick}
        />

        { linkList.map(this.renderMenuItem)}
      </>
    );
  };

  renderOnboardingMenu = () => {
    const {
      isLoggedIn,
    } = this.props;

    const menuLinks = isLoggedIn
      ? MENU_LINKS_ONBOARDING_LOGGED_IN
      : MENU_LINKS_ONBOARDING_LOGGED_OUT;

    return menuLinks.map((menuLink: MenuLink) => (
      <TopMenuLink
        key={menuLink.id}
        to={menuLink.destination}
        title={menuLink.name}
      >
        {menuLink.name}
      </TopMenuLink>
    ));
  };

  onBackButtonClick = () => {
    // $FlowFixMe
    const { history } = this.props;

    history.go(-1);
  };

  renderBurgerMenuItems = () => {
    const {
      isLoggedIn,
      location,
    } = this.props;

    if (this.isLoginLocation()) {
      return false;
    }

    if (isOnboardingPath(location)) {
      return this.renderOnboardingMenu();
    }

    if (isLoggedIn) {
      return this.renderLoggedInMenu();
    }

    return this.renderLoggedOutMenu();
  };

  isLoginLocation = (): boolean => {
    const {
      location,
    } = this.props;

    return location.pathname === urls.SIGNIN;
  };

  render() {
    const {
      fixed,
      isSidebarOpen,
      location,
      onOpenCloseClick,
      isLoggedIn,
    } = this.props;

    return (
      <TopMenuContainer fixed={fixed} id="TopMenuContainer">
        <TopMenuContent>
          {isOnboardingPath(location) && (
            <BackButton onClick={this.onBackButtonClick}>
              <ArrowLeftIcon />
            </BackButton>
          )}

          <MenuLogoLink
            to={isLoggedIn ? urls.TRAINING : urls.ROOT}
            title="Home"
            isonboarding={isOnboardingPath(location).toString()}
          >
            <Logo />
          </MenuLogoLink>

          <RightMenu>
            {this.renderBurgerMenuItems()}
            {!this.isLoginLocation() && <SYBurgerMenuButton isOpen={isSidebarOpen} onClick={onOpenCloseClick} />}
          </RightMenu>
        </TopMenuContent>
      </TopMenuContainer>
    );
  }
}

const mapStateToProps = ({ auth, payment }) => ({
  user: auth.user,
  isLoggedIn: auth.isLoggedIn,
  payment,
});

export default withRouter(connect(mapStateToProps, null)(TopMenu));
