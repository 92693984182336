// @flow

import { css } from 'styled-components';
import {
  CSS_BREAKPOINTS_MIN, SPACING_BASE, TRANSITION_DEFAULT_LEN, WHITE_COLOR,
} from '../../config/constants';
import InputTextStyleCSS from '../shared-styles/InputTextStyleCSS';

const GlobalInputStyle = css`
  width: 100%;
  padding: ${SPACING_BASE}px 0;
  background: transparent;
  border: 0;
  color: ${(props) => (props.color ? props.color : WHITE_COLOR)};
  outline: none;
  text-overflow: ellipsis;
  border-radius: 0;
  transition: border ${TRANSITION_DEFAULT_LEN}s;
  ${InputTextStyleCSS}
  
  @media ${CSS_BREAKPOINTS_MIN.sm} {
    padding: ${SPACING_BASE * 2}px 0;
  }

  ::placeholder {
    color: hexToRgba(WHITE_COLOR, 0.4);
  }
  
  &:disabled {
    cursor: not-allowed;
    opacity: 0.7;
  }
`;

export default GlobalInputStyle;
