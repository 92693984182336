import styled from 'styled-components';
import { TitleElement } from '../text/CohortLp/CohortLpTextElements';

import CalendarSvg from '../../assets/svg/caleb-calendar-icon.svg';
import BreathSvg from '../../assets/svg/caleb-breath-icon.svg';
import ConnectSvg from '../../assets/svg/caleb-connect-icon.svg';
import { CSS_BREAKPOINTS_MIN, GUTTER_SIZE } from '../../config/constants';
import SYPrimaryButton from '../SYPrimaryButton';

const Container = styled.section`
  padding: ${GUTTER_SIZE * 2}px 0;

  @media ${CSS_BREAKPOINTS_MIN.sm} {
    padding: ${GUTTER_SIZE * 2.5}px 0 ${GUTTER_SIZE * 3.5}px;
  }
`;

const Svg = styled.img`
  height: 90px;
  margin: 0 0 ${GUTTER_SIZE / 2}px;

  @media ${CSS_BREAKPOINTS_MIN.sm} {
    height: 110px;
  }
`;

const Row = styled.div`
  text-align: center;

  @media ${CSS_BREAKPOINTS_MIN.sm} {
    display: flex;
  }
`;

const Column = styled.div`
  margin: 0 0 ${GUTTER_SIZE * 2}px;

  @media ${CSS_BREAKPOINTS_MIN.sm} {
    flex: 1;

    &:nth-child(2n) {
      padding: 0 ${GUTTER_SIZE * 4}px;
    }
  }
`;

const Text = styled.p`
  font-font: Poppins-Light, sans-serif;
  font-size: 15px;
  line-height: 26px;

  text-align: center;
  letter-spacing: 0.075px;

  @media ${CSS_BREAKPOINTS_MIN.sm} {
    font-size: 16px;
    line-height: 26px;
  }
`;

const CTAWrapper = styled.div`
  padding: 0 ${GUTTER_SIZE}px;

  @media ${CSS_BREAKPOINTS_MIN.sm} {
    text-align: center;
  }
`;

const CTA = styled(SYPrimaryButton)`
  width: 100%;

  @media ${CSS_BREAKPOINTS_MIN.sm} {
    width: auto;
    min-width: 320px;
  }
`;

const MarleneHowItWorks = ({ onCTAClick, ctaTitle = 'ENROLL NOW' }) => (
  <Container>
    <TitleElement>How the course works</TitleElement>

    <Row>
      <Column>
        <Svg src={CalendarSvg} alt="Calendar SVG" />
        <Text>
          Once the course opens and launches, you will practise 3 times per week, as guided by Marlene.
        </Text>
      </Column>

      <Column>
        <Svg src={BreathSvg} alt="Breath SVG" />
        <Text>
          Together we’ll learn and practise all the branches of yoga, including Vinyasa
          <br />
          (the flow), Pranayama (breath-work) and Dhyana (meditation) – all guided by Marlene.
        </Text>
      </Column>

      <Column>
        <Svg src={ConnectSvg} alt="Connect SVG" />
        <Text>
          You can connect with a group of people who are in the same situation as you are. We are creating a safe space to share experiences,
          ideas and perspectives. Because when you share, you grow.
        </Text>
      </Column>
    </Row>

    <CTAWrapper>
      <CTA title={ctaTitle} onClick={onCTAClick} />
    </CTAWrapper>
  </Container>
);

export default MarleneHowItWorks;
