// @flow
import React from 'react';
import PostLoginContainer from '../components/PostLoginContainer';
import SYBody18pt from '../components/SYBody18pt';
import { SPACING_BASE, SPACING_NEXT_ELEMENT_MOBILE } from '../config/constants';
import SYHeadlineTwo from '../components/SYHeadlineTwo';

const PageNotFound = () => (
  <PostLoginContainer style={{ paddingTop: SPACING_NEXT_ELEMENT_MOBILE }}>
    <SYHeadlineTwo style={{
      marginBottom: SPACING_BASE * 6,
    }}
    >
      Page not found
    </SYHeadlineTwo>

    <SYBody18pt>
      Please check the location.
    </SYBody18pt>
  </PostLoginContainer>
);

export default PageNotFound;
