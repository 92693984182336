// @flow

import React from 'react';
import _padStart from 'lodash/padStart';
import styled from 'styled-components';
import type Moment from 'moment-timezone';

import {
  PAPER_WHITE_COLOR, PRIMARY_COLOR, SPACING_BASE, WHITE_COLOR,
} from '../config/constants';
import { marketingCampaignService } from '../services/MarketingBannerService';

const Countdown = styled.div`
  display: flex;
  animation: fadein 0.5s;
  justify-content: center;
  align-items: center;
`;

const TimeUnit = styled.div`
  position: relative;
  min-width: 35px;
  margin: 0 0 ${SPACING_BASE * 3}px;
  padding: ${SPACING_BASE}px 1px ${SPACING_BASE}px ${SPACING_BASE}px;
  background: ${WHITE_COLOR};
  border-radius: 3px;
  text-align: center;
  font-family: Barlow-Condensed, sans-serif;
  font-size: 17px;
  line-height: 1.29;
  letter-spacing: 4px;
  color: ${PRIMARY_COLOR};

  &:after {
    content: "${(props) => props.label}";
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    padding-top: 4px;
    z-index: 10;
    font-family: Poppins, sans-serif;
    font-size: 11px;
    line-height: 1.36;
    color: ${PAPER_WHITE_COLOR};
    letter-spacing: 1.65px;
  }
`;

const TimeDivider = styled.div`
  margin: -${SPACING_BASE * 3}px 2px 0; // same as margin-bottom from TimeUnit!
  color: ${WHITE_COLOR};
  font-family: Poppins-Medium, sans-serif;
  font-size: 17px;
  line-height: 1.71;
  letter-spacing: 0.09px;
`;

type TimeLeft = {
  total: number,
  days: number,
  hours: number,
  minutes: number,
  seconds: number,
};

class BannerCountdown extends React.PureComponent<{
  end: Moment,
  showDays?: boolean,
}, {|
  showBanner: boolean,
  timer: IntervalID | void,
  timeLeft: TimeLeft,
|}> {
  static defaultProps = {
    showDays: false,
  };

  state = {
    showBanner: true,
    timer: undefined,
    timeLeft: {
      total: 0, days: 0, hours: 0, minutes: 0, seconds: 0,
    },
  };

  componentDidMount() {
    // we need one initial time setting
    this.updateTimeLeft();

    this.setState({ timer: setInterval(this.updateTimeLeft, 1000) });
  }

  updateTimeLeft = () => {
    const { end } = this.props;
    const leftInMs = Date.parse(end.format()) - Date.parse(marketingCampaignService.userTime);
    const timeLeft = {
      total: leftInMs,
      seconds: Math.floor((leftInMs / 1000) % 60),
      minutes: Math.floor((leftInMs / 1000 / 60) % 60),
      hours: Math.floor((leftInMs / (1000 * 60 * 60)) % 24),
      days: Math.floor(leftInMs / (1000 * 60 * 60 * 24)),
    };

    if (leftInMs <= 0) {
      const { timer } = this.state;
      clearInterval(timer);
      this.setState({ showBanner: false });
    } else {
      this.setState({ timeLeft });
    }
  };

  render() {
    const { timeLeft, showBanner } = this.state;
    const { showDays } = this.props;

    if (showBanner) {
      return (
        <Countdown>
          {showDays && (
            <>
              <TimeUnit label="DAYS">{_padStart(timeLeft.days, 2, '0')}</TimeUnit>
              <TimeDivider>:</TimeDivider>
            </>
          )}
          <TimeUnit label="HRS">{_padStart(timeLeft.hours, 2, '0')}</TimeUnit>
          <TimeDivider>:</TimeDivider>
          <TimeUnit label="MIN">{_padStart(timeLeft.minutes, 2, '0')}</TimeUnit>
          <TimeDivider>:</TimeDivider>
          <TimeUnit label="SEC">{_padStart(timeLeft.seconds, 2, '0')}</TimeUnit>
        </Countdown>
      );
    }

    return null;
  }
}

export default BannerCountdown;
