import styled from 'styled-components';
import { CLOUDFRONT_STATIC, CSS_BREAKPOINTS_MIN, GUTTER_SIZE } from '../../config/constants';
import AspectRatio from '../AspectRatio';
import { TitleElement } from '../text/CohortLp/CohortLpTextElements';
import InstagramSvg from '../../assets/svg/instagram-logo.svg';
import FacebookSvg from '../../assets/svg/facebook-logo.svg';
import YouTubeSvg from '../../assets/svg/youtube-logo.svg';
import SocialLink from '../SocialLink';
import makeImageUrl from '../../modules/makeImageUrl';

const Container = styled.div`
  padding: ${GUTTER_SIZE * 2}px 0;

  @media ${CSS_BREAKPOINTS_MIN.sm} {
    padding: ${GUTTER_SIZE * 2.5}px 0 ${GUTTER_SIZE * 3.5}px;
  }
`;

const Content = styled.div`
  @media ${CSS_BREAKPOINTS_MIN.sm} {
    display: flex;
    flex-direction: row;
  }
`;

const ImageWrapper = styled.div`
  margin: 0 0 ${GUTTER_SIZE * 2}px;

  @media ${CSS_BREAKPOINTS_MIN.sm} {
    flex: 0 0 40%;
    margin: 0;
    padding-right: 5%;
  }
`;

const TrainerName = styled.h4`
  font-size: 20px;
  line-height: 25px;
  letter-spacing: 0.4px;

  @media ${CSS_BREAKPOINTS_MIN.sm} {
    font-size: 30px;
    line-height: 44px;
    letter-spacing: 0.75px;
  }
`;

const ImageElement = styled(AspectRatio)`
  background: url(${makeImageUrl({ url: `${CLOUDFRONT_STATIC}/img/pages/caleb-lp/caleb-trainger-image.jpg` })}) no-repeat center / cover;
`;

const SocialLinks = styled.div`
  ${SocialLink} {
    &:first-child {
      padding-left: 0;
    }
  }

  @media ${CSS_BREAKPOINTS_MIN.sm} {
    margin-bottom: ${GUTTER_SIZE}px;
  }
`;

const SocialImg = styled.img`
  height: 21px;

  @media ${CSS_BREAKPOINTS_MIN.sm} {
    height: 31px;
  }
`;

const Text = styled.p`
  margin: 0;
  font-family: Poppins-Light, sans-serif;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: 0.3px;

  &:last-child {
    margin: 0;
  }

  @media ${CSS_BREAKPOINTS_MIN.sm} {
    font-size: 16px;
    line-height: 22px;
  }
`;

const CalebMeetTheCoach = () => (
  <Container>
    <TitleElement>Meet Your Coach</TitleElement>

    <Content>
      <ImageWrapper>
        <ImageElement ratio={1} />
      </ImageWrapper>

      <div>
        <TrainerName>Caleb Jude Packham</TrainerName>

        <SocialLinks>
          <SocialLink href="//www.instagram.com/calebjudepackham/" title="Link to Instagram" target="_blank" rel="noreferrer">
            <SocialImg src={InstagramSvg} alt="Instagram Account" />
          </SocialLink>
          <SocialLink href="//www.facebook.com/calebthewellnesswarrior" title="Link to Facebook" target="_blank" rel="noreferrer">
            <SocialImg src={FacebookSvg} alt="Facebook Account" />
          </SocialLink>
          <SocialLink href="//www.youtube.com/channel/UC6Z6NN69YWLIzB102n8ZHCw" title="Link to YouTube channel" target="_blank" rel="noreferrer">
            <SocialImg src={YouTubeSvg} alt="YouTube Account" />
          </SocialLink>
        </SocialLinks>

        <Text>
          London-based Caleb Jude Packham is an Australian TV presenter turned internationally renowned yoga teacher.
        </Text>
        <Text>
          He is committed to making yoga accessible to all—particularly men. He has staged large-scale yoga events in iconic London venues,
          including Ministry of Sound and the View From The Shard, and has taught extensively on the international yoga circuit.
        </Text>
        <Text>
          In 2018, he co-founded Wellness Warrior, and in 2022 launched ‘The Power of Yoga for Men’, a beginner’s guide to building strength,
          mental clarity and emotional fitness. Published by Bloomsbury Sport and available through all outlets.
        </Text>
      </div>
    </Content>
  </Container>
);

export default CalebMeetTheCoach;
