// @flow

import Cookies from 'js-cookie';
import { LOCALSTORAGE_KEYS } from '../localStorageKeys';

export default (): boolean => {
  const isCookieSet = Cookies.get(LOCALSTORAGE_KEYS.isKnownUser);

  return !!isCookieSet;
};
