// @flow

import type { ApiResponseWorkout } from '../types/ApiResponseWorkout';
import Workout from './Workout';
import convertToBit from './convertToBit';
import convertBitToBoolean from './convertBitToBoolean';

const makeWorkoutFromResponse = (responseWorkout: ApiResponseWorkout): Workout => new Workout({
  description: responseWorkout?.description || '',
  shortDescription: responseWorkout?.shortDescription || '',
  duration: responseWorkout?.duration || 0,
  id: responseWorkout?.id,
  imageM: responseWorkout?.imageM || '',
  imageXl: responseWorkout?.imageXl || '',
  intensity: responseWorkout?.intensity || 0,
  isListable: responseWorkout?.isListable || 0,
  name: responseWorkout?.name || '',
  skillId: responseWorkout?.skillId || 1,
  skill: responseWorkout?.skill || '',
  videoUrl: responseWorkout?.videoUrl || '',
  intro: responseWorkout?.intro || null,
  done: convertToBit(responseWorkout?.done),
  started: convertToBit(responseWorkout?.started),
  historyWorkoutId: responseWorkout?.historyWorkoutId || null,
  historyPlanId: responseWorkout?.historyPlanId || null,
  isFreemium: convertBitToBoolean(responseWorkout?.isFreemium),
  isFree: convertToBit(responseWorkout?.isFree),
  externalContentSlug: responseWorkout?.externalContentSlug,
  parents: responseWorkout?.parents,
});

export default makeWorkoutFromResponse;
