// @flow

import React from 'react';

import { googleTrackingService } from '../services/GoogleTrackingProvider';
import { store } from '../store/configureStore';
import AutopilotAPI from '../services/AutopilotService';

export interface ViewInterface {
  +viewName: string,
}

/**
 * No PureComponent, otherwise some bindings will not work
 * anymore (like form validations (blur))
 */
class AbstractView<Props: {}, State: ?{} = null> extends React.Component<Props, State> {
  viewTimeEnd: DOMHighResTimeStamp = 0;

  viewTimeStart: DOMHighResTimeStamp = 0;

  componentDidMount() {
    const {
      // $FlowFixMe
      viewName,
    } = this;

    this.viewTimeStart = performance.now();

    const {
      auth: {
        user: {
          id,
        },
      },
    } = store.getState();

    googleTrackingService.trackView(viewName, {
      pageUrl: window.location.pathname,
      user_id: id,
    });
    AutopilotAPI.trackPageVisit();

    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
    this.viewTimeEnd = performance.now();

    const millisecondsViewed = this.viewTimeEnd - this.viewTimeStart;

    googleTrackingService.trackEvent('generic_event', {
      event_category: 'page',
      event_action: 'userSpentTimeOnPage',
      // $FlowFixMe
      event_label: this.viewName,
      event_value: parseInt(millisecondsViewed, 10) / 1000,
    });
  }

  trackButtonPress(buttonText: string, value?: string | number | null, preventScroll?: boolean = false) {
    googleTrackingService.trackEvent('generic_event', {
      event_category: 'buttonPressed',
      event_action: buttonText,
      event_label: value,
    });

    if (!preventScroll) {
      // window.scrollTo(0, 0);
    }
  }
}

export default AbstractView;
