// @flow
import React from 'react';
import styled from 'styled-components';
import { GREY_01_COLOR, PRIMARY_COLOR } from '../config/constants';
import NavigationLink from './NavigationLink';
import urls from '../config/urls';
import SYBody14pt from './SYBody14pt';

const NavigationHighlight = styled.span`
  text-decoration: underline;
`;

type OwnProps = {
  isCodeActive: boolean,
  onRemoveCodeClick: () => void,
};

const CouponEntryLink = ({ isCodeActive, onRemoveCodeClick }: OwnProps) => (
  <SYBody14pt
    center
    style={{
      fontWeight: 600,
    }}
  >
    {`${isCodeActive ? 'Accepted code!' : 'Got a code?'}`}
    {' '}

    {isCodeActive && (
      <span
        onClick={onRemoveCodeClick}
        style={{
          color: GREY_01_COLOR,
          cursor: 'pointer',
        }}
      >
        Remove code
      </span>
    )}

    {!isCodeActive && (
      <NavigationLink
        title="Enter a promotional code"
        to={urls.VOUCHER_PAGE}
        style={{ color: PRIMARY_COLOR, fontWeight: 400 }}
      >
        Enter it
        {' '}
        <NavigationHighlight>here</NavigationHighlight>
      </NavigationLink>
    )}
  </SYBody14pt>
);

export default CouponEntryLink;
