// @flow
import type { User } from '../types/User';
import convertToBit from './convertToBit';

/**
 * Checks, if the given user has full access, or not
 * @param {Object} user
 * @return {boolean} true, when user has a subscription or is a free user, otherwise false
 */
export default (user: User): boolean => Boolean(convertToBit(user.isFreeUser || user.isPremium));
