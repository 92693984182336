import styled from 'styled-components';
import { TitleElement } from './text/CohortLp/CohortLpTextElements';

import CheckinSvg from '../assets/svg/cohort-checkin.svg';
import GroupSvg from '../assets/svg/cohort-group.svg';
import ConstructionSvg from '../assets/svg/cohort-construction.svg';
import { CSS_BREAKPOINTS_MIN, GUTTER_SIZE } from '../config/constants';
import SYPrimaryButton from './SYPrimaryButton';

const Container = styled.section`
  padding: ${GUTTER_SIZE * 2}px 0;

  @media ${CSS_BREAKPOINTS_MIN.sm} {
    padding: ${GUTTER_SIZE * 2.5}px 0 ${GUTTER_SIZE * 3.5}px;
  }
`;

const Svg = styled.img`
  height: 90px;
  margin: 0 0 ${GUTTER_SIZE / 2}px;

  @media ${CSS_BREAKPOINTS_MIN.sm} {
    height: 160px;
  }
`;

const Row = styled.div`
  text-align: center;

  @media ${CSS_BREAKPOINTS_MIN.sm} {
    display: flex;
  }
`;

const Column = styled.div`
  margin: 0 0 ${GUTTER_SIZE * 2}px;

  @media ${CSS_BREAKPOINTS_MIN.sm} {
    flex: 1;

    &:nth-child(2n) {
      padding: 0 ${GUTTER_SIZE * 4}px;
    }
  }
`;

const Text = styled.p`
  font-font: Poppins-Light, sans-serif;
  font-size: 15px;
  line-height: 26px;

  text-align: center;
  letter-spacing: 0.075px;

  @media ${CSS_BREAKPOINTS_MIN.sm} {
    font-size: 16px;
    line-height: 26px;
  }
`;

const CTAWrapper = styled.div`
  padding: 0 ${GUTTER_SIZE}px;

  @media ${CSS_BREAKPOINTS_MIN.sm} {
    text-align: center;
  }
`;

const CTA = styled(SYPrimaryButton)`
  width: 100%;

  @media ${CSS_BREAKPOINTS_MIN.sm} {
    width: auto;
    min-width: 320px;
  }
`;

const CohortTransformation = ({ onCTAClick, ctaTitle = 'ENROLL NOW' }) => (
  <Container>
    <TitleElement>What a yoga transformation session looks like</TitleElement>

    <Row>
      <Column>
        <Svg src={CheckinSvg} alt="Checkin SVG" />
        <Text>
          Every Sunday at 6pm CET/ 11 AM CT you check-in with your group of like-minded peers for 90 minutes.
          All Sessions will be recorded and uploaded to our course platform so you can watch them later.
        </Text>
      </Column>

      <Column>
        <Svg src={GroupSvg} alt="Group SVG" />
        <Text>Together we learn and do exercises based on yoga, mindfulness and positive psychology – all guided by your coach.</Text>
      </Column>

      <Column>
        <Svg src={ConstructionSvg} alt="Construction SVG" />
        <Text>
          Your group consists of people who are in the same situation as you are. We are creating a safe space to share
          experiences, ideas and perspectives. Because when you share, you grow.
        </Text>
      </Column>
    </Row>

    <CTAWrapper>
      <CTA title={ctaTitle} onClick={onCTAClick} />
    </CTAWrapper>
  </Container>
);

export default CohortTransformation;
