// @flow
// eslint-disable-next-line max-classes-per-file
import _get from 'lodash/get';
import queryString from 'query-string';
import { API, Auth } from './Amplify';
import {
  API_GATEWAY_NAME, GOOGLE_CAPTCHA_V3_SITE_KEY, SUBSCRIPTION_PROVIDER_WEB, DEFAULT_PRODUCTS_EXTERNAL_IDS,
} from '../config/constants';
import makeProgramFromApiResponse from '../modules/makeProgramFromApiResponse';
import Program from '../modules/Program';
import type { MarkWorkoutDoneResponse } from '../types/MarkWorkoutDoneResponse';
import type { ApiPayloadMeWorkoutCompleted } from '../types/ApiTypes/ApiPayloadMeWorkoutCompleted';
import type { ReferredUserInfo } from '../types/ReferredUserInfo';
import type { SendFeedbackPayload } from '../types/SendFeedbackPayload';
import type { ApiResponseProduct } from '../types/ApiResponseProduct';
import type { CancelStripeSubscriptionPayload } from '../types/CancelStripeSubscriptionPayload';
import makeProgramWithWorkoutsFromApiResponse from '../modules/makeProgramWithWorkoutsFromApiResponse';
import type { Tag } from '../types/Tag';
import Workout from '../modules/Workout';
import type { ApiResponseWorkout } from '../types/ApiResponseWorkout';
import makeWorkoutFromApiResponse from '../modules/makeWorkoutFromApiResponse';
import type { ApiResponsePlan } from '../types/ApiResponsePlan';
import Plan from '../modules/Plan';
import type { PromoCodeType } from '../types/PromoCodeType';
import type { SocialLoginType } from '../types/SocialLoginType';
import type { ApiPayloadStripeCreateSession } from '../types/ApiTypes/ApiPayloadStripeCreateSession';
import type { ApiResponseStripeCreateSession } from '../types/ApiTypes/ApiResponseStripeCreateSession';
import type { ApiResponseStripeInvoice } from '../types/ApiTypes/ApiResponseStripeInvoice';
import type { ApiResponsePackages } from '../types/ApiTypes/ApiResponsePackages';
import type { ApiPayloadMeWorkoutStart } from '../types/ApiTypes/ApiPayloadMeWorkoutStart';
import type { ApiResponseMeWorkoutStart } from '../types/ApiTypes/ApiResponseMeWorkoutStart';
import type { ApiPayloadStartProgram } from '../types/ApiTypes/ApiPayloadStartProgram';
import { logger } from '../modules/Logger';
import type { SubscriptionProvider } from '../types/SubscriptionProvider';

type APIServiceErrorKey =
  'USER_ID_TOKEN_INVALID'
| 'API_404_REQUEST_ERROR'
| 'GENERAL_ERROR'
| 'InvalidProgramId';

export class APIServiceError {
  +name: string;

  +key: APIServiceErrorKey;

  +message: string;

  +stack: any;

  +status: Object;

  constructor(key: APIServiceErrorKey, message: string, statusCode?: number) {
    this.name = this.constructor.name;
    this.key = key;
    this.message = message;
    this.stack = (new Error(message)).stack;
    this.status = statusCode;
  }
}

// $FlowFixMe
APIServiceError.prototype = Object.create(Error.prototype);
APIServiceError.prototype.constructor = APIServiceError;

export type InitSocialLoginInput = {|
  type: SocialLoginType,
  email: string,
|}

async function getUserIdToken(): Promise<string | null> {
  try {
    const user = await Auth.currentAuthenticatedUser();
    const { jwtToken } = user.signInUserSession.idToken;
    return jwtToken;
  } catch (e) {
    const errorMessagesNotToTrack = ['not authenticated'];

    // do not log all the errors (eg user is simply just logged out)
    if (errorMessagesNotToTrack.indexOf(e) > -1) {
      return null;
    }

    logger.error(e, [
      {
        fileName: 'APIService.js',
        className: 'APIService',
        functionName: 'getUserIdToken',
      },
    ]);
  }

  return null;
}

// eslint-disable-next-line func-names
const captchaReadyPromise = () => new Promise((resolve, reject) => {
  window.grecaptcha.ready((err, req, result) => {
    if (err) {
      reject(err);
    } else {
      resolve(result);
    }
  });
});

async function makeRequest(method: 'GET' | 'POST' | 'PUT', path: string, body?: Object) {
  return API[method.toLowerCase()](API_GATEWAY_NAME, path, {
    body,
  });
}

async function makeAuthenticatedRequest(method: 'GET' | 'POST' | 'PUT', path: string, body?: Object) {
  const idToken = await getUserIdToken();

  if (idToken) {
    const init = {
      body,
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
    };

    try {
      return API[method.toLowerCase()](API_GATEWAY_NAME, path, init);
    } catch (error) {
      const message = _get(error, 'message');

      if (message && message.length > 0) {
        throw new APIServiceError('GENERAL_ERROR', `${message}. Check your connection and reload the page, please`);
      }

      const statusCode = _get(error, 'response.status') || JSON.stringify(error);
      throw new APIServiceError('API_404_REQUEST_ERROR', `API responded with ${statusCode}`, statusCode);
    }
  }

  throw new APIServiceError('USER_ID_TOKEN_INVALID', 'User, idToken is null.');
}

async function makeAuthenticatedGETRequest(path: string): Promise<any> {
  return makeAuthenticatedRequest('GET', path);
}

async function makeAuthenticatedPOSTRequest(path: string, body: Object): Promise<any> {
  return makeAuthenticatedRequest('POST', path, body);
}

async function makePOSTRequest(path: string, body: Object): Promise<any> {
  return makeRequest('POST', path, body);
}

async function makeAuthenticatedPUTRequest(path: string, body: Object): Promise<any> {
  return makeAuthenticatedRequest('PUT', path, body);
}

async function makeCaptchaGETRequest(path: string, body: Object): Promise<any> {
  await captchaReadyPromise();
  // attach the captcha token result to the request
  const { url, query } = queryString.parseUrl(path);
  query.captchaToken = await window.grecaptcha.execute(GOOGLE_CAPTCHA_V3_SITE_KEY, { action: '/promocodes' });
  query.provider = SUBSCRIPTION_PROVIDER_WEB;

  return makeRequest('GET', `${url}?${queryString.stringify(query)}`, body);
}

class APIService {
  async fetchMe() {
    return makeAuthenticatedGETRequest('/me');
  }

  async getTags(): Promise<Array<Tag>> {
    return makeAuthenticatedGETRequest('/tag');
  }

  async fetchActivePlan(): Promise<Plan> {
    const plan = await makeAuthenticatedGETRequest('/me/program');
    return makeProgramWithWorkoutsFromApiResponse(plan);
  }

  async fetchPrograms(): Promise<Array<Program>> {
    const response = await makeAuthenticatedGETRequest('/plan');

    return response.map(makeProgramFromApiResponse);
  }

  async fetchMasterCourses(): Promise<Array<Program>> {
    const response = await makeAuthenticatedGETRequest('/master-courses');

    return response.map(makeProgramFromApiResponse);
  }

  async fetchProgressActivePlan() {
    const response = await makeAuthenticatedGETRequest('/my-plan');
    return response;
  }

  async fetchActivePlanBySlug({ slug, startedAt, sessionId, userId }) {
    const response = await makeAuthenticatedPOSTRequest('/me/start-program-by-slug', { slug, startedAt, sessionId, userId });
    return response;
  }

  async fetchReferredUserByUser(): Promise<Array<ReferredUserInfo>> {
    return makeAuthenticatedGETRequest('/me/referred');
  }

  async fetchMeWasReferredBy(): Promise<Array<ReferredUserInfo>> {
    return makeAuthenticatedGETRequest('/me/referredBy');
  }

  async startProgram({ programId, startedAt, sessionId }: ApiPayloadStartProgram) {
    return makeAuthenticatedPOSTRequest('/me/program', { programId, startedAt, sessionId });
  }

  async checkAccessiblePlan({ programId }: ApiPayloadStartProgram) {
    return makeAuthenticatedGETRequest(`/plan/plan-accessible/${programId}`);
  }

  async checkAccessiblePlanBySlug(planSlug) {
    return makeAuthenticatedGETRequest(`/plan/plan-accessible-by-slug/${planSlug}`);
  }

  async markWorkoutDone({ sessionId, completedAt }: ApiPayloadMeWorkoutCompleted): Promise<MarkWorkoutDoneResponse> {
    console.log('markWorkoutDone ======> ');
    return makeAuthenticatedPUTRequest('/me/workout/complete', { sessionId, completedAt });
  }

  async markWorkoutStarted(body: ApiPayloadMeWorkoutStart): Promise<ApiResponseMeWorkoutStart> {
    return makeAuthenticatedPOSTRequest('/me/workout/start', body);
  }

  async markWorkoutStartedBySlug({ userId, slug, planSlug, sessionId, startedAt }): Promise<ApiResponseMeWorkoutStart> {
    return makeAuthenticatedPOSTRequest('/me/start-workout-by-slug', { userId, slug, planSlug, sessionId, startedAt });
  }

  async isValidReferrer(uuid: string): Promise<any> {
    return makeRequest('GET', `/referral/valid/${uuid}`);
  }

  async sendWorkoutFeedback(payload: SendFeedbackPayload) {
    return makeAuthenticatedPOSTRequest('/me/workout/feedback', payload);
  }

  async validateCoupon(code: string): Promise<PromoCodeType> {
    return makeCaptchaGETRequest(`/promocodes/${code}`);
  }

  async fetchActiveSubscription() {
    return makeAuthenticatedGETRequest('/me/subscription');
  }

  async fetchPurchasedItems() {
    return makeAuthenticatedGETRequest('/me/purchased-items');
  }

  async fetchActiveMasterCourseSubscription() {
    return makeAuthenticatedGETRequest('/me/master-subscription');
  }

  async cancelSubscription(payload: CancelStripeSubscriptionPayload) {
    return makeAuthenticatedPUTRequest('/me/subscription/cancel', { payload });
  }

  async getWorkoutsByTag(tags: Array<Tag>): Promise<Array<Workout>> {
    const tagIdList: string = tags.map((tag: Tag) => tag.id).join('&tagId=');
    const workouts: $ReadOnlyArray<ApiResponseWorkout> = await makeAuthenticatedGETRequest(`/tag/workouts/?tagId=${tagIdList}`);

    return workouts.map(makeWorkoutFromApiResponse);
  }

  async getProgramsByTag(tag: Tag): Promise<Array<Program>> {
    const programs = await makeAuthenticatedGETRequest(`/tag/programs?tagId=${tag.id}`);

    return programs.map(makeProgramFromApiResponse);
  }

  async isExistingEmail(email: string): Promise<any> {
    return makePOSTRequest('/user/existing', { email });
  }

  async fetchFreemiumWorkouts(): Promise<Array<ApiResponseWorkout>> {
    return makeAuthenticatedGETRequest('/workout/free');
  }

  async fetchRecommendedWorkouts(): Promise<ApiResponseWorkout[]> {
    return makeAuthenticatedGETRequest('/me/recommended-workouts');
  }

  async fetchWorkoutById(id: number): Promise<ApiResponseWorkout> {
    return makeAuthenticatedGETRequest(`/workout/${id}`);
  }

  async fetchWorkoutBySlug(slug: string): Promise<ApiResponseWorkout> {
    return makeAuthenticatedGETRequest(`/workout-by-slug/${slug}`);
  }

  async fetchRecommendedPlans(): Promise<Program[]> {
    return makeAuthenticatedGETRequest('/me/recommended-programs');
  }

  async fetchWorkouts(): Promise<Array<ApiResponseWorkout>> {
    return makeAuthenticatedGETRequest('/workout');
  }

  async fetchProgramWithWorkouts(programId: number): Promise<Plan> {
    const apiResponse: ApiResponsePlan = await makeAuthenticatedGETRequest(`/plan/${programId}`);
    return makeProgramWithWorkoutsFromApiResponse(apiResponse);
  }

  async fetchDefaultProducts(): Promise<Array<ApiResponseProduct>> {
    const externalIdQueryParam = DEFAULT_PRODUCTS_EXTERNAL_IDS.map((exId) => `&externalId=${exId}`).join('');
    const provider: SubscriptionProvider = 'STRIPE';

    return makeCaptchaGETRequest(`/products?provider=${provider}${externalIdQueryParam}`);
  }

  async fetchProductsByStripeIds(externalIds): Promise<Array<ApiResponseProduct>> {
    const externalIdQueryParam = externalIds.map((exId) => `&externalId=${exId}`).join('');
    const provider: SubscriptionProvider = 'STRIPE';
    return makeCaptchaGETRequest(`/products-by-stripe-ids?provider=${provider}${externalIdQueryParam}`);
  }

  async fetchProductsByPromoCode(code: string): Promise<Array<ApiResponseProduct>> {
    return makeCaptchaGETRequest(`/products?promoCode=${code}&provider=STRIPE&fill`);
  }

  async initSocialLogin(payload: InitSocialLoginInput): Promise<void> {
    return makePOSTRequest('/init-social-login', payload);
  }

  async createStripeSession(body: ApiPayloadStripeCreateSession): Promise<ApiResponseStripeCreateSession> {
    return makeAuthenticatedPOSTRequest('/stripe/create-stripe-session', body);
  }

  async fetchLastInvoices(): Promise<Array<ApiResponseStripeInvoice>> {
    return makeAuthenticatedGETRequest('/me/invoices');
  }

  async fetchPackages(): Promise<Array<ApiResponsePackages>> {
    const provider: SubscriptionProvider = 'STRIPE';
    return makeAuthenticatedGETRequest(`/products/packages?provider=${provider}`);
  }
}

export const apiService = new APIService();

export default APIService;
