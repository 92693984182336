// @flow

import React from 'react';
import _merge from 'lodash/merge';
import _spread from 'lodash/spread';
import _union from 'lodash/union';
import { css } from 'styled-components';
import hexToRgba from 'hex-to-rgba';
import TrackingButton from './TrackingButton';
import {
  BLACK_COLOR,
  CSS_BREAKPOINTS_MIN,
  DARK_GREY_COLOR,
  FACEBOOK_COLOR,
  GREY_04_COLOR,
  GUTTER_SIZE,
  MAIN_ORANGE_LIGHT_COLOR, MESSENGER_COLOR,
  PAPER_WHITE_COLOR,
  PRIMARY_COLOR,
  WHITE_COLOR,
} from '../config/constants';
import type { TrackingButtonProps } from '../types/TrackingButtonProps';

const BUTTON_HEIGHT = 50;
const BUTTON_HEIGHT_SMALL = 30;

const getDefaultStyles = (normal: boolean = true) => ({
  buttonStyle: css({
    display: 'inline-flex',
    height: normal ? BUTTON_HEIGHT : BUTTON_HEIGHT_SMALL,
    paddingLeft: normal ? GUTTER_SIZE * 2 : GUTTER_SIZE,
    paddingRight: normal ? GUTTER_SIZE * 2 : GUTTER_SIZE,
    justifyContent: 'center',
    alignItems: 'center',
    lineHeight: 1.67,
    cursor: 'pointer',
    color: WHITE_COLOR,
    textTransform: 'uppercase',
    whiteSpace: 'nowrap',
    outline: 'none',
    [`@media ${CSS_BREAKPOINTS_MIN.sm}`]: {
      minWidth: normal ? 150 : 'none',
      height: normal ? BUTTON_HEIGHT : BUTTON_HEIGHT_SMALL,
    },
  }),
  titleStyle: css({
    fontFamily: 'Poppins-Medium',
    fontSize: 13,
    letterSpacing: 3.25,
  }),
});

const typesMap = new Map([
  ['default', new Map([
    ['normal', {
      buttonStyle: css({
        border: 0,
        backgroundColor: PRIMARY_COLOR,
        '&:hover': {
          backgroundColor: MAIN_ORANGE_LIGHT_COLOR,
        },
      }),
      titleStyle: css({
        color: WHITE_COLOR,
      }),
    }],
    ['normal-disabled', {
      buttonStyle: css({
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: BUTTON_HEIGHT,
        paddingLeft: GUTTER_SIZE * 2,
        paddingRight: GUTTER_SIZE * 2,
        border: 0,
        backgroundColor: hexToRgba(PRIMARY_COLOR, 0.4),
        cursor: 'not-allowed',
      }),
      titleStyle: css({
        color: hexToRgba(WHITE_COLOR, 0.4),
      }),
    }],
    ['inverted', {
      buttonStyle: css({
        border: 0,
        backgroundColor: WHITE_COLOR,
        '&:hover': {
          backgroundColor: PAPER_WHITE_COLOR,
        },
      }),
      titleStyle: css({
        color: PRIMARY_COLOR,
      }),
    }],
    ['inverted-disabled', {
      buttonStyle: css({
        border: 0,
        backgroundColor: hexToRgba(WHITE_COLOR, 0.4),
        cursor: 'not-allowed',
        alignItems: 'center',
      }),
      titleStyle: css({
        color: hexToRgba(PRIMARY_COLOR, 0.4),
      }),
    }],
  ])],
  ['outline-noborder', new Map([
    ['normal', {
      buttonStyle: css({
        border: 0,
        backgroundColor: BLACK_COLOR,
        '&:hover': {
          backgroundColor: 'none',
        },
      }),
      titleStyle: css({
        color: 'rgba(255, 255, 255, 0.60)',
      }),
    }],
    ['normal-disabled', {
      buttonStyle: css({
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: BUTTON_HEIGHT,
        paddingLeft: GUTTER_SIZE * 2,
        paddingRight: GUTTER_SIZE * 2,
        border: 0,
        backgroundColor: BLACK_COLOR,
        cursor: 'not-allowed',
      }),
      titleStyle: css({
        color: 'rgba(255, 255, 255, 0.60)',
      }),
    }],
    ['inverted', {
      buttonStyle: css({
        border: 0,
        backgroundColor: WHITE_COLOR,
        '&:hover': {
          backgroundColor: PAPER_WHITE_COLOR,
        },
      }),
      titleStyle: css({
        color: PRIMARY_COLOR,
      }),
    }],
    ['inverted-disabled', {
      buttonStyle: css({
        border: 0,
        backgroundColor: hexToRgba(WHITE_COLOR, 0.4),
        cursor: 'not-allowed',
        alignItems: 'center',
      }),
      titleStyle: css({
        color: hexToRgba(PRIMARY_COLOR, 0.4),
      }),
    }],
  ])],
  ['grey', new Map([
    ['normal', {
      buttonStyle: css({
        backgroundColor: hexToRgba(DARK_GREY_COLOR, 0.4),
      }),
      titleStyle: css({
        color: PAPER_WHITE_COLOR,
      }),
    }],
    ['normal-disabled', {
      buttonStyle: css({
        display: 'inline-flex',
        justifyContent: 'center',
        backgroundColor: hexToRgba(DARK_GREY_COLOR, 0.2),
        cursor: 'not-allowed',
      }),
      titleStyle: css({
        color: hexToRgba(PAPER_WHITE_COLOR, 0.5),
      }),
    }],
    ['inverted', {
      buttonStyle: css({
        backgroundColor: PAPER_WHITE_COLOR,
      }),
      titleStyle: css({
        color: hexToRgba(DARK_GREY_COLOR, 0.4),
      }),
    }],
    ['inverted-disabled', {
      buttonStyle: css({
        backgroundColor: hexToRgba(PAPER_WHITE_COLOR, 0.5),
        cursor: 'not-allowed',
      }),
      titleStyle: css({
        color: hexToRgba(DARK_GREY_COLOR, 0.2),
      }),
    }],
  ])],
  ['outline', new Map([
    ['normal', {
      buttonStyle: css({
        borderTopWidth: 1,
        borderTopColor: PRIMARY_COLOR,
        borderLeftWidth: 1,
        borderLeftColor: PRIMARY_COLOR,
        borderRightWidth: 1,
        borderRightColor: PRIMARY_COLOR,
        borderBottomWidth: 1,
        borderBottomColor: PRIMARY_COLOR,
        backgroundColor: 'transparent',
        color: PRIMARY_COLOR,
        '&:hover': {
          backgroundColor: PRIMARY_COLOR,
          color: `${WHITE_COLOR} !important`,
        },
      }),
      titleStyle: css({
        color: 'inherit',
      }),
    }],
    ['normal-disabled', {
      buttonStyle: css({
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderTopWidth: 1,
        borderTopColor: hexToRgba(PRIMARY_COLOR, 0.4),
        borderLeftWidth: 1,
        borderLeftColor: hexToRgba(PRIMARY_COLOR, 0.4),
        borderRightWidth: 1,
        borderRightColor: hexToRgba(PRIMARY_COLOR, 0.4),
        borderBottomWidth: 1,
        borderBottomColor: hexToRgba(PRIMARY_COLOR, 0.4),
        backgroundColor: 'transparent',
        height: BUTTON_HEIGHT,
        cursor: 'not-allowed',
        [`@media ${CSS_BREAKPOINTS_MIN.sm}`]: {
          minWidth: 150,
        },
      }),
      titleStyle: css({
        color: hexToRgba(PRIMARY_COLOR, 0.4),
      }),
    }],
    ['inverted', {
      buttonStyle: css({
        borderTopWidth: 1,
        borderTopColor: WHITE_COLOR,
        borderLeftWidth: 1,
        borderLeftColor: WHITE_COLOR,
        borderRightWidth: 1,
        borderRightColor: WHITE_COLOR,
        borderBottomWidth: 1,
        borderBottomColor: WHITE_COLOR,
        backgroundColor: 'transparent',
        height: BUTTON_HEIGHT,
        [`@media ${CSS_BREAKPOINTS_MIN.sm}`]: {
          height: BUTTON_HEIGHT,
        },
      }),
      titleStyle: css({
        color: WHITE_COLOR,
      }),
    }],
    ['inverted-disabled', {
      buttonStyle: css({
        borderTopWidth: 1,
        borderTopColor: hexToRgba(WHITE_COLOR, 0.4),
        borderLeftWidth: 1,
        borderLeftColor: hexToRgba(WHITE_COLOR, 0.4),
        borderRightWidth: 1,
        borderRightColor: hexToRgba(WHITE_COLOR, 0.4),
        borderBottomWidth: 1,
        borderBottomColor: hexToRgba(WHITE_COLOR, 0.4),
        backgroundColor: 'transparent',
        height: BUTTON_HEIGHT,
        [`@media ${CSS_BREAKPOINTS_MIN.sm}`]: {
          height: BUTTON_HEIGHT,
        },
        cursor: 'not-allowed',
      }),
      titleStyle: css({
        color: WHITE_COLOR,
      }),
    }],
  ])],
  ['white', new Map([
    ['normal', {
      buttonStyle: css({
        backgroundColor: WHITE_COLOR,
        border: 0,
        '&:hover': {
          backgroundColor: hexToRgba(WHITE_COLOR, 0.9),
        },
      }),
      titleStyle: css({
        color: PRIMARY_COLOR,
      }),
    }],
    ['normal-disabled', {
      buttonStyle: css({}),
      titleStyle: css({}),
    }],

    ['inverted', {
      buttonStyle: css({}),
      titleStyle: css({}),
    }],

    ['inverted-disabled', {
      buttonStyle: css({}),
      titleStyle: css({}),
    }],
  ])],
  ['level-3', new Map([
    ['normal', {
      buttonStyle: css({
        border: 0,
        backgroundColor: 'transparent',
        textTransform: 'none',
      }),
      titleStyle: css({
        color: PRIMARY_COLOR,
        fontSize: 14,
        letterSpacing: 1.12,
        lineHeight: 1.43,
      }),
    }],
    ['normal-disabled', {
      buttonStyle: css({}),
      titleStyle: css({}),
    }],
    ['inverted', {
      buttonStyle: css({}),
      titleStyle: css({}),
    }],
    ['inverted-disabled', {
      buttonStyle: css({}),
      titleStyle: css({}),
    }],
  ])],
  ['facebook', new Map([
    ['normal', {
      buttonStyle: css({
        border: 0,
        backgroundColor: FACEBOOK_COLOR,
        '&:hover': {
          backgroundColor: hexToRgba(FACEBOOK_COLOR, 0.8),
        },
      }),
      titleStyle: css({
        fontFamily: ['Helvetica', 'Poppins', 'sans-serif'],
        fontWeight: 'bold',
        color: WHITE_COLOR,
        textTransform: 'none',
        letterSpacing: 'normal',
      }),
    }],
    ['normal-disabled', {
      buttonStyle: css({}),
      titleStyle: css({}),
    }],
    ['inverted', {
      buttonStyle: css({}),
      titleStyle: css({}),
    }],
    ['inverted-disabled', {
      buttonStyle: css({}),
      titleStyle: css({}),
    }],
  ])],
  ['messenger', new Map([
    ['normal', {
      buttonStyle: css({
        border: 0,
        backgroundColor: MESSENGER_COLOR,
        '&:hover': {
          backgroundColor: hexToRgba(MESSENGER_COLOR, 0.8),
        },
      }),
      titleStyle: css({
        fontFamily: ['Helvetica', 'Poppins', 'sans-serif'],
        fontWeight: 'bold',
        color: WHITE_COLOR,
        textTransform: 'none',
        letterSpacing: 'normal',
      }),
    }],
    ['normal-disabled', {
      buttonStyle: css({}),
      titleStyle: css({}),
    }],
    ['inverted', {
      buttonStyle: css({}),
      titleStyle: css({}),
    }],
    ['inverted-disabled', {
      buttonStyle: css({}),
      titleStyle: css({}),
    }],
  ])],
]);

// eslint-disable-next-line react/prefer-stateless-function
class SYPrimaryButton extends React.Component<TrackingButtonProps & {
  inverted?: boolean,
  disabled?: boolean,
  type?: 'default' | 'outline' | 'grey' | 'white' | 'level-3' | 'facebook' | 'messenger' | 'outline-noborder',
  icon?: React$Element<*>,
  startIcon?: React$Element<*>,
}> {
  static defaultProps = {
    inverted: false,
    type: 'default',
    small: false,
    startIcon: undefined,
    icon: undefined,
  };

  render() {
    const {
      inverted, type, buttonStyle, titleStyle, disabled, title, small, ...rest
    } = this.props;
    const buttonStyles = typesMap.get(type);

    if (!buttonStyles) {
      throw new Error(`No button styles defined for the type "${type || 'undefined'}"!`);
    }

    const defaultStyles = getDefaultStyles(!small);
    const buttonStylesType = buttonStyles.get(inverted ? 'inverted' : 'normal');

    const styles = {
      // $FlowFixMe
      buttonStyle: _spread(_union)([defaultStyles.buttonStyle, buttonStylesType.buttonStyle]),
      // $FlowFixMe
      titleStyle: _spread(_union)([defaultStyles.titleStyle, buttonStylesType.titleStyle]),
    };
    const disabledStyles = _merge(getDefaultStyles(!small), buttonStyles.get(inverted ? 'inverted-disabled' : 'normal-disabled'));

    if (titleStyle) {
      _merge(styles, { titleStyle });
    }

    if (buttonStyle) {
      _merge(styles, { buttonStyle });
    }

    return (
      <TrackingButton
        {...rest}
        title={title}
        disabled={disabled}
        buttonStyle={styles.buttonStyle}
        disabledStyle={disabledStyles.buttonStyle}
        disabledTitleStyle={disabledStyles.titleStyle}
        titleStyle={styles.titleStyle}
      />
    );
  }
}

export default SYPrimaryButton;
