// @flow

import styled from 'styled-components';

import SYBody18pt from './SYBody18pt';

const SYBody18ptMedium = styled(SYBody18pt)`
  font-family: 'Poppins-Medium';
`;

export default SYBody18ptMedium;
