// @flow
import _toLower from 'lodash/toLower';
import { Auth } from './Amplify';
import { apiService } from './APIService';
import type { SignUpParams } from '../types/SignUpParams';
import type { User } from '../types/User';
import type { SocialLoginType } from '../types/SocialLoginType';

export type SocialSignInParams = {|
  type: SocialLoginType,
  email: string,
  identityToken: string,
|};

class AuthService {
  async fetchMe(): Promise<User> {
    return apiService.fetchMe();
  }

  async isAuthenticated(): Promise<boolean> {
    try {
      await Auth.currentAuthenticatedUser({
        // Optional, by default is false.
        // If set to true, this call will send a request to
        // Cognito to get the latest user data
        bypassCache: false,
      });

      return true;
    } catch (e) {
      // not authenticated
    }

    return false;
  }

  async signUp(params: SignUpParams) {
    const email = _toLower(params.username);

    await Auth.signUp({
      username: email,
      password: params.password,
      attributes: {
        email,
        'custom:meta_data_1': JSON.stringify(params.metaData),
        'custom:meta_data_2': JSON.stringify(''),
      },
    });
  }

  async signIn(username: string, password?: string, customChallengeAnswer?: string): Promise<User> {
    Auth.configure({
      authenticationFlowType: password ? 'USER_PASSWORD_AUTH' : 'CUSTOM_AUTH',
    });

    const user = await Auth.signIn(username, password);

    if (user.challengeName === 'CUSTOM_CHALLENGE') {
      await Auth.sendCustomChallengeAnswer(user, customChallengeAnswer);
    }

    return this.fetchMe();
  }

  async socialSignIn({
    type,
    email,
    identityToken,
  }: SocialSignInParams) {
    await apiService.initSocialLogin({
      type,
      email,
    });

    return this.signIn(email, undefined, JSON.stringify({ identityToken }));
  }

  async signOut() {
    await Auth.signOut();
  }
}

export const authService = new AuthService();

export default AuthService;
