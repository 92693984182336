// @flow
import axios from 'axios';
import Cookies from 'js-cookie';

import moment from 'moment-timezone';
import { LOCALSTORAGE_KEYS } from '../localStorageKeys';
import { logger } from './Logger';
import { DEFAULT_LANGUAGE_TAG } from '../config/constants';

type ExchangeRate = { [string]: number };
type ApiResponseExchange = {|
  rates: ExchangeRate,
  base: string,
  date: string,
  success?: boolean
|};

const CURRENCY_EXCHANGE_ENDPOINT = 'http://api.exchangerate.host/live?access_key=87fba59d4fc915aa2c55468dcdc674f4&currencies=EUR,GBP';
// Increment to flush local cache of the user when updating the endpoint or payload
const DEFAULT_DATA: ApiResponseExchange = {
  'rates': {
    'EUR': 0.9165902841,
    'USD': 1.0,
    'GBP': 0.8204857929,
  },
  'base': 'USD',
  'date': '2020-05-25',
};

const languageTagToCurrency = new Map([
  ['de-DE', 'EUR'], // Germany
  ['en-US', 'USD'], // English (United States)
  ['en-GB', 'GBP'], // English (United Kingdom)
]);

const defaultCurrency = 'USD';

class CurrencyConverter {
  currency: string = defaultCurrency;

  currencyInfo: ApiResponseExchange = {
    ...DEFAULT_DATA,
  };

  languageTag: string = DEFAULT_LANGUAGE_TAG;

  setLatestCurrencyInfo = (data: ApiResponseExchange) => {
    let currencyInfo = {
      'rates': {
        'EUR': 0.9165902841,
        'USD': 1.0,
        'GBP': 0.8204857929,
      },
      'base': 'USD',
      'date': '2020-05-25',
    }
    if (data.quotes) {
      currencyInfo.rates = {
        'EUR': data.quotes['USDEUR'],
        'USD': 1.0,
        'GBP': data.quotes['USDGBP'],
      }
    }

    this.currencyInfo = currencyInfo;
    Cookies.set(LOCALSTORAGE_KEYS.currencyData, currencyInfo, { expires: moment().add(2, 'hour').toDate() });
  };

  init = async (languageTag: string) => {
    const latestCurrencyInfo: ApiResponseExchange = Cookies.getJSON(LOCALSTORAGE_KEYS.currencyData);

    this.locale = languageTag;

    if (latestCurrencyInfo && latestCurrencyInfo.success) {
      this.currencyInfo = latestCurrencyInfo;
      return;
    }

    await this.fetchLatestCurrencyInfo();
  };

  set locale(languageTag: string) {
    this.currency = languageTagToCurrency.get(languageTag) || defaultCurrency;
    this.languageTag = languageTag;
    Cookies.set(LOCALSTORAGE_KEYS.languageCode, languageTag);
  }

  get locale(): string {
    return this.languageTag;
  }

  getFormatted = (price: number, languageTag?: string, inputCurrency?: string): string => {
    const currency = (inputCurrency && inputCurrency.toUpperCase()) || languageTagToCurrency.get(languageTag) || this.currency;
    console.log({ currency });
    const locale = languageTag || this.languageTag;
    const rate = this.currencyInfo?.rates[currency];
    const cleanPrice = parseInt(price, 10);

    return new Intl.NumberFormat(locale, {
      style: 'currency',
      currency,
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    })
      .format((cleanPrice * rate) / 100)
      .replace(/\s/g, '');
  };

  fetchLatestCurrencyInfo = async () => {
    try {
      const { data } = await axios.get(CURRENCY_EXCHANGE_ENDPOINT);
      this.setLatestCurrencyInfo(data);
    } catch (error) {
      logger.error(error, [{
        fileName: 'CurrencyConverter.js',
        className: 'CurrencyConverter',
        functionName: 'fetchLatestCurrencyInfo()',
      }]);

      this.setLatestCurrencyInfo(DEFAULT_DATA);
    }
  };
}

export const currencyConverter = new CurrencyConverter();
export default CurrencyConverter;
