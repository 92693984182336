import _cloneDeep from "lodash/cloneDeep";
import _findIndex from "lodash/findIndex";
import * as types from "../actions/types";
import Workout from "../../modules/Workout";
import convertBitToBoolean from "../../modules/convertBitToBoolean";
import UserUtils from "../../utils/user.utils";

function updateWorkoutList(oldList, workoutUpdates) {
  return oldList.map((workout) => {
    if (workout.id === workoutUpdates.workoutId) {
      const updatedWorkout = new Workout(workout.toJSON());

      updatedWorkout.started =
        convertBitToBoolean(workoutUpdates.started) || workout.started;
      updatedWorkout.done =
        convertBitToBoolean(workoutUpdates.done) || workout.done;
      updatedWorkout.historyWorkoutId =
        workoutUpdates.historyWorkoutId || workout.historyWorkoutId;
      updatedWorkout.historyPlanId =
        workoutUpdates.historyPlanId || workout.historyPlanId;

      return updatedWorkout;
    }

    return workout;
  });
}

export default function planReducer(state = {}, action) {
  switch (action.type) {
    case types.ACTIVATE_PLAN_SUCCESS:
      return { ...state };

    case types.LOAD_ACTIVE_PLAN_SUCCESS:
      console.log("action.payload: ", action.payload);
      return action.payload;

    case types.PLAN_STATUS_UPDATE_SUCCESS:
      return { ...action.payload };

    case types.PLAN_WORKOUT_STATUS_UPDATE_SUCCESS: {
      const newState = _cloneDeep(state);
      newState.workouts = updateWorkoutList(state.workouts, action.payload);

      return newState;
    }

    case types.MARK_WORKOUT_DONE_SUCCESS: {
      const newState = _cloneDeep(state);
      const { workoutId } = action.payload;
      const { workouts: planWorkouts } = state;
      const index = _findIndex(planWorkouts, { id: workoutId });

      // if workout is part of the active plan
      if (index > -1) {
        newState.workouts[index].done = true;
        // newState.nextWorkout = UserUtils.getNextWorkoutByWorkouts(newState.workouts);
      }

      return newState;
    }

    case types.SET_NEXT_WORKOUT: {
      const newState = _cloneDeep(state);
      const { workoutId } = action.payload;
      const { workouts: planWorkouts } = state;
      const index = _findIndex(planWorkouts, { id: workoutId });

      // if workout is part of the active plan
      if (index > -1) {
        newState.nextWorkout = UserUtils.getNextWorkoutByWorkouts(
          newState.workouts
        );
      }
      console.log({ newState });
      return newState;
    }

    default:
      return state;
  }
}
