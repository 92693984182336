// @flow

import type { FreemiumState } from '../../types/FreemiumState';
import {
  FETCH_FREEMIUM_WORKOUTS_ACTION,
  FETCH_FREEMIUM_WORKOUTS_SUCCESS,
  FETCH_FREEMIUM_WORKOUTS_FAILED,
} from '../actions/types';
import makeWorkoutFromResponse from '../../modules/makeWorkoutFromApiResponse';

export const makeDefaultState = (): FreemiumState => ({
  workouts: [],
  isFetching: false,
});

export default function data(state: FreemiumState = makeDefaultState(), action: Object): FreemiumState {
  const { type, payload } = action;

  const makeNewState = (params: any = {}): FreemiumState => ({
    ...state,
    ...params,
  });

  switch (type) {
    case FETCH_FREEMIUM_WORKOUTS_ACTION: {
      return makeNewState({
        isFetching: true,
      });
    }

    case FETCH_FREEMIUM_WORKOUTS_SUCCESS: {
      const workouts = payload.map(makeWorkoutFromResponse);

      return makeNewState({
        isFetching: false,
        workouts,
      });
    }

    case FETCH_FREEMIUM_WORKOUTS_FAILED: {
      return makeNewState({
        isFetching: false,
      });
    }

    default:
      return state;
  }
}
