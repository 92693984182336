import styled from 'styled-components';
import { CLOUDFRONT_STATIC, CSS_BREAKPOINTS_MIN, GUTTER_SIZE } from '../config/constants';
import AspectRatio from './AspectRatio';
import { TitleElement } from './text/CohortLp/CohortLpTextElements';
import InstagramSvg from '../assets/svg/instagram-logo.svg';
import FacebookSvg from '../assets/svg/facebook-logo.svg';
import YouTubeSvg from '../assets/svg/youtube-logo.svg';
import SocialLink from './SocialLink';
import makeImageUrl from '../modules/makeImageUrl';

const Container = styled.div`
  padding: ${GUTTER_SIZE * 2}px 0;

  @media ${CSS_BREAKPOINTS_MIN.sm} {
    padding: ${GUTTER_SIZE * 2.5}px 0 ${GUTTER_SIZE * 3.5}px;
  }
`;

const Content = styled.div`
  @media ${CSS_BREAKPOINTS_MIN.sm} {
    display: flex;
    flex-direction: row;
  }
`;

const ImageWrapper = styled.div`
  margin: 0 0 ${GUTTER_SIZE * 2}px;

  @media ${CSS_BREAKPOINTS_MIN.sm} {
    flex: 0 0 40%;
    margin: 0;
    padding-right: 5%;
  }
`;

const TrainerName = styled.h4`
  font-size: 20px;
  line-height: 25px;
  letter-spacing: 0.4px;

  @media ${CSS_BREAKPOINTS_MIN.sm} {
    font-size: 30px;
    line-height: 44px;
    letter-spacing: 0.75px;
  }
`;

const ImageElement = styled(AspectRatio)`
  background: url(${makeImageUrl({ url: `${CLOUDFRONT_STATIC}/img/pages/cohort-lp/traininer-image-1-min.jpg` })}) no-repeat center / cover;
`;

const SocialLinks = styled.div`
  ${SocialLink} {
    &:first-child {
      padding-left: 0;
    }
  }

  @media ${CSS_BREAKPOINTS_MIN.sm} {
    margin-bottom: ${GUTTER_SIZE}px;
  }
`;

const SocialImg = styled.img`
  height: 21px;

  @media ${CSS_BREAKPOINTS_MIN.sm} {
    height: 31px;
  }
`;

const Text = styled.p`
  margin: 0;
  font-family: Poppins-Light, sans-serif;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: 0.3px;

  &:last-child {
    margin: 0;
  }

  @media ${CSS_BREAKPOINTS_MIN.sm} {
    font-size: 16px;
    line-height: 22px;
  }
`;

const CohortMeetTheCoach = () => (
  <Container>
    <TitleElement>Meet Your Coach</TitleElement>

    <Content>
      <ImageWrapper>
        <ImageElement ratio={1} />
      </ImageWrapper>

      <div>
        <TrainerName>Anastasis Tzanis</TrainerName>

        <SocialLinks>
          <SocialLink href="//www.instagram.com/anastasis.tzanis/" title="Link to Instagram" target="_blank" rel="noreferrer">
            <SocialImg src={InstagramSvg} alt="Instagram Account" />
          </SocialLink>
          <SocialLink href="//www.facebook.com/Anastasis.Yoga" title="Link to Facebook" target="_blank" rel="noreferrer">
            <SocialImg src={FacebookSvg} alt="Facebook Account" />
          </SocialLink>
          <SocialLink href="//www.youtube.com/channel/UCnXgl0uZWsHjo43-QJ6Fz-A" title="Link to YouTube channel" target="_blank" rel="noreferrer">
            <SocialImg src={YouTubeSvg} alt="YouTube Account" />
          </SocialLink>
        </SocialLinks>

        <Text>
          After servicing the Greek Special Forces as a paratrooper for 1 year, trading currency derivatives in New York and then London for 7 years,
          and picking up a couple of other dopamine-driven hobbies along the way; in 2009 I began studying yoga & nutritional therapy.
          Little did I know then that this will be the start of my future profession. At the time I was feeling stiff like a log and struggled
          with IBS. Fast forward 3 years, in January 2012 I launched a nutritional clinic supporting patients with autoimmune conditions and cancer,
          primarily through the adoption of fasting diets, and taught yoga mostly to students like my old self.
        </Text>
        <Text>
          During my time in the army and banking, I noticed how detrimental, operating long-term in a highly competitive environment can be.
          Stress is not a problem provided you know how to cope with it.
        </Text>
        <Text>
          “Individuals in competitive environments run a high risk of burnout. A modern, personalized approach to yoga and breathing,
          will help you be mentally resilient and physically flexible.”
          My mission is to teach people how to connect their minds and body on a practical level.
        </Text>
      </div>
    </Content>
  </Container>
);

export default CohortMeetTheCoach;
