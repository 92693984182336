// @flow

export const COMPLETED_REGISTRATION_EVENT = 'CompletedRegistration';
export const FIRST_TRAINING_COMPLETED_EVENT = 'FirstTrainingCompleted';
export const INVITE_INITIATED_EVENT = 'InviteInitiated';
export const PROGRAM_COMPLETED_EVENT = 'ProgramCompleted';
export const PROGRAM_SWITCHED_EVENT = 'ProgramSwitched';
export const SUBSCRIPTION_PURCHASE_FAILED_EVENT = 'SubscriptionPurchaseFailed';
export const SUBSCRIPTION_PURCHASE_INITIATED_EVENT = 'SubscriptionPurchaseInitiated';
export const SUBSCRIPTION_PURCHASE_SUCCESS_EVENT = 'SubscriptionPurchaseSuccess';
export const WORKOUT_DONE_EVENT = 'WorkoutDone';
export const WORKOUT_STARTED_EVENT = 'WorkoutStarted';
