// @flow

import { themeSkillYoga } from '../themeSkillYoga';

const reactSlickStyles = Object.freeze({
  '.slick-slider': {
    '& .slick-dots': {
      bottom: themeSkillYoga.rem(-40),

      '& li': {
        margin: 0,
      },

      '& button': {
        width: themeSkillYoga.rem(9),
        height: themeSkillYoga.rem(9),
        padding: 0,
        backgroundColor: themeSkillYoga.colors.WHITE_COLOR,
        opacity: 0.2,
        borderRadius: '50%',
        border: 0,
      },

      '& .slick-active': {
        '& button': {
          opacity: 1,

          '&:before': {
            content: 'none',
          },
        },
      },
    },

    '& .slick-list': {
      overflow: 'hidden',
      'touch-action': 'pan-y',
    },

    '& .slick-track': {
      display: 'flex',
      'touch-action': 'pan-y',
    },
  },
});

export default reactSlickStyles;
