// @flow

import { css } from 'styled-components';
import hexToRgba from 'hex-to-rgba';

import { BLACK_COLOR, CSS_BREAKPOINTS_MIN, SPACING_BASE } from '../../config/constants';

const SideBarPropsCSS = css`
  /* Individual item */
  .bm-item {
    display: inline-block;
  
    /* Our sidebar item styling */
    text-decoration: none;
    margin-bottom: 10px;
    color: #d1d1d1;
    transition: color 0.2s;
  }
  
  /* Position and sizing of burger button */
  .bm-burger-button {
    display: none;
  }
  
  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    display: none;
  }
  
  /* Color/shape of close button cross */
  .bm-cross {
    background: #bdc3c7;
  }
  
  /* General sidebar styles */
  .bm-menu {
    background: ${hexToRgba(BLACK_COLOR, 0.85)};
    padding: ${SPACING_BASE * 8}px ${SPACING_BASE * 2}px ${SPACING_BASE * 2}px;
    font-size: 1.15em;
    
    @media ${CSS_BREAKPOINTS_MIN.sm} {
      padding: ${SPACING_BASE * 6}px ${SPACING_BASE * 4}px;
    }
  }
  
  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
    fill: #373a47;
  }
  
  /* Wrapper for item list */
  .bm-item-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    
    @media ${CSS_BREAKPOINTS_MIN.sm} {
      align-items: flex-end;
    }
  }
  
  /* Styling of overlay */
  .bm-overlay {
    background: rgba(0, 0, 0, 0.3);
  }
  
  .bm-menu-wrap {
    width: 100% !important;
    
    @media ${CSS_BREAKPOINTS_MIN.xs} {
      width: 400px !important;
    }
  }
`;

export default SideBarPropsCSS;
