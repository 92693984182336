// @flow

import styled from 'styled-components';
import { CSS_BREAKPOINTS_MIN, WHITE_COLOR } from '../config/constants';

const SYTag13ptExtended = styled.p`
  font-size: 11px;
  line-height: 1.36;
  letter-spacing: 1.65px;
  color: ${(props) => (props.color ? props.color : WHITE_COLOR)};
  
  @media ${CSS_BREAKPOINTS_MIN.sm} {
    font-size: 13px;
    line-height: 1.15;
    letter-spacing: 1.95px;
  }
`;

export default SYTag13ptExtended;
