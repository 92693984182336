// @flow
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

import { PRIMARY_COLOR, TRANSITION_DEFAULT_LEN, WHITE_COLOR } from '../config/constants';

const NavigationLink = styled(NavLink)`
  color: ${WHITE_COLOR};
  text-decoration: none;
  outline: none;
  transition: color ${TRANSITION_DEFAULT_LEN}s;

  &:hover {
    color: ${PRIMARY_COLOR};
  }
`;

export default NavigationLink;
