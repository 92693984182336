import React from 'react';
import styled from 'styled-components';

const CookieText = styled.p`
  font-size: 10px;
`;

const DataPrivacyCookieText = () => (
  <>
    <CookieText>
      We use cookies to ensure to give you the best experience on our website. If you continue we will assume that you
      agree to our privacy policy.&nbsp;
    </CookieText>

    <a
      href="//skill-yoga.com/pages/privacy-policy"
      className="sy-cookie__link"
      title="Cookie Bestimmungen"
      rel="noopener noreferrer"
      target="_blank"
      style={{
        fontSize: 10,
      }}
    >
      Learn more
    </a>
  </>
);

export default DataPrivacyCookieText;
