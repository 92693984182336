import {
    useAllPrismicDocumentsByType
} from "@prismicio/react";
import { BANNER } from "../config/constants";

export function withDocumentBanner(Component) {
  return function WrappedComponent(props) {
    const [dataBanner] = useAllPrismicDocumentsByType(BANNER);
    console.log(`dataBanner: ${dataBanner}`);
    
    return <Component {...props} dataBanner={dataBanner?.[0]?.data} />;
  };
}
